import { gql } from 'apollo-angular';

import * as MSpecialization from './specialization';

export const specializationQuery = gql`
  query allSpecializationQuery {
    allSpecializations {
      ...specializationConnectionFragment
    }
  }
  ${MSpecialization.fragmentConnection}
`;
