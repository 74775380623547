export const frenchLangData = {
  MEDICAL_INFO_SYSTEM: "Système d'information médicale",
  ACTIVE_EVENTS: 'Événements actifs',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'Sélectionnez un événement pour parcourir les utilisateurs.',
  BACK_TO_THE_EVENT_SELECTION: "Retour à la sélection d'événements",
  IF_YOU_FORGOT_YOUR_PASSWORD: 'Si vous avez oublié votre mot de passe',
  LOGIN: "S'identifier",
  RESET_IT_HERE: 'réinitialiser ici',
  CHANGE_LANGUAGE_TO: 'Changer la langue en',
  TEST_SYSTEM: "SYSTÈME D'ESSAI",
  HELP: 'Aidez-moi',
  THIS_IS_TEST_ENV:
    'Ceci est un environnement de test, si vous voulez insérer une véritable enquête, allez à',
  USER_OR_PASS_IS_INCORRECT: "L'identifiant ou le mot de passe est incorrect",
  OPS: 'Ops',
  WE_DID_NOT_FIND: "Nous n'avons pas trouvé ce que vous cherchiez",
  VIDEO_TUTORIALS: 'Tutoriels vidéos',
  STILL_DIDNT_FIND: "Vous n'avez toujours pas trouvé ce que vous cherchiez,",
  ASK_THE_SUPPORT_TEAM: "Demandez à l'équipe d'assistance",
  LOGGED_IN_AS: 'connecté en tant que',
  MY_PROFILE: 'Mon profil',
  LOGOUT: 'Se déconnecter',
  RETRIEVING_YOUR_ACCESS_INFO: "Récupérer vos informations d'accès",
  LOGGED_IN_CAN_CLOSE: 'Connecté, vous pouvez maintenant fermer cet onglet',
  TOKEN_HAS_NOT_BEEN_SAVED:
    "Le jeton n'a pas été enregistré correctement, veuillez contacter le support",
  SHARE_POINT_LOGIN: 'Connexion SharePoint',
  LOGIN_TO_SHARE_POINT: 'Veuillez vous connecter à SharePoint pour continuer',
  PASSWORD_RESET: 'Réinitialisation du mot de passe',
  RESET_PASSWORD: 'réinitialiser le mot de passe',
  INSERT_YOUR_EMAIL_TO_RESET_PASS:
    'Veuillez insérer votre email pour réinitialiser votre mot de passe',
  CANCEL: 'Annuler',
  CANCEL_UPPERCASE: 'ANNULER',
  RETURN_TO_LOGIN: 'Retour à la connexion',
  USERNAME: "Nom d'utilisateur",
  USERNAME_IS_REQUIRED: "Nom d'utilisateur est nécessaire",
  PASSWORD: 'Mot de passe',
  PASSWORD_IS_REQUIRED: 'Mot de passe requis',
  FIRST_NAME: 'Prénom',
  LAST_NAME: 'Nom de famille',
  REGISTER: "S'inscrire",
  LOADING: 'Chargement',
  INSERT_NEW_PASS: 'Veuillez insérer votre nouveau mot de passe',
  VERIFYING_TOKEN: 'Vérification du jeton',
  PASSWORD_DO_NOT_MATCH: 'Les mots de passe ne correspondent pas',
  PASS_RESET_SUCCESSFUL: 'Réinitialisation du mot de passe réussie',
  SAVE_NEW_PASS: 'Enregistrer le nouveau mot de passe',
  GO_TO_INBOX: 'Accéder à la boîte de réception',
  SELECT_ALL: 'Tout sélectionner',
  DESELECT_ALL: 'Tout déselectionner',
  DO_NOT_SHOW_AGAIN: 'Ne pas montrer de nouveau',
  INQUIRY: 'Enquête',
  ATTACHMENT_WITH_THIS_INQUIRY: '> Pièces jointes à cette enquête',
  USER_DETAILS: "Détails de l'utilisateur",
  CLOSE: 'proche',
  TEAMS: 'Équipes',
  TEAM: 'Équipe',
  SPECIALIZATION_IS: 'La spécialisation est',
  REQUIRED: 'obligatoire',
  RESULTS_PER_PAGE: 'résultats par page',
  RESULTS_FOUND: 'Résultats trouvés',
  EXPORT: 'Exportation',
  PERSONAL_SETTINGS: 'Paramètres personnels',
  TEAM_SETTINGS: "Paramètres d'équipe",
  USER_SETTINGS_OF: 'PARAMÈTRES UTILISATEUR DE',
  SET_TEMPLATE: 'Définir le modèle',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: 'Les notifications seront remplacées par celles en cours',
  PREVIEW: 'Aperçu',
  NOTIFICATIONS: 'NOTIFICATIONS',
  ROLE: 'RÔLE',
  TEMPLATE_NAME: 'NOM DU MODÈLE',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'Enregistrer cet utilisateur en tant que modèle',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'Ajoutez un modèle à votre utilisateur et copiez le rôle et les préférences pour les notifications',
  AUTH_AND_NOTIFY_TEMPLATE: "Modèle d'autorisation et de notification",
  MY_ROLES: 'Mes rôles',
  STATUS: 'STATUT',
  THIS_ROLE_IS_TEMP:
    'Ce rôle est temporaire, il a été donné en raison du fait que cet utilisateur est un adjoint',
  CREATED: 'CRÉÉ',
  APPROVED_REJECTED: 'APPROUVÉ / REJETÉ',
  APPROVED_REJECTED_BY: 'APPROUVÉ / REJETÉ PAR',
  ARCHIVE: 'ARCHIVER',
  SAVE: 'sauvegarder',
  IMMEDIATE_EFFECT: 'Effet immédiat',
  OUT_OF_OFFICE_DEPUTY: 'ADJOINT HORS BUREAU',
  AUTO_ASSIGN_TO: 'CET EXCELLENT',
  GEOGRAPHY: 'GÉOGRAPHIE',
  ALIGNMENT: 'ALIGNEMENT',
  Alignments: 'Alignements',
  Address: 'Adresse',
  PHONE_FAX: 'Téléphone / Fax',
  Email: 'Email',
  Contacts: 'Contacts',
  WORK_INFO: 'Information sur le travail',
  LAST_NAME_IS: 'Nom de famille est',
  PERSONAL_INFO: 'Renseignements personnels',
  USER: 'Utilisatrice',
  ID: 'ID',
  UPDATED_FROM: 'mis à jour depuis',
  ON: 'sur',
  CRM: 'CRM',
  IMPORTED_FROM: 'importé de',
  CHANGE_PASS: 'Changer le mot de passe',
  NOTIFICATION_IN_TEMPLATE: 'Notifications dans le modèle',
  CONTINUE: 'Continuer',
  DONT_SHOW_AGAIN: 'Ne plus afficher ce message',
  USING_THE_TABS_ON_THE: "en utilisant les onglets en haut de l'écran",
  PERSONAL_PROFILE_SETTINGS: 'Paramètres de profil personnel',
  AND_YOUR: 'et ton',
  YOU_CAN_SWITCH_BETWEEN: 'Vous pouvez basculer entre vos',
  WELCOME_TO_YOUR_USER_PROFILE: 'Bienvenue sur votre profil utilisateur',
  SAVE_THIS_USER_AS: 'Enregistrer cet utilisateur en tant que modèle',
  INCLUDE_NOTIFY_SETTINGS: 'Comprend les paramètres de notification',
  INCLUDE_ROLES_SETTINGS: 'Comprend les paramètres des rôles',
  ACTIVE_NOTIFICATIONS: 'NOTIFICATIONS ACTIVES',
  SET_THIS_USER_AS_A_:
    'Définissez cette autorisation utilisateur et ces notifications comme nouveau modèle',
  REQUEST_AUTH: 'Demander une autorisation',
  REQUEST: 'Demande',
  FEEDBACK_SUPPORT: 'Commentaires et assistance',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    "Si vous ne trouvez toujours pas ce que vous cherchez, demandez à l'équipe d'assistance! Aussi, si vous avez une suggestion d'améliorations futures, c'est le bon endroit.",
  TEMPLATE: 'Modèle',
  SIGNATURE: 'Signatures',
  Disclaimers: 'Avertissements',
  Greetings: 'Salutations',
  CUSTOMIZE: 'PERSONNALISER',
  SAVE_UPPERCASE: 'ENREGISTRER',
  DELETE: 'SUPPRIMER',
  RESET_TO_DEFAULT: 'RÉINITIALISER PAR DÉFAUT',
  AFTER_SIGNATURE: 'Après signature',
  BEFORE_SIGNATURE: 'Avant la signature',
  AFTER_ANSWER: 'Après la réponse',
  BEFORE_ANSWER: 'Avant réponse',
  AFTER_GREET: 'Après les salutations',
  BEFORE_GREET: 'Avant les salutations',
  Any: 'Toute',
  HCP: 'HCP',
  NO_HCP: 'Pas de HCP',
  Position: 'Position',
  ON_LABEL: 'On label',
  OFF_LABEL: 'Off label',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'Vous essayez de personnaliser le modèle',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    "Vous n'êtes pas autorisé à le faire. Si vous souhaitez continuer, une copie du modèle sera créée et vous y apporterez les modifications.",
  CREATE_COPY: 'Créer une copie',
  RECEIVED_ON: 'Reçu le',
  SENDER_EMAIL: "Envoi d'un e-mail",
  SUBJECT: 'Matière',
  UNSPAM: 'Unspam',
  UPLOAD: 'Télécharger',
  APPROVED: 'Approuvée',
  DRAFT: 'Brouillon',
  SELECT_STATUS: 'Sélectionnez le statut',
  UPLOAD_AS: 'Télécharger en tant que',
  TITLE: 'Titre',
  REPOSITORY: 'Dépôt',
  BROWSE: 'Feuilleter',
  URL: 'URL',
  APPROVED_ON: 'Approuvé le',
  AUTH_HOLDER: "Titulaire de l'autorisation",
  AUTH_NUMBERS: "Numéros d'autorisation",
  APPROVED_FOR: 'Approuvé pour',
  SELECT_ONE_REGION: 'Sélectionnez une région',
  OWNED_BY: 'Appartient à',
  KEYWORDS: 'Mots clés',
  TOPICS: 'Les sujets',
  CATEGORIES: 'Les catégories',
  PRODUCTS: 'Des produits',
  PRODUCT: 'Produit',
  LANGUAGE_IS_REQUIRED: 'La langue est requise',
  LANGUAGE: 'Langue',
  TARGET_CUSTOMERS: 'Clients cibles',
  TYPE_IS_REQUIRED: 'Le type est requis',
  TYPE: 'Type',
  TITLE_IS_REQUIRED: 'Le titre est requis',
  DEFAULT_REFERENCE_IS_CREATED_:
    'La référence par défaut est créée avec le style Vancouver. Si vous souhaitez remplacer cela, entrez la référence entière ici',
  REFERENCE: 'Référence',
  DOI: 'DOI',
  PUBMED_ID: 'ID Pubmed',
  PAGES: 'Pages',
  ISSUE: 'Problème',
  VOLUME: 'Le volume',
  JOURNAL: 'Journal',
  AUTHORS: 'Auteures',
  ABSTRACT: 'Abstraite',
  REFERENCE_IS_REQUIRED: 'Une référence est requise',
  THIS_DOC_IS_APPROVED: 'Ce document est approuvé. Cliquez ici pour ouvrir le',
  ORIGINAL_DOCUMENT_AND_: 'document original et insérez vos commentaires',
  EDIT_FILE: 'Modifier le fichier',
  PROPERTIES: 'Propriétés',
  OPEN: 'Ouverte',
  LOCALIZED_DOCUMENTS: 'Documents localisés',
  ORIGINAL_DOCUMENTS: 'Document original',
  INTERNAL_APPROVAL_NUMBER: "Numéro d'approbation interne",
  CREATED_BY: 'Créé par',
  EXPIRATION_DATE: "Date d'expiration",
  VERSION_NUMBER: 'Numéro de version',
  NO_VERSION_AVAILABLE: 'Aucune version disponible',
  ARCHIVED_IF_NO_DRAFT: "Archivé: si aucune version préliminaire / approuvée n'est présente.",
  APPROVED_FOR_USERS: 'Approuvé: pour les utilisateurs.',
  DRAFT_FOR_AUTHORS_: 'Brouillon: pour les auteurs et les éditeurs.',
  VERSION_LEGEND: 'Légende de la version',
  VERSION: 'version',
  SELECT_ONE_LANG: 'Sélectionnez une langue.',
  SELECT_ONE_TYPE: 'Sélectionnez un type.',
  INTERNAL_NUMBER_REQUIRED: "Un numéro d'approbation interne est requis.",
  FILE: 'Fichier',
  ATTACH_FILE: 'Pièce jointe',
  LITERATURE: 'Littérature',
  SLIDE_DECK: 'Jeu de diapositives',
  PIL: 'PIL',
  FAQ: 'FAQ',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: "Ajouter une étape d'approbation",
  ADD_PARALLEL_STEP: 'Ajouter une étape parallèle',
  DELETE_STEP: "Supprimer l'étape",
  DELETE_ALL_PARALLEL_STEPS: 'Supprimer toutes les étapes parallèles',
  CHANGE_STATUS_NAME: 'Changer le nom du statut',
  SET_REQUIRED_ROLE: 'Définir le rôle requis',
  SET_TEAM_MEMBER: "Définir le membre de l'équipe",
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_:
    "Vous n'avez pas les autorisations pour modifier ce processus.",
  SAVE_ROLE: 'SAVE ROLE',
  CREATE_NEW_WORKFLOW: 'CRÉER UN NOUVEAU FLUX DE TRAVAIL',
  CREATE_NEW_STEP: 'CRÉER UNE NOUVELLE ÉTAPE',
  PLEASE_ENTER_THE_NUMBER_OF_:
    "Veuillez saisir le nombre d'étapes d'approbation parallèles, c'est-à-dire les étapes qui sont approuvées en même temps (pas de manière séquentielle). Si vous ne disposez d'aucune étape d'approbation parallèle, entrez 1. Vous pouvez toujours modifier le nombre d'étapes d'approbation ultérieurement.",
  APPROVAL_MUST_BE_AT_LEAST: "L'approbation doit être au moins",
  INSERT_THE_NEW_STATUS_NAME: 'Insérez le nouveau nom de statut',
  INSERT_THE_NEW_WORKFLOW_NAME: 'Insérez le nouveau nom de workflow.',
  INSERT_THE_NEW_STEP_NAME: "Insérez le nouveau nom de l'étape.",
  NEW_DOCUMENT: 'Nouveau document',
  CREATE: 'Créer',
  DOCUMENT_MANAGEMENT: 'Gestion de documents',
  CONFIRM: 'Confirmer',
  CONFIRM_PROCESS: 'CONFIRMER LE PROCESSUS',
  DEFINE_APPROVAL_STEPS_AND_TEAM: "DÉFINIR LES ÉTAPES D'APPROBATION ET L'ÉQUIPE",
  SET_DETAILS: 'DÉFINIR LES DÉTAILS',
  SELECT_TYPE: 'SÉLECTIONNER LE GENRE',
  COMPLETE_LOCALIZATION_PROCESS: 'Processus de localisation complet',
  CREATE_NEW_VERSION: 'Créer une nouvelle version',
  CREATE_NEW_DOCUMENT: 'Créer un nouveau document',
  IS_PRIVATE: 'Est privé',
  SHARED_WITH_OTHER_TEAMS: "Partagé avec d'autres équipes",
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    "Veuillez saisir le nombre d'étapes d'approbation séquentielles nécessaires. Si deux approbations sont en parallèle, cela compte comme une étape. Vous pouvez toujours modifier le nombre d'étapes d'approbation ultérieurement.",
  DONE: 'Terminé',
  SUBMIT_FOR_APPROVAL: 'Soumettre pour approbation',
  YOU_HAVE_TO_CONFIRM_THE_: 'Vous devez confirmer le processus avant le téléchargement.',
  SELECT_YOUR_DEC_TYPE: 'Sélectionnez votre type de document',
  TARGET_AUDIENCE: 'Public cible',
  PROCESS_DETAILS: 'Détails du processus',
  START_DATE: 'Date de début',
  DOC_DETAILS: 'Détails du document',
  CONFIRMATION: 'Confirmation',
  APPROVAL_PROCESS: "Processus d'approbation",
  SCHEDULED_EXP_DATE: "Date d'expiration prévue",
  SCHEDULED_PUBLISHING_DATE: 'Date de publication prévue',
  NO_PROCESS_SELECTED: 'Aucun processus sélectionné',
  COUNTRIES: 'Des pays',
  DOC_ID: 'ID du document',
  THERAPEUTIC_AREA: 'Domaine thérapeutique',
  FILTER: 'Filtre',
  BACK: 'arrière',
  ATTACH: 'Attacher',
  COPY_ANSWER: 'Copier la réponse',
  SIMILAR_INQUIRIES: 'Demandes similaires',
  SDR_FAQ: 'SRD / FAQ',
  SLIDE_DECKS: 'Decks coulissants',
  MEDICAL_DOC: 'Document médical',
  SHOW: 'Montrer',
  LOAD_MORE: 'Charger plus',
  NO_RESULT_FOUND: 'Aucun résultat trouvé.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'peut être utilisé pour rechercher une phrase entière, - peut être utilisé pour exclure des résultats de la recherche dans le mot spécifié',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'Rechercher des documents médicaux ou des demandes similaires',
  SEARCH: 'CHERCHER',
  DRAFT_VERSION: 'Brouillon',
  AVAILABLE_ACTIONS: 'Actions disponibles',
  OPEN_DOC: 'Ouvrir le document',
  UPLOAD_FILE: 'Téléverser un fichier',
  THE_DOCUMENT_IS_STILL_IN_APPROVAL_:
    "Le document est toujours en cours d'approbation, si vous souhaitez modifier le contenu du document, vous pouvez ouvrir le document dans Sharepoint ou télécharger une version mise à jour.",
  FINALIZE_LOCALIZATION: 'Finaliser la localisation',
  THIS_DOC_HAS_BEEN_LOCALIZED:
    'Ce document a été localisé, le processus doit être finalisé avant approbation.',
  Preview: 'Aperçu',
  PREVIEW_AND_SEND: 'Prévisualiser et envoyer',
  PREVIEW_AS_PDF: 'Aperçu en pdf',
  PUBLISHED_VERSION: 'Version publiée',
  NEW_VERSION: 'Nouvelle version',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    "Le document est toujours en cours d'approbation, si vous souhaitez modifier le contenu du document, téléchargez un autre fichier.",
  OUT_TO: 'pour',
  NEXT: 'suivante',
  SELECTED_SLIDES: 'Diapositives sélectionnées',
  GO_TO_SLIDE: 'ALLEZ À LA DIAPOSITIVE',
  PREV: 'précédente',
  SELECT_SLIDE: 'Sélectionnez une diapositive',
  ANSWER: 'Réponse',
  BACK_TO_STANDARD_VIEW: 'Retour à la vue standard.',
  UPLOAD_NEW_FILE: 'Télécharger un nouveau fichier',
  CREATE_FILE: 'CRÉER UN FICHIER',
  EDITING_WORD_DOC: "Modification d'un document Word",
  INSERT_FILE_NAME: 'Insérer un nom de fichier',
  PROCEED_WITH_THE_LINK_: 'Continuez avec le lien du fichier',
  A_NEW_WINDOW_WILL_BE_:
    "Une nouvelle fenêtre s'ouvrira avec un fichier vide, écrire le contenu et fermer l'onglet",
  INSERT_THE_NAME_FOR_:
    'Insérez le nom de la pièce jointe que vous souhaitez créer et poursuivez la création du fichier',
  INSTRUCTIONS: 'Instructions',
  CREATE_NEW_WORD_FILE_FOR: 'Créer un nouveau fichier Word pour',
  FROM: 'de',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    "Vous pouvez trouver ici les dernières demandes que vous avez insérées dans le système à la fois soumises et non encore soumises. Vous pouvez réutiliser les demandes que vous avez insérées pour créer des demandes plus similaires ou revenir aux demandes que vous n'avez pas terminé d'insérer.",
  MY_DRAFT_INQ: 'Mes projets de demandes',
  WARNING_CHANGES_NOT_SAVED: 'Avertissement: les modifications ne sont pas enregistrées',
  PAGE_THAT_WILL_BE_LOST: 'page qui sera perdue si vous décidez de continuer.',
  YOU_HAVE_UNUSED: 'Vous avez des modifications non enregistrées dans',
  ARE_YOU_SURE_YOU_WANT_TO_: 'Voulez-vous vraiment quitter cette page sans enregistrer?',
  LEAVE_THIS_PAGE: 'Quitter cette page',
  STAY_ON: 'Restez',
  ASSIGNED_TO: 'Assigné à',
  THIS_INQ_HAS_BEEND_INSERTED_: "Cette demande a été insérée lors de l'événement.",
  STATUS_IN_APPROVAL: 'Statut: En approbation',
  THE_RESPONSIBLE_FOR_THE_ANSWER_:
    "Le responsable de l'approbation de la réponse doit accepter la réponse.",
  STATUS_DRAFT: 'Statut: Brouillon',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    "Si vous souhaitez créer l'enquête réelle, allez dans l'onglet Soumettre et cliquez sur le bouton Créer.",
  INQ_IMPORTED_FROM: 'Enquête importée de',
  SEND_NEW_ANSWER: 'ENVOYER UNE NOUVELLE RÉPONSE',
  RETURN_TO_INBOX: 'RETOUR À LA BOÎTE DE RÉCEPTION',
  SUBMITTED_FOR_APPROVAL: 'Soumis pour approbation',
  ANSWER_SUBMITTED_ON: 'Réponse soumise le',
  BY: 'par',
  INQ_HAS_BEEN_CLOSED: "L'enquête a été close sans réponse.",
  BCC: 'Cci',
  ANSWER_GIVEN_ON: 'Réponse donnée le',
  ANSWER_SENT_ON: 'Réponse envoyée le',
  INBOX: 'Boîte de réception',
  OPEN_ORIGINAL_INQ: "Ouvrir l'enquête originale",
  CLOSE_ANSWER_NOT_NEEDED: 'Fermer: Anser non nécessaire',
  HISTORY: 'Histoire',
  ADD_A_NOTE: 'Ajouter une note',
  ADD_A_NOTE_INTO_: "Ajoutez une note dans l'historique de l'enquête.",
  SHARE_OR_START_A_FOLLOW_UP: 'Partager ou démarrer un suivi',
  SEE_ALL_INQ_RELATED_TO: 'Voir toutes les demandes liées à',
  SRD_NEEDED: 'SRD nécessaire',
  ANSWER_UNAVAILABLE: 'Réponse non disponible',
  QUESTION: 'Question',
  SUBMIT: 'SOUMETTRE',
  ANSWER_UPPERCASE: 'RÉPONSE',
  DETAILS: 'DÉTAILS',
  SPLIT_INQ: 'Enquête fractionnée',
  ADD_QUESTION: 'Ajouter une question',
  ENTER_QUESTION: 'Entrez la question',
  MARK_AS_ANSWERED: 'Marquer comme répondu',
  TO_BE_ANSWERED: 'Pour être répondu',
  FOLLOW_UP_RECEIVED: 'Suivi reçu',
  ERROR: 'Erreur',
  SHOW_ORIGINAL_MESSAGE: "Afficher le message d'origine",
  SEND: 'Envoyer',
  DO_NO_SEND: 'Ne pas envoyer',
  OK: "D'accord",
  MAX_LENGTH_FOR_ZIP_CODE: 'La longueur maximale du code postal est',
  CHARACTERS: 'personnages',
  ADDRESS_1_IS_: "L'adresse 1 est",
  LABEL_IS: "L'étiquette est",
  NO_QUESTION_INSERTED: 'Aucune question insérée',
  WARNING: 'ATTENTION',
  FOLLOW_UP_IS_NOT_AVAILABLE_: "Le suivi n'est pas disponible pour cette interaction.",
  INSERT_INQ_TEXT: "Insérer un texte d'enquête",
  SHOW_MORE: 'Montre plus',
  OUT_OF: 'hors de',
  INQ_TO_BE_SENT: 'Demandes à envoyer',
  HIDE_INQ: 'Masquer les demandes',
  SHARE: 'Partager',
  HCP_PROFILE_OF: 'PROFIL HCP de',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'Remplissez tous les champs obligatoires ci-dessous et au moins une adresse e-mail, puis enregistrez pour prévisualiser la notification de réception GDPR.',
  CONFIRMATION_SENT_BY: 'Avis envoyé par',
  COUNTRY_IS: 'Le pays est',
  COUNTRY: 'Pays',
  AT_LEAST_ONE_CONTACT_IS_: 'Au moins un contact est',
  EMAIL_IS: "L'email est",
  EMAIL_LABEL_IS: "Le libellé de l'e-mail est",
  PHONE_FAX_IS: "L'étiquette du téléphone / fax est",
  PHONE_FAX_NUMBER_IS: 'Le numéro de téléphone / fax est',
  ADDRESS: 'Adresse',
  DELETE_PERSONAL_DATA: 'Supprimer les données personnelles',
  HCP_VERIFIED: 'HCP vérifié',
  KEY_OPINION_LEADER: "Leader d'opinion clé",
  HOSPITAL_BASED: 'En milieu hospitalier',
  HCP_RELATED_INFORMATION: 'INFORMATIONS RELATIVES AUX HCP',
  ACCEPT: "J'accepte",
  REJECT: 'Rejeter',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'Voulez-vous accepter ces suggestions?',
  SALES_REP: 'REPRÉSENTANT COMMERCIAL',
  SUGGEST_A_TOPIC: 'Propose un sujet',
  TEAM_SPECIFIC_FIELDS: "CHAMPS SPÉCIFIQUES À L'ÉQUIPE",
  ADR_RELATED: "Lié à l'ADR",
  NOT_ADR_RELATED: "Pas lié à l'ADR",
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'Veuillez examiner le message et le classer comme lié aux effets indésirables du médicament ou non.',
  ADVERSE_DRUG_REACTION: 'Réaction indésirable au médicament',
  CHANGE_DETAILS: 'MODIFIER LES DÉTAILS',
  YES: 'Oui',
  NO: 'Non',
  DUE_DATE: "Date d'échéance",
  CRITICAL: 'Critique',
  NORMAL: 'Ordinaire',
  PRIORITY: 'Priorité',
  SELECT_DETAILS: 'CHOISIR LES DÉTAILS',
  SAVE_REVIEW: "Enregistrer l'avis",
  SET_TO_ALL_INQ: 'RÉGLÉ À TOUTES LES DEMANDES',
  PRODUCT_QUALITY_COMPLAINT: 'Réclamation concernant la qualité du produit',
  PQC_PC_A_PRODUCT_:
    "PQC / PC Une plainte ou un problème de qualité de produit (CQ) est défini comme toute préoccupation impliquant un défaut ou un dysfonctionnement de tout produit médicamenteux ou concernant la qualité, la performance, l'innocuité de tout produit médicamenteux, par ex. odeur et / ou goût altérés, emballage médiocre ou défectueux, contamination suspectée, etc.",
  OFF_LABEL_USE_OF_PHARMA___:
    "Utilisation hors AMM de médicaments pharmaceutiques pour une indication non approuvée ou dans un groupe d'âge, une posologie ou une voie d'administration non approuvés.",
  A_RESPONSE_TO_A_MEDICINAL_:
    "Une réponse à un médicament qui est une relation nocive et non intentionnelle et causale entre un médicament et un événement indésirable est au moins une possibilité raisonnable. Les cas de situation spéciale (par exemple, les cas de grossesse, l'utilisation hors AMM, le surdosage) doivent également être marqués comme ADR.",
  NORMAL_IS_7_WORKING_DAYS:
    'La normale est de 7 jours ouvrables, élevée est de 48 heures et critique (liée au patient) est de 24 heures',
  CATEGORY_AND_TOPIC_ARE: 'La catégorie et le sujet sont',
  PRODUCT_IS: 'Le produit est',
  DO_YOU_WANT_TO_ACCEPT_: 'Voulez-vous accepter ces suggestions?',
  MERGE: 'go',
  Duplicate: 'Dupliquer',
  Other: 'Autre',
  Spam: 'Spam',
  CLOSE_INTERACTION: 'Une interaction étroite',
  RE_OPEN_THE_TARGET_: "Rouvrir l'enquête cible après la fusion",
  MARK_AS_INTERNAL_: "Marquer comme message interne pour l'enquête existante.",
  MARK_AS_AN_ERROR_: "Marquer comme message d'erreur pour l'enquête existante.",
  THIS_MESSAGE_WILL_BE_EXISTING_:
    "Ce message sera marqué comme réponse de suivi d'une demande existante.",
  THANK_YOU: 'Je vous remercie',
  AN_INTERNAL_MESSAGE: 'Un message interne',
  AN_ERROR_MESSAGE: "Un message d'erreur",
  NEW_INQ: 'Nouvelle demande',
  A_FOLLOW_UP_RESPONSE: 'Une réponse de suivi',
  THIS_INQ_IS: 'Cette enquête est',
  SPLIT_INTERACTION: 'Interaction fractionnée',
  SAVE_AND_CLOSE: 'Sauver et fermer',
  SAVE_INQ: 'Enregistrer la demande',
  REVIEW_THE_TEXT_AND: 'Vérifiez le texte et la catégorisation, puis confirmez',
  CHOOSE_THE_RELEVANT_PRODUCT:
    'Choisissez le produit, la catégorie et le sujet appropriés pour le texte sélectionné',
  SELECT_THE_TEXT_THAT_:
    "Sélectionnez le texte qui identifie la question sur un produit / sujet (n'incluez pas les salutations, les signatures, les avis de non-responsabilité ou d'autres informations non pertinentes)",
  REPEAT_THE_PREVIOUS_: "Répétez les étapes précédentes pour ajouter d'autres demandes",
  READ_MORE: 'Lire la suite',
  READ_LESS: 'En lire moins',
  DO_NOT_INCLUDE_:
    "ne comprennent pas les salutations, les signatures, les avis de non-responsabilité ou d'autres informations non pertinentes",
  SELECT_THE_TEXT_THAT_IDENT:
    'Sélectionnez le texte qui identifie la question sur un produit / sujet',
  IF_YOU_HAVE_MULTIPLE_: 'Si vous avez plusieurs produits ou sujets, la demande doit être divisée.',
  THE_FOLLOWING_PROCESS_:
    'Le processus suivant vous aidera à identifier le texte pertinent pour chaque produit / sujet: ',
  EXPORT_TO_PDF: 'EXPORTER AU PDF',
  Added: 'Ajoutée',
  Deleted: 'Effacé',
  From: 'De',
  To: 'À',
  BACK_TO_INBOX: 'Retour à la boîte de réception',
  PERMISSION_DENIED: 'Permission refusée',
  THIS_INQ_IS_LOCKED_BY: 'Cette demande est verrouillée par',
  FORCE_UNLOCK: 'DÉVERROUILLAGE DE LA FORCE',
  INQUIRIES: 'Demandes',
  Download: 'Télécharger',
  Event: 'un événement',
  INSERTED_BY: 'Inséré par',
  LAST_EDITED_ON: 'Dernière modification le',
  LAST_EDITED_BY: 'Dernière modification par',
  CREATED_ON: 'Créé sur',
  TOPIC: 'Sujette',
  TABLE_OPTIONS: 'Options de table',
  DEPARTMENT: 'département',
  User: 'Utilisatrice',
  ADD_THERAPEUTIC_AREA: 'Ajouter un espace thérapeutique',
  CREATE_THERAPEUTIC_AREA: 'Créer un nouvel espace thérapeutique',
  ADD_NEW: 'Ajouter un nouveau',
  SELECT_TEAM_TO_PROCEED: "Sélectionnez l'équipe pour continuer",
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  SALES: 'Ventes',
  CALL_CENTER: "Centre d'appel",
  ALL: 'Toute',
  Warning: 'Attention',
  clear: 'claire',
  THESE_CHANGES_WILL_BE_: 'Ces modifications seront appliquées aux utilisateurs sélectionnés',
  INSERT_A_FOLLOW_UP_RESP: 'Insérer une réponse de suivi',
  INSERT_A_NOTE: 'Insérer une note',
  MERGED_FROM: 'Fusionné à partir de',
  CHANGES: 'Changements',
  SHOW_LESS: 'Montrer moins',
  COMPLETE: 'Achevée',
  MISSING: 'Disparue',
  CLOSE_AS_FORWARDED: 'Fermer comme transmis',
  CLOSE_EXT_TEAM: "Clôturer l'enquête telle que transmise à l'équipe externe",
  THE_INQ_BELONG_TO_A_:
    "Cette enquête appartient à un pays sous la responsabilité d'une équipe externe. L'enquête sera ensuite transmise automatiquement à cette équipe.",
  RESOLVE: 'Résoudre',
  TERRITORIES: 'Territoires',
  EXTERNAL_COMMUNICATION: 'COMMUNICATION EXTERNE',
  FOR_INQ_COMING_DIRECTLY_:
    "Pour les demandes provenant directement du HCP par e-mail, envoyez automatiquement une notification pour l'informer que la demande a été reçue.",
  INTERNAL_COMMUNICATION: 'COMMUNICATION INTERNE',
  SALESREP_RECEIVES_: 'Salesrep ne reçoit que les notifications de réponse à la demande',
  SALESREP_RECEIVES_FULL_ANSWER_:
    "Salesrep reçoit une réponse complète pour les demandes de renseignements sur l'étiquette",
  SALESREP_RECEIVES_ONLY_NOTIF_: 'Salesrep ne reçoit que les notifications de réponse à la demande',
  Name: 'Nom',
  Module: 'Module',
  Function: 'Une fonction',
  LAST_RUN: 'Dernier tour',
  End: 'Fin',
  Every: 'Chaque',
  Period: 'Période',
  Active: 'Active',
  Resolved: 'Résolue',
  ERROR_PREVIEW: 'Aperçu des erreurs',
  FAILURE_COUNT: "Nombre d'échecs",
  COMPLETED_ON: 'Terminé le',
  STARTED_ON: 'Commencé le',
  TASK_NAME: 'Nom de la tâche',
  PRODUCT_SPECIFIC: 'SPÉCIFIQUE AU PRODUIT',
  THERAPEUTIC_AREAS: 'ZONES THÉRAPEUTIQUES',
  PRODUCT_GROUPS: 'GROUPES DE PRODUITS',
  LICENSING_PARTNER: 'PARTENAIRE DE LICENCE',
  PRODUCT_CODE: 'CODE PRODUIT',
  EXTERNAL_ID: 'ID externe',
  OBJECT_TYPE: "Type d'objet",
  MIGRATION_NAME: 'Nom de la migration',
  MIGRATION_ERRORS: 'Erreurs de migration',
  ADD_SALES_REP: 'Ajouter un représentant commercial',
  ASSIGNED_TO_MSL: 'ATTRIBUÉ À MSL',
  NOT_ASSIGNED: 'Non attribué',
  ASSIGNED_TO_OTHER_MSL: 'Attribué à un autre MSL',
  ASSIGNED_TO_THIS_MSL: 'Attribué à ce MSL',
  SHOW_ONLY: 'Montrer seulement',
  SALES_REP_LIST: 'Liste des représentants commerciaux',
  ACTIVE_TASK: 'Activer la tâche',
  CHOOSE_USER_AND_SET_THE_SHIFT_:
    "Choisissez l'utilisateur et définissez le décalage pour l'événement",
  MANAGE_SHIFTS: 'GÉRER LES CHANGEMENTS',
  CREATE_NEW_USER: 'Créer un nouvel utilisateur',
  THIS_TEMPLATE_WILL_BE_AUTO_: 'Ce modèle sera automatiquement ajouté au membre de votre équipe.',
  TEMPLATE_FOR: 'Modèle pour',
  Day: 'journée',
  Week: 'La semaine',
  Month: 'Mois',
  Today: "Aujourd'hui",
  Archive: 'Archiver',
  PARTICIPANTS: 'PARTICIPANTES',
  EVENT_MANAGEMENT: 'GESTION DES ÉVÉNEMENTS',
  ADD_TASK: 'Ajouter une tâche',
  EDIT_TASK: 'Modifier la tâche',
  IS_ACTIVE: "C'est actif",
  Months: 'Mois',
  Days: 'Journées',
  Hours: 'Heures',
  Minutes: 'Minutes',
  HCP_ADMINISTRATION: 'ADMINISTRATION HCP',
  AUTH_REQ_ADMINISTRATION: "Administration des demandes d'authentification",
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_:
    'Le produit sera mis en évidence dans les domaines thérapeutiques sélectionnés.',
  PRODUCT_GROUPS_DEFINE_:
    "Les groupes de produits définissent l'équipe chargée de répondre à ces demandes de produits.",
  USER_ADMINISTRATION: 'Administration des utilisateurs',
  IS_PRODUCT_SPECIFIC: 'Est spécifique au produit',
  USER_TEMPLATES_ADMIN: 'Administration des modèles utilisateur',
  SET_DEFAULT_VALUES: 'DÉFINIR LES VALEURS PAR DÉFAUT',
  SET_DEFAULT_VALUES_FOR_: "Définir des valeurs par défaut pour l'événement",
  INQUIRY_DEFAULT_VAL: "Valeurs par défaut de l'enquête",
  CREATE_NEW_EVENT: 'CRÉER UN NOUVEL ÉVÉNEMENT',
  EDIT_EVENT: "MODIFIER L'ÉVÉNEMENT",
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'Définir les détails pour créer un nouvel événement',
  ADD_DOC_LINKED_TO_: "Ajouter des documents liés à l'événement",
  INC_CHANNEL: 'Inc. Channel',
  CHANGE_DETAILS_OF_THE_EVENT: "Modifier les détails de l'événement",
  DESCRIPTION: 'La description',
  CREATE_APPROVAL_PROCESS: "Choisissez le processus d'approbation",
  CREATE_NEW: 'Créer un nouveau',
  CUSTOMIZE_PROCESS: 'Personnaliser le processus',
  SELECTED_PROCESS: 'Processus sélectionné',
  CLONE: 'Cloner',
  PREFERENCES_TITLE: 'Préférences',
  NOTIFICATIONS_TITLE: 'Notifications',
  TEMPLATES: 'Modèles',
  LETTER_TEMPLATES: 'Modèle de lettre ',
  USER_TEMPLATES: 'Modèles utilisateur',
  TEAM_MEMBERS: "Membres de l'équipe",
  NEW_PASSWORD: 'Nouveau mot de passe',
  REPEAT_NEW_PASSWORD: 'Répété le nouveau mot de passe',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'Au moins 1 lettre minuscule',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'Au moins 1 lettre majuscule',
  AT_LEAST_1_DIGIT: 'Au moins 1 chiffre',
  AT_LEAST_1_SPECIAL_CHARACTER: 'Au moins 1 caractère spécial',
  MINIMUM_8_CHARACTERS: '8 caractères minimum',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'Ne doit contenir ni e-mail ni noms',
  SHOULD_MATCH_PASSWORD: 'Doit correspondre au mot de passe',
  USER_MANUAL: "Manuel de l'Utilisateur",
  SUPPORT: 'Soutien',
  BACK_TO_LOGIN: 'Retour connexion',
  PASSWORD_CONFIRMATION: 'Confirmation mot de passe',
  HAVE_AN_ACCOUNT: 'Vous avez déjà un compte?',
  Submit: 'Soumettre',
  Gender: 'Le sexe',
  PROFESSION: 'Profession',
  MEDICAL_INQ_ROLES: 'Rôle de traitement des demandes de renseignements médicaux',
  MEDICAL_INFO_MANAGER: "Responsable de l'information médicale",
  MEDICAL_SCIENCE_LIAISON: 'Liaison en sciences médicales',
  NOT_PROCESSING_INQUIRIES: 'Ne traite pas les demandes',
  ACTIVE_HEALTH_CARE_SPEC: 'Spécialiste des soins de santé actifs',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'Spécialiste des soins de santé non actif',
  PASS_IS_REQUIRED: 'Mot de passe requis!',
  EMAIL_IS_REQUIRED: "L'email est requis!",
  VALID_EMAIL: "S'il vous plaît, mettez une adresse email valide!",
  FIELD_IS_REQUIRED: 'Ce champ est requis!',
  ONLY_LETTERS: 'Veuillez saisir uniquement des lettres!',
  ARE_YOU_daiichi_EMPLOYEE: 'Êtes-vous un employé de Daiichi Sankyo?',
  here: 'ici',
  CREATE_AN_ACCOUNT: 'Créer un compte',
  FORGOT: 'Oubliée?',
  Medical: 'Médicale',
  Medical_not_resp: 'Médical - pas responsable de répondre aux MIR',
  Commercial: 'Commerciale',
  Market_access: "L'accès au marché",
  Pharmacovigilance: 'Pharmacovigilance',
  PR: 'PR',
  Please_specify: 'Veuillez préciser',
  Medical_information_manager: "Responsable de l'information médicale",
  Medical_advisor: 'Conseiller médical',
  Are_Medical_inquiries:
    'Êtes-vous responsable de répondre aux demandes de renseignements médicaux?',
  Are_Health_care_professional: 'Êtes-vous un professionnel de la santé en exercice?',
  Health_care_Industry: "L'industrie de la santé",
  Health_Authority: 'Autorité sanitaire',
  Journalist: 'Journaliste',
  Lawyer: 'Avocate',
  Patient: 'Patiente',
  Payor: 'Payeuse',
  Scientist: 'Scientifique',
  Angiologist: 'Angiologue',
  Cardiologist: 'Cardiologue',
  Cardiovascular_Surgeon: 'Chirurgien cardiovasculaire',
  Dentist: 'Dentiste',
  Gastroenterologist: 'Gastroentérologue',
  General_Practitioner: 'Médecin généraliste',
  Geriatrician: 'Gériatre',
  Gynecologist: 'Gynécologue',
  Haematologist: 'Hématologue',
  Internist: 'Interniste',
  Medical_Student: 'Étudiant en médecine',
  Nephrologist: 'Néphrologue',
  Neurologist: 'Neurologue',
  Neurosurgeon: 'Neurochirurgienne',
  Nurse: 'Infirmière',
  Oncologist: 'Oncologiste',
  Oncology_Nurse: 'Infirmière en oncologie',
  Other_HCP: 'Autre HCP',
  Other_Surgeon: 'Autre chirurgien',
  Pediatrician: 'Pédiatre',
  Pharmacist: 'Pharmacienne',
  Pharmacologist: 'Pharmacologue',
  Pharmacy_Technician: 'Préparateur en pharmacie',
  Pneumologist: 'Pneumologue',
  Radiologist: 'Radiologue',
  Rheumatologist: 'Rhumatologue',
  USER_PASS_INCORRECT: "L'identifiant ou le mot de passe est incorrect",
  SUBMITTED_REQUEST: 'Votre demande a été envoyée avec succès.',
  Reset: 'Réinitialiser',
  Reset_pass: 'réinitialiser le mot de passe',
  New_pass: 'nouveau mot de passe',
  RESET_PASS_EMAIL:
    'Un lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail',
  NEW_PASS_SUCCESS: 'Votre mot de passe a été réinitialisé avec succès',
  SOMETHING_WENT_WRONG: "Une erreur s'est produite, veuillez contacter le support.",
  EVENTS: 'Événements',
  Login_here: 'Connectez-vous ici',
  REGISTRATION: 'enregistrement',
  Create_an_account: 'Créer un compte',
  NO_RECORDS: "Pas d'enregistrements",
  INTERACTION: 'Interaction',
  SPECIALIZATION: 'Spécialisation',
  SPECIALIZATION_TYPE: 'Type de spécialisation',
  SAVED: 'Enregistrée',
  SAVING: 'Économie',
  TYPING: 'Dactylographie',
  CREATE_NEW_INTERACTION: 'Créer une nouvelle interaction',
  FILTER_BY: 'Filtrer par',
  SEND_EMAIL: 'Envoyer un e-mail',
  SAVE_WITHOUT_EMAIL: 'Enregistrer sans e-mail',
  SUBMIT_TO_INBOX: 'Soumettre à la boîte de réception',
  Create_my_acc: 'Créer mon compte',
  NEW_QUESTION: 'Nouvelle question',
  LABEL: 'Étiquette',
  SELECT_A_LABEL: 'Sélectionnez une étiquette',
  CITY: 'Ville',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_:
    'Suggérer une nouvelle catégorie ou en sélectionner une existante',
  SUGGEST_CATEGORY_OR_TOPIC: 'Suggérer une nouvelle catégorie ou un nouveau sujet',
  URGENT: 'Urgente',
  EXISTING_CATEGORY: 'Catégorie existante',
  NEW_CATEGORY_NAME: 'Nouveau nom de catégorie',
  NEW_TOPIC_NAME: 'Nouveau nom de sujet',
  CATEGORY: 'Catégorie',
  MSLA: 'MSLA',
  OUT_CHANNEL: 'Canal sortant',
  LOGIN_TO_CONTINUE: 'Connectez-vous pour continuer vers Madjenta',
  Email_address: 'Adresse e-mail',
  FORGOT_PASS: 'Mot de passe oublié?',
  Remember_me: 'Souviens-toi de moi',
  YOU_WILL_BE_NOTIFIED:
    'Un e-mail de confirmation a été envoyé avec des instructions supplémentaires pour votre inscription',
  SUCCESS_EMAIL_CONFIRMATION: 'Votre e-mail a été confirmé avec succès.',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'Vous serez averti lorsque votre demande aura été examinée',
  FAILED_EMAIL_CONFIRMATION: 'Nous ne sommes pas en mesure de confirmer votre e-mail.',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'Veuillez réessayer et si le problème persiste, écrivez au support avec une description du problème',
  RESET_FILTERS: 'Réinitialiser les filtres',
  NEW_PASS_RESET_SUCCESS: 'Réinitialisation du mot de passe avec succès!',
  Cardiovascular: 'Cardiovasculaire',
  Oncology: 'Oncologie',
  Due_On: 'Dû le',
  Follow_Up_Sent_On: 'Suivi envoyé le',
  Follow_Up_Received_On: 'Suivi reçu le',
  Closed_On: 'Fermé le',
  Reopened_On: 'Rouvert le',
  Inquiry_ID: 'ID de la demande',
  Search: 'Chercher',
  Assigned_to_Now: 'Attribué à (maintenant)',
  Edited_By: 'Édité par',
  Assigned_to_Any_Time: 'Attribué à (à tout moment)',
  Closed: 'Fermée',
  Foreign: 'Étrangère',
  Complex_search_Sentence_: 'Recherche complexe: Phrase: "phrase à rechercher", pas: -word',
  Search_inquiries: 'Rechercher des demandes',
  SEARCH_REPOSITORY: 'Rechercher dans le référentiel',
  MIR_INBOX: 'MIR INBOX',
  Filters: 'Filtres',
  Active_Filters: 'Filtres actifs',
  CONTACT_SUPPORT: 'Contactez le support',
  MedicalInformationRequests: 'Demandes de renseignements (pdf)',
  UserProfileNavigation: 'Profil utilisateur (pdf)',
  UserManualChanges: "Modifications du manuel de l'utilisateur (docx)",
  iPad_How_To: 'iPad (pdf)',
  MedIS_Assign_Inquiry: 'Attribuer une enquête',
  MedIS_Documentation_Approval_Process: "Processus d'approbation de la documentation",
  MedIS_Documentation_Overview: 'Aperçu de la documentation',
  MedIS_Inquiry_Documentation: 'Demande de documentation',
  MedIS_MIR_Inbox_Filters: 'Filtres de la boîte de réception MIR',
  MedIS_Merge_MIR: 'Fusionner MIR',
  MedIS_New_Documentation: 'Nouvelle documentation',
  MedIS_New_Inquiry: 'Nouvelle enquête',
  MedIS_Out_Of_Office: 'Absent du bureau',
  MedIS_Prepare_Custom_Answer: 'Préparer une réponse personnalisée',
  MedIS_Split_MIR_Part_I: 'Split MIR Partie I',
  MedIS_Split_MIR_Part_II: 'Split MIR Partie II',
  I_AGREE_TO: "Je suis d'accord",
  TermsAndConditionsLink: 'Termes et conditions',
  Title: 'Titre',
  INTERACTION_TITLE: "Titre de l'interaction",
  Move: 'Bouge toi',
  Left: 'La gauche',
  Right: 'Droite',
  Requestor: 'Demandeur',
  Assigned_To: 'Assigné à',
  Status: 'Statut',
  Note: 'Remarque',
  Confirm: 'Confirmer',
  rejectConfirm: 'Voulez-vous vraiment rejeter la demande?',
  approveConfirm: 'Voulez-vous vraiment approuver la demande?',
  newUser: 'Nouvel utilisateur',
  editUser: "Modifier l'utilisateur",
  deleteUser: "Supprimer l'utilisateur",
  newProduct: 'Nouveau produit',
  editProduct: 'Modifier le produit',
  deleteProduct: 'Supprimer le produit',
  newCategory: 'Nouvelle catégorie',
  editCategory: 'Modifier la catégorie',
  deleteCategory: 'Supprimer la catégorie',
  newTopic: 'Nouveau sujet',
  editTopic: 'Modifier le sujet',
  deleteTopic: 'Supprimer le sujet',
  userRequest: "Demande de l'utilisateur",
  productRequest: 'Demande de produit',
  categoryRequest: 'Demande de catégorie',
  categoryTopicRequest: 'Demande de catégorie / sujet',
  topicRequest: 'Demande de sujet',
  Suggestion: 'Suggestion',
  tradeName: 'Nom commercial',
  activeSubstance: 'Substance active',
  productName: 'Nom du produit',
  productSpecific: 'Est-ce spécifique au produit?',
  EMAIL_IN_USE: 'Il existe un compte avec cet e-mail',
  fromDate: 'Partir de la date',
  toDate: 'À ce jour',
  applyFilter: 'Appliquer le filtre',
  requestReceivedFrom: 'Demande reçue de',
  on: 'sur',
  at: 'à',
  to: 'à',
  add: 'ajouter',
  from: 'de',
  approve: 'approuver',
  reject: 'rejeter',
  withTheFollowingRole: 'avec le rôle suivant',
  forTheFollowingTeam: "pour l'équipe suivante",
  request: 'demande',
  submittedBy: 'Proposé par',
  INVALID_OR_EXPIRED_RESET_REQUEST:
    'La demande de réinitialisation du mot de passe est invalide ou a expiré',
  PASS_IS_RECENT: 'Le mot de passe a été utilisé récemment',
  Assigned_to_me: 'Assigné à moi',
  My_drafts: 'Mes brouillons',
  My_open_inquiries: 'Mes demandes ouvertes',
  My_closed_inquiries: 'Mes demandes fermées',
  Filter_inquiries_by: 'Filtrer les demandes par',
  New_inq: 'Nouvelle enquête',
  Inq_inbox: 'Boîte de réception des demandes',
  REPORTS: 'Rapports',
  My_teams_open_inq: 'Demandes ouvertes de mon équipe',
  My_teams_closed_inq: 'Demandes fermées de mon équipe',
  All_teams_open_inq: 'Toutes les équipes ouvrent des demandes',
  All_teams_closed_inq: 'Toutes les équipes ont fermé les enquêtes',
  Overdue: 'En retard',
  Due_this_week: 'Dû cette semaine',
  Pending_approval: 'En attente de validation',
  ADMINISTRATION: 'Administration',
  Show_all: 'Afficher tout',
  In_progress: 'En cours',
  Table_Settings_Columns: 'Paramètres de table - Colonnes',
  Change_column_visibility: 'Modifier la visibilité de la colonne',
  Reassign_to: 'Réaffecter à',
  set_priority: 'Définir la priorité',
  set_status: 'Définir le statut',
  Spam_list: 'Liste de spam',
  Institution: 'Institution',
  DUE_ON: 'Dû le',
  Profile_settings: 'Paramètres de profil',
  Preferences: 'Préférences',
  Role_settings: 'Paramètres de rôle',
  Notification_settings: 'Paramètres de notification',
  User_profile: "Profil de l'utilisateur",
  Select_person: 'Sélectionnez une personne',
  FROM_DATE: 'Partir de la date',
  TO_DATE: 'À ce jour',
  JOB_DESC: "Description de l'emploi",
  INQ_Management: 'Gestion des demandes',
  DOCS_Management: 'Gestion de documents',
  USER_Management: 'Gestion des utilisateurs',
  Define_Frequency: 'Définir la fréquence',
  All_teams_all_inq: 'Toutes les demandes pour toutes les équipes',
  My_teams_all_inq: 'Toutes les demandes de mon équipe',
  Search_people_here: 'Rechercher des personnes ici',
  Search_team_here: 'Rechercher une équipe ici',
  people: 'GENS',
  teams: 'ÉQUIPES',
  empty: '(vide)',
  can_not_be_assigned: "Cet utilisateur n'a pas d'équipe et ne peut pas être affecté",
  select_a_team: 'Veuillez sélectionner une équipe pour',
  confirm_team_change: "Confirmer le changement d'équipe",
  change_the_team_: "Voulez-vous vraiment changer l'équipe en",
  can_not_see_it_anymore: 'Vous ne pourrez pas le voir dans le courant',
  filtered_view: 'vue filtrée.',
  PHONE: 'Téléphone',
  FAX: 'Fax',
  LETTER: 'Lettre',
  F2F_SITE_VISIT: 'F2F (visite du site)',
  F2F_CONGRESS: 'F2F (Congrès)',
  F2F_ADBOARD: 'F2F (AdBoard)',
  F2F_OTHER: 'F2F (autre)',
  WEBINAR_SITE_VISIT: 'Virtuel (visite du site)',
  WEBINAR_CONGRESS: 'Virtuel (Congrès)',
  WEBINAR_ADBOARD: 'Virtuel (AdBoard)',
  WEBINAR_OTHER: 'Virtuel (autre)',
  CHAT: 'Bavarder',
  COURIER: 'Courrier',
  REP_DELIVERY: 'Rep. Livraison',
  SYSTEM: 'Système',
  WEB_FORM: 'Formulaire Web',
  Signature: 'Signature',
  Disclaimer: 'Avertissement',
  STATE: 'Etat',
  ADDRESS_1: 'Adresse 1',
  ADDRESS_2: 'Adresse 2',
  ZIP_CODE: 'Code postal',
  HIGH: 'Haute',
  ADVANCED: 'Avancée',
  CLICK_BELOW_TO_POPULATE_ANSWER:
    'Cliquez sur le texte et les champs ci-dessous pour remplir et modifier la réponse',
  DOCUMENT_VIEW: 'Vue du document',
  FORM_VIEW: 'Vue formulaire',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: "Vous n'avez pas le droit d'envoyer la réponse.",
  ANSWER_TEXT: 'Texte de réponse',
  CONTACT_INFORMATION: 'Informations de contact',
  COMPANY: 'Compagnie',
  SRD_NEEDED_TOOLTIP: 'La réponse à cette question nécessite un nouveau document SRD',
  ENABLE_ALL: 'Tout activer',
  DISABLE_ALL: 'Désactiver tous les',
  BEFORE: 'Avant',
  AFTER: 'Après',
  EDIT: 'Éditer',
  MY_TEMPLATES: 'Mes modèles',
  TEAM_TEMPLATES: "Modèles d'équipe",
  TEAM_TEMPLATES_HINT: "Modèle créé par Medical Information Manager pour toute l'équipe",
  SOURCE: 'La source',
  TEMPLATE_NO_MATCH_INTERACTION:
    "Ce modèle ne correspond pas aux détails de l'interaction et n'est pas applicable",
  EMAIL_SUBJECT: 'Sujet du courriel',
  INQUIRY_SUBMIT_HINT:
    'Veuillez remplir tous les champs obligatoires afin de répondre à la demande',
  SCHEDULED_PUBLISH_TO_EXP_DATE: "Publication programmée jusqu'à la date d'expiration",
  COPY_ATTACHMENT_TO_ANSWER: 'Copier la pièce jointe pour répondre',
  COPY_TEXT_TO_ANSWER: 'Copier le texte pour répondre',
  SELECT_INQUIRY: 'Sélectionner une demande',
  EDIT_INQ: 'Modifier la question',
  SPLIT_ANOTHER: 'Fractionner un autre',
  DELETE_INQ: 'Supprimer la demande',
  SELECT_TEXT_FOR_SPLIT: 'Ajouter une nouvelle question dans la même interaction',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'Générer une interaction / un cas séparé',
  SPLIT_ORIGINAL_TEXT: "Texte d'origine (Sélectionnez le texte pour le fractionnement)",
  SPLIT_TEXT_TO_REMAIN_: 'Texte pour rester dans la demande initiale',
  SPLIT_TEXT_TO_SPLIT_: 'Texte à diviser',
  SPLIT_SELECTED_SECTIONS: 'Sections sélectionnées pour le fractionnement',
  SPLIT_NO_MODIFICATIONS_HINT:
    "Le texte se remplira automatiquement lorsque vous sélectionnez des sections dans le texte d'origine",
  SPLIT_MODIFICATIONS_HINT:
    'Le texte a été modifié manuellement et toute autre sélection à partir du texte original ne sera pas appliquée.\n Cliquez ici pour annuler les modifications.',
  TEMPLATE_NO_MODIFICATIONS_HINT:
    "Le texte sera mis à jour automatiquement avec les modifications apportées aux détails de l'enquête.",
  SELECT_DIFFERENT_TEMPLATE: 'Sélectionnez un autre modèle',
  APPROVE: 'Approuver',
  LAST_EDIT_ON: 'Dernière modification le',
  LAST_EDIT_BY: 'Dernière modification par',
  INTERNAL_COMM: 'Communication interne',
  INACTIVE: 'Inactive',
  DEACTIVATE: 'Désactiver',
  ARCHIVED: 'Archivé',
  REPUBLISH: 'Republier',
  INBOX_NO_FILTERS_HINT:
    "Aucun filtre n'est sélectionné.\n Si vous ne trouvez pas la demande que vous recherchez, vérifiez que vous vous trouvez dans la bonne section de la boîte de réception.",
  INTERACTION_TEMPLATE_HINT:
    "Les signatures, les salutations et les clauses de non-responsabilité font partie de la communication écrite, comme les courriels, les lettres et les télécopies.\n Pour les utiliser, assurez-vous que le canal sortant est défini sur l'un d'entre eux.",
  CONFIRM_AUTH_REQUEST: "Confirmer la demande d'authentification",
  VIEW_AUTH_REQUEST: "Afficher la demande d'authentification",
  QUESTION_TITLE: 'Titre de question: ',
  NO_TITLE_INQUIRY: '-aucun-titre-enquête-',
  EXISTING_CATEGORIES: 'Catégories existantes',
  EXISTING_TOPICS: 'Sujets existants',
  DO_NOT_ASSIGN_PRODUCT: 'Ne pas attribuer de produit',
  QUESTION_TEXT: 'Texte de la question',
  DATE: 'Date',
  REJECTED: 'Rejetée',
  FILTER_AUTH_REQUESTS_BY: "Filtrer les demandes d'authentification par",
  ALL_REQUESTS_ALL_TEAMS: 'Toutes les demandes pour toutes les équipes',
  ALL_TEAMS: 'Toutes les équipes',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST:
    "Vous n'avez pas le droit de modifier les demandes de catégorie / sujet.",
  SEND_FOLLOW_UP: 'Envoyer le suivi',
  RECEIPT_NOTIFICATION_CUSTOMER: 'Notification de réception au client',
  GDPR_DISCLAIMER: 'Clause de non-responsabilité RGPD',
  ACTIONS: 'Actions',
  SUBMISSION_CONDITIONS: 'Conditions de soumission',
  TYPE_OF_NOTIFICATION: 'Type de notification',
  DIRECT_SUBMISSION: 'Soumission directe',
  DIRECT_SUBMISSION_TOOLTIP:
    'Pour les demandes soumises directement par le client par e-mail, envoyez une notification pour informer le client du traitement de ses données personnelles.',
  COMPANY_EMPLOYEE_SUBMISSION: "Soumis via un employé de l'entreprise",
  ENABLE_NOTIFICATION: 'Activer les notifications',
  NOTIFICATION_SENDER: "Notification à l'expéditeur",
  SALES_REP_DELIVERED_RESPONSE: 'Représentant des ventes pour la réponse livrée',
  NOTIFICATION_DELIVERED_RESPONSE: 'Notification de réponse livrée',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    "Pour les demandes soumises via un représentant de l'entreprise(représentant commercial, MSL ou autre), notifier l'expéditeur lorsque le client a reçu une réponse à sa demande",
  FULL_ANSWER: 'Réponse complète',
  NOTIFICATION: 'Notification',
  NAME: 'Nom',
  COLUMN_INIT_QUESTION_TEXT: 'Texte de la question originale',
  NOTIFICATION_ENABLED: 'Notification activée',
  DEFAULT_TEMPLATE: 'Modèle par défaut',
  SET_AS_DEFAULT: 'Définir par défaut',
  DEFAULT_TEMPLATE_TOOLTIP:
    'Le basculement est désactivé car il doit y avoir au moins un modèle par défaut!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: 'Le modèle par défaut ne peut pas être supprimé!',
  BATCH_UPDATE_ONLY:
    'Seule une mise à jour par lots est possible via la case à cocher la plus haute.',
  ACCESS_DENIED: 'Accès refusé!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE:
    "Vous n'êtes pas autorisé à accéder à cette page.",
  USER_ROLES: "Rôles d'utilisateur",
  MANAGE_USER_ROLES: 'Gérer les rôles des utilisateurs',
  ARCHIVE_ROLE: "Rôle d'archivage",
  ROLE_ARCHIVE_CONFIRMATION: 'Voulez-vous vraiment archiver ce rôle?',
  ROLES_FOR: 'Rôles pour',
  AUDIT_TRAIL: "Piste d'audit",
  ACTION_BY_USER: "Action par l'utilisateur",
  ACTION: 'Action',
  ORIGINAL_VALUE: "Valeur d'origine",
  CHANGE: 'Modifier',
  CONDITION: 'État',
  IP: 'IP',
  GEOLOCATION: 'Géolocalisation',
  NEW_VALUE: 'Nouvelle valeur',
  FILTER_BY_ACTION: 'Filtrer par action',
  SELECT_DATE: 'Sélectionner une date',
  TEAM_SAVE: 'sauvegarder',
  TEAM_DELETE: 'Effacer',
  TEAM_CREATE: 'Créer',
  TEAM_CLONE: 'Copie',
  TEAM_NAME: 'Nom',
  TEAM_CODE: 'Code',
  TEAM_ACTION_DELETE_CONFIRM: 'Voulez-vous vraiment supprimer',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: 'Faire attention!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: 'Une fois supprimé, il ne peut pas être restauré!',
  TEAM_FULL_ANSWER_ON_LABEL: "Réponse complète sur l'étiquette",
  TEAM_RESTRICTED_TEAM: 'Limitée Limité',
  TEAM_SEGREGATED_DATA: 'Données séparées',
  TEAM_PRIVATE_TEAM: 'Équipe privée',
  TEAM_AUTO_ASSIGN: 'Assignation automatique',
  TEAM_MAILBOX_IN: 'Boîte aux lettres entrante',
  TEAM_MAILBOX_OUT: 'Boîte aux lettres sortante',
  TEAM_DEFAULT_LANGUAGE: 'Langage par défaut',
  TEAM_COUNTRIES: 'Pays assignés',
  TEAM_PRODUCT_GROUPS: 'Affecter les groupes de produits',
  TEAM_PARENT: 'Équipe parentale',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'Équipe de racine de cluster de remplacement',
  TEAM_AFFILIATIONS: 'Équipes affiliées',
  TEAM_TIMEZONE: 'Fuseau horaire',
  UPDATE: 'Mise à jour',
  AUTO_ASSIGN_BY: 'Attribution automatique par',
  AUTO_ASSIGNMENTS: 'Affectations automobiles',
  NO_ONE: 'Personne',
  NOT_APPLICABLE: "N'est pas applicable",
  OVERRIDE_NOTIFICATIONS: 'Notifications de remplacement',
  OVERRIDE_NOTIFICATIONS_HINT:
    "Lorsque le modèle d'utilisateur avec des notifications de remplacement est défini sur l'utilisateur, il désactivera tous les paramètres de notification préexistants et activera uniquement les paramètres de notification répertoriés dans le modèle d'utilisateur",
  CREATED_MANUALLY: 'Créé manuellement',
  DOCUMENT_TITLE_FOR_WORD:
    "Le titre de documents de mots est généralement tiré de la section formatée de titre dans le document lui-même. Si le document ne contient pas de titre, vous pouvez le spécifier ici. Si vous ne spécifiez pas le titre ici et que le document Word ne contient pas de section formatée de titre, l'ID de document serait utilisé pour le titre.",
  ORDER_BY: 'Commandé par',
  FORMAT: 'Format',
  SELECT_QUESTION: 'Sélectionnez la question',
  SELECT_QUESTION_FOR_NOTE:
    'Sélectionnez la question pour laquelle vous souhaitez ajouter une note',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: "Ajouter une note pour l'enquête entière",
  REQUESTED_DATE: 'Date demandée',
  UNASSIGNED: 'Non attribué',
  BLANK: 'Blanche Blanc',
  ANSWER_SAVED_WITHOUT_EMAIL: 'Réponse enregistrée sans e-mail',
  INQ_RELATED_TO: 'Demandes liées à',
  QUESTION_TEXT_BEFORE_SPLIT: 'Question Texte avant la séparation',
  SPLIT_MERGE: 'Se séparer et fusionner',
  CLARIFICATION_QUESTION: 'Question de clarification',
  ACTIVE_ON: 'Actif sur',
  DISMISS: 'Rejeter',
  GO_TO_ENQUIRY: 'Aller enquête',
  NO_TEAMS: 'Sans équipe',
  NO_TEAMS_TIP:
    "Contient des utilisateurs, qui ne sont affectés à aucune équipe. Vous pouvez vérifier si certains d'entre eux sont de votre équipe et leur accorder le rôle approprié.",
  TASKS: 'Tâches',
  Therapeutic_Areas: 'Zones thérapeutiques',
  Product_Groups: 'Groupes de produits',
  Licensing_Partner: 'Partenaire de licence',
  Product_Code: 'Code produit',
  SELECT_EXISTING_ONE: 'Sélectionnez un existant',
  OR: 'ou',
  MANAGE_FORM: 'Gérer la forme',
  EVENT_FORM_ADDRESS: "Adresse du formulaire d'événement",
  EVENT_FORM_PREVIEW_WARING:
    "Assurez-vous que vous avez enregistré des modifications avant d'ouvrir le formulaire du Congrès ou de copier son adresse",
  SEARCH_SAVED_SEARCHES: 'Recherche de recherches enregistrées',
  GO: 'Aller',
  ASSIGN: 'Attribuer',
  CERTIFICATION: 'Certification',
  SORT: 'Trier',
  CREATE_NEW_MAILBOX: 'Créer une nouvelle boîte aux lettres',
  EDIT_MAILBOX: 'Modifier la boîte aux lettres',
  ADD_MAILBOX: 'Ajouter la boîte aux lettres',
  UNSET_INCOMING_MAILBOX: 'Nettcore la boîte aux lettres entrante',
  SET_INCOMING_MAILBOX: 'Définir la boîte aux lettres entrante',
  UNSET_OUTGOING_MAILBOX: 'Une boîte aux lettres sortante non',
  SET_OUTGOING_MAILBOX: 'Définir la boîte aux lettres sortante',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'Toutes les équipes avec une boîte aux lettres actuelle',
  MAILBOX_HISTORY: 'Historique des boîtes aux lettres',
  ALL_TEAMS_WITH_MAILBOX: 'Toutes les équipes avec boîte aux lettres',
  MAILBOX: 'Boites aux lettres',
  EMAIL_POLLING: 'Sondage par e-mail',
  ADVANCED_SEARCH: 'Recherche Avancée',
  SELECT_FIELDS: 'Sélectionner les champs',
  SEARCH_TERM: 'Terme de recherche',
  COLUMN_INTERACTION_ID: "ID d'interaction",
  COLUMN_QUESTION_ID: "Pièce d'identité",
  COLUMN_CREATED_ON: 'Créé sur',
  COLUMN_LAST_EDITED_DATE: 'Dernier édité sur',
  COLUMN_ASSIGNED_TO: 'Affecter à',
  COLUMN_DUE_DATE: "Date d'échéance",
  COLUMN_FOLLOW_SENT_DATE: "Date d'envoi de suivi",
  COLUMN_FOLLOW_RECEIVED_DATE: 'Date de suivi reçu',
  COLUMN_ANSWER_DATE: 'Date de réponse',
  COLUMN_CLOSED_ON_DATE: 'Fermé à la date',
  COLUMN_REOPENED_DATE: 'Date de réouverture',
  COLUMN_INSERTED_BY: 'Inséré par',
  COLUMN_LAST_EDITED_BY: 'Dernier édité par',
  COLUMN_ANSWERED_BY: 'Répondu par',
  COLUMN_APPROVED_BY: 'Approuvé par',
  COLUMN_INT_COMM_USER: 'Utilisateur de communication interne',
  COLUMN_INT_COMM_DATE: 'Date de communication interne',
  COLUMN_MY_TEAM: 'Mon équipe',
  COLUMN_PQC_RELATED: 'PQC lié',
  COLUMN_INCOMING_CHANNEL: 'Canal entrant',
  COLUMN_OUTGOING_CHANNEL: 'Canal sortant',
  COLUMN_OFF_LABEL: 'Sans étiquette',
  COLUMN_HCP_SPEC: 'Spécialisation HCP +',
  COLUMN_NON_HCP_SPEC: 'Profession non-HCP +',
  COLUMN_KEE: 'Kee',
  COLUMN_HOSPITAL_BASED: "À l'hôpital",
  COLUMN_CITY: 'Ville',
  COLUMN_INSTITUTION: 'Institution',
  COLUMN_TERRITORY: 'Territoire',
  CASE_ID: 'ID de cas',
  OPTION_ADD: 'AJOUTER',
  OPTION_AND: 'ET',
  OPTION_OR: 'OU',
  OPTION_NOT: 'NE PAS',
  OPTION_YES: 'Oui',
  OPTION_NO: 'Non',
  QUERY: 'Requête',
  EXPORT_LABEL: "Définir les colonnes d'exportation",
  SAVE_SEARCH: 'Enregistrer la recherche',
  SHARE_SEARCH: 'Partager la recherche',
  SEARCH_BTN: 'Chercher',
  BACK_ACTION: 'Retour',
  SAVE_CHANGES: 'Sauvegarder les modifications',
  SAVED_SEARCH: 'Recherche enregistrée',
  NEW: 'Nouvelle Nouveau',
  SAVE_AS: 'Enregistrer sous le nom',
  ENTER_RECIPIENTS: 'Entrez les destinataires',
  CLEAR_SAVED_SEARCH: 'Recherches enregistrées claires',
  NO_SAVED_SEARCH: 'Aucune recherche enregistrée trouvée',
  INT_COMM_TEXT: 'Texte de communication interne',
  FOLLOW_UP_TEXT: 'Texte de suivi',
  CONFIRM_DELETION: 'Confirmer la suppression',
  ARE_SURE: 'Êtes-vous sûr de vouloir supprimer la recherche enregistrée',
  PERMANENTLY_DELETED: 'La recherche sera en permanence <b> supprimée </b>.',
  NOTHING_SAVE: 'Rien à sauver.',
  NOTHING_SEARCH: 'Rien à rechercher.',
  NOTHING_EXPORT: "La recherche n'a aucun enregistrement d'exportation.",
  SHARE_SUCCESS: 'La recherche a été partagée avec succès',
  SHARE_FAILURE: "La recherche n'a pas pu être partagée",
  DELETED_SUCCESS: 'Recherche enregistrée avec succès',
  ALL_DELETED_SUCCESS: 'Supprimé avec succès toutes les recherches enregistrées',
  VIEW_SAVED_SEARCH: 'Afficher la recherche enregistrée',
  EDIT_SAVED_SEARCH: 'Modifier la recherche enregistrée',
  EXPORT_SAVED_SEARCH: 'Exporter la recherche enregistrée',
  EXPORT_SEARCH: "Recherche d'exportation",
  DELETE_SAVED_SEARCH: 'Supprimer la recherche enregistrée',
  SELECT_EXPORT_COLUMNS: 'Sélectionnez des colonnes pour exporter',
  SAVED_SEARCH_ALREADY_EXIST: 'La recherche avec ce nom existe déjà.',
  CHARACTER_LIMIT: 'Min 3 caractères. MAX 35 caractères.',
  CHARACTER_LEFT: 'Les personnages sont laissés',
  ACTIVATE: 'Activer',
  INACTIVE_USER:
    "Cet utilisateur n'est plus actif. Veuillez contacter votre administrateur système.",
  FOLLOW_UP: 'Suivre',
  USER_NOT_FOUND: 'Utilisateur non trouvé',
  DEPARTMENTS: 'Départements',
  EMAIL: 'E-MAIL',
  ADD_NEW_DEPARTMENT: 'Ajouter le nouveau département',
  DEPARTMENT_COUNTRY: 'PAYS',
  DEPARTMENT_COMPANY: 'COMPAGNIE',
  DEPARTMENT_TEAM: 'ÉQUIPE',
  DEPARTMENT_TA: 'Zone thérapeutique',
  DEPARTMENT_PRODUCT: 'PRODUIT',
  DEPARTMENT_ACTIONS: 'ACTIONS',
  DEPARTMENT_CONFIRMATION_1: 'Confirmer la suppression?',
  DEPARTMENT_CONFIRMATION_2: 'Cette action est irréversible.',
  DEPARTMENT_NAME: 'Nom du département',
  TYPE_DEPARTMENT: 'Taper',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: "Pays - Veuillez d'abord sélectionner l'équipe",
  DEP_NAME_CHARACTER_LIMIT: 'Max 200 caractères.',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: "Demande de l'utilisateur",
      CategoryTopicRequest: 'Catégorie / demande de sujet',
      ProductRequest: 'Demande de produit',
      UserUpdateRequest: "Demande de mise à jour de l'utilisateur",
    },
    TYPE: {
      NewUser: 'Nouvel utilisateur',
      NewCategoryTopic: 'Nouvelle catégorie / sujet',
      NewUserUpdate: "Mise à jour de l'utilisateur",
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS:
        'Définir la liste de spécialisation spécifique du pays',
      IS_COUNTRY_SPECIFIC: 'Est-ce spécifique au pays',
      TYPE: 'Type de demandeur',
      SPECIALIZATION_EXISTS:
        'La configuration pour « Type/Spécialisation du demandeur » existe déjà.',
    },
    AUTHORIZATION_REQUESTS: { GENDER: 'Le genre', COUNTRY: 'Pays', TITLE: 'Titre' },
  },
  hcp: 'HCP',
  emp: 'Employée Employé',
  cons: 'Non-HCP',
  OOO_DEPUTY: 'OOO ENTAY',
  NO_DEPUTY: 'Pas de député',
  UNSPECIFIED_END_DATE: 'Date de fin non spécifiée',
  DELEGATORS: 'Délégantes Délégants',
  NO_DELEGATORS: 'Pas de délégants',
  PREVIEW_DELEGATORS: 'Aperçu des délégants',
  REMOVE_DEPUTY: 'Retirer le député',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'Les utilisateurs qui vous ont défini comme adjoint',
  CHANGED_USER: 'Utilisateur modifié',
  DEFAULT: 'Défaut',
  SPECIALIZATIONS: 'Spécialisations',
  ENQUIRERS_LIST: 'Liste des enquêteurs',
  EDIT_THE_RECORD: 'Modifier le record',
  DEACTIVATE_THE_RECORD: 'Désactiver le dossier',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'Absent du bureau',
      HINT: "Seuls les utilisateurs actifs, avec un rôle de traitement de l'enquête, qui ne sont pas hors service, sont disponibles pour la sélection.",
      MODAL_TITLE: 'Confirmer les paramètres hors bureau',
      MODAL_MSG_1:
        "Vous avez mis l'option hors bureau avec effet immédiat, toutes vos demandes seront immédiatement attribuées au député.",
      MODAL_MSG_2:
        "Vous avez mis en place l'option de bureau, toutes vos demandes seront affectées au député pendant la période de sortie du bureau.",
    },
  },
  GENDER_MALE: 'M.',
  GENDER_FEMALE: 'Mme.',
  TITLE_DR: 'Docteur',
  TITLE_PHD: 'Doctorat',
  TITLE_PROF: 'Prof.',
  TITLE_PROF_DR: 'Prof. Dr.',
  HOME: 'Maison',
  HOSPITAL: 'Hôpital',
  OFFICE: 'Bureau',
  WORK: 'Travailler',
  INSERT_IMAGE: 'Insérer une image',
  USER_ADMIN: 'Admin utilisateur',
  MAILBOXES: 'Boîtes aux lettres',
  AUTHORIZATION_REQUESTS: "Demandes d'autorisation",
  METADATA: 'Métadonnées',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: 'Mission',
      FORWARD: "Vers l'avant",
      FOLLOW_UP: 'Suivre',
      DUE_DATE_SETTINGS: "Paramètres de date d'échéance",
      CLOSE: 'proche',
      DELIVERY_ERROR: 'Erreur de livraison',
      SCHEDULER: 'Planificatrice Planificateur',
      USER_REPORTS: "Rapports d'utilisateurs",
      DOCUMENT_APPROVAL: 'Approbation de document',
      AUTHORIZATION_REQUEST: "Demandes d'autorisation",
      NEW_INQUIRY: 'Nouvelle demande',
      INQUIRY_APPROVAL: 'Approbation de la demande',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: 'Une demande de rôle a été approuvée / rejetée',
      AUTHORIZATION_REQUEST_3: 'Un rôle attend mon approbation',
      DOCUMENT_APPROVAL_1: 'Un document attend votre approbation',
      DOCUMENT_APPROVAL_2: 'Un document a été approuvé / rejeté',
      DOCUMENT_APPROVAL_3: 'Un document a été archivé',
      DOCUMENT_APPROVAL_4: 'Un document a été approuvé avec des changements',
      DOCUMENT_APPROVAL_5: 'Un document sera archivé dans',
      DOCUMENT_APPROVAL_6: 'Liste des documents approuvés',
      DUE_DATE_2: 'Répéter après le passé',
      ERROR_1: 'Échec de la notification de livraison (par exemple, e-mail incorrect)',
      FOLLOW_UP_1: 'Un suivi ou un rappel a été envoyé au client',
      FOLLOW_UP_4: 'Un suivi a été reçu',
      IMPORT_DATA_1: "Un nouvel objet a été créé pendant l'importation",
      IMPORT_DATA_4: "Un nouvel utilisateur a été créé pendant l'importation",
      IMPORT_DATA_5:
        "Un utilisateur correspondait à un déjà existant mais ne provenait pas d'une importation précédente",
      IMPORT_DATA_6:
        "Une erreur s'est produite lors de l'importation de données à partir d'une source externe",
      INQUIRY_APPROVAL_4:
        'Votre réponse proposée a été approuvée / approuvée avec des modifications / rejetés',
      METADATA_MANAGEMENT_1: 'Une nouvelle métadonnée a été créée',
      NEW_DOCUMENT_1: 'Un nouveau document approuvé est disponible dans mon pays',
      NEW_DOCUMENT_2: "Un nouveau document est disponible pour l'approbation locale",
      NEW_DOCUMENT_3:
        "Un nouveau document local est disponible pour l'approbation européenne / mondiale",
      NEW_DOCUMENT_4: 'Un nouveau document mondial est disponible',
      NEW_DOCUMENT_5: 'Un nouveau document européen est disponible',
      NEW_DOCUMENT_6: "Un nouveau document européen est disponible pour l'approbation mondiale",
      SCHEDULER_TASK_1: "Une erreur s'est produite lors de l'exécution d'une tâche",
      USER_REPORTS_1: 'Liste des utilisateurs actifs',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3:
          "Recevoir des notifications vous informant lorsqu'une demande de rôle utilisateur doit être approuvée / rejetée",
        AUTHORIZATION_REQUEST_2:
          "Recevoir des notifications lorsque un nouvel utilisateur a été créé ou lorsqu'un rôle a été demandé par un utilisateur de votre équipe",
        AUTHORIZATION_REQUEST_4:
          "Recevoir des notifications lorsqu'un nouveau sujet et / ou une catégorie a été suggéré",
        FOLLOW_UP_4:
          'Recevoir des notifications pour les demandes auxquelles je suis affecté et qui ont reçu une réponse de suivi de la part du demandeur',
        NEW_INQUIRY_1:
          'Recevez des notifications pour les nouvelles demandes reçues dans votre pays, insérées par un demandeur/utilisateur ou transmises par une autre équipe',
        ANSWER_2:
          "Recevoir des notifications pour les demandes auxquelles il a été répondu que j'ai travaillé en tant que collaborateur, mais que je ne suis pas directement affecté à la demande",
        DUE_DATE_1:
          'En sélectionnant cette option, toutes les notifications ci-dessous seront activées. Pour réduire le nombre de notifications, désélectionnez celles qui ne sont pas nécessaires.',
      },
      FREQUENCIES: {
        INSTANTLY: 'Immédiatement',
        DAILY: 'du quotidien',
        WEEKLY: 'Hebdomadaire',
        QUARTERLY: 'Trimestrielle Trimestriel',
        MONTHLY: 'Mensuelle Mensuel',
        SECOND_DAY: 'Tous les deux jours',
        THIRD_DAY: 'Chaque troisième jour',
        ONE_WEEK: 'Une semaine',
        ONE_MONTH: 'Un mois',
        TWO_MONTHS: 'Deux mois',
        THREE_MONTHS: 'Trois mois',
        SIX_MONTHS: 'Six mois',
        NINE_MONTHS: 'Neuf mois',
        EVERY_TWO_WEEKS: 'Toutes les deux semaines',
        EVERY_THREE_WEEKS: 'Toutes les trois semaines',
        EVERY_FOUR_WEEKS: 'Toutes les quatre semaines',
      },
      OPTIONS: {
        ALMOST_DUE: 'Presque en raison',
        DUE_NOW: 'Pour tout de suite',
        DUE_SOON: 'Dû bientôt',
        EARLY_ON: 'Tôt',
        TEXT_GUIDES: {
          DUE_NOW: 'Recevez des notifications lorsque la demande est due',
          EARLY_ON:
            "Recevez des notifications à l'avance pour les demandes qui doivent être traitées: Normal 4 jours - Élevé 2 jours - Critique 24 heures avant la date d'échéance",
          DUE_SOON:
            "Recevez des notifications pour les demandes qui doivent être traitées prochainement: Normal 48 heures - Élevé 12 heures - Critique 6 heures avant la date d'échéance",
          ALMOST_DUE:
            "Recevez des notifications pour les demandes qui arrivent à échéance : Normal 24 heures - Élevé 6 heures - Critique 3 heures avant la date d'échéance",
        },
      },
      AUTHORIZATION_REQUEST_4: 'Une catégorie / sujet a été demandé dans mon équipe',
      AUTHORIZATION_REQUEST_2: "Demandes d'utilisateurs",
      IMPORT_DATA_2: "Un nouveau demandeur a été créé lors de l'importation",
      IMPORT_DATA_3:
        "Un demandeur correspondait à un demandeur déjà existant mais ne provenait pas d'une importation précédente",
      ANSWER_2: "Une enquête à laquelle j'ai participé a reçu une réponse",
      ASSIGNMENT_1: "Une enquête m'a été confiée",
      ASSIGNMENT_5: 'Une enquête a été confiée à mon adjoint',
      CLOSE_1: 'Une enquête a été clôturée',
      DUE_DATE_1:
        "Une enquête à laquelle je suis affecté est proche de la date d'échéance, informez-moi",
      FOLLOW_UP_7: 'Une enquête a été perdue pour le suivi',
      FORWARD_1: 'Une demande de renseignements a été transmise',
      INQUIRY_APPROVAL_1: 'Une demande est en attente de votre approbation',
      INTERNAL_1: 'Une note a été ajoutée à ma demande assignée',
      NEW_INQUIRY_1: 'Une nouvelle demande a été reçue dans mon pays',
      SPAM_1: 'Nouvelle demande reçue dans le spam',
      ANSWER_1: "Recevoir une copie de la réponse dans les interactions auxquelles j'ai répondu",
      ANSWER_6: 'Recevoir une copie de la réponse dans les interactions approuvées par moi',
    },
  },
  NEW_TEAM: 'Nouvelle équipe',
  SmPc: 'SMPC',
  USERS: 'Utilisatrices Utilisateurs',
  PAGINATOR_NEXT_PAGE_LABEL: 'Page suivante',
  PAGINATOR_PREV_PAGE_LABEL: 'Page précédente',
  PAGINATOR_FIRST_PAGE_LABEL: 'Première page',
  PAGINATOR_LAST_PAGE_LABEL: 'Dernière page',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'objets par page',
  Date: 'Date',
  Action: 'Action',
  'Action by user': "Action par l'utilisateur",
  'Changed user': 'Utilisateur modifié',
  'Original value': "Valeur d'origine",
  'New value': 'Nouvelle valeur',
  REGION: 'Région',
  TEAM_REGION_LABEL: "Région - Veuillez d'abord sélectionner l'entreprise",
  TEAM_THERAPEUTIC_AREA_LABEL: "Zones thérapeutiques - Veuillez d'abord sélectionner l'entreprise",
  TEAM_PRODUCT_LABEL: "Produits - Veuillez d'abord sélectionner la zone thérapeutique",
  TEAM_COUNTRIES_LABEL: "Pays - Veuillez d'abord sélectionner la région",
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'Définir les produits pour chaque pays',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'Veuillez sélectionner au moins un produit pour',
  VIEW_CHANGES: 'Afficher les modifications',
  Sales_Representative: 'Commercial',
  Other_Commercial: 'Autres publicités',
  REGIONS: 'Régions',
  MAIN_CLIENT: 'Client principal',
  LICENSE_PARTNER: 'Partenaire de licence',
  TEAM_SET_UP: 'Équipe installée',
  LIST_TEAMS: 'Équipes de liste',
  COMPANIES: 'Entreprises',
  DOMAINS: 'Domaines',
  TOKEN_REFRESH_NOTICE:
    "Vous avez demandé un nouvel e-mail de confirmation pour l'enregistrement de votre compte Enqmed.",
  TOKEN_REFRESH_SUCCESS: 'Un nouveau lien de confirmation a été envoyé à votre e-mail.',
  TOKEN_REFRESH_ERROR:
    "Une erreur s'est produite. Veuillez contacter l'assistance si vous avez besoin d'aide supplémentaire.",
  PLEASE_ENTER_MAX: 'Veuillez saisir maximum',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    "Veuillez consulter les modifications ci-dessous, le cas échéant, et confirmez si l'entreprise doit être modifiée.",
  COMPANY_ACTION_EDIT_WARNING_MESSAGE:
    'Veuillez procéder avec prudence car les modifications affecteront les équipes',
  THERE_ARE_NO_CHANGES: "Il n'y a pas de changements.",
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Veuillez vérifier les entreprises existantes dans le tableau derrière la boîte de dialogue car nous avons déjà une entreprise avec les mêmes valeurs.',
  SIMPLE_TEXT:
    'Veuillez saisir uniquement les lettres (A-Z, A-Z), les numéros (0-9) et les symboles spéciaux comme (Dash, période, espace et colon).',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS:
    'Veuillez sélectionner au moins un pays pour la région',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    'Veuillez sélectionner au moins un produit pour la zone thérapeutique',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    "Vous avez sélectionné des produits / s appartenant à plus d'une zone thérapeutique, veuillez sélectionner toutes les zones thérapeutiques nécessaires.",
  HTML_CHANGES: 'Changements HTML',
  SIDE_BY_SIDE_VIEW: 'Vue côte à côte',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Veuillez vérifier les domaines existants dans le tableau derrière la boîte de dialogue car nous avons déjà un domaine avec les mêmes valeurs.',
  OUT_CHANNEL_ERROR_MSG: 'Adresse postale de la lettre de canal sortante Enquirer',
  OUT_CHANNEL_ERROR_MSG_LONG:
    "Canal sortant sélectionné comme lettre. Veuillez saisir l'adresse postale de Enquirer dans la zone Enquirer",
  LABEL_FOR: 'Étiqueter',
  male: 'Femelle Mâle',
  female: 'Femelle',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    "Vous n'avez pas le droit d'archiver ce rôle. Seul <b> mims </b> pour l'équipe peut archiver des rôles pour les membres de leur équipe.",
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION:
    "Envoyer un e-mail à l'utilisateur sur demande approbation / rejet",
  APPROVE_REQUEST_QUESTION: 'Voulez-vous approuver la demande?',
  NEW_USER_REQUEST: 'Nouvelle demande utilisateur',
  OPEN_REQUESTS: 'Demandes ouvertes',
  APPROVED_REQUESTS: 'Demandes approuvées',
  REJECTED_REQUESTS: 'Demandes rejetées',
  USER_REQUESTS: "Demandes d'utilisateurs",
  REQUEST_FOR: 'Demande',
  CATEGORY_TOPIC_REQUESTS: 'Demandes de catégorie / sujet',
  WITH_THE_FOLLOWING_PAIRS: 'avec les paires suivantes:',
  MY_TEAM_OPEN_REQUESTS: 'Demandes ouvertes',
  MY_TEAM_APPROVED_REQUESTS: 'Demandes approuvées',
  MY_TEAM_REJECTED_REQUESTS: 'Demandes rejetées',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: "Document l'approbation dans EnqMed",
  DOCUMENTS_APPROVAL: 'Approbation des documents',
  DOCUMENTS_APPROVAL_TITLE:
    "Paramètres du processus d'approbation des documents pour l'équipe sélectionnée",
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    "Canal sortant sélectionné comme fax. Veuillez saisir le numéro de fax d'Enquirer dans la zone Enquirer",
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    "Canal sortant sélectionné comme téléphone. Veuillez saisir le numéro de téléphone d'Enquirer dans la zone Enquirer",
  OUT_CHANNEL_FAX_ERROR_MSG: "Valeur de l'enquêteur de fax de canal sortant",
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: 'Valeur du téléphone de canal sortant Enquirer',
  COPYRIGHT_PART_ONE: "droits d'auteur",
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. Tous droits réservés.',
  REVIEWED_DATE: 'Date examinée',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    "Canal sortant sélectionné comme e-mail. Veuillez saisir l'e-mail d'Enquirer dans la zone Enquirer",
  OUT_CHANNEL_EMAIL_ERROR_MSG: "Valeur de l'e-mail de la chaîne sortante Enquirer",
  ENTER_USER_COMMENT_OPTIONAL: "Entrez le commentaire à l'utilisateur (facultatif)",
  SELECT_ANOTHER_CATEGORY: 'Sélectionnez une autre catégorie',
  SELECT_ANOTHER_PRODUCT: 'Sélectionnez un autre produit',
  SUGGESTED_TOPIC: 'Sujet suggéré',
  EDIT_USER_REQUEST: "Modifier la demande de l'utilisateur",
  NEW_TOPIC_REQUEST: 'Nouveau sujet suggéré',
  EDIT_TOPIC_REQUEST: 'Modifier le sujet suggéré',
  NEW_CATEGORY_REQUEST: 'Nouvelle catégorie suggérée',
  EDIT_CATEGORY_REQUEST: 'Modifier la catégorie suggérée',
  REVIEW_COMMENT: 'Revoir commentaire',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'Document Approbation Workflows',
    CREATE_NEW: 'Créer un nouveau workflow',
    CUSTOMIZE: 'Personnaliser le flux de travail',
    NAME: 'Nom du flux de travail',
    ERROR_MSG_DUPLICATE_NAME: 'Le flux de travail avec ce nom existe déjà.',
    SAVE: 'Sauver le flux de travail',
    DELETE: 'Supprimer le workflow',
    STEP: 'Étape',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: 'Tous les utilisateurs avec un rôle auteur de documents.',
    APPROVAL_DEADLINE: "Date limite d'approbation",
    SELECT_APPROVAL_TYPE: "Sélectionnez le type d'approbation",
    SELECT_APPROVAL_TYPE_FIRST: "Sélectionnez d'abord le type d'approbation",
    SELECT_REVIEWERS: 'Sélectionner les critiques',
    ACTION_ON_APPROVE_WITH_CHANGES: 'Action sur approuver avec les changements',
    ACTION_ON_REJECT: 'Action sur rejeter',
    FILL_ALL_REQUIRED_FIELDS:
      "Veuillez remplir tous les champs requis afin d'ajouter une nouvelle étape.",
    ALL_APPROVAL_TYPES_USED:
      "Vous ne pouvez pas ajouter plus de sous-étapes d'approbation car vous avez utilisé tous les types disponibles pour l'étape actuelle.",
    CONFIRMATION_ROW_1:
      "Vous essayez de supprimer le flux de travail d'approbation des documents pour l'équipe {{ NAME }}.",
    CONFIRMATION_ROW_2: "Voulez-vous vraiment supprimer le flux de travail d'approbation?",
    CONFIRMATION_TITLE: "Suppression du workflow d'approbation du document",
    TEAM_VIEW_SELECT_EXISTING:
      "Sélectionnez un flux de travail d'approbation de document existant dans la liste ci-dessous ou ",
    DOC_VIEW_SELECT_EXISTING: "Sélectionnez un flux de travail d'approbation de document existant",
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      "Une seule étape d'approbation unique peut être répertoriée comme définitive.",
    APPROVAL_TYPE_TOOLTIP:
      "Il n'y a pas d'utilisateurs avec un rôle d'approbation de documents pour cette équipe. Veuillez d'abord ajouter des rôles d'approbation.",
    INFO_DIALOG_TITLE: "Il n'y a pas de workflow d'approbation de document pour cette équipe.",
    INFO_DIALOG_MSG:
      "Veuillez contacter votre MIM local pour créer un workflow d'approbation de documents pour l'équipe.",
    STEP_LIMIT_REACHED: "Vous avez atteint la limite de {{ MAX_STEPS }} étapes d'approbation.",
  },
  DOCUMENTS_WITHOUT_APPROVAL: "Types de documents qui ne nécessitent pas d'approbation",
  PLEASE_SELECT_TYPE: 'Veuillez sélectionner le type',
  ABORT_FILE_UPLOAD: 'Aborter le téléchargement du fichier',
  USERS_REPORT: 'Signaler les utilisateurs',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG:
    'En fermant cette fenêtre, vous perdez les informations déjà remplies.',
  DO_YOU_WANT_TO_PROCEED: 'Voulez-vous poursuivre?',
  USERS_REPORT_DESCRIPTION:
    'Liste des utilisateurs de toutes les équipes supplémentaires où MIM pour Daiichi Sankyo Europe Oncology et Cardiovascular ont un rôle MIM.',
  TEMPLATE_CONFIRM_MSG: 'Une fois le modèle supprimé, il ne peut pas être restauré!',
  EVENT_CONFIRM_MSG: "Une fois l'événement archivé, il ne peut pas être restauré!",
  CREATE_DRAFT: 'Créer un projet',
  CREATE_USER: 'Créer un utilisateur',
  ADD_ROLE: 'Ajouter un rôle',
  DOMAIN_ADDRESS_INVALID_FOR: 'Adresse de domaine invalide pour',
  AVAILABLE_ADDRESSES: 'Adresses disponibles',
  IN_TEAM: 'en équipe',
  TO_THE_SELECTED_USER: "à l'utilisateur sélectionné",
  USER_INFORMATION: "Informations de l'utilisateur",
  LOGIN_VERIFY_EMAIL_ADDRESS:
    "L'adresse e-mail que vous utilisez doit être mise à jour car l'adresse de domaine ne correspond pas à l'équipe / s votre profil est associée. <br> Veuillez consulter votre administrateur système pour obtenir de l'aide ou contacter l'équipe d'assistance.",
  MESSAGE: 'Message',
  IN_APPROVAL: 'En approbation',
  IN_REVIEW: 'En revue',
  DOCUMENT_APPROVAL: 'Approbation de document',
  DOCUMENT_TITLE: 'Titre du document',
  SEARCH_DOCUMENTS: 'Rechercher des documents',
  APPROVAL_INBOX: "Boîte de réception d'approbation",
  FILTER_DOCUMENTS_BY: 'Filtrer les documents par',
  submit_to_approval: "Se soumettre à l'approbation",
  create_document_metadata: 'Créer des métadonnées de document',
  archive: 'Archive',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    "Je reconnais qu'il est mon obligation de signaler toutes les réactions indésirables (ADR) de tous les produits médicaux en fonction des exigences locales. Enqmed n'est pas destiné à saisir des rapports sur les réactions indésirables des médicaments - les ADR doivent être signalés directement dans la pharmacovigilance en fonction des exigences locales",
  VERSION_DIALOG: {
    TITLE: 'Nouvelle version disponible',
    MESSAGE:
      "Une nouvelle version du logiciel est disponible!\n Vous devez obtenir la nouvelle version, afin de bénéficier de ses améliorations et d'éviter les problèmes liés à l'utilisation de l'ancienne version, vous devez l'obtenir.",
    SAVE_BUTTON: 'Obtenez la nouvelle version',
  },
  ACTIVE_INACTIVE: 'Actif inactif',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Veuillez vérifier les catégories existantes dans le tableau derrière la boîte de dialogue car nous avons déjà une catégorie avec les mêmes valeurs.',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'Erreur! Échec de la livraison',
  VIA: 'via',
  INSERTED_MANUALLY: 'Inséré manuellement',
  AS_A: 'comme un',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Veuillez vérifier les produits existants dans le tableau derrière la boîte de dialogue car nous avons déjà des produits avec les mêmes valeurs.',
  NOT_PRODUCT_SPECIFIC: 'Non spécifique au produit',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Veuillez vérifier les sujets existants dans le tableau derrière la boîte de dialogue car nous avons déjà des sujets avec les mêmes valeurs.',
  PLEASE_ENTER_MAX_CHARACTERS: 'Veuillez saisir un maximum de {{ MAX_CHARS }} caractères.',
  THANK_YOU_MESSAGE: 'message de remerciement',
  AS: 'comme',
  THERAPEUTIC_AREA_PARENT: 'Espace parents',
  MAIN_THERAPEUTIC_AREA: 'Domaine thérapeutique principal',
  CHOOSE_AN_IMAGE: 'Choisissez une image',
  ALLOWED_FORMATS: 'Seuls les formats {{ FORMATS }} sont autorisés',
  REMOVE_IMAGE: "Supprimer l'image",
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Veuillez vérifier les zones thérapeutiques existantes dans le tableau derrière la boîte de dialogue car nous avons déjà des zones avec les mêmes valeurs.',
  PLAIN_TEXT_VIEW: 'Affichage en texte brut',
  RICH_TEXT_VIEW: 'Affichage en texte enrichi',
  EDIT_ITEM: 'Modifier {{ ITEM }}',
  CREATE_NEW_ITEM: 'Créer un nouveau {{ ITEM }}',
  ADD_NEW_ITEM: 'Ajouter un nouveau {{ ITEM }}',
  ADD_ITEM: 'Ajouter {{ ITEM }}',
  AND_HENCE_STATUS_CHANGED_FROM: 'et donc le statut a changé de',
  FOLLOW_UP_WITH_ENQUIRER: 'Suivi auprès du demandeur',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW:
    'Les éléments suivants doivent être insérés pour enregistrer les modifications:',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION:
    'Un suivi n’est pas possible pour une interaction fermée.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'Interaction en état de révision. Veuillez enregistrer votre avis pour activer ce menu.',
  QUESTION_TEXT_MIN_SIX_CHARS: 'Texte de la question (minimum 6 caractères)',
  PLEASE_PROVIDE_AN_ANSWER_:
    'Veuillez fournir une réponse (minimum 6 caractères ou une pièce jointe).',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    "Veuillez noter que l'utilisateur doit récupérer le mot de passe à partir de 'Mot de passe oublié'.",
  ENABLE_USER_LOGIN_MESSAGE: "Cela permettra à l'utilisateur de se connecter.",
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE:
    'Cela désactivera la connexion des utilisateurs et les notifications.',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    'Veuillez définir vos préférences de notification depuis votre profil utilisateur!',
  SET_PASSWORD: 'Veuillez définir votre mot de passe',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'Après réactivation de votre compte, votre mot de passe a été effacé.',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: 'Confirmez la désactivation de la spécialisation!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'Cela le supprimera de la liste des spécialisations actives.',
  GO_TO_ROLES: 'Aller aux rôles',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    "Vos modifications sur les informations de contact de l'utilisateur ont déclenché le changement de l'e-mail de connexion de {{ OLD_USERNAME }} à {{ NEW_USERNAME }}",
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'Veuillez attribuer des rôles et des notifications.',
  DO_YOU_WANT_TO_CONTINUE: 'Voulez-vous continuer?',
  COPY_ANSWER_TO_NEW_INTERACTION: 'Copier la réponse à la nouvelle interaction',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'De nouvelles demandes ont été importées. Assurez-vous de caractériser et de répondre à toutes les demandes.',
  LEAVE_WITHOUT_SAVING: 'Partir sans économiser',
  PENDING_CHANGES_MESSAGE: 'Les modifications en attente sont {{ CHANGES }}?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    'Êtes-vous sûr de vouloir diviser le texte sélectionné dans une nouvelle interaction?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    "Cela supprimera le texte sélectionné de l'interaction et en créera un nouveau.",
  DOWNLOAD_ERROR: 'Erreur de téléchargement!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    'Vous essayez de télécharger plus de 1000 demandes. Le nombre maximal pris en charge est 1000. Veuillez affiner votre sélection.',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION:
    'Vous essayez de modifier la caractérisation de la question!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    "Veuillez noter que changer la caractérisation de la question ne permettra aucune modification de la réponse précédemment donnée ni l'envoi d'une nouvelle réponse!",
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    'Vous êtes sur le point de rouvrir une interaction fermée pour envoyer une nouvelle réponse.',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE: 'Êtes-vous sûr de vouloir convertir ce suivi en question?',
  ARE_YOU_SURE: 'Es-tu sûr?',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: 'Confirmer la désactivation de {{ USER_INFO }}',
  USER_REACTIVATION_CONFIRMATION_QUESTION: 'Confirmer la réactivation de {{ USER_INFO }}',
  USER_REACTIVATED_MESSAGE: "L'utilisateur {{ USER_INFO }} a été réactivé avec succès!",
  DELETE_NOT_POSSIBLE: 'Supprimer impossible!',
  SEARCH_NO_LONGER_EXIST:
    "Cette recherche n'existe plus car elle a été supprimée par l'expéditeur.",
  LOGIN_CREDENTIALS_CHANGED: 'Informations de connexion modifiées!',
  EDIT_TEXT: 'Éditer le texte',
  FOLLOW_UP_ATTEMPTS: 'Tentatives de suivi',
  FIRST_FOLLOW_UP_INFO_TEXT: "Premier suivi envoyé dans le cadre d'une interaction individuelle",
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    "Si l'un des éléments ci-dessous est désactivé après la demande initiale, le processus passera à l'étape suivante.",
  SECOND_ATTEMPT: 'Deuxième tentative (1er rappel)',
  THIRD_ATTEMPT: 'Troisième tentative (1er rappel)',
  FINAL_ATTEMPT: 'Perdu de suivi (fermer)',
  REMINDER_FREQUENCY: 'Fréquence de rappel',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE:
    'Appliquer la traduction automatique dans la langue utilisée pour contacter le demandeur',
  ON_OFF_SWITCH: 'Allumé éteint',
  DAY: 'Jour',
  DAYS: 'Jours',
  MONTH: 'Mois',
  MONTHS: 'Mois',
  RESET_TEMPLATES_TO_DEFAULTS: 'Réinitialiser les modèles aux valeurs par défaut',
  SAVE_FREQUENCY: 'Enregistrer la fréquence',
  SELECT_FREQUENCY: 'Sélectionnez la fréquence',
  TEMPLATE_MODIFICATIONS_HINT:
    'Le texte a été modifié manuellement et ne sera plus modifié automatiquement. Cliquez ici pour annuler les modifications et réactiver les mises à jour automatiques.',
  LOGIN_INCORRECT_EMAIL_ADDRESS: 'Adresse e-mail incorrecte!',
  AUDIT_TRAIL_FOR: "Piste d'audit pour {{ ITEM }}",
  AUDIT_TRAIL_FOR_ALL: "Piste d'audit pour tous {{ ITEM }}",
  HISTORY_CHANGES_FOR: "L'historique change pour",
  HOLIDAY_GDPR_DISCLAIMER: 'Avis de non-responsabilité RGPD pour les fêtes',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER:
    'Gérer la clause de non-responsabilité du RGPD pendant les vacances',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS:
    'Modèle RGPD de vacances manquant pour la ou les équipes suivantes',
  BULK_CREATE: 'Création groupée',
  LAST_SUCCESS: 'Dernier succès',
  LAST_FAILURE: 'Dernier échec',
  FAILURE: 'Échec',
  TASK: 'Tâche',
  Weeks: 'Semaines',
  REPEAT_EVERY: 'Répétez à chaque',
  DEACTIVATE_ITEM: 'Désactiver {{ ITEM }}',
  RESET_FAILURE_COUNT: "Réinitialiser le nombre d'échecs",
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Veuillez vérifier les tâches existantes dans le tableau derrière la boîte de dialogue car nous avons déjà des tâches avec les mêmes valeurs.',
  ACTIVATE_ITEM: 'Activer {{ ITEM }}',
  PLEASE_ENTER_MIN_NUMBER: 'Veuillez saisir un nombre supérieur à {{ MIN_NUMBER }}.',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      "L'interaction cible sélectionnée pour la fusion est déjà fermée. Cette enquête est une nouvelle interaction et il n’est pas nécessaire de la fusionner.",
    DESCRIPTION_FOR_OPEN_INTERACTION:
      'Une ou plusieurs nouvelles questions seront ajoutées à la demande existante, toutes les questions doivent recevoir une réponse. Veuillez sélectionner les questions à fusionner.',
    VALIDATION_MSG:
      "Veuillez sélectionner au moins une question dans l'interaction fusionnée pour procéder à la fusion.",
  },
  MERGED_INTERACTION: 'Interaction fusionnée',
  TARGET_INTERACTION: 'Interaction cible',
  MERGE_SUGGESTION: 'Suggestion de fusion',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'Veuillez consulter la suggestion de fusion ci-dessous',
  INQUIRERS_COMPARISON_TITLE:
    "Veuillez noter que les demandeurs sont différents pour l'interaction fusionnée et l'interaction cible.",
  INQUIRERS_COMPARISON_QUESTION:
    'Veuillez confirmer si vous souhaitez continuer malgré cette différence.',
  TERRITORY: 'Territoire',
  DOMAIN: 'Domaine',
  IN: 'dans',
  TEAM_GROUP_FILTER: {
    TITLE: 'Définir des autorisations pour afficher des équipes supplémentaires',
    SAVE_BTN_TOOLTIP:
      "Veuillez saisir tous les champs obligatoires pour l'équipe et enregistrer d'abord l'équipe.",
  },
  ADR_REF_ID: 'Numéro de référence ADR',
  PQC_REF_ID: 'ID de référence PQC',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS:
    'Cet attachement est présent dans de multiples interactions',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'Veuillez cocher la case si vous souhaitez supprimer la pièce jointe de toutes les interactions.',
  CONFIRM_DELETE_ATTACHMENT: 'Veuillez confirmer si vous souhaitez supprimer cette pièce jointe.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE:
    'Supprimer de toutes les interactions mentionnées ci-dessus',
  ATTACHMENT_DELETED: 'Pièce jointe supprimée',
  ROLES: 'Les rôles',
  SET_TO_ALL_INQ_TOOLTIP:
    'Définissez la même caractérisation ADR, PQC et hors AMM pour toutes les questions.',
  TASKS_LIST: 'Liste des tâches',
  TASK_LOGS: 'Journaux de tâches',
  SELECTED: 'Choisi',
  LICENSE_COUNTER: 'Compteur de licences',
  LICENSES_LIMIT: 'Limite de numéros de licence',
  USERS_TO_EXCLUDE: 'Utilisateurs à exclure',
  ENTER_MAX_NUMBER_OF_LICENSES: "Frais de licence contractés (rôles d'utilisateur).",
  SELECT_USER_ROLES_TO_COUNT:
    "Sélectionnez les rôles d'utilisateur applicables pour le nombre de frais de licence.",
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'Excluez les utilisateurs du décompte des frais de licence.',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'Notifications de réception et de vente',
  REFRESH_MIGRATION_ERRORS: 'Actualiser la liste des erreurs de migration',
  MARKED_AS_RESOLVED_BY: 'Marqué comme résolu par',
  MARKED_AS_RESOLVED_ON: 'Marqué comme résolu le',
  MARK_AS_RESOLVED: 'Marquer comme résolu',
  ERROR_ON: 'Erreur sur',
  TEXT_WITH_SPECIAL_CHARS:
    "Veuillez saisir uniquement des lettres (a-z, A-Z), des chiffres (0-9) et des symboles spéciaux comme un tiret (-), un point (.), un espace, deux points (:), une virgule (,), des parenthèses (()), vers l'avant. barre oblique (/) et plus (+).",
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    'Interaction dans le statut soumis pour approbation. Veuillez approuver/rejeter pour activer ce menu.',
  REFRESH_TASK_LOGS: 'Actualiser la liste des journaux de tâches',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE:
    'Veuillez confirmer si vous souhaitez procéder aux modifications!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED:
    "L'archivage manuel n'est pas autorisé pour les rôles suppléants.",
  RUN_COUNTER: 'Compteur de course',
  EXPORT_LICENSES: "Licences d'exportation",
  LICENSE_COUNT_MESSAGE: 'Le nombre de licences utilisées est de:',
  QUESTIONS: 'Des questions',
  CLARIFICATION: 'Clarification',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG:
      "Veuillez sélectionner au moins une question dans l'interaction cible pour procéder à la fusion.",
    DESCRIPTION:
      "La question de clarification sera ajoutée sous la ou les questions sélectionnées dans l'interaction cible.",
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION:
      'Sélectionnez cette option pour signaler l’interaction comme un double d’une interaction existante.',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    "Fusion précédente en tant que question de clarification détectée. Tous les champs de réponse sélectionnés en plus des champs de réponse précédemment sélectionnés dans l'interaction cible seront rouverts pour réponse.",
  DISABLED_ANSWER_FIELD_TOOLTIP:
    'Interaction rouverte pour fournir une réponse à une autre question. La réponse à cette question a déjà été fournie précédemment.',
  TIP: 'Conseil',
  MERGE_TIP_TEXT:
    "Si vous souhaitez rouvrir l'interaction {{ MERGED_INTO_ID }} à la place, veuillez appuyer sur Non et lancer un nouveau processus de fusion indiquant l'interaction cible {{ MERGED_INTO_ID }}.",
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    "Vous sélectionnez une opération de fusion sans ouvrir l'interaction cible.",
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    "Vous sélectionnez une opération de fusion dans une interaction cible non valide {{ TARGET_INTERACTION_ID }}.<br>L'interaction {{ TARGET_INTERACTION_ID }} a été fermée avec le statut {{ STATUS }}{{ LAST_MERGE_INFO }}.",
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'Cela entraînera une interaction fermée et une incapacité à fournir une réponse.',
  INTO_INTERACTION: 'en interaction',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: "Veuillez sélectionner l'interaction à fusionner.",
  PLEASE_SELECT_MERGE_REASON: 'Veuillez sélectionner le motif de la fusion.',
  QUESTION_CANNOT_BE_DELETED:
    'Cette question ne peut pas être supprimée car il existe une question de clarification associée sans réponse.',
  MERGE_REASON: 'Raison de la fusion',
  WITH_REOPEN: 'avec réouverture',
  MERGED_INTO_QUESTION_IDS: 'fusionné avec les identifiants de question',
  MERGED_INTO_QUESTIONS: 'fusionné en question(s)',
  MERGED_PREVIOUSLY_FROM: 'fusionné précédemment à partir de',
  FROM_INTERACTION_ID: "à partir de l'ID d'interaction",
  IN_INTERACTION_ID: "dans l'ID d'interaction",
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION:
    "Vous êtes sur le point d'annuler la suggestion de fusion.",
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'Ne plus afficher la suggestion de fusion?',
  PRODUCT_RESOLVED_VIA_AI: 'Produit (résolu via AI)',
  REQUIRED_FIELDS_HINT: "S'il-vous-plaît remplissez tous les champs requis",
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: 'Au moins un contact est requis !',
  DISCLAIMER_INFO: 'Informations sur la clause de non-responsabilité',
  QUESTION_ID: 'Numéro de question',
  QUESTION_IDS: 'ID de questions',
  SHOW_TEAMS: 'Afficher les équipes',
  LAST_POLLING: 'Dernier sondage',
  HOST: 'Hôte',
  PROTOCOL: 'Protocole',
  FROM_EMAIL: "De l'email",
  REASON_FOR_REMOVAL: 'Raison de la suppression',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: "Une interaction fermée n'est pas modifiable.",
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    "L'interaction comporte plus d'une question. Modification possible à partir d'une interaction ouverte uniquement.",
  BULK_EDIT: 'Modification groupée',
  HOW_TO_ENABLE_BULK_EDIT:
    "Au moins deux modèles dans la même langue doivent être sélectionnés afin d'effectuer une mise à jour groupée.",
  REASON_FOR_UPDATE: 'Raison de la mise à jour',
  FOLLOW_UP_RESEND: 'Suivi ré-envoyé',
  SENT_FOLLOW_UP_REMINDER: 'envoyé un rappel de suivi',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'interaction fermée et notification de perte de vue envoyée',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS:
    "Veuillez saisir un texte d'une longueur minimale de 15 caractères.",
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST:
    "Suivi désactivé! Veuillez d'abord insérer le demandeur.",
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    "Demande de suivi désactivée! Veuillez d'abord insérer l'e-mail valide du demandeur.",
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'Le numéro du type de fréquence sélectionné doit être compris entre 1 et {{ MAX_ALLOWED_FREQUENCY }}.',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'Veuillez sélectionner le type de fréquence.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'Le décompte commence à la date et à l’heure de la demande de suivi initiale. Pour convertir les jours en semaines, multipliez par 7, par ex. 2 semaines = 14 jours, pour les jours ou les mois, multipliez par 30, par ex. 1 mois = 30 jours.',
  VALID_PHONE_FAX: 'Veuillez saisir un numéro de téléphone/fax valide!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    'Sélectionnez une langue pour afficher et gérer les modèles traduits',
  RETRANSLATE: 'Retraduire',
  FOLLOW_UP_REMINDERS_INFO_TEXT:
    'Les modèles ci-dessous sont en anglais uniquement, les traductions sont gérées ci-dessous',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'Tous les modèles ci-dessus seront réinitialisés aux valeurs par défaut (la modification manuelle sera supprimée, la fréquence réinitialisée à 14 jours et la case de traduction décochée), retraduits dans toutes les langues et effaceront is_manually_modified.',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'Modifiez le texte du modèle et retraduisez toutes les traductions qui ne sont pas modifiées manuellement.',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'Retraduisez le texte du modèle original, y compris ceux modifiés manuellement.',
  FOLLOW_UP_REMINDER_TEMPLATES: 'Modèles de rappel de suivi',
  MANUALLY_MODIFIED: 'Modifié manuellement',
  SYNC: 'Synchroniser',
  DIFFERENT: 'Différent',
  DEFAULT_CONFIRMATION_QUESTION: 'Veuillez confirmer si vous souhaitez continuer.',
  SEND_WELCOME_MAIL_: 'Envoyer un e-mail de bienvenue avec réinitialisation du mot de passe',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    "Je confirme qu'une nouvelle information de suivi a été reçue par le demandeur et que je souhaite rouvrir l'interaction pour fournir une réponse.",
  ONLY_LETTERS_SPACE_AND_DASH:
    'Veuillez saisir uniquement des lettres, des espaces et le symbole -!',
  INFO: 'Info',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    "Le changement de pays du demandeur modifiera l'affectation de l'interaction de l'équipe {{ CURRENT_TEAM }} à l'équipe {{ FUTURE_TEAM }}.",
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    "Le changement de pays du demandeur modifiera l'affectation de l'interaction de l'équipe {{ CURRENT_TEAM }} à l'équipe {{ FUTURE_TEAM }}. L'utilisateur {{ ASSIGNED_TO }} ne dispose pas des autorisations pour l'équipe {{ FUTURE_TEAM }} et son affectation personnelle sera supprimée.",
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    "Le changement affectera les affectations d'équipe dans les interactions supplémentaires suivantes, où les affectations personnelles seront supprimées: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}",
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    "Le changement de pays du demandeur modifiera les affectations d'équipe dans les interactions supplémentaires suivantes, où l'affectation personnelle sera supprimée. {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}",
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    "ID d'interaction: {{ INTERACTION_ID }} de {{ CURRENT_TEAM }} à {{ FUTURE_TEAM }}, affectation personnelle supprimée de l'utilisateur {{ ASSIGNED_TO }}",
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    "L'utilisateur {{ EDITING_USER }} a modifié le pays du demandeur {{ ENQUIRER }} dans l'ID d'interaction: {{ UPDATED_INTERACTION_ID }}, ce qui a modifié l'affectation de l'interaction actuelle de l'équipe {{ OLD_TEAM }} à l'équipe {{ NEW_TEAM }}.",
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    "L'utilisateur {{ EDITING_USER }} a modifié le pays du demandeur {{ ENQUIRER }} dans l'administration HCP, ce qui a modifié l'affectation de l'interaction actuelle de l'équipe {{ OLD_TEAM }} à l'équipe {{ NEW_TEAM }}.",
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    "L'utilisateur {{ INTERACTION_ASSIGN_TO }} n'a pas d'autorisations pour l'équipe {{ NEW_TEAM }} et son affectation personnelle est supprimée.",
  PERSONAL_ASSIGNMENT: 'Devoir personnel',
  DUE_TO_FU_REQUEST: "en raison d'une demande de suivi",
  DUE_TO_FU_RESPONSE: "en raison d'une réponse de suivi",
  DUE_TO_FU_REMINDERS_FREQ_UPDATE:
    "en raison de la mise à jour de la fréquence des rappels de suivi depuis la page d'administration",
  UPDATED_FROM_INBOX: 'mis à jour depuis la boîte de réception',
  ARCHIVED_ROLE: 'Rôle archivé',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    "L'archivage du rôle d'utilisateur modifiera les affectations dans les interactions supplémentaires suivantes, où les affectations personnelles seront supprimées: {{ USER_INTERACTIONS }}",
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    "L'utilisateur {{ EDITING_USER }} a modifié l'utilisateur {{ INTERACTION_ASSIGN_TO }}, archivant son rôle en tant que {{ USER_ROLE }} dans l'équipe {{ TEAM }}. L'utilisateur {{ INTERACTION_ASSIGN_TO }} n'a pas d'autorisations pour l'équipe {{ TEAM }} et son affectation personnelle est supprimée.",
  ASSIGNMENT: 'Affectation',
  PERIOD_TO_PERIOD_OVERLAP: 'La période et la période à laquelle se termine se chevauchent',
  PERIOD_TO_PERIOD_MISMATCH:
    'La période et la période à ont des durées différentes - {{ PERIOD }} {{ INTERVAL }} et {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'question créée à partir de la réponse de suivi',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    "Cette option permet de créer une nouvelle question à partir d'un suivi.",
  ADD_AS_A_NEW_QUESTION: 'Ajouter comme nouvelle question',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    "Veuillez noter que vous essayez d'archiver le dernier rôle approuvé pour cet utilisateur, ce qui entraînera la désactivation de l'utilisateur et empêchera son accès ultérieur à EnqMed.",
  ATTEMPTED_FOLLOW_UP: 'a tenté un suivi',
  FAILED_FU_ATTEMPT: 'Tentative de suivi échouée',
  UNABLE_TO_REACH_ENQUIRER: 'Impossible de joindre le demandeur.',
  FAILED_FU_ATTEMPT_TOOLTIP:
    "Sélectionnez cette option si une tentative de joindre le demandeur a été effectuée mais n'a pas abouti, par exemple en cas d'impossibilité de le joindre par téléphone, d'échec du rendez-vous en personne ou autre.\nLa tentative sera enregistrée dans l'historique des interactions.",
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS:
    'Vous serez redirigé vers une page pour laquelle vous détenez les autorisations.',
  FU_STOPPED_DUE_TO_ENQ_CHANGE:
    "en raison de l'arrêt du processus de suivi suite au changement de demandeur",
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    'Vous êtes sur le point de changer de demandeur, ce qui arrêtera le processus de suivi.',
  INQUIRER_DETAILS: "Détails de l'enquêteur",
  TEAM_NOTIFY_INQUIRER: "Avertir l'enquêteur",
  INQUIRER_TYPE: 'Type de demandeur',
  DELETE_INQUIRER_PII: 'Supprimer les informations personnelles du demandeur',
  MARKED_INQUIRER_PII_DELETE: 'Informations personnelles du demandeur marquées pour suppression',
  UNMARKED_INQUIRER_PII_DELETE: 'Informations personnelles du demandeur non marquées à supprimer',
  NEW_ENQUIRER: 'Nouveau demandeur',
  NEW_ENQUIRER_TOOLTIP:
    "Utilisez cette option pour créer un nouveau demandeur. Si vous devez modifier les données du demandeur existant, n'utilisez pas cette option.",
  CONFIRM_SET_NEW_ENQUIRER:
    'Veuillez confirmer que vous souhaitez remplacer le demandeur existant par un nouveau.<br>Toutes les informations saisies seront perdues.<br>Si vous souhaitez plutôt modifier les données du demandeur existant, modifiez les données directement dans le formulaire et appuyez sur Enregistrer.',
  INQUIRER: 'Enquêteur',
  KEEP_INQUIRER_DETAILS_OPEN: 'Gardez les coordonnées du demandeur ouvertes',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'Vous avez des modifications non enregistrées, qui seraient perdues si vous fermiez les détails du demandeur.',
  SHARE_FROM_HERE_YOU_CAN_:
    "Partager : à partir d'ici, vous pouvez attribuer, transférer ou démarrer un suivi avec le demandeur.",
  UPDATED_INQUIRER_DETAILS: "détails de l'enquêteur mis à jour",
  CREATED_INQUIRER: 'demandé créé',
  SUGGESTED_INQ: 'Demandeurs suggérés',
  SELECT_INQUIRER: 'Sélectionner un enquêteur',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    "Le consentement à l'obligation ADR et le demandeur doivent être sélectionnés afin de soumettre l'interaction",
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    "La réponse n'a pas été envoyée au demandeur en raison d'une adresse e-mail erronée.",
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'Merci ! La notification sera envoyée une fois la demande soumise ou répondue.',
  MSG_NOTIFICATION_WILL_BE_SEND_2:
    'Merci ! La notification sera envoyée une fois le demandeur enregistré.',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'La notification GDPR suivante sera envoyée au demandeur par courrier électronique.',
  NOTIFICATION_SENT_TO_THE_INQ:
    'La notification GDPR suivante a été envoyée au demandeur par courrier électronique.',
  SEND_A_NOTIFICATION_TO_THE_INQ: 'Envoyez la notification GDPR suivante au demandeur par e-mail.',
  INQ_HAS_NO_EMAIL: "le demandeur n'a pas d'e-mail.",
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    "Une notification n'a PAS été envoyée au demandeur indiquant que la demande a été reçue.",
  NOTIFICATION_SENT_TO_THE_INQUIRER:
    'Notification envoyée au demandeur indiquant que sa demande a été reçue.',
  THIS_IS_JUST_A_THANK_YOU:
    'Il s’agit simplement d’un message de remerciement de la part de l’auteur de la demande existante.',
  SAVE_INQUIRER: 'Enregistrer Enquirer',
  INQ_DEFAULT_VAL: "Valeurs par défaut de l'enquêteur",
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    "Pour les demandes soumises par l'intermédiaire d'un représentant de l'entreprise (représentant commercial, MSL ou autre), informez le demandeur du traitement de ses données personnelles.",
  SELECT_PREFERRED_CONTACT_ERROR: 'Vous devez sélectionner un contact préféré pour le demandeur.',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'Suivi initié par le demandeur',
  NO_INQUIRER: 'Pas de demande',
  'Changed inquirer': 'Demandeur changé',
  DELETE_INQUIRY_MESSAGE:
    "Vous êtes sur le point de supprimer cette demande. Cette action n'est pas réversible.",
  QUESTION_REQUIRE_ANSWER_MESSAGE:
    'La nouvelle question nécessitera une réponse pour pouvoir clôturer l’enquête.',
  IMAGE: 'Image',
  THERAPEUTIC_AREA_STATUS_MESSAGE: 'La zone thérapeutique est {{ STATUS }}',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    ", car il n'y a pas de produits associés. Veuillez sélectionner au moins un produit pour activer la zone thérapeutique.",
  EXPAND_ALL_THERAPEUTIC_AREAS: 'Développer tous les domaines thérapeutiques',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: 'Réduire tous les domaines thérapeutiques',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    'Pour définir une zone thérapeutique (TA) comme TA principale, désélectionnez la TA parent et enregistrez.',
  EXTRA_STEP_REQUIRED_TO_LOGIN: 'Étape supplémentaire requise pour se connecter',
  EXTRA_STEP_DESCRIPTION:
    'Une confirmation de connexion vous a été envoyée par e-mail. Veuillez suivre les étapes indiquées afin de continuer vers le système.',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT:
    "Veuillez saisir un texte d'une longueur minimale de 15 caractères ou une pièce jointe.",
  REASON_FOR_REJECTION: 'Motif du rejet',
  NOTE_PROPERLY_INSERTED_IN_HISTORY: "La note a été correctement insérée dans l'historique.",
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    "En sélectionnant {{ ACTION_TYPE }} le {{ METADATA_TYPE }}, les modifications supplémentaires suivantes s'appliqueront:",
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    "En supprimant les produits, les modifications connexes supplémentaires suivantes s'appliqueront:",
};
