import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  BMedicalDocument,
  BMedicalDocumentVersion,
  MedicalDocumentsService,
} from 'app/modules/data-model/data-model.module';
import { MatDialog } from '@angular/material/dialog';
import { DocumentCreateComponent } from 'app/modules/repository/document-create/document-create.component';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';

@Component({
  selector: 'app-document-available-actions',
  templateUrl: './document-available-actions.component.html',
})
export class DocumentAvailableActionsComponent implements OnDestroy {
  version: BMedicalDocumentVersion;
  private _document: BMedicalDocument;
  private subs: Subscription = new Subscription();
  todayDateTime: Date = new Date();

  constructor(
    private service: MedicalDocumentsService,
    private matDialog: MatDialog,
    private messageService: MessageHandlerService
  ) {}

  @Input()
  set document(document: BMedicalDocument) {
    this.version = document && document.currentVersion;
    this._document = document;
  }

  get document(): BMedicalDocument {
    return this._document;
  }

  get statusName(): string {
    if (!this.version) {
      return '';
    }
    return this.version.statusName === 'Approved' ? 'APPROVED' : 'ARCHIVED';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  createNewVersion(): void {
    const ref = this.matDialog.open(DocumentCreateComponent, { disableClose: true });
    ref.componentInstance.createNewVersion(this._document);
  }

  setStatus(val: 'Approved' | 'Archived'): void {
    this.subs.add(
      this.service.setStatus(this._document.pk(), val).subscribe({
        next: () => {
          this.version.statusName = val;
        },
        error: (err) => {
          this.messageService.httpError('Document Status Change', err);
        },
      })
    );
  }
}
