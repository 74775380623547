<mat-card
  appearance="raised"
  class="medis-clickable word"
  *ngIf="documentType == 'srd'"
  [ngClass]="{ selected: isSelected }"
>
  <i class="far fa-file-word"></i>
  <div>{{ 'SRD' | translate }}</div>
</mat-card>
<mat-card
  appearance="raised"
  class="medis-clickable pdf"
  *ngIf="documentType == 'smpc'"
  [ngClass]="{ selected: isSelected }"
>
  <i class="far fa-file-pdf"></i>
  <div>{{ 'SmPc' | translate }}</div>
</mat-card>
<mat-card
  appearance="raised"
  class="medis-clickable word"
  *ngIf="documentType == 'faq'"
  [ngClass]="{ selected: isSelected }"
>
  <i class="far fa-file-word"></i>
  <div>{{ 'FAQ' | translate }}</div>
</mat-card>
<mat-card
  appearance="raised"
  class="medis-clickable pdf"
  *ngIf="documentType == 'pil'"
  [ngClass]="{ selected: isSelected }"
>
  <i class="far fa-file-pdf"></i>
  <div>{{ 'PIL' | translate }}</div>
</mat-card>
<mat-card
  appearance="raised"
  class="medis-clickable powerpoint"
  *ngIf="documentType == 'slide'"
  [ngClass]="{ selected: isSelected }"
>
  <i class="far fa-file-powerpoint"></i>
  <div>{{ 'SLIDE_DECK' | translate }}</div>
</mat-card>
<mat-card
  appearance="raised"
  class="medis-clickable pdf"
  *ngIf="documentType == 'lit'"
  [ngClass]="{ selected: isSelected }"
>
  <i class="far fa-file-pdf"></i>
  <div>{{ 'LITERATURE' | translate }}</div>
</mat-card>
