export enum DialogModesEnum {
  View = 'view',
  Edit = 'edit',
}

export enum EDialogStatuses {
  BACK,
  SUBMIT_FOR_APPROVAL,
  ABORT_FILE_UPLOAD,
  CREATE_DRAFT,
}

export enum ESpamDetailDialogResult {
  UNSPAM = 'UNSPAM',
  ARCHIVE = 'ARCHIVE',
}
