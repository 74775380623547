<h2 mat-dialog-title>
  {{ 'SPLIT_INQ' | translate }}
  <i class="far fa-times-circle medis-icon-large" (click)="ref.close()"></i>
</h2>
<mat-dialog-content class="split-content">
  <div class="medis-pb-20">
    <div class="text-13-800-dark">{{ 'SPLIT_ORIGINAL_TEXT' | translate }}</div>
    <div
      class="text-section"
      (click)="textSelection()"
      (mouseup)="textSelection()"
      (mouseout)="textSelection()"
    >
      {{ originalText }}
    </div>
  </div>
  <div *ngIf="extractSections.length" class="medis-pb-20">
    <div class="text-13-800-dark">{{ 'SPLIT_SELECTED_SECTIONS' | translate }}</div>
    <div
      *ngFor="let section of extractSections; let i = index"
      class="medis-flex-container-between"
    >
      {{ section.text }}
      <i
        class="fas fa-ban medis-icon-reject"
        (click)="removeSelection(i)"
        matTooltip="{{ 'Reset' | translate }}"
      ></i>
    </div>
  </div>
  <div id="editors-container" class="medis-pb-20">
    <div class="medis-flex-container-between medis-pv-5">
      <span class="text-13-800-dark">{{ 'SPLIT_TEXT_TO_SPLIT_' | translate }}</span>
      <i
        *ngIf="!isExtractModified"
        class="fas fa-sync-alt medis-icon"
        matTooltip="{{ 'SPLIT_NO_MODIFICATIONS_HINT' | translate }}"
      ></i>
      <i
        *ngIf="isExtractModified"
        class="fas fa-exclamation-triangle medis-icon-reject"
        (click)="resetExtractedText()"
        matTooltip="{{ 'SPLIT_MODIFICATIONS_HINT' | translate }}"
      ></i>
    </div>
    <editor
      [(ngModel)]="extractedText"
      (ngModelChange)="extractedTextChanged()"
      [init]="tinyMceOptions"
    ></editor>
    <div class="medis-flex-container-between medis-pv-5">
      <span class="text-13-800-dark">{{ 'SPLIT_TEXT_TO_REMAIN_' | translate }}</span>
      <i
        *ngIf="!isRemainingModified"
        class="fas fa-sync-alt medis-icon"
        matTooltip="{{ 'SPLIT_NO_MODIFICATIONS_HINT' | translate }}"
      ></i>
      <i
        *ngIf="isRemainingModified"
        class="fas fa-exclamation-triangle medis-icon-reject"
        (click)="resetRemainingText()"
        matTooltip="{{ 'SPLIT_MODIFICATIONS_HINT' | translate }}"
      ></i>
    </div>
    <editor
      [(ngModel)]="remainingText"
      (ngModelChange)="remainingTextChanged()"
      [init]="tinyMceOptions"
    ></editor>
    <div id="split-editor-toolbar"></div>
  </div>
  <div
    class="medis-flex-container-between medis-clickable"
    (click)="areDetailsShown = !areDetailsShown"
  >
    <div class="text-13-800-dark w-100">{{ 'SELECT_DETAILS' | translate }}</div>
    <i class="fas medis-icon" [ngClass]="areDetailsShown ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
  </div>
  <div class="split-details" [ngClass]="{ invisible: !areDetailsShown }">
    <app-search-select
      [config]="metadata.productConfig"
      [(selectedValue)]="metadata.selectedProduct"
      class="w-100"
    ></app-search-select>
    <app-search-select
      [config]="metadata.categoryConfig"
      [(selectedValue)]="metadata.selectedCategory"
      class="w-100"
    ></app-search-select>
    <app-search-select
      [config]="metadata.topicConfig"
      [(selectedValue)]="metadata.selectedTopic"
      class="w-100"
    ></app-search-select>
    <div>
      <span class="text-12-700-dark">{{ 'ADR_RELATED' | translate }}</span>
      <i
        class="far fa-question-circle medis-icon medis-mr-10"
        matTooltip="{{ 'A_RESPONSE_TO_A_MEDICINAL_' | translate }}"
      ></i>
      <app-multi-toggle [choices]="choices" [(value)]="adrRelated"></app-multi-toggle>
    </div>
    <div>
      <span class="text-12-700-dark">{{ 'OFF_LABEL' | translate }}</span>
      <i
        class="far fa-question-circle medis-icon medis-mr-10"
        matTooltip="{{ 'OFF_LABEL_USE_OF_PHARMA___' | translate }}"
      ></i>
      <app-multi-toggle [choices]="choices" [(value)]="offLabel"></app-multi-toggle>
    </div>
    <div>
      <span class="text-12-700-dark">{{ 'PRODUCT_QUALITY_COMPLAINT' | translate }}</span>
      <i
        class="far fa-question-circle medis-icon medis-mr-10"
        matTooltip="{{ 'PQC_PC_A_PRODUCT_' | translate }}"
      ></i>
      <app-multi-toggle [choices]="choices" [(value)]="productComplaint"></app-multi-toggle>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <mat-checkbox [(ngModel)]="splitAnother">{{ 'SPLIT_ANOTHER' | translate }}</mat-checkbox>
  <button
    mat-button
    class="medis-button-basic"
    matTooltip="{{ 'SELECT_TEXT_FOR_SPLIT' | translate }}"
    [disabled]="!extractedText || splitInProcess"
    (click)="split(false)"
  >
    <i class="fas fa-plus"></i>
    {{ 'SPLIT_INQ' | translate }}
  </button>
  <button
    mat-button
    class="medis-button-basic"
    matTooltip="{{ 'SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION' | translate }}"
    [disabled]="!extractedText || splitInProcess"
    (click)="split(true)"
  >
    <i [ngClass]="extractedText ? 'medis-svg-split-azure' : 'medis-svg-split-grey'"></i>
    {{ 'CREATE_NEW_INTERACTION' | translate }}
  </button>
  <button mat-button (click)="ref.close()" class="medis-button-reject">
    {{ 'CANCEL' | translate }}
  </button>
</mat-dialog-actions>
