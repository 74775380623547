import { gql } from 'apollo-angular';

import {
  MLiterature,
  MMedicalDocument,
  MMedicalDocumentVersion,
  MTherapeuticArea,
} from './medical-document';
import * as MLanguage from '../language/language';
import * as MStatus from '../status/status';
import { MProduct, MRegion } from '../region/region';
import * as MCountry from '../country/country';
import * as MCategory from '../category/category';
import * as MTopic from '../topic/topic';
import { MUser, MTeam } from '../user/user';
import * as MMedicalDocumentType from '../medical-document-type/medical-document-type';
import * as MMedicalDocumentAudience from '../medical-document-audience/medical-document-audience';
import * as MMedicalDocumentVersionFile from '../medical-document-version-file/medical-document-version-file';
import * as MMDWord from '../md-word/md-word';
import * as MMDWordSection from '../md-word-section/md-word-section';

export const searchAllMedicalDocuments = gql`
  query filterAllMedicalDocument($filters: String!) {
    allMedicalDocument(terms: $filters) {
      edges {
        node {
          ...medicalDocumentFragment
          lastVersionForUser {
            ...medicalDocumentVersionFragment
            firstWord {
              ...mdWordFragment
              sections {
                ...mdWordSectionConnectionFragment
              }
            }
          }
          type {
            ...medicalDocumentTypeFragment
          }
          targetAudiences {
            ...medicalDocumentAudienceConnectionFragment
          }
          ownerRegion {
            ...regionFragment
          }
          language {
            ...languageFragment
          }
          usableByRegions {
            ...regionConnectionFragment
          }
          products {
            ...productConnectionFragment
          }
          categories {
            ...categoryConnectionFragment
          }
          topics {
            ...topicConnectionFragment
          }
          literatureDetails {
            ...literatureFragment
          }
        }
      }
    }
  }
  ${MMedicalDocument.fragment}
  ${MMedicalDocumentVersion.fragment}
  ${MMDWord.fragment}
  ${MMDWordSection.fragmentConnection}
  ${MMedicalDocumentType.fragment}
  ${MMedicalDocumentAudience.fragmentConnection}
  ${MRegion.fragment}
  ${MLanguage.fragment}
  ${MRegion.fragmentConnection}
  ${MProduct.fragmentConnection}
  ${MCategory.fragmentConnection}
  ${MTopic.fragmentConnection}
  ${MLiterature.fragment}
`;

export const mutateUpdateMedicalDocument = gql`
  mutation updateMedicaldocument($params: UpdateMedicalDocumentInput!) {
    updateMedicaldocument(input: $params) {
      medicalDocument {
        ...medicalDocumentFragment
        lastVersionForUser {
          ...medicalDocumentVersionFragment
          status {
            ...statusFragment
          }
          files {
            ...medicalDocumentVersionFileConnectionFragment
          }
          createdBy {
            ...userFragment
          }
        }
        localizationOf {
          ...medicalDocumentFragment
        }
        type {
          ...medicalDocumentTypeFragment
        }
        targetAudiences {
          ...medicalDocumentAudienceConnectionFragment
        }
        ownerRegion {
          ...regionFragment
        }
        language {
          ...languageFragment
        }
        usableByRegions {
          ...regionConnectionFragment
        }
        products {
          ...productConnectionFragment
        }
        categories {
          ...categoryConnectionFragment
        }
        topics {
          ...topicConnectionFragment
        }
        localizationOf {
          ...medicalDocumentFragment
        }
        localizedMd {
          ...medicalDocumentConnectionFragment
        }
        literatureDetails {
          ...literatureFragment
        }
      }
    }
  }
  ${MMedicalDocument.fragment}
  ${MMedicalDocumentVersion.fragment}
  ${MMedicalDocumentVersionFile.fragmentConnection}
  ${MStatus.fragment}
  ${MMedicalDocument.fragment}
  ${MMedicalDocument.fragmentConnection}
  ${MMedicalDocumentType.fragment}
  ${MMedicalDocumentAudience.fragmentConnection}
  ${MRegion.fragment}
  ${MRegion.fragmentConnection}
  ${MLanguage.fragment}
  ${MProduct.fragmentConnection}
  ${MCategory.fragmentConnection}
  ${MTopic.fragmentConnection}
  ${MUser.fragment}
  ${MLiterature.fragment}
`;

export const retrieveMedDocInformation = gql`
  query getMedicalDocument($id: ID!) {
    medicaldocument(id: $id) {
      ...medicalDocumentFragment
      owner {
        ...teamFragment
      }
      currentVersion {
        ...medicalDocumentVersionFragment
        availableActions
        files {
          ...medicalDocumentVersionFileConnectionFragment
        }
        firstWord {
          ...mdWordFragment
          sections {
            ...mdWordSectionConnectionFragment
          }
        }
      }
      type {
        ...medicalDocumentTypeFragment
      }
      therapeuticAreas {
        ...therapeuticAreaConnectionFragment
      }
      targetAudiences {
        ...medicalDocumentAudienceConnectionFragment
      }
      language {
        ...languageFragment
      }
      products {
        ...productConnectionFragment
      }
      categories {
        ...categoryConnectionFragment
      }
      topics {
        ...topicConnectionFragment
      }
      literatureDetails {
        ...literatureFragment
      }
      usableByCountries {
        ...countryConnectionFragment
      }
    }
  }
  ${MMedicalDocument.fragment}
  ${MMedicalDocumentVersion.fragment}
  ${MMedicalDocumentVersionFile.fragmentConnection}
  ${MMDWord.fragment}
  ${MMDWordSection.fragmentConnection}
  ${MMedicalDocumentType.fragment}
  ${MMedicalDocumentAudience.fragmentConnection}
  ${MLanguage.fragment}
  ${MProduct.fragmentConnection}
  ${MCategory.fragmentConnection}
  ${MTopic.fragmentConnection}
  ${MTeam.fragment}
  ${MLiterature.fragment}
  ${MTherapeuticArea.fragmentConnection}
  ${MCountry.fragmentConnection}
`;

export const createDocumentWithApproval = gql`
  mutation createDocumentWithApproval($params: CreateApprovalProcessInput!) {
    createApprovalProcess(input: $params) {
      medicalDocument {
        ...medicalDocumentFragment
        type {
          ...medicalDocumentTypeFragment
        }
        versions {
          ...medicalDocumentVersionConnectionFragment
        }
      }
    }
  }
  ${MMedicalDocumentVersion.fragmentConnection}
  ${MMedicalDocumentType.fragment}
  ${MMedicalDocument.fragment}
`;

export const createMedicalDocumentVersion = gql`
  mutation createMedicalDocumentVersion($params: CreateMedicalDocumentVersionInput!) {
    createMedicalDocumentVersion(input: $params) {
      medicalDocument {
        ...medicalDocumentFragment
        type {
          ...medicalDocumentTypeFragment
        }
        versions {
          ...medicalDocumentVersionConnectionFragment
        }
      }
    }
  }
  ${MMedicalDocumentVersion.fragmentConnection}
  ${MMedicalDocumentType.fragment}
  ${MMedicalDocument.fragment}
`;

export const executeDocumentAction = gql`
  mutation executeDocumentAction($params: MDActionInput!) {
    mdAction(input: $params) {
      document {
        ...medicalDocumentFragment
        currentVersion {
          ...medicalDocumentVersionFragment
          availableActions
          files {
            ...medicalDocumentVersionFileConnectionFragment
          }
          firstWord {
            ...mdWordFragment
            sections {
              ...mdWordSectionConnectionFragment
            }
          }
        }
        type {
          ...medicalDocumentTypeFragment
        }
        targetAudiences {
          ...medicalDocumentAudienceConnectionFragment
        }
        ownerRegion {
          ...regionFragment
        }
        language {
          ...languageFragment
        }
        usableByRegions {
          ...regionConnectionFragment
        }
        products {
          ...productConnectionFragment
        }
        categories {
          ...categoryConnectionFragment
        }
        topics {
          ...topicConnectionFragment
        }
        literatureDetails {
          ...literatureFragment
        }
      }
    }
  }
  ${MMedicalDocument.fragment}
  ${MMedicalDocumentVersion.fragment}
  ${MMedicalDocumentVersionFile.fragmentConnection}
  ${MMDWord.fragment}
  ${MMDWordSection.fragmentConnection}
  ${MMedicalDocumentType.fragment}
  ${MMedicalDocumentAudience.fragmentConnection}
  ${MRegion.fragment}
  ${MLanguage.fragment}
  ${MRegion.fragmentConnection}
  ${MProduct.fragmentConnection}
  ${MCategory.fragmentConnection}
  ${MTopic.fragmentConnection}
  ${MLiterature.fragment}
`;
