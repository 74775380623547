import { gql } from 'apollo-angular';
import { BaseModel, rProperty } from '../data-model';

export class BMDWordSection extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() text: string;
  @rProperty() order: string;
  @rProperty() selected?: boolean;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {}
}

export const fragment = gql`
  fragment mdWordSectionFragment on MDWordSectionNode {
    id
    name
    text
    order
    createdTs
    editedTs
  }
`;

export const fragmentConnection = gql`
  fragment mdWordSectionConnectionFragment on MDWordSectionNodeConnection {
    edges {
      node {
        ...mdWordSectionFragment
      }
    }
  }
  ${fragment}
`;
