import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { BaseModel } from 'app/modules/data-model/data-model';
import { BUser } from 'app/modules/data-model/user/user';
import { BInquirer } from 'app/modules/data-model/inquirer/inquirer';
import { Channels } from '@mi-tool/consts';

@Pipe({ name: 'toCSV' })
export class ToCSVPipe implements PipeTransform {
  transform(value: any[], fieldName: string): string {
    return value
      ? value
          .map((obj) => obj[fieldName] || '')
          .filter((x) => x != '')
          .join(', ')
      : '';
  }
}

@Pipe({ name: 'valueFromKey' })
export class ValueFromKeyPipe implements PipeTransform {
  transform(value: any, fieldName: string): any {
    return value && value.hasOwnProperty(fieldName) ? value[fieldName] : '';
  }
}

@Pipe({ name: 'dateChecker' })
export class DateChecker extends DatePipe {
  // @ts-ignore
  transform(date: Date): string {
    return date
      ? super.transform(date, 'dd MMM yyyy') + ', ' + super.transform(date, 'shortTime')
      : '';
  }
}

@Pipe({ name: 'dateCheckerNoHour' })
export class DateCheckerNoHour extends DatePipe {
  // @ts-ignore
  transform(date): string {
    return date ? super.transform(date, 'dd MMM yyyy') : '';
  }
}

@Pipe({ name: 'sanitizeHtml' })
export class SanitizeHtml implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(v: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(v);
  }
}

@Pipe({ name: 'sanitizeUrl' })
export class SanitizeUrl implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(v: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(v);
  }
}

@Pipe({ name: 'validateRequiredFields', pure: true })
export class ValidateRequiredFields implements PipeTransform {
  transform(_: any, ...args: any[]) {
    return args.some((arg) => Boolean(arg) === false);
  }
}

@Pipe({ name: 'getNames', pure: true })
export class GetNames implements PipeTransform {
  transform(objects: any, showAll: boolean = false) {
    let objectNames = objects
      .map((object) => object.name)
      .sort((name1, name2) => name1.localeCompare(name2))
      .filter(Boolean)
      .join(', ');
    return !showAll && objectNames.length > 30
      ? (objectNames.slice(29, 30) == ',' ? objectNames.slice(0, 33) : objectNames.slice(0, 30)) +
          '..'
      : objectNames;
  }
}

@Pipe({ name: 'filterBy', pure: true })
export class FilterBy implements PipeTransform {
  transform(objArray: any[], property: string, value: any, isFormGroupObj = false) {
    return objArray.filter((object) => {
      const objectValue = isFormGroupObj ? object?.value?.[property] : object?.[property];
      return objectValue === value;
    });
  }
}

@Pipe({ name: 'pk', pure: true })
export class PrimaryKeyPipe implements PipeTransform {
  transform(object: BaseModel) {
    return object?.pk();
  }
}

@Pipe({ name: 'displayInfo', pure: true })
export class DisplayInfoPipe implements PipeTransform {
  transform(object: BUser | BInquirer): string {
    return object?.displayInfo();
  }
}

@Pipe({ name: 'fullName', pure: true })
export class FullNamePipe implements PipeTransform {
  transform(object: BUser | BInquirer): string {
    return object?.fullName();
  }
}

@Pipe({ name: 'channelTranslationKey', pure: true })
export class ChannelTranslationKeyPipe implements PipeTransform {
  transform(channel: string): string {
    return Channels.resolveTranslationKey(channel);
  }
}
