import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  BUser,
  BInquirer,
  BEntity,
  InquirerService,
  SingleUserService,
} from 'app/modules/data-model/data-model.module';

@Component({
  selector: 'app-entity-preview',
  templateUrl: './entity-preview.component.html',
  styleUrls: ['./entity-preview.component.scss'],
})
export class EntityPreviewComponent implements OnInit, OnDestroy {
  user: BUser;
  inquirer: BInquirer;
  department: BEntity;

  @Input() entityIdentifier: boolean;
  @Input() position: string = 'right';

  isLoading: boolean;

  private _entity: BEntity;
  private subs: Subscription = new Subscription();

  constructor(private inquirerService: InquirerService, private userService: SingleUserService) {
    this.isLoading = false;
    this.entityIdentifier = true;
  }

  ngOnInit() {
    this.subs.add(
      this.userService.basicUser.subscribe((user) => {
        this.user = user;
        this.isLoading = false;
      })
    );
    this.subs.add(
      this.inquirerService.basicInquirer.subscribe((inquirer) => {
        this.inquirer = inquirer;
        this.isLoading = false;
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  @Input()
  set entity(entity: BEntity) {
    if (this._entity == undefined && entity != undefined) {
      this._entity = entity;
      this.isLoading = true;
      this.retrieveEntityData();
    }
  }

  get entity(): BEntity {
    return this._entity;
  }

  private retrieveEntityData(): void {
    if (this.entity.class == 'user') {
      this.userService.performBasicQuery(parseInt(this.entity.id));
    } else if (this.entity.class == 'inquirer') {
      this.inquirerService.performBasicQuery(parseInt(this.entity.id));
    } else if (this.entity.class == 'department') {
      this.department = this.entity;
      this.isLoading = false;
    }
  }
}
