function parseNode(node) {
  if (['OL', 'UL', 'LI'].indexOf(node.tagName) >= 0) {
    node.style.textIndent = 0;
    node.style.marginLeft = 0;
  }
  for (let child of node.children) {
    parseNode(child);
  }
}

function basicOptions(
  extraToolbar1Items: string,
  simple = false,
  disabled = false,
  position = '#toolbar'
) {
  let options = {
    inline: !simple,
    theme: 'modern',
    statusbar: !simple,
    plugins:
      'fullpage fullscreen paste searchreplace autolink directionality visualblocks visualchars link table image charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount imagetools colorpicker textpattern help',
    toolbar2: simple
      ? false
      : 'formatselect | fontselect fontsizeselect | bold italic strikethrough subscript superscript | forecolor backcolor | removeformat ',
    toolbar1: simple
      ? false
      : 'fullscreen | undo redo | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | table charmap | ' +
        extraToolbar1Items,
    image_advtab: true,
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
    paste_data_images: true,
    menubar: simple ? false : 'edit view insert format table help mybutton',
    content_css: '/assets/css/editor-area.css',
    paste_webkit_styles: 'all',
    paste_retain_style_properties: 'all',
    paste_postprocess: function (plugin, args) {
      parseNode(args.node);
    },
    fixed_toolbar_container: position,
  };
  if (simple) {
    options['toolbar'] = false;
    options['disabled'] = disabled;
    options['forced_root_block'] = '';
  }
  return options;
}

export const tinyMceOptions = (disabled?) =>
  basicOptions('upload-medis upload-image-medis', false, disabled);
export const tinyMceOptionsAdministrationTA = (simple?) => basicOptions('', simple);
export const tinyMceOptionsAnswer = (disabled?, position?) =>
  basicOptions('repo similar upload-medis upload-image-medis', false, disabled, position);
export const tinyMceOptionsAnswerTemplate = (disabled?, position?) =>
  basicOptions(
    'repo similar upload-medis upload-image-medis select-template',
    false,
    disabled,
    position
  );

export namespace TinyMCE {
  export function getEditor(editorId: string): TinyMCEInstance {
    return (window as any).tinymce.get(editorId);
  }

  export function focusEditor(editorId: string): void {
    const editor = document.getElementById(editorId);
    if (editor) {
      editor.focus();
    }
  }

  export function getTextContent(editorId: string): string {
    return getEditor(editorId).getBody().textContent.trim();
  }

  export function validatePlainTextLength(text: string, minLength: number): boolean {
    return text.replace(/<[^>]+>|&nbsp;|[^\p{L}\p{N}]/gu, '').trim().length >= minLength;
  }

  export function validateEditorPlainTextLength(editorId: string, minLength: number): boolean {
    return validatePlainTextLength(getTextContent(editorId), minLength);
  }
}

export type TinyMCEInstance = {
  id: number;
  getBody: () => any;
  isDirty: () => boolean;
  isNotDirty: boolean;
  addButton: (name: string, options: any) => any;
  setMode: (val: 'readonly' | 'design') => any;
  on: (eventName: 'init' | string, callback: Function) => any;
};
