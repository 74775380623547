import { NameValue } from '@mi-tool/consts';

import { BaseModel, rProperty } from '../data-model';
import { BRole } from '../role/role';
import { BTemplateRole } from '../template-role/template-role';
import { BTeam, BUserNotification } from '../user/user';

export class BUserTemplate extends BaseModel {
  @rProperty() id: string;
  @rProperty() overrideNotifications: boolean;
  @rProperty() name: string;

  templateRoles: BTemplateRole[];
  notifications: BUserNotification[];
  defaults: { teamId: number; roleId: number }[] = [];

  rolesPerTeam: NameValue<string>[] = [];

  init() {}

  static fromRest(json: any): BUserTemplate {
    json.templateRoles = BTemplateRole.fromRestArray(json.templateRoles);
    json.notifications = BUserNotification.fromRestArray(json.notifications);
    const template = new BUserTemplate();
    Object.assign(template, json);
    template.initRolesPerTeam();
    return template;
  }

  static fromRestArray(json: any[]): BUserTemplate[] {
    return json.map((v) => BUserTemplate.fromRest(v));
  }

  isDefaultFor(role: BRole, team: BTeam): boolean {
    return (
      role &&
      team &&
      this.defaults.find((d) => d.roleId === role.pk() && d.teamId === team.pk()) !== undefined
    );
  }

  private initRolesPerTeam(): void {
    this.rolesPerTeam = Array.from(new Set(this.templateRoles.map((tr) => tr.team.name)))
      .sort((aName, bName) => aName.localeCompare(bName))
      .map((teamName) => {
        const roles = this.templateRoles
          .filter((tr) => tr.team.name === teamName)
          .map((tr) => tr.role.description)
          .join(', ');
        return { name: teamName, value: roles };
      });
  }
}
