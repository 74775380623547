import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { InquirySearch } from '../../data-model/inquiry/inquiry';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  allSavedSearches$: Subject<boolean> = new Subject<boolean>();
  allSavedSearchesData$: Subject<InquirySearch[]> = new Subject<InquirySearch[]>();
}
