<div class="right-panel-container">
  <div class="title medis-flex-container-between" (click)="toggleRightSidebarFolded()">
    <span class="text-15-700-dark">{{ 'Table_Settings_Columns' | translate }}</span>
    <i class="far fa-times-circle medis-icon-large"></i>
  </div>
  <span class="section text-13-500-light">{{ 'Change_column_visibility' | translate }}</span>
  <div cdkDropList (cdkDropListDropped)="drop($event)">
    @for (column of allColumns; track column) {
    <div class="section" cdkDrag>
      <i class="fas fa-grip-lines"></i>
      <mat-checkbox [(ngModel)]="column.checked" (ngModelChange)="modelChanged()">
        {{ column.label | translate }}
      </mat-checkbox>
    </div>
    }
  </div>
</div>
