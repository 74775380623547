import { Component, OnDestroy, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Router } from '@angular/router';

import { LoadingService } from './loading.service';
import { environment } from 'app/../environments/environment';
import { AppNotificationsService } from './common/common/app-notifications.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from './auth/auth.service';
import { Subscription } from 'rxjs';
import { LoadingSpinComponent } from './common/common/loading-spin/loading-spin.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  supportEmail = environment.supportEmail;
  isSupportedUserAgent: boolean;
  userAgent = navigator.userAgent;
  notifications: AppNotificationsService.Notification[] = [];
  currentNotification: AppNotificationsService.Notification = undefined;
  notificationsInterval: NodeJS.Timeout;
  year = new Date().getFullYear();
  loggedInUserIsSysAdmin: boolean;
  systemWarning = environment.systemWarning;

  private subs: Subscription = new Subscription();
  private loadingDialogRef: MatDialogRef<LoadingSpinComponent>;

  constructor(
    private loadingService: LoadingService,
    private idle: Idle,
    private matDialog: MatDialog,
    private appNotificationsService: AppNotificationsService,
    private router: Router,
    private authService: AuthService
  ) {
    this.isSupportedUserAgent =
      !navigator.userAgent.includes ||
      (!navigator.userAgent.includes('Edge/18.') &&
        !navigator.userAgent.includes('Edge/17.') &&
        !navigator.userAgent.includes('Edge/16.') &&
        !navigator.userAgent.includes('Edge/15.') &&
        !navigator.userAgent.includes('Edge/14.'));
  }

  get currentNotificationIndex(): number {
    let idx =
      (this.currentNotification &&
        this.notifications.findIndex((n) => n.id === this.currentNotification.id)) ||
      -1;
    if (idx === -1) {
      this.currentNotification = this.notifications[0];
      idx = 0;
    }
    return idx;
  }

  ngOnInit(): void {
    this.subs.add(
      this.authService.user.subscribe((loggedInUser) => {
        this.loggedInUserIsSysAdmin = loggedInUser?.isSysAdmin();
      })
    );

    this.loadingService.showLoadingSpinner.subscribe((serviceIsLoading) => {
      /*
      promise is needed to set a new microtask to be executed after the end of the current one, otherwise we receive a response
      and we try to change the modal component after the rendering is ended and angular throws the error ExpressionChangedAfterItHasBeenCheckedError
      */
      Promise.resolve(null).then(() => {
        this.toggleLoading(serviceIsLoading);
      });
    });

    // sets an idle timeout of 2 hours, if the user is not working in the last 2 hours force page refresh to avoid caching problems with deployes
    this.idle.setIdle(7200);
    // sets a timeout period of 5 seconds just to debug .
    this.idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onTimeout.subscribe(() => {
      // force reload to avoid user caching problem
      location.reload();
    });
    this.idle.onIdleStart.subscribe(() => {
      console.log('Idle Start');
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      console.log('Page will be refreshed in ', countdown);
    });

    this.idle.watch();

    this.authService.userAuthenticated.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.reloadAppNotifications();
        this.notificationsInterval = setInterval(() => {
          this.reloadAppNotifications();
        }, 5 * 60 * 1000);
      } else {
        clearInterval(this.notificationsInterval);
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  toggleLoading(serviceIsLoading: boolean) {
    if (serviceIsLoading && this.loadingDialogRef == undefined) {
      this.loadingDialogRef = this.matDialog.open(LoadingSpinComponent, {
        panelClass: 'loading-modal',
      });
    } else if (!serviceIsLoading && this.loadingDialogRef != undefined) {
      this.loadingDialogRef.close();
      this.loadingDialogRef = undefined;
    }
  }

  navigateTo(notification: AppNotificationsService.Notification): void {
    this.router.navigateByUrl('/detail/' + notification.refId);
  }

  dismiss(notification: AppNotificationsService.Notification): void {
    this.appNotificationsService.dismiss(notification.id).subscribe((resp) => {
      this.setAppNotifications(resp);
    });
  }

  private reloadAppNotifications(): void {
    this.appNotificationsService.get().subscribe((resp) => {
      this.setAppNotifications(resp);
    });
  }

  private setAppNotifications(notifications: AppNotificationsService.Notification[]): void {
    this.notifications = notifications;
    this.currentNotification =
      this.currentNotification && this.currentNotificationIndex !== -1
        ? this.currentNotification
        : notifications.length
        ? notifications[0]
        : undefined;
  }
}
