import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConfirmDialogModule } from '@mi-tool/components/confirm-dialog/confirm-dialog.module';
import { NumberOnlyDirective } from './directives/medis-mat-number-only.directive';
import {
  MedisMatSidenavHelperDirective,
  MedisMatSidenavTogglerDirective,
} from './directives/medis-mat-sidenav/medis-mat-sidenav.directive';

const shared = [
  MedisMatSidenavHelperDirective,
  MedisMatSidenavTogglerDirective,
  NumberOnlyDirective,
];

@NgModule({
  declarations: shared,
  imports: [CommonModule, ConfirmDialogModule],
  exports: shared,
})
export class SharedModule {}
