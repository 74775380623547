import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  readonly showLoadingSpinner = new BehaviorSubject<boolean>(false);

  private requestCounter = 0;

  enter() {
    this.requestCounter += 1;
    if (this.requestCounter == 1) {
      this.showLoadingSpinner.next(true);
    }
  }

  exit() {
    if (this.requestCounter > 0) {
      this.requestCounter = this.requestCounter - 1;
    }
    if (this.requestCounter == 0) {
      this.showLoadingSpinner.next(false);
    }
  }
}
