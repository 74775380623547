<ng-container *ngIf="!item.hidden">
  <!-- item.url -->
  <a
    class="nav-link"
    [ngClass]="item.classes"
    *ngIf="item.url"
    [routerLink]="[item.url]"
    [queryParams]="item.queryParams"
    [routerLinkActive]="['active', 'accent']"
    [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    [attr.data-testid]="dataTestId"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <span
    class="nav-link"
    *ngIf="!item.url"
    [attr.data-testid]="dataTestId"
    [ngClass]="navigation.lastNavigationItemId === item.id ? 'active accent' : ''"
    (click)="navigation.lastNavigationItemId = item.id"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </span>

  <ng-template #itemContent>
    <i *ngIf="item.icon" [ngClass]="item.icon" class="fa nav-link-icon"></i>
    <img
      *ngIf="item.svgIcon"
      [src]="'../../../assets/img/icons/' + item.svgIcon + '.svg'"
      alt="icon"
      class="nav-link-icon"
    />
    <span [attr.data-testid]="item.translate" class="nav-link-title">{{
      item.translate | translate
    }}</span>
  </ng-template>
</ng-container>
