import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { BAttachment, BInteraction, BUser } from 'app/modules/data-model/data-model.module';
import { InteractionsService } from 'app/modules/data-model/interaction/interactions.service';
import { BHistory, BInquiry } from 'app/modules/data-model/interaction/interaction';
import { ActiveInquiryService } from 'app/modules/inquiry-detail/active-inquiry.service';
import {
  EditInteractionService,
  SingleInteractionService,
} from 'app/modules/data-model/interaction/interaction.service';
import { MedisSidebarService, StateHolder } from '@mi-tool/components/sidebar/sidebar.service';
import { DetailHistoryComponent } from 'app/modules/inquiry-detail/detail-history/detail-history.component';
import { AuthService } from 'app/auth/auth.service';
import { GenericDialogComponent } from 'app/common/common/generic-dialog/generic-dialog.component';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { SplitComponent } from 'app/modules/inquiry-detail/detail-navigation/modal/split.component';

@Component({
  selector: 'app-detail-left-bar',
  templateUrl: './detail-left-bar.component.html',
  styleUrls: ['./detail-left-bar.component.scss'],
})
export class DetailLeftBarComponent implements OnDestroy {
  activeInquiry: BInquiry;
  groupedDates: string[] = [];
  state: StateHolder;
  deleteInquiryInProcess = false;
  interactionLockedByAnotherUser = false;
  loggedInUserIsSysAdmin = false;
  questionPlainTextMinLengthForTruncate: number = 20;

  private loggedInUser: BUser;
  private _interaction: BInteraction;
  private checkingAdditionalMessages: boolean = false;
  private importantHistoryObjects: BHistory[] = [];
  private notTruncatedQuestionIds: string[] = [];
  private subs = new Subscription();

  constructor(
    private modalService: MatDialog,
    private activeInquiryService: ActiveInquiryService,
    private singleInteractionService: SingleInteractionService,
    private editInteractionService: EditInteractionService,
    private sidebarService: MedisSidebarService,
    private interactionsService: InteractionsService,
    private messageService: MessageHandlerService,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    this.subs.add(
      this.authService.user.subscribe((loggedInUser) => {
        this.loggedInUser = loggedInUser;
        this.loggedInUserIsSysAdmin = this.loggedInUser?.isSysAdmin();
      })
    );
    this.subs.add(
      this.interactionsService.getLeftBarHistory$.subscribe(() => {
        if (!this.interaction || !this.checkingAdditionalMessages || !this.loggedInUserIsSysAdmin) {
          return;
        }
        this.getLeftBarHistory();
      })
    );
    this.subs.add(
      this.activeInquiryService.activeInquiry.subscribe((inquiry) => {
        this.activeInquiry = inquiry;
      })
    );
    this.subs.add(
      this.activeInquiryService.interaction.subscribe((interaction) => {
        this.interaction = interaction;
      })
    );
    this.state = this.sidebarService.getSidebarState('navbar');
    this.subs.add(
      this.editInteractionService.response.subscribe((interaction) => {
        this.activeInquiryService.interaction.next(interaction);
      })
    );
    this.subs.add(
      this.singleInteractionService.interaction.subscribe((interaction) => {
        this.activeInquiryService.interaction.next(interaction);
      })
    );
    this.subs.add(
      this.interactionsService.responseKeepAliveLock.subscribe((interaction) => {
        this.interactionLockedByAnotherUser =
          this.interactionsService.isInteractionLockedByAnotherUser(interaction, this.loggedInUser);
      })
    );
  }

  set interaction(interaction: BInteraction) {
    this._interaction = interaction;
    this.updateGroupDates();
    this.checkingAdditionalMessages = true;
  }

  get interaction(): BInteraction {
    return this._interaction;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getHistoryForDate(dateString: string): BHistory[] {
    return this.importantHistoryObjects.filter(
      (i) => new Date(i.createdTs).toDateString() == dateString
    );
  }

  getInquiriesForAllDates(): BInquiry[] {
    return this.groupedDates
      .map((date) => this.getInquiriesForDate(date))
      .reduce((acc, cur) => acc.concat(cur));
  }

  getInquiriesForDate(dateString: string): BInquiry[] {
    if (!this.interaction) {
      return [];
    }
    return this.interaction.inquiries
      .filter((i) => new Date(i.createdTs).toDateString() == dateString)
      .sort(
        (a: BInquiry, b: BInquiry) =>
          new Date(a.createdTs).getTime() - new Date(b.createdTs).getTime()
      );
  }

  switchInquiry(inquiry: BInquiry): void {
    if (!this.activeInquiry || this.activeInquiry.id !== inquiry.id) {
      this.activeInquiryService.activeInquiry.next(inquiry);
    }
    this.activeInquiryService.inquirySelectedFromLeftBar.next(inquiry);
  }

  getAttachmentForInquiry(inquiry: BInquiry | BHistory): BAttachment[] {
    if (inquiry instanceof BHistory) {
      return inquiry.attachments;
    } else {
      const questionHistory = inquiry.history.filter((x) => x.category === 'question');
      return questionHistory.length > 0 ? questionHistory[0].attachments : [];
    }
  }

  openInternalComm(inquiry: BInquiry): void {
    if (this.interactionLockedByAnotherUser) {
      return;
    }
    const modalRef = this.modalService.open(DetailHistoryComponent);
    modalRef.componentInstance.interaction = this.interaction;
    modalRef.componentInstance.inquiry = inquiry;
    modalRef.componentInstance.mode = 'internal_comm';
  }

  splitInquiry(inquiry: BInquiry): void {
    if (this.interactionLockedByAnotherUser) {
      return;
    }
    const data = { inquiry: inquiry, interaction: this.interaction };
    this.modalService.open(SplitComponent, {
      width: '50%',
      minWidth: '900px',
      height: 'fit-content',
      data: data,
    });
  }

  deleteInquiry(inquiry: BInquiry): void {
    if (this.deleteInquiryInProcess || this.interactionLockedByAnotherUser) {
      return;
    }
    if (inquiry.isLastWithActiveCQ(this.interaction)) {
      GenericDialogComponent.showMessage(
        this.modalService,
        this.translateService.instant('QUESTION_CANNOT_BE_DELETED')
      );
      return;
    }
    new GenericDialogComponent.Builder(this.modalService)
      .title('WARNING')
      .message('DELETE_INQUIRY_MESSAGE')
      .confirmationQuestion('ARE_YOU_SURE')
      .cancelLabel('CANCEL')
      .saveLabel('CONFIRM')
      .closeOnButton()
      .onSave(() => {
        this.deleteInquiryInProcess = true;
        this.subs.add(
          this.interactionsService
            .deleteInquiry(this.interaction.isNew(), this.interaction.pk(), inquiry.pk())
            .subscribe({
              next: () => {
                this.singleInteractionService.refreshInteraction(this.interaction.id);
              },
              complete: () => {
                this.deleteInquiryInProcess = false;
              },
            })
        );
      });
  }

  createNewInquiry(): void {
    // it's like a split but without text
    const newInquiry = new BInquiry('');
    newInquiry.question = new BHistory({ text: '' });
    this.interaction.inquiries.push(newInquiry);
    const inquiryData = [];
    for (const inquiry of this.interaction.inquiries) {
      const data = { id: inquiry.id, text: inquiry.question.text };
      if (inquiry.product) {
        data['product_id'] = inquiry.product.id;
      }
      inquiryData.push(data);
    }
    this.editInteractionService.editInteraction({
      id: this.interaction.id,
      split: JSON.stringify(inquiryData),
    });
  }

  isTruncated(questionId: string): boolean {
    return !this.notTruncatedQuestionIds.includes(questionId);
  }

  toggleTruncation(questionId: string): void {
    const idx = this.notTruncatedQuestionIds.indexOf(questionId);
    if (idx === -1) {
      this.notTruncatedQuestionIds.push(questionId);
    } else {
      this.notTruncatedQuestionIds.splice(idx, 1);
    }
  }

  private updateGroupDates(): void {
    if (this.interaction) {
      this.groupedDates = [];
      for (let inquiry of this.interaction.inquiries) {
        let date = new Date(inquiry.createdTs).toDateString();
        if (this.groupedDates.indexOf(date) < 0) {
          this.groupedDates.push(date);
        }
      }
      for (let history of this.importantHistoryObjects) {
        let date = new Date(history.createdTs).toDateString();
        if (this.groupedDates.indexOf(date) < 0) {
          this.groupedDates.push(date);
        }
      }
      // order grouped dates as inverse order
      this.groupedDates = this.groupedDates
        .map((x) => new Date(x))
        .sort((a: Date, b: Date) => a.getTime() - b.getTime())
        .map((d) => d.toDateString());
    }
  }

  private getLeftBarHistory(): void {
    this.interactionsService.getLeftBarHistory(this.interaction.pk()).subscribe({
      next: (history) => {
        this.importantHistoryObjects = history;
        this.updateGroupDates();
        this.checkingAdditionalMessages = false;
      },
      error: (error) => {
        this.importantHistoryObjects = [];
        this.updateGroupDates();
        this.checkingAdditionalMessages = false;
        this.messageService.httpError(
          'An error occurred while fetching the left bar history',
          error
        );
      },
    });
  }
}
