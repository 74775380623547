import { Component, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  BAttachment,
  BHistory,
  AttachmentsService,
} from 'app/modules/data-model/data-model.module';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';

@Component({
  selector: 'app-upload-attachment',
  templateUrl: './upload-attachment.component.html',
})
export class UploadAttachmentComponent implements OnDestroy {
  @Input() componentId: string;
  @Input() history: BHistory;
  @Input() activeEditor: any;

  @ViewChild('fileUploadButton', { static: false }) fileUploadButton;
  @ViewChild('fileUploadImageButton', { static: false }) fileUploadImageButton;

  @Output() attachmentUploaded: EventEmitter<BAttachment>;
  private subs = new Subscription();

  constructor(
    private attachmentsService: AttachmentsService,
    private messageService: MessageHandlerService
  ) {
    this.attachmentUploaded = new EventEmitter<BAttachment>();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  attachFile(fileInput: any) {
    if (fileInput.target.files[0]) {
      const reader: FileReader = new FileReader();
      reader.onloadend = (e) => {
        this.loadUpload(fileInput.target.files[0].name, reader.result as string);
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  loadUpload(name: string, file: string): void {
    if (this.history) {
      this.subs.add(
        this.attachmentsService
          .uploadToHistory(this.history.pk(), this.history.isNew(), 'name:' + name + file)
          .subscribe({
            next: () => {
              this.attachmentUploaded.emit();
            },
            error: (error) => {
              this.messageService.httpError('Upload Attachment', error);
            },
          })
      );
    } else {
      // Attachment will be created without an history, these must be linked at the end of the process
      this.subs.add(
        this.attachmentsService.upload('name:' + name + file).subscribe({
          next: (attachment) => {
            this.attachmentUploaded.emit(attachment);
          },
          error: (error) => {
            this.messageService.httpError('Upload Attachment', error);
          },
        })
      );
    }
  }

  openAttachment(): void {
    (this.fileUploadButton.nativeElement as HTMLElement).click();
  }

  openAttachmentImage(): void {
    (this.fileUploadImageButton.nativeElement as HTMLElement).click();
  }

  attachFileImage(fileInput: any) {
    // specific function to upload image in text as base64
    if (fileInput.target.files[0]) {
      const reader: FileReader = new FileReader();
      const thisTmp = this;
      reader.onloadend = (e) => {
        thisTmp.activeEditor.insertContent(
          '<img width="400px" src="' + reader.result.toString() + '"/>'
        );
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
}
