import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { isNil, omitBy } from 'lodash';
import { BLicenseCounter } from './license-counter';
import { map } from 'rxjs/operators';
import { Ajax } from 'app/common/ajax';

export type TLicenseCounterData = {
  licenseCounterLimit?: number;
  licenseCounterRoleIds?: number[];
  licenseCounterExcludedUserIds?: number[];
};

@Injectable({ providedIn: 'root' })
export class LicenseCounterRestService {
  private readonly URL: string;
  private readonly URL_LICENSES_IN_USE: string;
  private readonly URL_EXPORT_EXCEL: string;

  constructor(private http: HttpClient, private urlResolver: UrlResolverService) {
    this.URL = urlResolver.misApiUrlForPath('/tasks/license-counter');
    this.URL_LICENSES_IN_USE = urlResolver.misApiUrlForPath(
      '/tasks/license-counter/licenses-in-use'
    );
    this.URL_EXPORT_EXCEL = urlResolver.misApiUrlForPath('/tasks/license-counter/export-excel');
  }

  fetchLicenseCounterDetails(): Observable<BLicenseCounter> {
    return this.http.get<BLicenseCounter>(this.URL).pipe(
      map((response) => {
        return BLicenseCounter.fromRest(response);
      })
    );
  }

  fetchLicensesInUse(): Observable<number> {
    return this.http.get<number>(this.URL_LICENSES_IN_USE);
  }

  exportLicenseCounterExcel(): Observable<Blob> {
    return this.http.get<Blob>(this.URL_EXPORT_EXCEL, {
      ...Ajax.X_NO_LOADING_OPTIONS,
      responseType: 'blob' as 'json',
    });
  }

  edit(data: TLicenseCounterData): Observable<void> {
    return this.http.put<void>(this.URL, omitBy(data, isNil));
  }
}
