import { gql } from 'apollo-angular';

import {
  base64ToModel,
  base64ToPK,
  BaseModel,
  parseArray,
  parseAttr,
  pkToBase64,
  rProperty,
} from '../data-model';
import { BLanguage } from '../language/language';
import { BTeam, BUser } from 'app/modules/data-model/user/user';
import { BInquirer } from 'app/modules/data-model/inquirer/inquirer';
import { BEmail } from 'app/modules/data-model/email/email';
import { BPhone } from 'app/modules/data-model/phone/phone';
import { BAddress } from 'app/modules/data-model/address/address';
import { BEvent } from '../event/event';
import { BProduct, BRegion } from 'app/modules/data-model/region/region';
import { BTopic } from 'app/modules/data-model/topic/topic';
import { BCategory } from 'app/modules/data-model/category/category';
import { BAttachment } from '../attachment/attachment';
import { BStatus } from 'app/modules/data-model/status/status';
import { BAnswerTemplate } from '../user/user';
import { BMedicalDocument } from 'app/modules/data-model/medical-document/medical-document';
import {
  inquiryAnswerTextMinLength,
  inquiryQuestionTextMinLength,
  LABEL_OPTIONS,
  priorityLevel,
} from '@mi-tool/consts/const';
import { environment } from 'environments/environment';
import { HistoryStatuses } from '@mi-tool/enums';
import { TinyMCE } from 'app/common/tiny-mce-options';

export class BInteraction extends BaseModel {
  @rProperty() id: string;
  @rProperty() title: string;
  @rProperty() mainStatus: string;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  @rProperty(Date) closedTs: Date;
  @rProperty() deletePiOnClose: boolean;
  @rProperty() piDeleted: boolean;
  @rProperty() mailSubject: string;
  @rProperty() mailTo: string;
  @rProperty() mailFrom: string;
  @rProperty() initialText: string;
  @rProperty() initialPlainText: string;
  @rProperty() outgoingChannel: string;
  @rProperty() outgoingBccList: string; //comma separated values
  @rProperty() productList: string;
  @rProperty() categoryList: string;
  @rProperty() topicList: string;
  @rProperty() statusLabel: string;
  @rProperty() confidential: boolean;
  @rProperty() answerUnavailable: boolean;
  @rProperty() needSrd: boolean;
  @rProperty() answerPreview: string;
  @rProperty(Date) lockedTs: Date;
  @rProperty() importedId: string;
  @rProperty(Date) importedTs: Date;
  @rProperty() importedSource: string;
  @rProperty() needNotificationReceive: boolean;
  @rProperty(Date) inquirerNotificationSentTs: Date;
  @rProperty() inquirerNotificationText: string;
  @rProperty() position: string;
  @rProperty() highlighted: boolean;
  @rProperty() pendingChanges: string;
  @rProperty(Date) dueDate: Date;

  //relations
  inquirer: BInquirer;
  insertedBy: BUser;
  team: BTeam;
  language: BLanguage;
  editedBy: BUser;
  assignedTo: BUser;
  inquiries: BInquiry[];

  statusList: string[];
  lockedBy: BUser;
  inquirerNotificationSentBy: BUser;
  event: BEvent;

  currentInteractionAnswer: BInteractionAnswer;
  // old interaction answers
  interactionAnswers: BInteractionAnswer[];

  // FE utility fields
  maxPriortiy: string;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.language = parseAttr<BLanguage>(json, BLanguage, 'language');
    this.team = parseAttr<BTeam>(json, BTeam, 'team');
    this.editedBy = parseAttr<BUser>(json, BUser, 'editedBy');
    this.insertedBy = parseAttr<BUser>(json, BUser, 'insertedBy');
    this.assignedTo = parseAttr<BUser>(json, BUser, 'assignedTo');
    this.lockedBy = parseAttr<BUser>(json, BUser, 'lockedBy');
    this.inquirerNotificationSentBy = parseAttr<BUser>(json, BUser, 'inquirerNotificationSentBy');
    this.inquirer = parseAttr<BInquirer>(json, BInquirer, 'inquirer');
    this.event = parseAttr<BEvent>(json, BEvent, 'event');
    if (!this.inquirer) {
      this.inquirer = new BInquirer({
        emails: [new BEmail('')],
        phones: [new BPhone('')],
        addresses: [new BAddress('')],
      });
    }
    this.inquiries = parseArray<BInquiry>(json, BInquiry, 'inquiries');

    this.interactionAnswers = parseArray<BInteractionAnswer>(
      json,
      BInteractionAnswer,
      'interactionAnswers'
    );
    this.currentInteractionAnswer = parseAttr<BInteractionAnswer>(
      json,
      BInteractionAnswer,
      'currentInteractionAnswer'
    );
    if (json && json['statusList']) {
      this.statusList = JSON.parse(json['statusList']);
    }
    this.refreshTitle();
    this._checkSuggestions();
  }

  static fromRest(json: any): BInteraction {
    if (json) {
      json.inquirer = BInquirer.fromRest(json.inquirer);
      json.insertedBy = BUser.fromRest(json.insertedBy);
      json.editedBy = BUser.fromRest(json.editedBy);
      json.assignedTo = BUser.fromRest(json.assignedTo);
      json.team = BTeam.fromRest(json.team);
      json.inquiries = BInquiry.fromRestArray(json.inquiries);
      json.createdTs = new Date(json.createdTs);
      json.editedTs = new Date(json.editedTs);
      json.dueDate = new Date(json.dueDate);
      const interaction = Object.assign(new BInteraction({}), json);
      interaction.maxPriortiy = interaction.getMaxPriority();
      return interaction;
    }
    return json;
  }

  static fromRestArray(json: any[]): BInteraction[] {
    return json && json.map((v) => BInteraction.fromRest(v));
  }

  _checkSuggestions() {
    this.suggestions.fields.forEach((fieldName) => {
      if (!this[fieldName]) {
        if (this.objectFields.indexOf(fieldName) >= 0) {
          this[fieldName] = this.suggestions[fieldName].value;
        }
        if (fieldName == 'language') {
          this.language = new BLanguage({
            id: pkToBase64('LanguageNode', this.suggestions[fieldName].value),
          });
        }
        if (fieldName.indexOf('inquirer__') >= 0) {
          // fields for existing relations (like inquirer)
          var inquirerField = fieldName.split('__')[1];

          if (inquirerField == 'email') {
            this.inquirer.suggestions.fields.push('emails');
            this.inquirer.emails.push(
              new BEmail({
                label: 'Office',
                val: this.suggestions[fieldName].value,
                suggestions: '{"isSuggestion": true}',
                isMain: 'TRUE',
              })
            );
          } else if (inquirerField == 'phone') {
            this.inquirer.suggestions.fields.push('phones');
            this.inquirer.phones.push(
              new BPhone({
                label: 'Office',
                val: this.suggestions[fieldName].value,
                suggestions: '{"isSuggestion": true}',
              })
            );
          } else {
            this.inquirer.suggestions.fields.push(inquirerField);
            this.inquirer[inquirerField] = this.suggestions[fieldName].value;
          }
        }
      }
      if (this.inquirer.emails.length >= 2) {
        this.inquirer.emails = this.inquirer.emails.filter((x) => x.val != '');
      }
      if (this.inquirer.phones.length >= 2) {
        this.inquirer.phones = this.inquirer.phones.filter((x) => x.val != '');
      }
      if (fieldName == 'inquirer') {
        this.inquirer = new BInquirer({
          id: pkToBase64('InquirerNode', this.suggestions[fieldName].value),
          emails: [new BEmail('')],
          phones: [new BPhone('')],
          addresses: [new BAddress('')],
        });
      }
    });
  }

  private refreshTitle(): void {
    /* function to refresh title field based on interaction data */
    if (this.title) {
      return;
    }
    if (this.mailSubject) {
      this.title = this.mailSubject;
      return;
    }
    this.title = this.team?.name || '';
    if (this.inquiries.length > 1) {
      this.title += ' Multiple Enquiries';
    } else if (this.inquiries.length === 1 && this.inquiries[0].product) {
      this.title += ' ' + this.inquiries[0].product.name;
    }
  }

  pk(): number {
    if (!this.id) {
      return null;
    }
    return base64ToPK(this.id);
  }

  isNew(): boolean {
    if (!this.id) {
      return false;
    }
    return base64ToModel(this.id) == 'NewInteractionNode';
  }

  isInApproval(): boolean {
    return !this.isNew() && this.statusLabel?.includes('Submitted for Approval');
  }

  isClosed(): boolean {
    return this.mainStatus === 'closed';
  }

  isInReview(): boolean {
    return this.mainStatus === 'in_review' || this.statusLabel?.includes('In Review');
  }

  isClosedOrInReview(): boolean {
    return this.isClosed() || this.isInReview();
  }

  isMerged(): boolean {
    return this.statusLabel?.includes('Merged');
  }

  isClosedWithoutAnswer(): boolean {
    // As a closed case without an answer, we count cases with the status Not A Mir, Test, Spam, Duplicate, Process Error, or Other.
    return ['Not A Mir', 'Test', 'Spam', 'Duplicate', 'Process Error', 'Other'].some((status) =>
      this.statusLabel?.includes(status)
    );
  }

  isMergedOrClosedWithoutAnswer(): boolean {
    return this.isMerged() || this.isClosedWithoutAnswer();
  }

  getMaxPriority(): string {
    if (!this.inquiries.length) {
      return undefined;
    }
    let max = 0;
    for (let i = 0; i < this.inquiries.length; ++i) {
      if (
        priorityLevel[this.inquiries[i].priority.toUpperCase()] >
        priorityLevel[this.inquiries[max].priority.toUpperCase()]
      ) {
        max = i;
      }
    }
    return this.inquiries[max].priority;
  }

  hasFollowUpStatus(): boolean {
    const followUpStatuses = ['requested', '1st_remainder', '2nd_remainder', 'lost', 'response'];
    return this.statusList?.some((status) => followUpStatuses.includes(status));
  }

  hasFollowUpAwaitingStatus(): boolean {
    // The follow-up awaiting statuses are first, second, third follow-up attempt or lost to follow-up.
    const followUpAwaitingStatuses = ['requested', '1st_remainder', '2nd_remainder', 'lost'];
    return this.statusList?.some((status) => followUpAwaitingStatuses.includes(status));
  }

  hasMergeSuggestion(): boolean {
    return Boolean(this?.suggestions?.fields?.includes('merge_to_interaction'));
  }

  hasMoreThanOneInquiry(): boolean {
    return this.inquiries.length > 1;
  }

  hasAnswer(): boolean {
    return this.interactionAnswers?.some((answer) => !answer.draft);
  }

  inquirerIsValid(): boolean {
    return !!this.inquirer && !!this.inquirer.id;
  }

  detailsIsValid(): boolean {
    for (let inq of this.inquiries) {
      if (!inq.detailsIsValid()) {
        return false;
      }
    }
    return true;
  }

  hasValidAnswerDraft(): boolean {
    return this.inquiries.every((inq) => inq.hasValidAnswerDraft());
  }

  hasValidQuestions(): boolean {
    return this.inquiries.every(
      (inquiry) => inquiry?.question?.plainText?.trim()?.length >= inquiryQuestionTextMinLength
    );
  }

  checkInitialAdr() {
    return (
      this.inquiries.length === 1 &&
      this.inquiries[0].question &&
      this.inquiries[0].question.text &&
      this.inquiries[0].adrRelated == undefined
    );
  }

  get inApproval(): boolean {
    return (
      this.statusList &&
      this.statusList.includes('submitted') &&
      this.currentInteractionAnswer == undefined
    );
  }

  bccAsList(): string[] {
    return (this.outgoingBccList && this.outgoingBccList.split(',')) || [];
  }

  get questionAttachments(): BAttachment[] {
    let attachments = [];
    for (const inquiry of this.inquiries) {
      inquiry.history
        .filter((x) => x.category == 'question')
        .map((h) => h.attachments)
        .forEach((a) => {
          attachments = attachments.concat(a);
        });
    }
    return attachments;
  }

  isInquirerNotificationSent(): boolean {
    return Boolean(this.inquirerNotificationSentTs);
  }

  hasLetterAddress(addressValues?: BAddress[]): boolean {
    const addresses = (addressValues || this.inquirer.addresses).filter((address) => {
      return Boolean(address.address1 && address.city && address.country && address.zipCode);
    });

    return Boolean(addresses.length);
  }

  hasCorrectInquiryPhoneFax(phone: string, label: string): boolean {
    switch (this.outgoingChannel) {
      case 'fax':
        return Boolean(label === LABEL_OPTIONS.fax && !!phone);
      case 'telephone':
        return Boolean(label !== LABEL_OPTIONS.fax && !!phone);
      default:
        return true;
    }
  }

  hasEmailAddress(emailValues: BEmail[] = this.inquirer.emails): boolean {
    return emailValues.some((e) => e.val && e.isValid() && !e.isDeleted);
  }

  hasCorrectOutgoingChannel(
    phonesValues = this.inquirer.phones,
    emailValues = this.inquirer.emails,
    addressValues = this.inquirer.addresses
  ): boolean {
    switch (this.outgoingChannel) {
      case 'letter':
        return this.hasLetterAddress(addressValues);
      case 'fax':
      case 'telephone':
        return phonesValues.some((p) => this.hasCorrectInquiryPhoneFax(p.val, p.label));
      case 'email':
        return this.hasEmailAddress(emailValues);

      default:
        return true;
    }
  }

  resolveOutgoingChannelLongErrorMessage(): string | undefined {
    const ERROR_MAP = {
      letter: 'OUT_CHANNEL_ERROR_MSG_LONG',
      fax: 'OUT_CHANNEL_FAX_ERROR_MSG_LONG',
      telephone: 'OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG',
      email: 'OUT_CHANNEL_EMAIL_ERROR_MSG_LONG',
    };
    return ERROR_MAP[this.outgoingChannel];
  }

  resolveOutgoingChannelShortErrorMessage(): string | undefined {
    const ERROR_MAP = {
      letter: 'OUT_CHANNEL_ERROR_MSG',
      fax: 'OUT_CHANNEL_FAX_ERROR_MSG',
      telephone: 'OUT_CHANNEL_TELEPHONE_ERROR_MSG',
      email: 'OUT_CHANNEL_EMAIL_ERROR_MSG',
    };
    return ERROR_MAP[this.outgoingChannel];
  }

  isInquirerNotificationSentAndInquirerChanged(): boolean {
    return Boolean(this.isInquirerNotificationSent() && !this.inquirerIsValid());
  }

  isLetterInvalid(): boolean {
    return this.outgoingChannel === 'letter' && !this.hasLetterAddress();
  }

  resolveMissingFields(translateService, inquiryId: string | null = null): string[] {
    const fields = new Set<string>();
    const populate = (key: string, condition: boolean): void => {
      condition && fields.add(translateService.instant(key));
    };
    const isNullOrUndefined = (val: any): boolean => val === undefined || val === null;
    const isEmptyString = (val: string): boolean => {
      return val?.trim()?.length === 0;
    };
    populate('INQUIRER', !this.inquirerIsValid());
    for (let inq of this.inquiries) {
      if (inquiryId && inquiryId !== inq.id) {
        continue;
      }
      populate('ADR_RELATED', isNullOrUndefined(inq.adrRelated));
      populate('OFF_LABEL', isNullOrUndefined(inq.offLabel));
      populate('PRODUCT_QUALITY_COMPLAINT', isNullOrUndefined(inq.productComplaint));
      populate('PRIORITY', isNullOrUndefined(inq.priority));
      populate('PRODUCT', isNullOrUndefined(inq.product));
      populate('CATEGORY', isNullOrUndefined(inq.category));
      populate('TOPIC', isNullOrUndefined(inq.topic));
    }
    populate('EMAIL_SUBJECT', isEmptyString(this.currentInteractionAnswer?.subject));
    populate('ANSWER_TEXT', !this.hasValidAnswerDraft());
    populate('QUESTION_TEXT_MIN_SIX_CHARS', !this.hasValidQuestions());
    populate(this.resolveOutgoingChannelShortErrorMessage(), !this.hasCorrectOutgoingChannel());

    return Array.from(fields);
  }

  getLastInteractionAnswer(): BInteractionAnswer | null {
    if (!this.interactionAnswers?.length) {
      return null;
    }
    return this.interactionAnswers.reduce((lastInteractionAnswer, currentInteractionAnswer) => {
      return currentInteractionAnswer.editedTs > lastInteractionAnswer.editedTs
        ? currentInteractionAnswer
        : lastInteractionAnswer;
    });
  }

  hasClarificationQuestionWithoutAnswer(selectedInquiryIds: string[]): boolean {
    const lastInteractionAnswer = this.getLastInteractionAnswer();
    if (!lastInteractionAnswer) {
      return false;
    }
    return this.inquiries.some(
      (inquiry) =>
        !selectedInquiryIds.includes(inquiry.id) &&
        inquiry.clarificationQuestions.some(
          (clarificationQuestion) => clarificationQuestion.editedTs > lastInteractionAnswer.editedTs
        )
    );
  }

  isOutgoingChannelEmail(): boolean {
    return 'email' === this.outgoingChannel;
  }

  isOutgoingChannelPhoneFax(): boolean {
    return ['fax', 'telephone'].includes(this.outgoingChannel);
  }
}

export namespace MInteraction {
  export const fragment = gql`
    fragment interactionFragment on InteractionNode {
      id
      createdTs
      editedTs
      closedTs
      dueDate
      mainStatus
      outgoingChannel
      confidential
      answerUnavailable
      needSrd
      outgoingBccList
      importedId
      importedTs
      importedSource
      deletePiOnClose
      piDeleted
      needNotificationReceive
      inquirerNotificationSentTs
      pendingChanges
      statusLabel
    }
  `;

  export const fragmentNew = gql`
    fragment newInteractionFragment on NewInteractionNode {
      id
      deletePiOnClose
      outgoingChannel
      confidential
      answerUnavailable
      needSrd
      outgoingBccList
      dueDate
    }
  `;

  export const fragmentConnection = gql`
    fragment interactionConnectionFragment on InteractionNodeConnection {
      edges {
        node {
          ...interactionFragment
        }
      }
    }
    ${fragment}
  `;
}

export class BInquiry extends BaseModel {
  @rProperty() id: string;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  @rProperty() priority: string;
  @rProperty() offLabel: boolean;
  @rProperty() productComplaint: boolean;
  @rProperty() productComplaintReferenceId: string;
  @rProperty() adrRelated: boolean;
  @rProperty() adrRelatedReferenceId: string;
  @rProperty() answerUnavailable: boolean;
  @rProperty() needSrd: boolean;
  // when we want to avoid too many join, download only ID and decode relation with dedicated service
  @rProperty() productId: string;
  @rProperty() categoryId: string;
  @rProperty() topicId: string;
  @rProperty() importedId: string;
  @rProperty() importedSource: string;
  @rProperty(Date) importedTs: string;

  //relations
  question: BHistory;
  answer: BHistory;
  history: BHistory[];
  duplicateOf: BInquiry;
  topic: BTopic;
  category: BCategory;
  interaction: BInteraction;
  language: BLanguage;
  createdBy: BUser;
  editedBy: BUser;
  assignedTo: BUser;
  product: BProduct;
  status: BStatus;
  region: BRegion;

  clarificationQuestions: BHistory[] = [];
  hasInternalCommunication: boolean;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    if (this.priority) {
      this.priority = this.priority.toLowerCase();
    }
    this.question = parseAttr<BHistory>(json, BHistory, 'question');
    this.answer = parseAttr<BHistory>(json, BHistory, 'answer');
    this.history = parseArray<BHistory>(json, BHistory, 'history');
    this.interaction = parseAttr<BInteraction>(json, BInteraction, 'interaction');
    this.language = parseAttr<BLanguage>(json, BLanguage, 'language');
    this.createdBy = parseAttr<BUser>(json, BUser, 'createdBy');
    this.editedBy = parseAttr<BUser>(json, BUser, 'editedBy');
    this.assignedTo = parseAttr<BUser>(json, BUser, 'assignedTo');
    this.product = parseAttr<BProduct>(json, BProduct, 'product');
    this.status = parseAttr<BStatus>(json, BStatus, 'status');
    this.region = parseAttr<BRegion>(json, BRegion, 'region');
    this.category = parseAttr<BCategory>(json, BCategory, 'category');
    this.topic = parseAttr<BTopic>(json, BTopic, 'topic');
    this.duplicateOf = parseAttr<BInquiry>(json, BInquiry, 'duplicateOf');
    this._checkSuggestions();
    if (this.history && this.history.length) {
      this.answer = this.answer || this.history.find((h) => 'answer_draft' === h.category);
      this.answer = this.answer || this.history.find((h) => 'answer' === h.category);
      this.question = this.question || this.history.find((h) => 'question' === h.category);
      this.clarificationQuestions = this.history.filter(
        (history) => history?.category === 'clarification_question'
      );
      this.hasInternalCommunication = this.history.some((h) => h.category === 'other');
    }
  }

  static fromRest(json: any): BInquiry {
    if (json) {
      json.topic = BTopic.fromRest(json.topic);
      json.category = BCategory.fromRest(json.category);
      json.product = BProduct.fromRest(json.product);
      return Object.assign(new BInquiry({}), json);
    }
    return json;
  }

  static fromRestArray(json: any[]): BInquiry[] {
    return json && json.map((v) => BInquiry.fromRest(v));
  }

  pk(): number {
    return base64ToPK(this.id);
  }

  isNew(): boolean {
    if (!this.id) {
      return false;
    }
    return base64ToModel(this.id) == 'NewInquiryNode';
  }

  title(): string {
    let title = '';
    if (this.category) {
      title += this.category.name;
    }
    if (this.topic) {
      if (title != '') {
        title += ' - ';
      }
      title += this.topic.name;
    }
    return title;
  }

  private _checkSuggestions() {
    this.suggestions.fields.forEach((fieldName) => {
      if (!this[fieldName]) {
        if (this.objectFields.indexOf(fieldName) >= 0) {
          this[fieldName] = this.suggestions[fieldName].value;
        }
        if (fieldName == 'product') {
          this.product = new BProduct({
            id: pkToBase64('ProductNode', this.suggestions[fieldName].value),
          });
        }
        if (fieldName == 'category') {
          this.category = new BCategory({
            id: pkToBase64('CategoryNode', this.suggestions[fieldName].value),
          });
        }
        if (fieldName == 'topic') {
          this.topic = new BTopic({
            id: pkToBase64('TopicNode', this.suggestions[fieldName].value),
          });
        }
      }
    });
  }

  detailsIsValid(): boolean {
    for (let param of [
      'adrRelated',
      'offLabel',
      'productComplaint',
      'priority',
      'product',
      'category',
      'topic',
    ]) {
      if (this[param] == undefined) {
        return false;
      }
    }
    return true;
  }

  hasValidAnswerDraft(textOverride: string = undefined): boolean {
    let draft: BHistory;
    if (!(draft = this.history?.find((x) => x.category === 'answer_draft'))) {
      return false;
    }
    const text = textOverride || draft.plainText || '';
    return (
      draft.linkedFiles.length > 0 ||
      TinyMCE.validatePlainTextLength(text, inquiryAnswerTextMinLength)
    );
  }

  isLastWithActiveCQ(interaction: BInteraction): boolean {
    return (
      interaction?.currentInteractionAnswer?.historyObjects.filter(
        (answerHistory) => answerHistory.inquiryId !== this.id && !answerHistory.hasStatusDisabled()
      ).length === 0
    );
  }
}

export namespace MInquiry {
  export const fragment = gql`
    fragment inquiryFragment on InquiryNode {
      id
      priority
      createdTs
      editedTs
      offLabel
      productComplaint
      productComplaintReferenceId
      adrRelated
      adrRelatedReferenceId
      needSrd
      answerUnavailable
      importedId
      importedSource
      importedTs
    }
  `;

  export const fragmentNew = gql`
    fragment newInquiryFragment on NewInquiryNode {
      id
      priority
      createdTs
      offLabel
      productComplaint
      productComplaintReferenceId
      adrRelated
      adrRelatedReferenceId
      needSrd
      answerUnavailable
    }
  `;

  export const fragmentConnection = gql`
    fragment inquiryConnectionFragment on InquiryNodeConnection {
      edges {
        node {
          ...inquiryFragment
        }
      }
    }
    ${fragment}
  `;
}

export class BInteractionAnswer extends BaseModel {
  /***
   * Old interaction sent (or saved) to the inquirer
   * */
  @rProperty() id: string;
  @rProperty() interactionId: string;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  @rProperty() draft: boolean;
  @rProperty() outgoingChannel: string;
  @rProperty() outgoingBccList: string;
  @rProperty() outgoingToList: string;
  @rProperty() subject: string;
  @rProperty() fromEmail: string;
  @rProperty() answerPreview: string;

  historyObjects: BHistory[];
  answerTemplates: BAnswerTemplate[];
  // TODO: Remove and use answerTemplates with order
  greetings: BAnswerTemplate;
  signature: BAnswerTemplate;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    if (json && json['newHistoryObjects'] != undefined) {
      this.historyObjects = parseArray<BHistory>(json, BHistory, 'newHistoryObjects');
    } else {
      this.historyObjects = parseArray<BHistory>(json, BHistory, 'historyObjects');
    }
    this.answerTemplates = parseArray<BAnswerTemplate>(json, BAnswerTemplate, 'answerTemplates');
    if (this.answerTemplates.length > 0) {
      let greetings = this.answerTemplates.filter((x) => x.type == 'greetings');
      if (greetings.length > 0) {
        this.greetings = greetings[0];
      }
      let signature = this.answerTemplates.filter((x) => x.type == 'signature');
      if (signature.length > 0) {
        this.signature = signature[0];
      }
    }
  }

  bccAsList(): string[] {
    return (this.outgoingBccList && this.outgoingBccList.split(',')) || [];
  }

  isSentViaEmail(): boolean {
    return (this.draft || this.outgoingToList) && this.outgoingChannel === 'EMAIL';
  }
}

export namespace MInteractionAnswer {
  export const fragment = gql`
    fragment interactionAnswerFragment on InteractionAnswerNode {
      id
      createdTs
      editedTs
      outgoingChannel
      draft
      outgoingBccList
      subject
      outgoingToList
      fromEmail
    }
  `;

  export const fragmentConnection = gql`
    fragment interactionAnswerConnectionFragment on InteractionAnswerNodeConnection {
      edges {
        node {
          ...interactionAnswerFragment
        }
      }
    }
    ${fragment}
  `;
}

type HistoryDescriptionObj = {
  status?: string[];
  withReopen?: boolean;
  hasParam?: boolean;
  mergeReason?: string;
  inquirerId?: number;
  inquirer?: BInquirer;
};

export class BHistory extends BaseModel {
  @rProperty() id: string;
  @rProperty() category: string;
  @rProperty() channel: string;
  @rProperty() text: string;
  @rProperty() internal: boolean;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  // needed to handle locally the creation of a new object
  @rProperty() inquiryId: string;
  @rProperty() historyDescription: string | HistoryDescriptionObj;
  @rProperty() plainText: string;
  @rProperty() mergeOrDuplicateParam: number;
  // needed to check if interaction must be refreshed
  @rProperty(Date) interactionEditedTs: Date;
  @rProperty() answerOrder: number;
  interactionId: number;
  mergedFromInteractionId: number;
  @rProperty() status: string;

  // relations
  attachments: BAttachment[];
  medicalDocuments: BMedicalDocument[];
  createdBy: BUser;
  editedBy: BUser;
  interaction: BInteraction;
  reassignedFrom: BUser;
  reassignedTo: BUser;

  changed: boolean = false;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    // fix enum uppercase problem
    if (this.channel) {
      this.channel = this.channel.toLowerCase();
    }
    this.attachments = parseArray<BAttachment>(json, BAttachment, 'attachments');
    this.medicalDocuments = parseArray<BMedicalDocument>(
      json,
      BMedicalDocument,
      'medicalDocuments'
    );
    this.createdBy = parseAttr<BUser>(json, BUser, 'createdBy');
    this.editedBy = parseAttr<BUser>(json, BUser, 'editedBy');
    this.interaction = parseAttr<BInteraction>(json, BInteraction, 'interaction');
    for (var document of this.medicalDocuments) {
      document.historyObjectId = this.id;
    }
    // replace relative path for attachments to make sure visualization is correct
    if (this.text) {
      this.text = this.text.replace(
        'src="/mis/attachment/',
        'src="' + environment.apiBaseUrl + 'mis/attachment/'
      );
    }
  }

  static fromRest(json: any): BHistory {
    if (json) {
      json.createdBy = BUser.fromRest(json.createdBy);
      json.reassignedFrom = BUser.fromRest(json.reassignedFrom);
      json.reassignedTo = BUser.fromRest(json.reassignedTo);
      json.createdTs = new Date(json.createdTs);
      json.attachments = BAttachment.fromRestArray(json.attachments);
      json.medicalDocuments = BMedicalDocument.fromRestArray(json.medicalDocuments);
      return Object.assign(new BHistory({}), json);
    }
    return json;
  }

  static fromRestArray(json: any[]): BHistory[] {
    return json && json.map((v) => BHistory.fromRest(v));
  }

  pk(): number {
    return base64ToPK(this.id);
  }

  isNew(): boolean {
    return base64ToModel(this.id) === 'NewHistoryNode';
  }

  get linkedFiles(): (BAttachment | BMedicalDocument)[] {
    return [].concat(this.attachments, this.medicalDocuments);
  }

  static createEntry(interaction: BInteraction): BHistory {
    const hist = new BHistory({});
    hist.category = 'create';
    hist.createdBy = interaction.insertedBy;
    hist.createdTs = interaction.createdTs;
    hist.channel = interaction.mailFrom
      ? 'email'
      : interaction.importedSource
      ? 'CRM'
      : 'manual insert';
    return hist;
  }

  hasStatusDisabled(): boolean {
    return this.status === HistoryStatuses.DISABLED;
  }
}

export namespace MHistory {
  export const fragment = gql`
    fragment historyFragment on HistoryNode {
      id
      category
      channel
      text
      internal
      createdTs
      editedTs
      inquiryId
      mergeOrDuplicateParam
    }
  `;

  export const fragmentNew = gql`
    fragment newHistoryFragment on NewHistoryNode {
      id
      category
      channel
      text
      internal
      createdTs
      inquiryId
    }
  `;

  export const fragmentConnection = gql`
    fragment historyConnectionFragment on HistoryNodeConnection {
      edges {
        node {
          ...historyFragment
        }
      }
    }
    ${fragment}
  `;
}
