import { gql } from 'apollo-angular';

import { BaseModel, rProperty, parseAttr, parseArray, base64ToPK } from '../data-model';
import { BUser } from '../user/user';
import { BMedicalDocument } from '../medical-document/medical-document';
import { BProduct } from '../region/region';
import { BTopic } from '../topic/topic';
import { BCountry } from '../country/country';
import { BLanguage } from '../language/language';
import { BCategory } from '../category/category';
import { BEntity } from '../entity/entity';

function _getTimeStr(ts: Date): string {
  if (ts) {
    let hours = ts.getHours().toString();
    if (hours.length == 1) {
      hours = '0' + hours;
    }
    let minutes = ts.getMinutes().toString();
    if (minutes.length == 1) {
      minutes = '0' + minutes;
    }
    return hours + ':' + minutes;
  }
  return '00:00';
}

export class BEventDefaultValue extends BaseModel {
  // this is just an encapsulation class, used always BEvent, no service dedicated
  @rProperty() id: string;
  @rProperty() adrRelated: boolean;
  @rProperty() offLabel: boolean;
  @rProperty() productComplaint: boolean;
  @rProperty() channel: string;

  product: BProduct;
  category: BCategory;
  topic: BTopic;
  defaultCountry: BCountry;
  language: BLanguage;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.product = parseAttr<BProduct>(json, BProduct, 'product');
    this.category = parseAttr<BCategory>(json, BCategory, 'category');
    this.topic = parseAttr<BTopic>(json, BTopic, 'topic');
    this.defaultCountry = parseAttr<BCountry>(json, BCountry, 'defaultCountry');
    this.language = parseAttr<BLanguage>(json, BLanguage, 'language');
    if (this.channel) {
      this.channel = this.channel.toLowerCase();
    }
  }
}

export class BEventUserShift extends BaseModel {
  @rProperty() id: string;
  @rProperty(Date) startTs;
  @rProperty(Date) endTs;

  user: BUser;
  entity: BEntity;
  entityId: number;
  saved: boolean;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.user = parseAttr<BUser>(json, BUser, 'user');
    this.entity = parseAttr<BEntity>(json, BEntity, 'entity');
    this.saved = true;
  }

  getStartTimeStr(): string {
    return _getTimeStr(this.startTs);
  }

  getEndTimeStr(): string {
    return _getTimeStr(this.endTs);
  }
}

export class BEvent extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) startTs: Date;
  @rProperty(Date) endTs: Date;
  @rProperty() dailyStartTs: string;
  @rProperty() dailyEndTs: string;
  @rProperty() uri: string;
  @rProperty(Date) formActiveFrom: Date;
  @rProperty(Date) formActiveTo: Date;

  isActive: boolean = true;
  partecipants: BUser[];
  documents: BMedicalDocument[];
  defaultValues: BEventDefaultValue;
  userShifts: BEventUserShift[];
  products: BProduct[];

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.partecipants = parseArray<BUser>(json, BUser, 'partecipants');
    this.documents = parseArray<BMedicalDocument>(json, BMedicalDocument, 'documents');
    this.defaultValues = parseAttr<BEventDefaultValue>(json, BEventDefaultValue, 'defaultValues');
    if (!this.defaultValues) {
      this.defaultValues = new BEventDefaultValue({});
    }
    this.userShifts = parseArray<BEventUserShift>(json, BEventUserShift, 'userShifts');
    if (!this.userShifts) {
      this.userShifts = [];
    }
    if (this.dailyStartTs) {
      this.dailyStartTs = this.dailyStartTs.split(':')[0] + ':' + this.dailyStartTs.split(':')[1];
    }
    if (this.dailyEndTs) {
      this.dailyEndTs = this.dailyEndTs.split(':')[0] + ':' + this.dailyEndTs.split(':')[1];
    }
    this.products = parseArray<BProduct>(json, BProduct, 'products');
  }

  static fromRest(json: any): BEvent {
    return Object.assign(new BEvent({}), json);
  }

  static fromRestArray(json: any[]): BEvent[] {
    return json && json.map((v) => BEvent.fromRest(v));
  }

  getMId() {
    return base64ToPK(this.id);
  }

  getMName() {
    return this.name;
  }

  getStartTimeStr(): string {
    return _getTimeStr(this.startTs);
  }

  getEndTimeStr(): string {
    return _getTimeStr(this.endTs);
  }

  get partecipantDescription(): string {
    if (!this.partecipants || this.partecipants.length == 0) {
      return '';
    }
    return this.partecipants.map((user) => user.fullName()).join(', ');
  }
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment eventFragment on EventNode {
    id
    name
    createdTs
    startTs
    endTs
    dailyStartTs
    dailyEndTs
    uri
    formActiveFrom
    formActiveTo
  }
`;

export const fragmentConnection = gql`
  fragment eventConnectionFragment on EventNodeConnection {
    edges {
      node {
        ...eventFragment
      }
    }
  }
  ${fragment}
`;

export const eventDefaultValueFragment = gql`
  fragment eventDefaultValueFragment on EventDefaultValueNode {
    id
    channel
    adrRelated
    offLabel
    productComplaint
    defaultCountry {
      id
      name
    }
    language {
      id
      name
    }
    product {
      id
      name
    }
    category {
      id
      name
    }
    topic {
      id
      name
    }
  }
`;

export const eventUserShiftFragment = gql`
  fragment eventUserShiftFragment on EventUserShiftNode {
    id
    startTs
    endTs
    user {
      id
      firstName
      lastName
    }
  }
`;
