import { gql } from 'apollo-angular';

import { BaseModel, rProperty, parseAttr } from '../data-model';
import { BRole } from '../role/role';
import { BTeam, MTeam } from '../user/user';
import * as MRole from 'app/modules/data-model/role/role';

export class BTemplateRole extends BaseModel {
  @rProperty() id: string;

  //relations
  role: BRole;
  team: BTeam;
  // userTemplate: BUserTemplate;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.role = parseAttr<BRole>(json, BRole, 'role');
    this.team = parseAttr<BTeam>(json, BTeam, 'team');
  }

  static fromRest(json: any): BTemplateRole {
    json.role = BRole.fromRest(json.role);
    json.team = BTeam.fromRest(json.team);
    return Object.assign(new BTemplateRole({}), json);
  }

  static fromRestArray(json: any[]): BTemplateRole[] {
    return json.map((v) => BTemplateRole.fromRest(v));
  }
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment templateRoleFragment on TemplateRoleNode {
    id
    role {
      ...roleFragment
    }
    team {
      ...teamFragment
    }
  }
  ${MTeam.fragment}
  ${MRole.fragment}
`;
