import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { SimplePage } from 'app/common/types';
import { Ajax } from 'app/common/ajax';
import { map } from 'rxjs/operators';
import { isNil, omitBy } from 'lodash';
import { BMigrationError } from './migration-error';

export type TMigrationErrorData = {
  id: string;
  resolved: boolean;
};

export type TMigrationErrorDialogData = {
  migrationError: BMigrationError;
};

export type TMigrationErrorParams = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: string;
  id?: number;
  migrationName?: string;
  objectType?: string;
  importedId?: number;
  error?: string;
  resolved?: string;
  editedTs?: string;
  errorTs?: string;
  editedById?: string;
};

@Injectable({ providedIn: 'root' })
export class MigrationErrorRestService {
  private readonly URL: string;

  constructor(private http: HttpClient, private urlResolver: UrlResolverService) {
    this.URL = urlResolver.misApiUrlForPath('/migration-errors/');
  }

  fetchMigrationErrorsList(params: TMigrationErrorParams): Observable<SimplePage<BMigrationError>> {
    return this.http
      .get<SimplePage<BMigrationError>>(this.URL, {
        ...Ajax.X_NO_LOADING_OPTIONS,
        params: omitBy(params, isNil),
      })
      .pipe(
        map((response) => {
          return { ...response, records: BMigrationError.fromRestArray(response.records) };
        })
      );
  }

  edit(data: TMigrationErrorData): Observable<void> {
    return this.http.put<void>(this.URL, omitBy(data, isNil));
  }
}
