import { Component, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import {
  BUser,
  BInquirer,
  InquirerService,
  SingleUserService,
} from 'app/modules/data-model/data-model.module';

@Component({
  selector: 'app-modal-user',
  templateUrl: './modal-user.component.html',
})
export class NgbdModalUser implements OnDestroy {
  user: BUser;
  inquirer: BInquirer;
  isPermissionDenied: boolean = false;
  isLoading: boolean = false;

  private subs = new Subscription();

  constructor(
    public ref: MatDialogRef<NgbdModalUser>,
    inquirerService: InquirerService,
    userService: SingleUserService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data.userId) {
      this.subs.add(
        userService.basicUser.subscribe((user) => {
          this.isLoading = false;
          this.user = user;
        })
      );
      this.isLoading = true;
      userService.performBasicQuery(data.userId);
    } else if (data.inquirerId) {
      this.subs.add(
        inquirerService.basicInquirer.subscribe((inquirer) => {
          this.isLoading = false;
          this.inquirer = inquirer;
        })
      );
      this.subs.add(
        inquirerService.permissionDenied.subscribe(() => {
          this.isLoading = false;
          this.isPermissionDenied = true;
        })
      );
      this.isLoading = true;
      inquirerService.performBasicQuery(data.inquirerId);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
