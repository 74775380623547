export const chineseLangData = {
  MEDICAL_INFO_SYSTEM: '医疗信息系统',
  ACTIVE_EVENTS: '活动事件',
  SELECT_AN_EVENT_TO_BROWSE_USERS: '选择一个事件以浏览用户。',
  BACK_TO_THE_EVENT_SELECTION: '返回活动选择',
  IF_YOU_FORGOT_YOUR_PASSWORD: '如果您忘记密码',
  LOGIN: '登录',
  RESET_IT_HERE: '在这里重置',
  CHANGE_LANGUAGE_TO: '将语言更改为',
  TEST_SYSTEM: '测试系统',
  HELP: '救命',
  THIS_IS_TEST_ENV: '这是一个测试环境，如果要插入真实查询，请转到',
  USER_OR_PASS_IS_INCORRECT: '用户名或密码不正确',
  OPS: '行动',
  WE_DID_NOT_FIND: '我们没有找到您想要的东西',
  VIDEO_TUTORIALS: '影片教学',
  STILL_DIDNT_FIND: '仍然找不到您想要的东西，',
  ASK_THE_SUPPORT_TEAM: '询问支持团队',
  LOGGED_IN_AS: '登录为',
  MY_PROFILE: '我的简历',
  LOGOUT: '登出',
  RETRIEVING_YOUR_ACCESS_INFO: '检索您的访问信息',
  LOGGED_IN_CAN_CLOSE: '登录后，您现在可以关闭此标签',
  TOKEN_HAS_NOT_BEEN_SAVED: '令牌未正确保存，请联系支持人员',
  SHARE_POINT_LOGIN: 'SharePoint登录',
  LOGIN_TO_SHARE_POINT: '请登录到SharePoint以继续',
  PASSWORD_RESET: '重设密码',
  RESET_PASSWORD: '重设密码',
  INSERT_YOUR_EMAIL_TO_RESET_PASS: '请插入您的电子邮件以重置密码',
  CANCEL: '取消',
  CANCEL_UPPERCASE: '取消',
  RETURN_TO_LOGIN: '返回登录',
  USERNAME: '用户名',
  USERNAME_IS_REQUIRED: '用户名为必填项',
  PASSWORD: '密码',
  PASSWORD_IS_REQUIRED: '密码是必需的',
  FIRST_NAME: '名字',
  LAST_NAME: '姓',
  REGISTER: '寄存器',
  LOADING: '载入中',
  INSERT_NEW_PASS: '请输入您的新密码',
  VERIFYING_TOKEN: '验证令牌',
  PASSWORD_DO_NOT_MATCH: '密码不匹配',
  PASS_RESET_SUCCESSFUL: '密码重置成功',
  SAVE_NEW_PASS: '保存新密码',
  GO_TO_INBOX: '转到收件箱',
  SELECT_ALL: '全选',
  DESELECT_ALL: '全部取消选择',
  DO_NOT_SHOW_AGAIN: '不再显示',
  INQUIRY: '查询',
  ATTACHMENT_WITH_THIS_INQUIRY: '>与此查询有关的附件',
  USER_DETAILS: '使用者详细资料',
  CLOSE: '关',
  TEAMS: '队伍',
  TEAM: '球队',
  SPECIALIZATION_IS: '专业化是',
  REQUIRED: '需要',
  RESULTS_PER_PAGE: '每页结果',
  RESULTS_FOUND: '找到结果',
  EXPORT: '出口',
  PERSONAL_SETTINGS: '个人设定',
  TEAM_SETTINGS: '团队设定',
  USER_SETTINGS_OF: '用户设置',
  SET_TEMPLATE: '设置模板',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: '通知将被当前通知覆盖',
  PREVIEW: '预习',
  NOTIFICATIONS: '通知事项',
  ROLE: '角色',
  TEMPLATE_NAME: '模板名称',
  SAVE_THIS_USER_AS_A_TEMPLATE: '将此用户另存为模板',
  ADD_A_TEMPLATE_TO_YOUR_USER: '向您的用户添加模板，并复制通知的角色和首选项',
  AUTH_AND_NOTIFY_TEMPLATE: '授权和通知模板',
  MY_ROLES: '我的角色',
  STATUS: '状态',
  THIS_ROLE_IS_TEMP: '该角色是临时的，由于该用户是代理人，因此已被授予',
  CREATED: '已建立',
  APPROVED_REJECTED: '批准/拒绝',
  APPROVED_REJECTED_BY: '批准/拒绝',
  ARCHIVE: '存档',
  SAVE: '保存',
  IMMEDIATE_EFFECT: '立即生效',
  OUT_OF_OFFICE_DEPUTY: '不在办公室',
  AUTO_ASSIGN_TO: '如此优秀',
  GEOGRAPHY: '地理',
  ALIGNMENT: '对准',
  Alignments: '对齐方式',
  Address: '地址',
  PHONE_FAX: '电话/传真',
  Email: '电子邮件',
  Contacts: '联络人',
  WORK_INFO: '工作资讯',
  LAST_NAME_IS: '姓是',
  PERSONAL_INFO: '个人信息',
  USER: '用户',
  ID: 'ID',
  UPDATED_FROM: '更新自',
  ON: '上',
  CRM: '客户关系管理',
  IMPORTED_FROM: '从进口',
  CHANGE_PASS: '更改密码',
  NOTIFICATION_IN_TEMPLATE: '模板中的通知',
  CONTINUE: '继续',
  DONT_SHOW_AGAIN: '不再显示此消息',
  USING_THE_TABS_ON_THE: '使用屏幕顶部的标签',
  PERSONAL_PROFILE_SETTINGS: '个人资料设置',
  AND_YOUR: '和你的',
  YOU_CAN_SWITCH_BETWEEN: '您可以在',
  WELCOME_TO_YOUR_USER_PROFILE: '欢迎使用您的用户个人资料',
  SAVE_THIS_USER_AS: '将此用户另存为模板',
  INCLUDE_NOTIFY_SETTINGS: '包括通知设置',
  INCLUDE_ROLES_SETTINGS: '包括角色设置',
  ACTIVE_NOTIFICATIONS: '主动通知',
  SET_THIS_USER_AS_A_: '将此用户授权和通知设置为新模板',
  REQUEST_AUTH: '请求授权',
  REQUEST: '请求',
  FEEDBACK_SUPPORT: '反馈与支持',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    '如果仍然找不到所需的内容，请联系支持团队！ 另外，如果您对未来的改进有建议，那么这里是正确的地方。',
  TEMPLATE: '模板',
  SIGNATURE: '签名',
  Disclaimers: '免责声明',
  Greetings: '问候',
  CUSTOMIZE: '定制',
  SAVE_UPPERCASE: '保存',
  DELETE: '删除',
  RESET_TO_DEFAULT: '重置为默认',
  AFTER_SIGNATURE: '签名后',
  BEFORE_SIGNATURE: '签名前',
  AFTER_ANSWER: '回答后',
  BEFORE_ANSWER: '回答之前',
  AFTER_GREET: '问候后',
  BEFORE_GREET: '问候之前',
  Any: '任何',
  HCP: 'HCP',
  NO_HCP: '没有HCP',
  Position: '位置',
  ON_LABEL: '在标签上',
  OFF_LABEL: '关闭标签',
  YOU_ARE_TRYING_TO_CUSTOMIZE: '您正在尝试自定义模板',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    '您无权执行此操作。 如果要继续，将创建模板的副本，然后在此处进行更改。',
  CREATE_COPY: '建立副本',
  RECEIVED_ON: '收到日期',
  SENDER_EMAIL: '发送邮件',
  SUBJECT: '学科',
  UNSPAM: '反垃圾邮件',
  UPLOAD: '上载',
  APPROVED: '已批准',
  DRAFT: '草案',
  SELECT_STATUS: '选择状态',
  UPLOAD_AS: '上载为',
  TITLE: '标题',
  REPOSITORY: '资料库',
  BROWSE: '浏览',
  URL: '网址',
  APPROVED_ON: '已批准',
  AUTH_HOLDER: '授权持有人',
  AUTH_NUMBERS: '授权号',
  APPROVED_FOR: '批准用于',
  SELECT_ONE_REGION: '选择一个地区',
  OWNED_BY: '拥有者',
  KEYWORDS: '关键词',
  TOPICS: '话题',
  CATEGORIES: '分类目录',
  PRODUCTS: '产品展示',
  PRODUCT: '产品',
  LANGUAGE_IS_REQUIRED: '语言为必填项',
  LANGUAGE: '语言',
  TARGET_CUSTOMERS: '目标客户',
  TYPE_IS_REQUIRED: '类型为必填项',
  TYPE: '类型',
  TITLE_IS_REQUIRED: '标题为必填项',
  DEFAULT_REFERENCE_IS_CREATED_:
    '默认参考是使用Vancouver样式创建的。 如果要覆盖此内容，请在此处输入完整参考',
  REFERENCE: '参考',
  DOI: 'DOI',
  PUBMED_ID: '公开ID',
  PAGES: '页数',
  ISSUE: '问题',
  VOLUME: '体积',
  JOURNAL: '日志',
  AUTHORS: 's',
  ABSTRACT: '抽象',
  REFERENCE_IS_REQUIRED: '需要参考',
  THIS_DOC_IS_APPROVED: '该文件已批准。 点击这里打开',
  ORIGINAL_DOCUMENT_AND_: '原始文件并插入您的评论',
  EDIT_FILE: '编辑档案',
  PROPERTIES: '物产',
  OPEN: '打开',
  LOCALIZED_DOCUMENTS: '本地化文件',
  ORIGINAL_DOCUMENTS: '原始文件',
  INTERNAL_APPROVAL_NUMBER: '内部批准号',
  CREATED_BY: '由...制作',
  EXPIRATION_DATE: '截止日期',
  VERSION_NUMBER: '版本号',
  NO_VERSION_AVAILABLE: '没有可用的版本',
  ARCHIVED_IF_NO_DRAFT: '已存档：如果没有草稿/批准版本。',
  APPROVED_FOR_USERS: '已批准：适用于用户。',
  DRAFT_FOR_AUTHORS_: '草稿：作者和编辑。',
  VERSION_LEGEND: '版本图例',
  VERSION: '版',
  SELECT_ONE_LANG: '选择一种语言。',
  SELECT_ONE_TYPE: '选择一种类型。',
  INTERNAL_NUMBER_REQUIRED: '需要内部批准号。',
  FILE: '文件',
  ATTACH_FILE: '附加档案',
  LITERATURE: '文献',
  SLIDE_DECK: '滑梯',
  PIL: '皮尔',
  FAQ: '常问问题',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: '添加批准步骤',
  ADD_PARALLEL_STEP: '添加并行步骤',
  DELETE_STEP: '删除步骤',
  DELETE_ALL_PARALLEL_STEPS: '删除所有并行步骤',
  CHANGE_STATUS_NAME: '变更状态名称',
  SET_REQUIRED_ROLE: '设置所需角色',
  SET_TEAM_MEMBER: '设置团队成员',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: '您无权更改此过程。',
  SAVE_ROLE: '保存角色',
  CREATE_NEW_WORKFLOW: '建立新的工作流程',
  CREATE_NEW_STEP: '创建新步骤',
  PLEASE_ENTER_THE_NUMBER_OF_:
    '请输入并行批准步骤的数量，即同时（而不是顺序）批准的步骤。 如果没有并行的批准步骤，请输入1。您以后可以随时更改批准步骤的数量。',
  APPROVAL_MUST_BE_AT_LEAST: '批准必须至少',
  INSERT_THE_NEW_STATUS_NAME: '插入新的状态名称',
  INSERT_THE_NEW_WORKFLOW_NAME: '插入新的工作流名称。',
  INSERT_THE_NEW_STEP_NAME: '插入新的步骤名称。',
  NEW_DOCUMENT: '新文件',
  CREATE: '创建',
  DOCUMENT_MANAGEMENT: '文档管理',
  CONFIRM: '确认',
  CONFIRM_PROCESS: '确认过程',
  DEFINE_APPROVAL_STEPS_AND_TEAM: '确定批准步骤和团队',
  SET_DETAILS: '设定细节',
  SELECT_TYPE: '选择类型',
  COMPLETE_LOCALIZATION_PROCESS: '完整的本地化流程',
  CREATE_NEW_VERSION: '建立新版本',
  CREATE_NEW_DOCUMENT: '建立新文件',
  IS_PRIVATE: '是私人的',
  SHARED_WITH_OTHER_TEAMS: '与其他团队共享',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    '请输入所需的顺序批准步骤数。 如果两个审批同时进行，则视为第一步。 您以后随时可以更改批准步骤的数量。',
  DONE: '完成',
  SUBMIT_FOR_APPROVAL: '提请批准',
  YOU_HAVE_TO_CONFIRM_THE_: '您必须在上传之前确认该过程。',
  SELECT_YOUR_DEC_TYPE: '选择您的文件类型',
  TARGET_AUDIENCE: '目标听众',
  PROCESS_DETAILS: '工艺细节',
  START_DATE: '开始日期',
  DOC_DETAILS: '文件详细资料',
  CONFIRMATION: '确认书',
  APPROVAL_PROCESS: '审批流程',
  SCHEDULED_EXP_DATE: '计划的到期日期',
  SCHEDULED_PUBLISHING_DATE: '预定发布日期',
  NO_PROCESS_SELECTED: '未选择过程',
  COUNTRIES: '国别',
  DOC_ID: '文件编号',
  THERAPEUTIC_AREA: '治疗区域',
  FILTER: '过滤',
  BACK: '背部',
  ATTACH: '连接',
  COPY_ANSWER: '复制答案',
  SIMILAR_INQUIRIES: '类似查询',
  SDR_FAQ: 'SRD /常见问题',
  SLIDE_DECKS: '滑梯',
  MEDICAL_DOC: '医疗文件',
  SHOW: '显示',
  LOAD_MORE: '装载更多',
  NO_RESULT_FOUND: '未找到结果。',
  CAN_BE_USED_TO_SEARCH_FOR_AN_: '可用于搜索整个句子，-可用于从指定单词中的搜索结果中排除',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: '搜索医疗文件或类似查询',
  SEARCH: '搜索',
  DRAFT_VERSION: '草稿版本',
  AVAILABLE_ACTIONS: '可用操作',
  OPEN_DOC: '打开文件',
  UPLOAD_FILE: '上传文件',
  THE_DOCUMENT_IS_STILL_IN_APPROVAL_:
    '该文档仍在批准中，如果要更改文档内容，可以在Sharepoint中打开该文档或上传更新的版本。',
  FINALIZE_LOCALIZATION: '完成本地化',
  THIS_DOC_HAS_BEEN_LOCALIZED: '该文档已本地化，必须在批准之前完成流程。',
  Preview: '预习',
  PREVIEW_AND_SEND: '預覽並發送',
  PREVIEW_AS_PDF: '预览为pdf',
  PUBLISHED_VERSION: '发行版本',
  NEW_VERSION: '新版本',
  THE_DOC_IS_STILL_IN_APPROVAL_: '如果您要更改文档内容，请上载另一个文件，该文件仍在审批中。',
  OUT_TO: '出去',
  NEXT: '下一个',
  SELECTED_SLIDES: '选定的幻灯片',
  GO_TO_SLIDE: '滑行',
  PREV: '上一个',
  SELECT_SLIDE: '选择幻灯片',
  ANSWER: '回答',
  BACK_TO_STANDARD_VIEW: '返回到标准视图。',
  UPLOAD_NEW_FILE: '上载新文件',
  CREATE_FILE: '创建文件',
  EDITING_WORD_DOC: '编辑Word文档',
  INSERT_FILE_NAME: '插入文件名',
  PROCEED_WITH_THE_LINK_: '继续文件的链接',
  A_NEW_WINDOW_WILL_BE_: '一个新窗口将打开，其中包含一个空文件，写入内容并关闭选项卡',
  INSERT_THE_NAME_FOR_: '插入您要创建的附件的名称，然后继续创建文件',
  INSTRUCTIONS: '使用说明',
  CREATE_NEW_WORD_FILE_FOR: '为创建新的Word文件',
  FROM: '从',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    '您可以在此处找到您已在系统中插入的最后一个已提交和尚未提交的查询。 您可以重复使用插入的查询来创建更多类似的查询，或者返回尚未完成插入的查询。',
  MY_DRAFT_INQ: '我的草稿查询',
  WARNING_CHANGES_NOT_SAVED: '警告：更改未保存',
  PAGE_THAT_WILL_BE_LOST: '如果您决定继续，该页面将会丢失。',
  YOU_HAVE_UNUSED: '您有未保存的更改',
  ARE_YOU_SURE_YOU_WANT_TO_: '您确定要离开此页面而不保存吗？',
  LEAVE_THIS_PAGE: '离开这个页面',
  STAY_ON: '呆着',
  ASSIGNED_TO: '分配给',
  THIS_INQ_HAS_BEEND_INSERTED_: '活动期间已插入此查询。',
  STATUS_IN_APPROVAL: '状态：批准中',
  THE_RESPONSIBLE_FOR_THE_ANSWER_: '答案批准负责人必须接受答案。',
  STATUS_DRAFT: '状态：草稿',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    '如果要创建实际查询，请转到“提交”选项卡，然后单击“创建”按钮。',
  INQ_IMPORTED_FROM: '询价进口',
  SEND_NEW_ANSWER: '发送新答案',
  RETURN_TO_INBOX: '返回收件箱',
  SUBMITTED_FOR_APPROVAL: '提交批准',
  ANSWER_SUBMITTED_ON: '答案提交于',
  BY: '通过',
  INQ_HAS_BEEN_CLOSED: '查询已经结束，没有答案。',
  BCC: '密件抄送',
  ANSWER_GIVEN_ON: '给出的答案',
  ANSWER_SENT_ON: '答案已发送',
  INBOX: '收件箱',
  OPEN_ORIGINAL_INQ: '打开原始查询',
  CLOSE_ANSWER_NOT_NEEDED: '结束：不需要分析服务',
  HISTORY: '历史',
  ADD_A_NOTE: '添加便条',
  ADD_A_NOTE_INTO_: '在查询历史记录中添加注释。',
  SHARE_OR_START_A_FOLLOW_UP: '分享或开始跟进',
  SEE_ALL_INQ_RELATED_TO: '查看所有有关的查询',
  SRD_NEEDED: '需要SRD',
  ANSWER_UNAVAILABLE: '答案不可用',
  QUESTION: '题',
  SUBMIT: '提交',
  ANSWER_UPPERCASE: '回答',
  DETAILS: '细节',
  SPLIT_INQ: '拆分查询',
  ADD_QUESTION: '添加问题',
  ENTER_QUESTION: '输入问题',
  MARK_AS_ANSWERED: '标记为已回答',
  TO_BE_ANSWERED: '待回答',
  FOLLOW_UP_RECEIVED: '后续跟进',
  ERROR: '错误',
  SHOW_ORIGINAL_MESSAGE: '显示原始讯息',
  SEND: '发送',
  DO_NO_SEND: '不要寄',
  OK: '好',
  MAX_LENGTH_FOR_ZIP_CODE: '邮递区号的最大长度为',
  CHARACTERS: '人物',
  ADDRESS_1_IS_: '地址1为',
  LABEL_IS: '标签为',
  NO_QUESTION_INSERTED: '没有插入问题',
  WARNING: '警告',
  FOLLOW_UP_IS_NOT_AVAILABLE_: '后续活动不适用于此互动。',
  INSERT_INQ_TEXT: '插入查询文字',
  SHOW_MORE: '显示更多',
  OUT_OF: '在......之外',
  INQ_TO_BE_SENT: '发送查询',
  HIDE_INQ: '隐藏查询',
  SHARE: '分享',
  HCP_PROFILE_OF: '的HCP个人资料',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    '填寫以下所有必填字段和至少一個電子郵件地址，然後保存以預覽 GDPR 收據通知。',
  CONFIRMATION_SENT_BY: '通知發送者',
  COUNTRY_IS: '国家是',
  COUNTRY: '国家',
  AT_LEAST_ONE_CONTACT_IS_: '至少有一个联系人是',
  EMAIL_IS: '电子邮件是',
  EMAIL_LABEL_IS: '电子邮件标签为',
  PHONE_FAX_IS: '电话/传真标签为',
  PHONE_FAX_NUMBER_IS: '电话/传真号码为',
  ADDRESS: '地址',
  DELETE_PERSONAL_DATA: '删除个人资料',
  HCP_VERIFIED: 'HCP验证',
  KEY_OPINION_LEADER: '关键意见领袖',
  HOSPITAL_BASED: '基于医院',
  HCP_RELATED_INFORMATION: 'HCP相关信息',
  ACCEPT: '接受',
  REJECT: '拒绝',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: '您要接受这些建议吗？',
  SALES_REP: '销售代表',
  SUGGEST_A_TOPIC: '建议一个话题',
  TEAM_SPECIFIC_FIELDS: '团队特定领域',
  ADR_RELATED: 'ADR相关',
  NOT_ADR_RELATED: '与ADR不相关',
  PLEASE_REVIEW_THE_MESSAGE_AND_: '请查看该消息，并将其分类为与药物不良反应相关。',
  ADVERSE_DRUG_REACTION: '药物不良反应',
  CHANGE_DETAILS: '更改细节',
  YES: '是',
  NO: '没有',
  DUE_DATE: '截止日期',
  CRITICAL: '危急',
  NORMAL: '正常',
  PRIORITY: '优先',
  SELECT_DETAILS: '选择详细信息',
  SAVE_REVIEW: '保存评论',
  SET_TO_ALL_INQ: '设置为所有查询',
  PRODUCT_QUALITY_COMPLAINT: '产品质量投诉',
  PQC_PC_A_PRODUCT_:
    'PQC / PC A产品质量投诉（QC）或问题的定义是：涉及任何药品的缺陷或故障或有关药品的质量，性能，安全性的任何问题，例如 气味和/或味道改变，包装不良或有缺陷，可疑的污染等。',
  OFF_LABEL_USE_OF_PHARMA___: '未经批准的适应症或未批准的年龄组，剂量或给药途径的标签外使用药物。',
  A_RESPONSE_TO_A_MEDICINAL_:
    '对有害的和非预期的药品的响应以及药品与不良事件之间的因果关系至少是合理的可能性。 此外，特殊情况下的案例（例如怀孕案例，不合规定使用，过量）也需要标记为ADR。',
  NORMAL_IS_7_WORKING_DAYS: '正常是7个工作日，高峰是48小时，危急（与患者相关）是24小时',
  CATEGORY_AND_TOPIC_ARE: '类别和主题是',
  PRODUCT_IS: '产品是',
  DO_YOU_WANT_TO_ACCEPT_: '您要接受这些建议吗？',
  MERGE: '合并',
  Duplicate: '重复',
  Other: '其他',
  Spam: '垃圾邮件',
  CLOSE_INTERACTION: '紧密互动',
  RE_OPEN_THE_TARGET_: '合并后重新打开目标查询',
  MARK_AS_INTERNAL_: '标记为现有查询的内部消息。',
  MARK_AS_AN_ERROR_: '标记为现有查询的错误消息。',
  THIS_MESSAGE_WILL_BE_EXISTING_: '该消息将被标记为现有查询的后续响应。',
  THANK_YOU: '谢谢',
  AN_INTERNAL_MESSAGE: '内部消息',
  AN_ERROR_MESSAGE: '错误讯息',
  NEW_INQ: '新查询',
  A_FOLLOW_UP_RESPONSE: '后续回应',
  THIS_INQ_IS: '该查询是',
  SPLIT_INTERACTION: '拆分互动',
  SAVE_AND_CLOSE: '保存并关闭',
  SAVE_INQ: '保存查询',
  REVIEW_THE_TEXT_AND: '查看文本和分类，然后确认',
  CHOOSE_THE_RELEVANT_PRODUCT: '为所选文本选择相关的产品，类别和主题',
  SELECT_THE_TEXT_THAT_:
    '选择标识有关一个产品/主题的问题的文本（不包括问候语，签名，免责声明或其他不相关的信息）',
  REPEAT_THE_PREVIOUS_: '重复上述步骤以添加更多查询',
  READ_MORE: '阅读更多',
  READ_LESS: '少读',
  DO_NOT_INCLUDE_: '不包含问候语，签名，免责声明或其他无关信息',
  SELECT_THE_TEXT_THAT_IDENT: '选择标识有关一种产品/主题的问题的文本',
  IF_YOU_HAVE_MULTIPLE_: '如果您有多个产品或主题，则必须拆分查询。',
  THE_FOLLOWING_PROCESS_: '以下过程将帮助您识别每个产品/主题的相关文本：',
  EXPORT_TO_PDF: '导出为PDF',
  Added: '添加',
  Deleted: '已删除',
  From: '从',
  To: '至',
  BACK_TO_INBOX: '返回收件箱',
  PERMISSION_DENIED: '没有权限',
  THIS_INQ_IS_LOCKED_BY: '该查询被锁定',
  FORCE_UNLOCK: '强制解锁',
  INQUIRIES: '咨询处',
  Download: '下载',
  Event: '事件',
  INSERTED_BY: '插入者',
  LAST_EDITED_ON: '最后编辑日期',
  LAST_EDITED_BY: '最后编辑者',
  CREATED_ON: '创建于',
  TOPIC: '话题',
  TABLE_OPTIONS: '表格选项',
  DEPARTMENT: '部门',
  User: '用户',
  ADD_THERAPEUTIC_AREA: '添加治疗区域',
  CREATE_THERAPEUTIC_AREA: '创造新的治疗领域',
  ADD_NEW: '添新',
  SELECT_TEAM_TO_PROCEED: '选择团队继续',
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  SALES: '营业额',
  CALL_CENTER: '呼叫中心',
  ALL: '所有',
  Warning: '警告',
  clear: '明确',
  THESE_CHANGES_WILL_BE_: '这些更改将应用于选定的用户',
  INSERT_A_FOLLOW_UP_RESP: '插入跟进回应',
  INSERT_A_NOTE: '插入便条',
  MERGED_FROM: '合并自',
  CHANGES: '变化',
  SHOW_LESS: '显示较少',
  COMPLETE: '完成',
  MISSING: '失踪',
  CLOSE_AS_FORWARDED: '以转发方式关闭',
  CLOSE_EXT_TEAM: '关闭查询，转发给外部团队',
  THE_INQ_BELONG_TO_A_: '该查询属于一个由外部团队负责的国家/地区。 然后，查询将自动转发给该团队。',
  RESOLVE: '解决',
  TERRITORIES: '领地',
  EXTERNAL_COMMUNICATION: '外部通讯',
  FOR_INQ_COMING_DIRECTLY_:
    '对于通过电子邮件直接从HCP发送的查询，将自动发送通知以通知他已收到查询。',
  INTERNAL_COMMUNICATION: '内部通讯',
  SALESREP_RECEIVES_: 'Salesrep仅收到有关查询已答复的通知',
  SALESREP_RECEIVES_FULL_ANSWER_: 'Salesrep会收到有关标签查询的完整答案',
  SALESREP_RECEIVES_ONLY_NOTIF_: 'Salesrep仅收到有关查询已答复的通知',
  Name: '名称',
  Module: '模组',
  Function: '功能',
  LAST_RUN: '最后运行',
  End: '结束',
  Every: '每一个',
  Period: '期',
  Active: '活性',
  Resolved: '解决',
  ERROR_PREVIEW: '错误预览',
  FAILURE_COUNT: '失败计数',
  COMPLETED_ON: '完成于',
  STARTED_ON: '开始于',
  TASK_NAME: '任务名称',
  PRODUCT_SPECIFIC: '特定产品',
  THERAPEUTIC_AREAS: '治疗领域',
  PRODUCT_GROUPS: '产品组',
  LICENSING_PARTNER: '许可合作伙伴',
  PRODUCT_CODE: '产品代码',
  EXTERNAL_ID: '外部ID',
  OBJECT_TYPE: '对象类型',
  MIGRATION_NAME: '迁移名称',
  MIGRATION_ERRORS: '迁移错误',
  ADD_SALES_REP: '添加销售代表',
  ASSIGNED_TO_MSL: '分配给MSL',
  NOT_ASSIGNED: '未分配',
  ASSIGNED_TO_OTHER_MSL: '分配给其他MSL',
  ASSIGNED_TO_THIS_MSL: '分配给此MSL',
  SHOW_ONLY: '只显示',
  SALES_REP_LIST: '销售代表清单',
  ACTIVE_TASK: '激活任务',
  CHOOSE_USER_AND_SET_THE_SHIFT_: '选择用户并设置活动的班次',
  MANAGE_SHIFTS: '管理移位',
  CREATE_NEW_USER: '建立新使用者',
  THIS_TEMPLATE_WILL_BE_AUTO_: '该模板将自动添加到您的团队成员中。',
  TEMPLATE_FOR: '的模板',
  Day: '天',
  Week: '周',
  Month: '月',
  Today: '今天',
  Archive: '封存',
  PARTICIPANTS: '参加者',
  EVENT_MANAGEMENT: '事件管理',
  ADD_TASK: '添加任务',
  EDIT_TASK: '编辑任务',
  IS_ACTIVE: '活跃',
  Months: '月数',
  Days: '天',
  Hours: '小时',
  Minutes: '分钟',
  HCP_ADMINISTRATION: 'HCP管理',
  AUTH_REQ_ADMINISTRATION: '验证请求管理',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_: '该产品将在选定的治疗区域中突出显示。',
  PRODUCT_GROUPS_DEFINE_: '产品组定义了负责回答此产品查询的团队。',
  USER_ADMINISTRATION: '用户管理',
  IS_PRODUCT_SPECIFIC: '是特定于产品的',
  USER_TEMPLATES_ADMIN: '用户模板管理',
  SET_DEFAULT_VALUES: '设置默认值',
  SET_DEFAULT_VALUES_FOR_: '设置事件的默认值',
  INQUIRY_DEFAULT_VAL: '查询默认值',
  CREATE_NEW_EVENT: '创建新活动',
  EDIT_EVENT: '编辑事件',
  SET_DETAILS_TO_CREATE_NEW_EVENT: '设置详细信息以创建新事件',
  ADD_DOC_LINKED_TO_: '添加链接到事件的文档',
  INC_CHANNEL: '公司频道',
  CHANGE_DETAILS_OF_THE_EVENT: '更改活动的详细信息',
  DESCRIPTION: '描述',
  CREATE_APPROVAL_PROCESS: '选择批准流程',
  CREATE_NEW: '创建新的',
  CUSTOMIZE_PROCESS: '定制流程',
  SELECTED_PROCESS: '选定的过程',
  CLONE: '克隆',
  PREFERENCES_TITLE: '首选项',
  NOTIFICATIONS_TITLE: '通知事项',
  TEMPLATES: '范本',
  LETTER_TEMPLATES: '信函模板',
  USER_TEMPLATES: '用户模板',
  TEAM_MEMBERS: '团队成员',
  NEW_PASSWORD: '新密码',
  REPEAT_NEW_PASSWORD: '重复新密码',
  AT_LEAST_1_LOWERCASE_CHARACTER: '至少1个小写字母',
  AT_LEAST_1_UPPERCASE_CHARACTER: '至少1个大写字母',
  AT_LEAST_1_DIGIT: '至少1位数字',
  AT_LEAST_1_SPECIAL_CHARACTER: '至少1个特殊字符',
  MINIMUM_8_CHARACTERS: '至少8个字符',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: '不应包含电子邮件或姓名',
  SHOULD_MATCH_PASSWORD: '应该与密码匹配',
  USER_MANUAL: '用户手册',
  SUPPORT: '支持',
  BACK_TO_LOGIN: '回到登入',
  PASSWORD_CONFIRMATION: '确认密码',
  HAVE_AN_ACCOUNT: '已经有帐号了？',
  Submit: '提交',
  Gender: '性别',
  PROFESSION: '职业',
  MEDICAL_INQ_ROLES: '医疗查询处理角色',
  MEDICAL_INFO_MANAGER: '医学信息经理',
  MEDICAL_SCIENCE_LIAISON: '医学联络',
  NOT_PROCESSING_INQUIRIES: '不处理查询',
  ACTIVE_HEALTH_CARE_SPEC: '积极卫生保健专家',
  NOT_ACTIVE_HEALTH_CARE_SPEC: '不活跃的卫生保健专家',
  PASS_IS_REQUIRED: '密码是必需的！',
  EMAIL_IS_REQUIRED: '电子邮件为必填项！',
  VALID_EMAIL: '请输入有效的电子邮件地址！',
  FIELD_IS_REQUIRED: '这是必填栏！',
  ONLY_LETTERS: '请仅输入字母！',
  ARE_YOU_daiichi_EMPLOYEE: '您是第一三共员工吗？',
  here: '这里',
  CREATE_AN_ACCOUNT: '创建一个帐户',
  FORGOT: '忘记？',
  Medical: '医疗类',
  Medical_not_resp: '医疗-对MIR不承担责任',
  Commercial: '商业广告',
  Market_access: '市场准入',
  Pharmacovigilance: '药物警戒',
  PR: '公关',
  Please_specify: '请明确说明',
  Medical_information_manager: '医疗信息经理',
  Medical_advisor: '医学顾问',
  Are_Medical_inquiries: '您有责任回答医疗咨询吗？',
  Are_Health_care_professional: '您是执业的医疗保健专业人员吗？',
  Health_care_Industry: '医疗保健行业',
  Health_Authority: '卫生局',
  Journalist: '记者',
  Lawyer: '律师',
  Patient: '患者',
  Payor: '付款人',
  Scientist: '科学家',
  Angiologist: '血管学家',
  Cardiologist: '心脏科医师',
  Cardiovascular_Surgeon: '心血管外科医生',
  Dentist: '牙医',
  Gastroenterologist: '肠胃科医生',
  General_Practitioner: '全科医生',
  Geriatrician: '老年医师',
  Gynecologist: '妇科医生',
  Haematologist: '血液科医师',
  Internist: '实习医生',
  Medical_Student: '医学专业的学生',
  Nephrologist: '肾脏科医师',
  Neurologist: '神经学家',
  Neurosurgeon: '神经外科医生',
  Nurse: '护士',
  Oncologist: '肿瘤医师',
  Oncology_Nurse: '肿瘤科护士',
  Other_HCP: '其他HCP',
  Other_Surgeon: '其他外科医生',
  Pediatrician: '儿科医生',
  Pharmacist: '药剂师',
  Pharmacologist: '药理师',
  Pharmacy_Technician: '药剂师',
  Pneumologist: '肺科医师',
  Radiologist: '放射科医生',
  Rheumatologist: '风湿病学家',
  USER_PASS_INCORRECT: '用户名或密码不正确',
  SUBMITTED_REQUEST: '您的请求已成功提交。',
  Reset: '重启',
  Reset_pass: '重设密码',
  New_pass: '新密码',
  RESET_PASS_EMAIL: '重置密码链接已发送至您的电子邮件',
  NEW_PASS_SUCCESS: '您的密码已成功重置',
  SOMETHING_WENT_WRONG: '出现问题，请与支持部门联系。',
  EVENTS: '大事記',
  Login_here: '在此登錄',
  REGISTRATION: '註冊',
  Create_an_account: '創建一個帳戶',
  NO_RECORDS: '沒有記錄',
  INTERACTION: '相互作用',
  SPECIALIZATION: '專業化',
  SPECIALIZATION_TYPE: '專業類型',
  SAVED: '已保存',
  SAVING: '保存',
  TYPING: '打字',
  CREATE_NEW_INTERACTION: '建立新的互動',
  FILTER_BY: '過濾',
  SEND_EMAIL: '發電子郵件',
  SAVE_WITHOUT_EMAIL: '不用電子郵件保存',
  SUBMIT_TO_INBOX: '提交到收件箱',
  Create_my_acc: '創建我的賬戶',
  NEW_QUESTION: '新問題',
  LABEL: '標籤',
  SELECT_A_LABEL: '選擇一個標籤',
  CITY: '市',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: '建議一個新類別或選擇一個現有類別',
  SUGGEST_CATEGORY_OR_TOPIC: '建議新類別或主題',
  URGENT: '緊急',
  EXISTING_CATEGORY: '現有類別',
  NEW_CATEGORY_NAME: '新類別名稱',
  NEW_TOPIC_NAME: '新主題名稱',
  CATEGORY: '類別',
  MSLA: 'MSLA',
  OUT_CHANNEL: '傳出頻道',
  LOGIN_TO_CONTINUE: '登錄以繼續使用Madjenta',
  Email_address: '電子郵件地址',
  FORGOT_PASS: '忘記密碼？',
  Remember_me: '記得我',
  YOU_WILL_BE_NOTIFIED: '確認電子郵件已發送，並附有進一步的註冊說明',
  SUCCESS_EMAIL_CONFIRMATION: '您的電子郵件已成功確認。',
  SUCCESS_EMAIL_CONFIRMATION_SUB: '審核您的請求後，您會收到通知',
  FAILED_EMAIL_CONFIRMATION: '我們無法確認您的電子郵件。',
  FAILED_EMAIL_CONFIRMATION_SUB: '請重試，如果問題仍然存在，請寫信給支持並提供問題描述',
  RESET_FILTERS: '重置過濾器',
  NEW_PASS_RESET_SUCCESS: '密碼重置成功！',
  Cardiovascular: '心血管的',
  Oncology: '腫瘤科',
  Due_On: '由於上',
  Follow_Up_Sent_On: '後續發送',
  Follow_Up_Received_On: '收到後續通知',
  Closed_On: '關門',
  Reopened_On: '重新開啟',
  Inquiry_ID: '查詢編號',
  Search: '搜索',
  Assigned_to_Now: '分配給（現在）',
  Edited_By: '編輯者',
  Assigned_to_Any_Time: '分配給（任何時間）',
  Closed: '關閉',
  Foreign: '國外',
  Complex_search_Sentence_: '複雜搜索：句子：“要搜索的句子”，不是：-word',
  Search_inquiries: '搜索查詢',
  SEARCH_REPOSITORY: '搜索資料庫',
  MIR_INBOX: '先生收件箱',
  Filters: '篩選器',
  Active_Filters: '有源濾波器',
  CONTACT_SUPPORT: '聯繫支持',
  MedicalInformationRequests: '查詢（pdf）',
  UserProfileNavigation: '用戶個人資料（pdf）',
  UserManualChanges: '用戶手冊更改（docx）',
  iPad_How_To: 'iPad（pdf）',
  MedIS_Assign_Inquiry: '分配查詢',
  MedIS_Documentation_Approval_Process: '文件審批流程',
  MedIS_Documentation_Overview: '文檔概述',
  MedIS_Inquiry_Documentation: '查詢文件',
  MedIS_MIR_Inbox_Filters: 'MIR收件箱過濾器',
  MedIS_Merge_MIR: '合併MIR',
  MedIS_New_Documentation: '新文件',
  MedIS_New_Inquiry: '新查詢',
  MedIS_Out_Of_Office: '不在辦公室',
  MedIS_Prepare_Custom_Answer: '準備自定義答案',
  MedIS_Split_MIR_Part_I: '拆分MIR第一部分',
  MedIS_Split_MIR_Part_II: '拆分MIR第二部分',
  I_AGREE_TO: '我同意',
  TermsAndConditionsLink: '條款和條件',
  Title: '標題',
  INTERACTION_TITLE: '互動標題',
  Move: '移動',
  Left: '剩下',
  Right: '對',
  Requestor: '請求者',
  Assigned_To: '分配給',
  Status: '狀態',
  Note: '注意',
  Confirm: '確認',
  rejectConfirm: '您確定要拒絕該請求嗎？',
  approveConfirm: '您確定要批准該請求嗎？',
  newUser: '新的用戶',
  editUser: '編輯使用者',
  deleteUser: '刪除用戶',
  newProduct: '新產品',
  editProduct: '編輯產品',
  deleteProduct: '刪除產品',
  newCategory: '新類別',
  editCategory: '編輯類別',
  deleteCategory: '刪除類別',
  newTopic: '新話題',
  editTopic: '編輯主題',
  deleteTopic: '刪除主題',
  userRequest: '用戶要求',
  productRequest: '產品要求',
  categoryRequest: '類別要求',
  categoryTopicRequest: '類別/主題請求',
  topicRequest: '主題請求',
  Suggestion: '建議',
  tradeName: '商品名',
  activeSubstance: '活性物質',
  productName: '產品名稱',
  productSpecific: '它是特定於產品的嗎？',
  EMAIL_IN_USE: '該電子郵件已有一個帳戶',
  fromDate: '從日期',
  toDate: '至今',
  applyFilter: '套用篩選器',
  requestReceivedFrom: '收到的請求',
  on: '上',
  at: '在',
  to: '至',
  add: '加',
  from: '從',
  approve: '批准',
  reject: '拒絕',
  withTheFollowingRole: '扮演以下角色',
  forTheFollowingTeam: '對於以下團隊',
  request: '請求',
  submittedBy: '由...所提交',
  INVALID_OR_EXPIRED_RESET_REQUEST: '密碼重置請求無效或已過期',
  PASS_IS_RECENT: '最近使用密碼',
  Assigned_to_me: '分配給我',
  My_drafts: '我的草稿',
  My_open_inquiries: '我的公開詢問',
  My_closed_inquiries: '我的封閉查詢',
  Filter_inquiries_by: '篩選查詢依據',
  New_inq: '新查詢',
  Inq_inbox: '查詢收件箱',
  REPORTS: '報告書',
  My_teams_open_inq: '我的團隊的公開查詢',
  My_teams_closed_inq: '我團隊的封閉查詢',
  All_teams_open_inq: '所有團隊公開詢問',
  All_teams_closed_inq: '所有團隊已關閉查詢',
  Overdue: '逾期',
  Due_this_week: '這周到期',
  Pending_approval: '待批准',
  ADMINISTRATION: '行政',
  Show_all: '顯示所有',
  In_progress: '進行中',
  Table_Settings_Columns: '表設置-列',
  Change_column_visibility: '更改列的可見性',
  Reassign_to: '重新分配給',
  set_priority: '設定優先順序',
  set_status: '設定狀態',
  Spam_list: '垃圾郵件清單',
  Institution: '機構',
  DUE_ON: '由於上',
  Profile_settings: '個人資料設置',
  Preferences: '優先',
  Role_settings: '角色設定',
  Notification_settings: '通知設置',
  User_profile: '用戶資料',
  Select_person: '選擇人',
  FROM_DATE: '從日期',
  TO_DATE: '至今',
  JOB_DESC: '職位描述',
  INQ_Management: '查詢管理',
  DOCS_Management: '文件管理',
  USER_Management: '用戶管理',
  Define_Frequency: '定義頻率',
  All_teams_all_inq: '所有團隊的所有查詢',
  My_teams_all_inq: '我團隊的所有查詢',
  Search_people_here: '在這裡搜索人',
  Search_team_here: '搜索團隊在這裡',
  people: '人',
  teams: '團隊',
  empty: '（空）',
  can_not_be_assigned: '該用戶沒有團隊，無法分配',
  select_a_team: '請選擇一個團隊',
  confirm_team_change: '確認團隊變更',
  change_the_team_: '您確定要將團隊更改為',
  can_not_see_it_anymore: '您將無法在當前畫面中看到它',
  filtered_view: '過濾視圖。',
  PHONE: '電話',
  FAX: '傳真',
  LETTER: '信',
  F2F_SITE_VISIT: 'F2F（實地考察）',
  F2F_CONGRESS: 'F2F（國會）',
  F2F_ADBOARD: 'F2F（AdBoard）',
  F2F_OTHER: 'F2F（其他）',
  WEBINAR_SITE_VISIT: '虛擬（實地考察）',
  WEBINAR_CONGRESS: '虛擬（大會）',
  WEBINAR_ADBOARD: '虛擬（AdBoard）',
  WEBINAR_OTHER: '虛擬（其他）',
  CHAT: '聊天室',
  COURIER: '信使',
  REP_DELIVERY: '銷售代表',
  SYSTEM: '系統',
  WEB_FORM: '網絡表格',
  Signature: '簽名',
  Disclaimer: '免責聲明',
  STATE: '州',
  ADDRESS_1: '地址1',
  ADDRESS_2: '地址2',
  ZIP_CODE: '郵政編碼',
  HIGH: '高',
  ADVANCED: '高級',
  CLICK_BELOW_TO_POPULATE_ANSWER: '單擊下面的文本和字段以填充和編輯答案',
  DOCUMENT_VIEW: '文件檢視',
  FORM_VIEW: '表格檢視',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: '您無權發送答案。',
  ANSWER_TEXT: '答案文字',
  CONTACT_INFORMATION: '聯繫信息',
  COMPANY: '公司',
  SRD_NEEDED_TOOLTIP: '此查詢的答案需要新的SRD文件',
  ENABLE_ALL: '全部啟用',
  DISABLE_ALL: '禁用所有',
  BEFORE: '之前',
  AFTER: '後',
  EDIT: '編輯',
  MY_TEMPLATES: '我的模板',
  TEAM_TEMPLATES: '團隊模板',
  TEAM_TEMPLATES_HINT: '醫療信息經理為整個團隊創建的模板',
  SOURCE: '資源',
  TEMPLATE_NO_MATCH_INTERACTION: '該模板與交互詳細信息不匹配，因此不適用',
  EMAIL_SUBJECT: '電子郵件主題',
  INQUIRY_SUBMIT_HINT: '請填寫所有必填字段，以便回答查詢',
  SCHEDULED_PUBLISH_TO_EXP_DATE: '計劃發佈到到期日期',
  COPY_ATTACHMENT_TO_ANSWER: '複製附件以回答',
  COPY_TEXT_TO_ANSWER: '複製文字以作答',
  SELECT_INQUIRY: '選擇查詢',
  EDIT_INQ: '編輯問題',
  SPLIT_ANOTHER: '拆分另一個',
  DELETE_INQ: '刪除查詢',
  SELECT_TEXT_FOR_SPLIT: '在同一互動中添加新問題',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: '生成單獨的交互/案例',
  SPLIT_ORIGINAL_TEXT: '原始文本（選擇要拆分的文本）',
  SPLIT_TEXT_TO_REMAIN_: '文本保留在初始查詢中',
  SPLIT_TEXT_TO_SPLIT_: '要分割的文字',
  SPLIT_SELECTED_SECTIONS: '選定的拆分部分',
  SPLIT_NO_MODIFICATIONS_HINT: '當您從原始文本中選擇部分時，文本將自動填充',
  SPLIT_MODIFICATIONS_HINT: '該文本已被手動修改，並且將不會應用從原始文本中進行的任何進一步選擇。',
  TEMPLATE_NO_MODIFICATIONS_HINT: '文本將隨著查詢詳細信息的更改而自動更新。',
  SELECT_DIFFERENT_TEMPLATE: '選擇其他模板',
  APPROVE: '批准',
  LAST_EDIT_ON: '最後編輯時間',
  LAST_EDIT_BY: '最後編輯者',
  INTERNAL_COMM: '內部溝通',
  INACTIVE: '不活躍',
  DEACTIVATE: '停用',
  ARCHIVED: '已封存',
  REPUBLISH: '重新發布',
  INBOX_NO_FILTERS_HINT: '沒有選擇過濾器。',
  INTERACTION_TEMPLATE_HINT: '簽名，問候和免責聲明是書面交流的一部分，例如電子郵件，信件和傳真。',
  CONFIRM_AUTH_REQUEST: '確認身份驗證請求',
  VIEW_AUTH_REQUEST: '查看驗證請求',
  QUESTION_TITLE: '問題標題：',
  NO_TITLE_INQUIRY: '-無標題查詢-',
  EXISTING_CATEGORIES: '現有類別',
  EXISTING_TOPICS: '現有主題',
  DO_NOT_ASSIGN_PRODUCT: '不分配產品',
  QUESTION_TEXT: '問題文字',
  DATE: '日期',
  REJECTED: '拒絕',
  FILTER_AUTH_REQUESTS_BY: '篩選身份驗證請求依據',
  ALL_REQUESTS_ALL_TEAMS: '所有團隊的所有要求',
  ALL_TEAMS: '所有團隊',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST: '您無權編輯類別/主題請求。',
  SEND_FOLLOW_UP: '发送跟进',
  COLUMN_INIT_QUESTION_TEXT: '原始问题文本',
  NOTIFICATION_ENABLED: '启用通知',
  DEFAULT_TEMPLATE: '默认模板',
  SET_AS_DEFAULT: '设为默认',
  DEFAULT_TEMPLATE_TOOLTIP: '切换被禁用，因为必须至少有一个默认模板！',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: '默认模板不能删除！',
  BATCH_UPDATE_ONLY: '通过最顶部的复选框只能进行批量更新。',
  ACCESS_DENIED: '拒絕訪問！',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE: '您無權訪問此頁面。',
  USER_ROLES: '用戶角色',
  MANAGE_USER_ROLES: '管理用戶角色',
  ARCHIVE_ROLE: '存檔角色',
  ROLE_ARCHIVE_CONFIRMATION: '您真的要存檔此角色嗎？',
  ROLES_FOR: '角色',
  AUDIT_TRAIL: '審計跟踪',
  ACTION_BY_USER: '用戶操作',
  ACTION: '行動',
  ORIGINAL_VALUE: '原始值',
  CHANGE: '改變',
  CONDITION: '條件',
  IP: 'IP',
  GEOLOCATION: '地理位置',
  NEW_VALUE: '新值',
  FILTER_BY_ACTION: '按动作过滤',
  SELECT_DATE: '选择日期',
  TEAM_SAVE: '节省',
  TEAM_DELETE: '删除',
  TEAM_CREATE: '创造',
  TEAM_CLONE: '复制',
  TEAM_NAME: '姓名',
  TEAM_CODE: '代码',
  TEAM_ACTION_DELETE_CONFIRM: '你真的想删除吗',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: '当心！',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: '删除后，它将无法恢复！',
  TEAM_FULL_ANSWER_ON_LABEL: '标签上的完整答案',
  TEAM_RESTRICTED_TEAM: '受限制的',
  TEAM_SEGREGATED_DATA: '隔离数据',
  TEAM_PRIVATE_TEAM: '私人团队',
  TEAM_AUTO_ASSIGN: '自动分配',
  TEAM_MAILBOX_IN: '入站邮箱',
  TEAM_MAILBOX_OUT: '出站邮箱',
  TEAM_DEFAULT_LANGUAGE: '默认语言',
  TEAM_COUNTRIES: '分配的国家',
  TEAM_PRODUCT_GROUPS: '分配产品组',
  TEAM_PARENT: '家长团队',
  TEAM_OVERRIDE_CLUSTER_ROOT: '覆盖集群根团队',
  TEAM_AFFILIATIONS: '会员团队',
  TEAM_TIMEZONE: '时区',
  UPDATE: '更新',
  AUTO_ASSIGN_BY: '自动分配',
  AUTO_ASSIGNMENTS: '自动分配',
  NO_ONE: '没有人',
  NOT_APPLICABLE: '不适用',
  OVERRIDE_NOTIFICATIONS: '替代通知',
  OVERRIDE_NOTIFICATIONS_HINT:
    '当将通知的用户模板设置为用户时，它将禁用任何先前的通知设置，并仅启用用户模板中列出的通知设置',
  CREATED_MANUALLY: '手动创建',
  DOCUMENT_TITLE_FOR_WORD:
    'Word文档的标题通常取自文档本身中的标题格式部分。 如果文档不包含标题，则可以在此处指定。 如果您在此处不指定标题，并且Word文档不包含标题格式的部分，则该文档ID将用于标题。',
  ORDER_BY: '顺序',
  FORMAT: '格式',
  SELECT_QUESTION: '选择问题',
  SELECT_QUESTION_FOR_NOTE: '选择要添加注意的问题',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: '为整个查询添加注释',
  REQUESTED_DATE: '申请日期',
  UNASSIGNED: '未分配',
  BLANK: '空白的',
  ANSWER_SAVED_WITHOUT_EMAIL: '没有电子邮件保存的答案',
  INQ_RELATED_TO: '有关的查询',
  QUESTION_TEXT_BEFORE_SPLIT: '拆分之前的问题文本',
  SPLIT_MERGE: '分裂并合并',
  CLARIFICATION_QUESTION: '澄清问题',
  ACTIVE_ON: '活跃',
  DISMISS: '解雇',
  GO_TO_ENQUIRY: '去查询',
  NO_TEAMS: '没有团队',
  NO_TEAMS_TIP:
    '包含未分配给任何团队的用户。 您可以检查其中的一些来自您的团队，并授予他们适当的角色。',
  TASKS: '任务',
  Therapeutic_Areas: '治疗区域',
  Product_Groups: '产品组',
  Licensing_Partner: '许可合作伙伴',
  Product_Code: '产品代码',
  SELECT_EXISTING_ONE: '选择现有的一个',
  OR: '或者',
  MANAGE_FORM: '管理表格',
  EVENT_FORM_ADDRESS: '事件表格地址',
  EVENT_FORM_PREVIEW_WARING: '确保在打开国会表格或复制其地址之前保存了任何更改',
  SEARCH_SAVED_SEARCHES: '搜索保存的搜索',
  GO: '去',
  ASSIGN: '分配',
  CERTIFICATION: '认证',
  SORT: '种类',
  CREATE_NEW_MAILBOX: '创建新的邮箱',
  EDIT_MAILBOX: '编辑邮箱',
  ADD_MAILBOX: '添加邮箱',
  UNSET_INCOMING_MAILBOX: '未设置的邮箱',
  SET_INCOMING_MAILBOX: '设置传入邮箱',
  UNSET_OUTGOING_MAILBOX: '未设置的传出邮箱',
  SET_OUTGOING_MAILBOX: '设置传出邮箱',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: '所有带有当前邮箱的团队',
  MAILBOX_HISTORY: '邮箱历史记录',
  ALL_TEAMS_WITH_MAILBOX: '所有带有邮箱的团队',
  MAILBOX: '邮箱',
  EMAIL_POLLING: '电子邮件投票',
  ADVANCED_SEARCH: '高级搜索',
  SELECT_FIELDS: '选择字段',
  SEARCH_TERM: '搜索词',
  COLUMN_INTERACTION_ID: '交互ID',
  COLUMN_QUESTION_ID: '问题ID',
  COLUMN_CREATED_ON: '创建于',
  COLUMN_LAST_EDITED_DATE: '最后编辑',
  COLUMN_ASSIGNED_TO: '分配给',
  COLUMN_DUE_DATE: '截止日期',
  COLUMN_FOLLOW_SENT_DATE: '随访发送日期',
  COLUMN_FOLLOW_RECEIVED_DATE: '收到的后续日期',
  COLUMN_ANSWER_DATE: '答案日期',
  COLUMN_CLOSED_ON_DATE: '关闭日期',
  COLUMN_REOPENED_DATE: '重新开放日期',
  COLUMN_INSERTED_BY: '插入',
  COLUMN_LAST_EDITED_BY: '最后编辑',
  COLUMN_ANSWERED_BY: '回答',
  COLUMN_APPROVED_BY: '由...批准',
  COLUMN_INT_COMM_USER: '内部通信用户',
  COLUMN_INT_COMM_DATE: '内部通信日期',
  COLUMN_MY_TEAM: '我的团队',
  COLUMN_PQC_RELATED: 'PQC相关',
  COLUMN_INCOMING_CHANNEL: '传入渠道',
  COLUMN_OUTGOING_CHANNEL: '外向频道',
  COLUMN_OFF_LABEL: '关闭标签',
  COLUMN_HCP_SPEC: 'HCP +专业化',
  COLUMN_NON_HCP_SPEC: '非HCP +职业',
  COLUMN_KEE: '基',
  COLUMN_HOSPITAL_BASED: '基于医院',
  COLUMN_CITY: '城市',
  COLUMN_INSTITUTION: '机构',
  COLUMN_TERRITORY: '领土',
  CASE_ID: '案例ID',
  OPTION_ADD: '添加',
  OPTION_AND: '和',
  OPTION_OR: '或者',
  OPTION_NOT: '不是',
  OPTION_YES: '是的',
  OPTION_NO: '不',
  QUERY: '询问',
  EXPORT_LABEL: '定义导出列',
  SAVE_SEARCH: '保存搜索',
  SHARE_SEARCH: '共享搜索',
  SEARCH_BTN: '搜索',
  BACK_ACTION: '后退',
  SAVE_CHANGES: '保存更改',
  SAVED_SEARCH: '保存的搜索',
  NEW: '新的',
  SAVE_AS: '另存为名称',
  ENTER_RECIPIENTS: '输入收件人',
  CLEAR_SAVED_SEARCH: '清晰保存的搜索',
  NO_SAVED_SEARCH: '找不到保存的搜索',
  INT_COMM_TEXT: '内部通信文本',
  FOLLOW_UP_TEXT: '后续文本',
  CONFIRM_DELETION: '确认删除',
  ARE_SURE: '您确定要删除保存的搜索吗',
  PERMANENTLY_DELETED: '搜索将被永久删除</b>。',
  NOTHING_SAVE: '什么都不要保存。',
  NOTHING_SEARCH: '没什么可搜索的。',
  NOTHING_EXPORT: '搜索没有出口记录。',
  SHARE_SUCCESS: '搜索已成功共享',
  SHARE_FAILURE: '无法共享搜索',
  DELETED_SUCCESS: '成功删除了保存的搜索',
  ALL_DELETED_SUCCESS: '成功删除了所有保存的搜索',
  VIEW_SAVED_SEARCH: '查看保存的搜索',
  EDIT_SAVED_SEARCH: '编辑保存的搜索',
  EXPORT_SAVED_SEARCH: '导出保存的搜索',
  EXPORT_SEARCH: '导出搜索',
  DELETE_SAVED_SEARCH: '删除保存的搜索',
  SELECT_EXPORT_COLUMNS: '选择要导出的列',
  SAVED_SEARCH_ALREADY_EXIST: '使用该名称搜索已经存在。',
  CHARACTER_LIMIT: '最小3个字符。 最大35个字符。',
  CHARACTER_LEFT: '字符留下来',
  ACTIVATE: '启用',
  INACTIVE_USER: '该用户不再活动。 请联系您的系统管理员。',
  FOLLOW_UP: '跟进',
  RECEIPT_NOTIFICATION_CUSTOMER: '收据通知',
  GDPR_DISCLAIMER: 'GDPR免责声明',
  ACTIONS: '动作',
  SUBMISSION_CONDITIONS: '提交条件',
  TYPE_OF_NOTIFICATION: '通知类型',
  ENABLE_NOTIFICATION: '启用通知',
  NOTIFICATION_SENDER: '发件人通知',
  SALES_REP_DELIVERED_RESPONSE: '销售代表以进行交付的答复',
  FULL_ANSWER: '完整答案',
  NOTIFICATION: '通知',
  DIRECT_SUBMISSION: '直接提交',
  DIRECT_SUBMISSION_TOOLTIP:
    '有关通过电子邮件直接向客户提交的查询，请发送通知，以告知客户处理其个人数据。',
  COMPANY_EMPLOYEE_SUBMISSION: '通过公司雇员提交',
  NOTIFICATION_DELIVERED_RESPONSE: '已交付的响应通知',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    '对于通过公司代表提交的查询（销售代表，MSL或其他），当客户收到其查询答案时，发件人',
  USER_NOT_FOUND: '找不到用户',
  DEPARTMENTS: '部门',
  NAME: '姓名',
  EMAIL: '电子邮件',
  ADD_NEW_DEPARTMENT: '添加新部门',
  DEPARTMENT_COUNTRY: '国家',
  DEPARTMENT_COMPANY: '公司',
  DEPARTMENT_TEAM: '团队',
  DEPARTMENT_TA: '治疗区域',
  DEPARTMENT_PRODUCT: '产品',
  DEPARTMENT_ACTIONS: '动作',
  DEPARTMENT_CONFIRMATION_1: '确认删除？',
  DEPARTMENT_CONFIRMATION_2: '此动作是不可逆转的。',
  DEPARTMENT_NAME: '部门名称',
  TYPE_DEPARTMENT: '类型',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: '国家 - 请首先选择团队',
  DEP_NAME_CHARACTER_LIMIT: '最大200个字符。',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: '用户请求',
      CategoryTopicRequest: '类别/主题请求',
      ProductRequest: '产品请求',
      UserUpdateRequest: '用户更新请求',
    },
    TYPE: { NewUser: '新用户', NewCategoryTopic: '新类别/主题', NewUserUpdate: '用户更新' },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS: '定义国家特定专业清单',
      IS_COUNTRY_SPECIFIC: '是否特定于国家/地区',
      TYPE: '询价人类型',
      SPECIALIZATION_EXISTS: '“询问者类型/专业”配置已存在。',
    },
    AUTHORIZATION_REQUESTS: { GENDER: '性别', COUNTRY: '国家', TITLE: '标题' },
  },
  hcp: 'HCP',
  emp: '员工',
  cons: '非HCP',
  OOO_DEPUTY: 'OOO代理',
  NO_DEPUTY: '没有代理',
  UNSPECIFIED_END_DATE: '未指定的结束日期',
  DELEGATORS: '代表们',
  NO_DELEGATORS: '没有代表',
  PREVIEW_DELEGATORS: '预览代表',
  REMOVE_DEPUTY: '删除副手',
  USERS_WHO_SET_YOU_AS_DEPUTY: '将您设置为副手的用户',
  CHANGED_USER: '更改了用户',
  DEFAULT: '默认',
  SPECIALIZATIONS: '专业',
  ENQUIRERS_LIST: '询问者列表',
  EDIT_THE_RECORD: '编辑记录',
  DEACTIVATE_THE_RECORD: '停用记录',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: '不在办公室',
      HINT: '只有具有查询处理角色的活跃用户，他们不辞职。',
      MODAL_TITLE: '确认办公室设置',
      MODAL_MSG_1: '您已经开始立即撤离办公室选项，所有查询将立即分配给代理人。',
      MODAL_MSG_2: '您已经列出了办公室选项，您的所有查询将在外交期间分配给代理人。',
    },
  },
  GENDER_MALE: '先生。',
  GENDER_FEMALE: '太太。',
  TITLE_DR: '博士',
  TITLE_PHD: '博士学位',
  TITLE_PROF: '教授',
  TITLE_PROF_DR: '博士教授',
  HOME: '家',
  HOSPITAL: '医院',
  OFFICE: '办公室',
  WORK: '工作',
  INSERT_IMAGE: '插入图像',
  USER_ADMIN: '用户管理员',
  MAILBOXES: '邮箱',
  AUTHORIZATION_REQUESTS: '授权请求',
  METADATA: '元数据',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: '任务',
      FORWARD: '向前',
      FOLLOW_UP: '跟进',
      DUE_DATE_SETTINGS: '到期日期设置',
      CLOSE: '关',
      DELIVERY_ERROR: '交货错误',
      SCHEDULER: '调度程序',
      USER_REPORTS: '用户报告',
      DOCUMENT_APPROVAL: '文件批准',
      AUTHORIZATION_REQUEST: '授权请求',
      NEW_INQUIRY: '新咨询',
      INQUIRY_APPROVAL: '询价审批',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: '角色请求被批准/拒绝',
      AUTHORIZATION_REQUEST_3: '角色正在等待我的认可',
      DOCUMENT_APPROVAL_1: '文件正在等待您的批准',
      DOCUMENT_APPROVAL_2: '文件被批准/拒绝',
      DOCUMENT_APPROVAL_3: '文件存档',
      DOCUMENT_APPROVAL_4: '一份文件已被批准，并进行了更改',
      DOCUMENT_APPROVAL_5: '文件将存档',
      DOCUMENT_APPROVAL_6: '批准文件清单',
      DUE_DATE_2: '过去到期后重复',
      ERROR_1: '交付通知失败（例如，电子邮件不正确）',
      FOLLOW_UP_1: '随访或提醒已发送给客户',
      FOLLOW_UP_4: '收到了后续',
      IMPORT_DATA_1: '导入期间已经创建了一个新对象',
      IMPORT_DATA_4: '导入期间创建了新用户',
      IMPORT_DATA_5: '用户与已经存在的用户匹配，但不是来自以前的导入',
      IMPORT_DATA_6: '从外部来源导入数据期间发生了错误',
      INQUIRY_APPROVAL_4: '您提出的答案已通过更改 /拒绝获得批准 /批准',
      METADATA_MANAGEMENT_1: '已经创建了一个新的元数据',
      NEW_DOCUMENT_1: '我国有新的批准文件可用',
      NEW_DOCUMENT_2: '新文件可用于本地批准',
      NEW_DOCUMENT_3: '新的本地文件可供欧洲 /全球批准',
      NEW_DOCUMENT_4: '有新的全球文档可用',
      NEW_DOCUMENT_5: '有新的欧洲文件可用',
      NEW_DOCUMENT_6: '新的欧洲文件可供全球批准',
      SCHEDULER_TASK_1: '执行任务期间发生了错误',
      USER_REPORTS_1: '活跃用户列表',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3: '接收通知，通知您何时需要批准/拒绝用户角色请求',
        AUTHORIZATION_REQUEST_2: '当创建新用户或用户在您的团队中请求角色时，接收通知',
        AUTHORIZATION_REQUEST_4: '建议新主题和/或类别时接收通知',
        FOLLOW_UP_4: '接收我所负责的咨询通知，并收到咨询者的后续回复',
        NEW_INQUIRY_1: '接收您所在国家/地区收到的新咨询通知，由咨询者/用户输入或从其他团队转发',
        ANSWER_2: '收到已答复的咨询通知，告知我作为合作者参与了该咨询，但我并未直接负责该咨询',
        DUE_DATE_1: '选择此选项将激活以下所有通知。要减少通知数量，请取消选择不需要的通知。',
      },
      FREQUENCIES: {
        INSTANTLY: '即刻',
        DAILY: '日常的',
        WEEKLY: '每周',
        QUARTERLY: '季刊',
        MONTHLY: '每月',
        SECOND_DAY: '每第二天',
        THIRD_DAY: '每三天',
        ONE_WEEK: '一周',
        ONE_MONTH: '一个月',
        TWO_MONTHS: '两个月',
        THREE_MONTHS: '三个月',
        SIX_MONTHS: '六个月',
        NINE_MONTHS: '九个月',
        EVERY_TWO_WEEKS: '每两周一次',
        EVERY_THREE_WEEKS: '每三周一次',
        EVERY_FOUR_WEEKS: '每四个星期',
      },
      OPTIONS: {
        ALMOST_DUE: '几乎到期',
        DUE_NOW: '现在到期',
        DUE_SOON: '即将到期',
        EARLY_ON: '早期',
        TEXT_GUIDES: {
          DUE_NOW: '在查询到期时接收通知',
          EARLY_ON: '提前收到到期咨询的通知：正常 4 天 - 严重 2 天 - 到期日前 24 小时',
          DUE_SOON: '接收即将截止的咨询通知：正常 48 小时 - 高 12 小时 - 关键 6 小时截止',
          ALMOST_DUE: '接收即将到期的咨询通知：正常 24 小时 - 高 6 小时 - 关键 3 小时到期前',
        },
      },
      AUTHORIZATION_REQUEST_4: '我的团队要求类别/主题',
      AUTHORIZATION_REQUEST_2: '用户请求',
      IMPORT_DATA_2: '导入过程中创建了新的询价人',
      IMPORT_DATA_3: '查询者与已经存在的查询者匹配，但不是来自以前的导入',
      ANSWER_2: '我参与的一个调查得到了答复',
      ASSIGNMENT_1: '我被分配了一项调查',
      ASSIGNMENT_5: '一项调查被指派给我的副手',
      CLOSE_1: '调查已结束',
      DUE_DATE_1: '我负责的一项咨询已接近截止日期，请通知我',
      FOLLOW_UP_7: '一项询问未能跟进',
      FORWARD_1: '已转发问询',
      INQUIRY_APPROVAL_1: '一项咨询正在等待您的批准',
      INTERNAL_1: '已向我分配的咨询添加注释',
      NEW_INQUIRY_1: '我的国家收到了新的询价',
      SPAM_1: '垃圾邮件中收到新咨询',
      ANSWER_1: '在我回答的互动中收到答案的副本',
      ANSWER_6: '在我批准的互动中接收答案的副本',
    },
  },
  NEW_TEAM: '新团队',
  SmPc: 'SMPC',
  USERS: '用户',
  PAGINATOR_NEXT_PAGE_LABEL: '下一页',
  PAGINATOR_PREV_PAGE_LABEL: '上一页',
  PAGINATOR_FIRST_PAGE_LABEL: '第一页',
  PAGINATOR_LAST_PAGE_LABEL: '最后一页',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: '每页项目',
  Date: '日期',
  Action: '行动',
  'Action by user': '用户的操作',
  'Changed user': '更改了用户',
  'Original value': '原始价值',
  'New value': '新价值',
  REGION: '地区',
  TEAM_REGION_LABEL: '地区 - 请首先选择公司',
  TEAM_THERAPEUTIC_AREA_LABEL: '治疗领域 - 请首先选择公司',
  TEAM_PRODUCT_LABEL: '产品 - 请首先选择治疗区域',
  TEAM_COUNTRIES_LABEL: '国家 - 请首先选择区域',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: '为每个国家定义产品',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: '请至少选择一种产品',
  VIEW_CHANGES: '查看更改',
  Sales_Representative: '销售代表',
  Other_Commercial: '其他广告',
  REGIONS: '地区',
  MAIN_CLIENT: '主要客户',
  LICENSE_PARTNER: '许可合作伙伴',
  TEAM_SET_UP: '团队设置',
  LIST_TEAMS: '列出团队',
  COMPANIES: '公司',
  DOMAINS: '域',
  TOKEN_REFRESH_NOTICE: '您为您的ENQMED帐户注册提供了新的确认电子邮件。',
  TOKEN_REFRESH_SUCCESS: '已将新的确认链接发送到您的电子邮件。',
  TOKEN_REFRESH_ERROR: '发生错误。 如果您需要进一步的帮助，请联系支持。',
  PLEASE_ENTER_MAX: '请输入最大值',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE: '请查看以下更改（如果有），并确认是否应更改公司。',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE: '请谨慎行事，因为更改会影响团队',
  THERE_ARE_NO_CHANGES: '没有变化。',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '请检查对话框背后的现有公司，因为我们已经拥有相同价值的公司。',
  SIMPLE_TEXT: '请仅输入字母（A-Z，A-Z），数字（0-9）和特殊符号（如仪表，周期，空间和结肠）。',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS: '请至少为地区选择一个国家',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS: '请至少选择一种用于治疗区域的产品',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    '您选择了属于多个治疗领域的产品，请选择所有需要的治疗领域。',
  HTML_CHANGES: 'HTML改变',
  SIDE_BY_SIDE_VIEW: '并排视图',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '请检查对话框背后表中的现有域，因为我们已经具有相同值的域。',
  OUT_CHANNEL_ERROR_MSG: '即将卸下的频道字母询问者的邮政地址',
  OUT_CHANNEL_ERROR_MSG_LONG: '被选为字母的传出频道。 请在Enquirer区域输入Enquirer的邮政地址',
  LABEL_FOR: '标签',
  male: '男性',
  female: '女性',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    '您无权存档此角色。 团队只有<b> mims </b>可以为其团队成员归档角色。',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION: '根据请求批准/拒绝将电子邮件发送给用户',
  APPROVE_REQUEST_QUESTION: '您要批准该请求吗？',
  NEW_USER_REQUEST: '新用户请求',
  OPEN_REQUESTS: '打开请求',
  APPROVED_REQUESTS: '批准的请求',
  REJECTED_REQUESTS: '拒绝的请求',
  USER_REQUESTS: '用户请求',
  REQUEST_FOR: '要求',
  CATEGORY_TOPIC_REQUESTS: '类别/主题请求',
  WITH_THE_FOLLOWING_PAIRS: '与以下对：',
  MY_TEAM_OPEN_REQUESTS: '打开请求',
  MY_TEAM_APPROVED_REQUESTS: '批准的请求',
  MY_TEAM_REJECTED_REQUESTS: '拒绝的请求',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'enqed中的文件批准',
  DOCUMENTS_APPROVAL: '文件批准',
  DOCUMENTS_APPROVAL_TITLE: '用于选定团队的文档批准过程的设置',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG: '被选为传真。 请在Enquirer区域输入Enquirer的传真号码',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    '传出频道选择为电话。 请在Enquirer区域输入Enquirer的电话号码',
  OUT_CHANNEL_FAX_ERROR_MSG: '传出频道传真询问者的价值',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: '传出频道电话询问者的价值',
  COPYRIGHT_PART_ONE: '版权',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd.保留所有权利。',
  REVIEWED_DATE: '审查日期',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    '将传出频道选为电子邮件。 请在Enquirer区域输入Enquirer的电子邮件',
  OUT_CHANNEL_EMAIL_ERROR_MSG: '传出频道电子邮件询问者的价值',
  ENTER_USER_COMMENT_OPTIONAL: '输入给用户评论（可选）',
  SELECT_ANOTHER_CATEGORY: '选择另一个类别',
  SELECT_ANOTHER_PRODUCT: '选择另一个产品',
  SUGGESTED_TOPIC: '建议的话题',
  EDIT_USER_REQUEST: '编辑用户请求',
  NEW_TOPIC_REQUEST: '建议的新话题',
  EDIT_TOPIC_REQUEST: '编辑建议的主题',
  NEW_CATEGORY_REQUEST: '建议的新类别',
  EDIT_CATEGORY_REQUEST: '编辑建议类别',
  REVIEW_COMMENT: '评论评论',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: '文件批准工作流程',
    CREATE_NEW: '创建新的工作流程',
    CUSTOMIZE: '自定义工作流程',
    NAME: '工作流名称',
    ERROR_MSG_DUPLICATE_NAME: '此名称的工作流已经存在。',
    SAVE: '保存工作流程',
    DELETE: '删除工作流程',
    STEP: '步',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: '所有具有文档作者角色的用户。',
    APPROVAL_DEADLINE: '批准截止日期',
    SELECT_APPROVAL_TYPE: '选择批准类型',
    SELECT_APPROVAL_TYPE_FIRST: '首先选择批准类型',
    SELECT_REVIEWERS: '选择审稿人',
    ACTION_ON_APPROVE_WITH_CHANGES: '批准更改的行动',
    ACTION_ON_REJECT: '拒绝行动',
    FILL_ALL_REQUIRED_FIELDS: '请填写所有必需的字段，以添加新步骤。',
    ALL_APPROVAL_TYPES_USED: '当您使用了当前步骤可用的所有类型时，您无法添加更多的批准子步骤。',
    CONFIRMATION_ROW_1: '您正在尝试删除团队的文件批准工作流程 {{ NAME }}.',
    CONFIRMATION_ROW_2: '您真的想删除批准工作流程吗？',
    CONFIRMATION_TITLE: '文件批准工作流删除',
    TEAM_VIEW_SELECT_EXISTING: '从下面的列表中选择现有文档批准工作流程或',
    DOC_VIEW_SELECT_EXISTING: '选择现有的文档批准工作流程',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL: '只能将单个批准步骤列为最终。',
    APPROVAL_TYPE_TOOLTIP: '该团队没有用户具有文档批准角色。 请先添加批准角色。',
    INFO_DIALOG_TITLE: '该团队没有文件批准工作流。',
    INFO_DIALOG_MSG: '请联系您当地的MIM，为团队创建文档批准工作流程。',
    STEP_LIMIT_REACHED: '您已达到 {{ MAX_STEPS }} 个审批步骤的限制。',
  },
  DOCUMENTS_WITHOUT_APPROVAL: '不需要批准的文档类型',
  PLEASE_SELECT_TYPE: '请选择类型',
  ABORT_FILE_UPLOAD: '中止文件上传',
  USERS_REPORT: '用户报告',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG: '通过关闭此窗口，您将失去已经填写的信息。',
  DO_YOU_WANT_TO_PROCEED: '你想继续吗？',
  USERS_REPORT_DESCRIPTION:
    '所有其他团队的用户列表，其中MIM的Daiichi Sankyo Europe肿瘤学和心血管均具有MIM角色。',
  TEMPLATE_CONFIRM_MSG: '一旦删除了模板，就无法恢复！',
  EVENT_CONFIRM_MSG: '事件存档后，它将无法恢复！',
  CREATE_DRAFT: '创建草稿',
  CREATE_USER: '创建用户',
  ADD_ROLE: '添加角色',
  DOMAIN_ADDRESS_INVALID_FOR: '域地址无效',
  AVAILABLE_ADDRESSES: '可用地址',
  IN_TEAM: '在团队中',
  TO_THE_SELECTED_USER: '给选定的用户',
  USER_INFORMATION: '用户信息',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    '您所使用的电子邮件地址需要更新，因为域地址与团队相关。<br>请咨询您的系统管理员以寻求帮助或与支持团队联系。',
  MESSAGE: '信息',
  IN_APPROVAL: '在批准中',
  IN_REVIEW: '在评论中',
  DOCUMENT_APPROVAL: '文件批准',
  DOCUMENT_TITLE: '文件名',
  SEARCH_DOCUMENTS: '搜索文档',
  APPROVAL_INBOX: '批准收件箱',
  FILTER_DOCUMENTS_BY: '过滤文档通过',
  submit_to_approval: '提交批准',
  create_document_metadata: '创建文档元数据',
  archive: '档案',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    '我承认，根据当地要求，我有义务报告所有医疗产品的所有不良药物反应（ADR）。 ENQMED无意捕获有关药物不良反应的报告 - 必须根据局部要求直接报告ADR',
  VERSION_DIALOG: {
    TITLE: '新版本可用',
    MESSAGE:
      '有新版本的软件可用！\n 您需要获取新版本，以便从其中的改进中获益并避免使用旧版本时出现问题，您需要获取它。',
    SAVE_BUTTON: '获取新版本',
  },
  ACTIVE_INACTIVE: '活跃/不活跃',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '请检查对话框后面表格中的现有类别，因为我们已经有具有相同值的类别。',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: '错误！投递失败',
  VIA: '通过',
  INSERTED_MANUALLY: '手动插入',
  AS_A: '作为一个',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '请检查对话框后面表格中的现有产品，因为我们已经有具有相同值的产品。',
  NOT_PRODUCT_SPECIFIC: '不特定于产品',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '请检查对话框后面的表中的现有主题，因为我们已经有具有相同值的主题。',
  PLEASE_ENTER_MAX_CHARACTERS: '请输入最多 {{ MAX_CHARS }} 个字符。',
  THANK_YOU_MESSAGE: '谢谢留言',
  AS: '作为',
  THERAPEUTIC_AREA_PARENT: '家长区',
  MAIN_THERAPEUTIC_AREA: '主要治疗领域',
  CHOOSE_AN_IMAGE: '选择图像',
  ALLOWED_FORMATS: '仅允许 {{ FORMATS }} 格式',
  REMOVE_IMAGE: '删除图像',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '请检查对话框后面表格中的现有治疗区域，因为我们已经有具有相同值的区域。',
  PLAIN_TEXT_VIEW: '纯文本视图',
  RICH_TEXT_VIEW: '富文本视图',
  EDIT_ITEM: '编辑 {{ ITEM }}',
  CREATE_NEW_ITEM: '创建新 {{ ITEM }}',
  ADD_NEW_ITEM: '添加新 {{ ITEM }}',
  ADD_ITEM: '添加 {{ ITEM }}',
  AND_HENCE_STATUS_CHANGED_FROM: '因此状态从',
  FOLLOW_UP_WITH_ENQUIRER: '跟进询问者',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW: '必须插入以下内容才能保存更改：',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION: '封闭式互动无法进行后续跟进。',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW: '审核状态下的交互。请保存评论以启用此菜单。',
  QUESTION_TEXT_MIN_SIX_CHARS: '问题文本（至少 6 个字符）',
  PLEASE_PROVIDE_AN_ANSWER_: '请提供答案（至少 6 个字符或附件）。',
  USER_MUST_RECOVER_PASSWORD_MESSAGE: '请注意，用户必须从“忘记密码”中恢复密码。',
  ENABLE_USER_LOGIN_MESSAGE: '这将启用用户登录。',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE: '这将禁用用户登录和通知。',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE: '请从您的用户个人资料中设置您的通知首选项！',
  SET_PASSWORD: '请设置您的密码',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION: '重新激活您的帐户后，您的密码已被删除。',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: '确认专业化停用！',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    '这会将其从具有活跃专业化的列表中删除。',
  GO_TO_ROLES: '前往角色',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    '您对用户联系信息的更改导致登录电子邮件地址从 {{ OLD_USERNAME }} 更改为 {{ NEW_USERNAME }}',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: '请分配角色和通知。',
  DO_YOU_WANT_TO_CONTINUE: '你想继续吗？',
  COPY_ANSWER_TO_NEW_INTERACTION: '将答案复制到新交互中',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE: '新的查询已导入。请务必描述并回答所有询问。',
  LEAVE_WITHOUT_SAVING: '离开而不保存',
  PENDING_CHANGES_MESSAGE: '待处理的更改是 {{ CHANGES }}？',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION: '您确定要在新交互中拆分所选文本吗？',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE: '这将从交互中删除所选文本并从中创建一个新文本。',
  DOWNLOAD_ERROR: '下载错误！',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    '您正在尝试下载超过 1000 个查询。支持的最大数量为 1000。请优化您的选择。',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION: '您正在尝试改变问题特征！',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    '请注意，更改问题特征将不允许对先前给出的答案进行任何更改，也不允许发送新答案！',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE: '您即将重新打开封闭的交互以发送新答案。',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE: '您确定要将此跟进转换为问题吗？',
  ARE_YOU_SURE: '你确定吗？',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: '确认停用 {{ USER_INFO }}',
  USER_REACTIVATION_CONFIRMATION_QUESTION: '确认重新激活 {{ USER_INFO }}',
  USER_REACTIVATED_MESSAGE: '用户 {{ USER_INFO }} 重新激活成功！',
  DELETE_NOT_POSSIBLE: '删除不了！',
  SEARCH_NO_LONGER_EXIST: '此搜索不再存在，因为它已被发件人删除。',
  LOGIN_CREDENTIALS_CHANGED: '登录凭据已更改！',
  EDIT_TEXT: '编辑文字',
  FOLLOW_UP_ATTEMPTS: '后续尝试',
  FIRST_FOLLOW_UP_INFO_TEXT: '在个人互动中发送的第一个后续信息',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT: '如果在初始请求后停用以下任何一项，则流程将过渡到下一步',
  SECOND_ATTEMPT: '第二次尝试（第一次提醒）',
  THIRD_ATTEMPT: '第三次尝试（第一次提醒）',
  FINAL_ATTEMPT: '失访（关闭）',
  REMINDER_FREQUENCY: '提醒频率',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE: '将自动翻译成用于联系询问者的语言',
  ON_OFF_SWITCH: '开关',
  DAY: '天',
  DAYS: '天',
  MONTH: '月',
  MONTHS: '几个月',
  RESET_TEMPLATES_TO_DEFAULTS: '将模板重置为默认值',
  SAVE_FREQUENCY: '保存频率',
  SELECT_FREQUENCY: '选择频率',
  TEMPLATE_MODIFICATIONS_HINT:
    '文本已手动修改，不会再自动更改。单击此处放弃修改并重新启用自动更新。',
  LOGIN_INCORRECT_EMAIL_ADDRESS: '不正确的电子邮箱地址！',
  AUDIT_TRAIL_FOR: '{{ ITEM }} 的审核跟踪',
  AUDIT_TRAIL_FOR_ALL: '所有 {{ ITEM }} 的审核跟踪',
  HISTORY_CHANGES_FOR: '历史变更为',
  HOLIDAY_GDPR_DISCLAIMER: '假期 GDPR 免责声明',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER: '管理假期 GDPR 免责声明',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS: '以下团队缺少假期 GDPR 模板',
  BULK_CREATE: '批量创建',
  LAST_SUCCESS: '最后的成功',
  LAST_FAILURE: '最后的失败',
  FAILURE: '失败',
  TASK: '任务',
  Weeks: '周数',
  REPEAT_EVERY: '对每个重复',
  DEACTIVATE_ITEM: '停用 {{ ITEM }}',
  RESET_FAILURE_COUNT: '重置失败计数',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '请检查对话框后面的表中的现有任务，因为我们已经有具有相同值的任务。',
  ACTIVATE_ITEM: '激活 {{ ITEM }}',
  PLEASE_ENTER_MIN_NUMBER: '请输入大于 {{ MIN_NUMBER }} 的数字。',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION: '选定的合并目标交互已关闭。本次查询为新交互，无需合并。',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      '现有询问中将添加一个新问题，所有问题都必须得到回答。请选择要合并的问题。',
    VALIDATION_MSG: '请从合并的交互中至少选择一个问题以继续合并。',
  },
  MERGED_INTERACTION: '合并交互',
  TARGET_INTERACTION: '目标互动',
  MERGE_SUGGESTION: '合并建议',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: '请查看下面的合并建议',
  INQUIRERS_COMPARISON_TITLE: '请注意合并交互和目标交互的不同询问者',
  INQUIRERS_COMPARISON_QUESTION: '尽管存在这种差异，请确认您是否愿意继续。',
  TERRITORY: '领土',
  DOMAIN: '领域',
  IN: '在',
  TEAM_GROUP_FILTER: {
    TITLE: '定义查看其他团队的权限',
    SAVE_BTN_TOOLTIP: '请输入团队的所有必填字段并先保存团队。',
  },
  ADR_REF_ID: 'ADR 参考 ID',
  PQC_REF_ID: 'PQC 参考 ID',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS: '此附件存在于多次交互中',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    '如果您想从所有交互中删除附件，请选中复选框选项。',
  CONFIRM_DELETE_ATTACHMENT: '请确认您是否要删除此附件。',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE: '从上述所有互动中删除',
  ATTACHMENT_DELETED: '附件已删除',
  ROLES: '角色',
  SET_TO_ALL_INQ_TOOLTIP: '为所有问题设置相同的 ADR、PQC 和标签外表征。',
  TASKS_LIST: '任务列表',
  TASK_LOGS: '任务日志',
  SELECTED: '已选择',
  LICENSE_COUNTER: '执照柜台',
  LICENSES_LIMIT: '许可证数量限制',
  USERS_TO_EXCLUDE: '要排除的用户',
  ENTER_MAX_NUMBER_OF_LICENSES: '合同许可费（用户角色）。',
  SELECT_USER_ROLES_TO_COUNT: '选择适用于许可证费用计数的用户角色。',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: '从许可费用计数中排除用户。',
  RECEIPT_AND_SALES_NOTIFICATIONS: '收据和销售通知',
  REFRESH_MIGRATION_ERRORS: '刷新迁移错误列表',
  MARKED_AS_RESOLVED_BY: '标记为已解决',
  MARKED_AS_RESOLVED_ON: '标记为已解决',
  MARK_AS_RESOLVED: '标记为已解决',
  ERROR_ON: '错误开启',
  TEXT_WITH_SPECIAL_CHARS:
    '请仅输入字母 (a-z、A-Z)、数字 (0-9) 和特殊符号，例如破折号 (-)、句点 (.)、空格、冒号 (:)、逗号 (,)、括号 (())、正向斜杠 (/) 和加号 (+)。',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL: '交互已提交审批状态。请批准/拒绝启用此菜单。',
  REFRESH_TASK_LOGS: '刷新任务日志列表',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE: '如果您想继续进行更改，请确认！',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED: '不允许对代理角色进行手动归档。',
  RUN_COUNTER: '运行计数器',
  EXPORT_LICENSES: '出口许可证',
  LICENSE_COUNT_MESSAGE: '已使用的许可证数量为：',
  QUESTIONS: '问题',
  CLARIFICATION: '澄清',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG: '请在目标交互中至少选择一个问题以继续合并。',
    DESCRIPTION: '澄清问题将添加到目标交互中选定的问题下。',
  },
  MERGE_AS_DUPLICATE: { DESCRIPTION: '选择此选项可将交互标记为现有交互的副本。' },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    '检测到之前的合并作为澄清问题。除了目标交互中之前选择的答案字段之外，所有选定的答案字段都将重新打开以供回答。',
  DISABLED_ANSWER_FIELD_TOOLTIP: '重新开放互动以回答另一个问题。这个问题的答案之前已经给出了。',
  TIP: '提示',
  MERGE_TIP_TEXT:
    '如果您希望重新打开交互 {{ MERGED_INTO_ID }}，请按“否”并启动一个新的合并流程，指示目标交互 {{ MERGED_INTO_ID }}。',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN: '您正在选择合并操作而不打开目标交互。',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    '您正在选择对无效目标交互 {{ TARGET_INTERACTION_ID }} 执行合并操作。<br>交互 {{ TARGET_INTERACTION_ID }} 已关闭，状态为 {{ STATUS }}{{ LAST_MERGE_INFO }}。',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER: '这将导致封闭的互动并且无法提供答案。',
  INTO_INTERACTION: '进入互动',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: '请选择要合并的交互。',
  PLEASE_SELECT_MERGE_REASON: '请选择合并原因。',
  QUESTION_CANNOT_BE_DELETED: '该问题无法删除，因为存在相关的未回答的澄清问题。',
  MERGE_REASON: '合并原因',
  WITH_REOPEN: '重新开放',
  MERGED_INTO_QUESTION_IDS: '合并到问题 ID 中',
  MERGED_INTO_QUESTIONS: '合并到问题中',
  MERGED_PREVIOUSLY_FROM: '之前合并自',
  FROM_INTERACTION_ID: '来自交互 ID',
  IN_INTERACTION_ID: '在交互ID中',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: '您即将取消合并建议。',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: '不再显示合并建议？',
  PRODUCT_RESOLVED_VIA_AI: '产品（通过人工智能解决）',
  REQUIRED_FIELDS_HINT: '请填写所有必填字段',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: '至少需要一个联系人！',
  DISCLAIMER_INFO: '免责信息',
  QUESTION_ID: '问题编号',
  QUESTION_IDS: '问题 ID',
  SHOW_TEAMS: '表演团队',
  LAST_POLLING: '最后投票',
  HOST: '主持人',
  PROTOCOL: '协议',
  FROM_EMAIL: '来自电子邮件',
  REASON_FOR_REMOVAL: '删除原因',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: '封闭式交互不可编辑。',
  METADATA_DROPDOWNS_TOOLTIP_TEXT: '互动不止一个问题。只能通过开放交互进行编辑。',
  BULK_EDIT: '批量编辑',
  HOW_TO_ENABLE_BULK_EDIT: '必须至少选择两个相同语言的模板才能进行批量更新。',
  REASON_FOR_UPDATE: '更新原因',
  FOLLOW_UP_RESEND: '后续信息已重新发送',
  SENT_FOLLOW_UP_REMINDER: '发送后续提醒',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION: '结束互动并发送失联后续通知',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: '请输入至少长度为 15 个字符的文本。',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST: '无法跟进！请先输入询问者。',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    '后续请求已禁用！请先插入有效的询问者电子邮件。',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    '所选频率类型的数字应介于 1 和 {{ MAX_ALLOWED_FREQUENCY }} 之间。',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: '请选择频率类型。',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    '从首次跟进请求的日期和时间开始计数。若要将天数转换为周数，请乘以 7，例如 2 周 = 14 天；若要将天数转换为月数，请乘以 30，例如 1 个月 = 30 天。',
  VALID_PHONE_FAX: '请输入有效的电话/传真号码！',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES: '选择一种语言来查看和管理翻译的模板',
  RETRANSLATE: '重新翻译',
  FOLLOW_UP_REMINDERS_INFO_TEXT: '以下模板仅提供英文版本，翻译如下',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    '所有上述模板都将重置为默认值（手动修改将被删除，频率重置为 14 天，翻译复选框取消选中），重新翻译为所有语言并将清除 is_manually_modified。',
  FOLLOW_UP_EDIT_BTN_TOOLTIP: '编辑模板文本并重新翻译所有未手动修改的翻译。',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP: '重新翻译原始模板文本，包括手动修改的文本。',
  FOLLOW_UP_REMINDER_TEMPLATES: '后续提醒模板',
  MANUALLY_MODIFIED: '手动修改',
  SYNC: '同步',
  DIFFERENT: '不同的',
  DEFAULT_CONFIRMATION_QUESTION: '请确认您是否要继续。',
  SEND_WELCOME_MAIL_: '发送包含密码重置的欢迎电子邮件',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    '我确认已收到询问者新的后续信息，并且我愿意重新开启互动以提供答案。',
  ONLY_LETTERS_SPACE_AND_DASH: '请仅输入字母、空格和-符号！',
  INFO: '信息',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    '询问者国家/地区的变化将使互动的分配从团队 {{ CURRENT_TEAM }} 更改为团队 {{ FUTURE_TEAM }}。',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    '询问者国家/地区的变更将使互动的分配从团队 {{ CURRENT_TEAM }} 更改为团队 {{ FUTURE_TEAM }}。用户 {{ ASSIGNED_TO }} 没有 {{ FUTURE_TEAM }} 团队的权限，其个人分配将被删除。',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    '此更改将影响以下额外互动中的团队分配，其中个人分配将被删除：{{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    '询问者所在国家/地区的变更将改变以下附加互动中的团队分配，其中个人分配将被删除。{{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    '互动 ID：{{ INTERACTION_ID }} 从 {{ CURRENT_TEAM }} 到 {{ FUTURE_TEAM }}，已从用户 {{ ASSIGNED_TO }} 中删除个人分配',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    '用户 {{ EDITING_USER }} 在交互 ID {{ UPDATED_INTERACTION_ID }} 中更改了询问者 {{ ENQUIRER }} 的国家/地区，从而将当前交互的分配从团队 {{ OLD_TEAM }} 更改为团队 {{ NEW_TEAM }}。',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    '用户 {{ EDITING_USER }} 更改了 HCP 管理中的询问者 {{ ENQUIRER }} 国家/地区，从而将当前交互的分配从团队 {{ OLD_TEAM }} 更改为团队 {{ NEW_TEAM }}。',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    '用户 {{ INTERACTION_ASSIGN_TO }} 没有 {{ NEW_TEAM }} 团队的权限，因此其个人任务已被删除。',
  PERSONAL_ASSIGNMENT: '个人任务',
  DUE_TO_FU_REQUEST: '由于后续请求',
  DUE_TO_FU_RESPONSE: '由于后续响应',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE: '由于管理页面更新了后续提醒频率',
  UPDATED_FROM_INBOX: '从收件箱更新',
  ARCHIVED_ROLE: '已归档角色',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    '归档用户角色将更改以下附加交互中的分配，其中个人分配将被删除：{{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    '用户 {{ EDITING_USER }} 编辑了 {{ INTERACTION_ASSIGN_TO }} 用户，将其角色存档为 {{ TEAM }} 团队中的 {{ USER_ROLE }}。用户 {{ INTERACTION_ASSIGN_TO }} 没有 {{ TEAM }} 团队的权限，其个人分配已被删除。',
  ASSIGNMENT: '任务',
  PERIOD_TO_PERIOD_OVERLAP: '期间与截止期间重叠',
  PERIOD_TO_PERIOD_MISMATCH:
    '期间和截止期间的持续时间不同 - {{ PERIOD }} {{ INTERVAL }} 和 {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: '根据后续回复创建问题',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP: '该选项允许从后续问题中创建新问题。',
  ADD_AS_A_NEW_QUESTION: '添加为新问题',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    '请注意，您正在尝试存档该用户的最后一个批准角色，这将导致用户停用并阻止他们进一步访问 EnqMed。',
  ATTEMPTED_FOLLOW_UP: '尝试跟进',
  FAILED_FU_ATTEMPT: '后续尝试失败',
  UNABLE_TO_REACH_ENQUIRER: '无法联络询问者。',
  FAILED_FU_ATTEMPT_TOOLTIP:
    '如果尝试联系询问者但没有成功，请选择此选项，例如无法通过电话联系、面对面或其他预约失败。\n该尝试将记录在互动历史记录中。',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS: '您将被重定向到您拥有权限的页面。',
  FU_STOPPED_DUE_TO_ENQ_CHANGE: '由于更换询问者导致后续流程停止',
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU: '您即将切换询问者，这将停止后续过程。',
  INQUIRER_DETAILS: '询问者详情',
  TEAM_NOTIFY_INQUIRER: '通知詢問者',
  INQUIRER_TYPE: '询价人类型',
  DELETE_INQUIRER_PII: '删除询问者个人信息',
  MARKED_INQUIRER_PII_DELETE: '标记查询者个人资料以供删除',
  UNMARKED_INQUIRER_PII_DELETE: '未标记查询者个人信息以供删除',
  NEW_ENQUIRER: '新询问者',
  NEW_ENQUIRER_TOOLTIP:
    '使用此选项可创建一个全新的询问者。如果需要编辑现有询问者数据，请不要使用此选项。',
  CONFIRM_SET_NEW_ENQUIRER:
    '请确认您是否希望用新的询价人替换现有的询价人。<br>所有输入的信息都将丢失。<br>如果您希望编辑现有询价人的数据，请直接在表格中修改数据，然后按“保存”。',
  INQUIRER: '询问者',
  KEEP_INQUIRER_DETAILS_OPEN: '保持询问者详细信息开放',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    '您有未保存的更改，如果您关闭询问者详细信息，这些更改将会丢失。',
  SHARE_FROM_HERE_YOU_CAN_: '分享：从这里您可以分配、转发或开始与询问者进行跟进。',
  UPDATED_INQUIRER_DETAILS: '更新询问者详情',
  CREATED_INQUIRER: '创建询问者',
  SUGGESTED_INQ: '建议询问者',
  SELECT_INQUIRER: '选择询问者',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_: '必须选择 ADR 义务同意书和询问者，才能提交互动',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_: '由于电子邮件地址错误，答复尚未发送给询问者。',
  MSG_NOTIFICATION_WILL_BE_SEND_1: '谢谢！提交或答复咨询后，将会发送通知。',
  MSG_NOTIFICATION_WILL_BE_SEND_2: '谢谢！查询者保存后将发送通知。',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ: '以下 GDPR 通知将通过电子邮件发送给询问者。',
  NOTIFICATION_SENT_TO_THE_INQ: '以下 GDPR 通知已通过电子邮件发送给询问者。',
  SEND_A_NOTIFICATION_TO_THE_INQ: '通过电子邮件向询问者发送以下 GDPR 通知。',
  INQ_HAS_NO_EMAIL: '询问者沒有電子郵件。',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ: '尚未向询问者发送已收到询问的通知。',
  NOTIFICATION_SENT_TO_THE_INQUIRER: '向询问者发送已收到询问的通知。',
  THIS_IS_JUST_A_THANK_YOU: '这只是现有咨询的咨询者发来的感谢信息。',
  SAVE_INQUIRER: '保存询问者',
  INQ_DEFAULT_VAL: '询问器默认值',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    '对于通过公司代表（销售代表、MSL 或其他）提交的问询，请通知问询者有关其个人数据的处理情况。',
  SELECT_PREFERRED_CONTACT_ERROR: '您必须为询问者选择一个首选联系方式。',
  FOLLOW_UP_INITIATED_BY_INQUIRER: '询问者发起的跟进',
  NO_INQUIRER: '沒有詢問者',
  'Changed inquirer': '更换询问者',
  DELETE_INQUIRY_MESSAGE: '您即将删除此查询。此操作不可撤消。',
  QUESTION_REQUIRE_ANSWER_MESSAGE: '新问题需要答案才能结束调查。',
  IMAGE: '图像',
  THERAPEUTIC_AREA_STATUS_MESSAGE: '治疗领域为 {{ STATUS }}',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    '，因为没有关联产品。请选择至少一个产品以将治疗区域设置为活动状态。',
  EXPAND_ALL_THERAPEUTIC_AREAS: '拓展所有治疗领域',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: '折叠所有治疗领域',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT: '要将治疗区域 (TA) 设置为主 TA，请取消选择父 TA 并保存。',
  EXTRA_STEP_REQUIRED_TO_LOGIN: '登录需要额外步骤',
  EXTRA_STEP_DESCRIPTION: '登录确认已发送至您的电子邮箱。请按照其中的步骤操作，以继续使用系统。',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT: '请输入至少长度为15个字符的文本或附件。',
  REASON_FOR_REJECTION: '拒绝原因',
  NOTE_PROPERLY_INSERTED_IN_HISTORY: '注释已正确插入历史记录中。',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    '通过 {{ ACTION_TYPE }} 选定 {{ METADATA_TYPE }}，将应用以下附加相关更改：',
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE: '删除这些产品后，将应用以下额外的相关变化：',
};
