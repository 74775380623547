// List of filters that are not shown on the UI of the advanced search nor on the Inbox filters
export const EXPORT_FILTERS_TO_IGNORE = ['mode', 'orderBy', 'page', 'pageSize'];

const OLD_FILTER_NAMES = [
  'is_closed',
  'teamName',
  'assignedTo',
  'statusLabel',
  'products',
  'insertedBy',
  'approvedBy',
  'answeredBy',
  'created_ts',
];

export const FIELDS_TO_IGNORE = [
  'selectAll',
  'state_id',
  'mode',
  'orderBy',
  'my_teams',
  'page',
  'pageSize',
  'assigned_to_me',
  ...OLD_FILTER_NAMES,
];
