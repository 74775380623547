export enum EAuthStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Open = 'Open',
}

export enum EAuthType {
  NewUser = 'NewUser',
  NewCategoryTopic = 'NewCategoryTopic',
  NewUserUpdate = 'NewUserUpdate',
}

export enum EAuthTitle {
  User = 'User',
  UserRequest = 'userRequest',
  CategoryTopicRequest = 'categoryTopicRequest',
  ProductRequest = 'productRequest',
}
