import { gql } from 'apollo-angular';

import { BaseModel, rProperty, parseAttr } from '../data-model';
import { BCountry } from '../country/country';

export class BAddress extends BaseModel {
  @rProperty() id: string;
  @rProperty() label: string;
  @rProperty() address1: string;
  @rProperty() address2: string;
  @rProperty() city: string;
  @rProperty() zipCode: string;
  @rProperty() state: string;
  @rProperty() isMain: boolean;
  @rProperty() isDeleted: boolean;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  @rProperty() importedId: string;
  @rProperty() importedSource: string;
  @rProperty(Date) importedTs: string;

  //relations
  //editedBy
  country: BCountry;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.country = parseAttr<BCountry>(json, BCountry, 'country');
    if (!json) {
      this.address1 = '';
    }
    for (var field of ['isMain', 'isDeleted']) {
      // Need to keep the true value since we can also clone the email which is already true
      this[field] = json[field] == 'TRUE' || json[field] === true ? true : false;
    }
  }

  get addressDescription() {
    var description = '';
    if (this.address1) description += this.address1;
    if (this.address2) description += ', ' + this.address2;
    if (this.city) description += ', ' + this.city;
    if (this.zipCode) description += ', ' + this.zipCode;
    if (this.state) description += ', ' + this.state;
    return description;
  }

  shortRepresentation(countryName?: string): string {
    countryName = countryName || (this.country && this.country.name);
    return [countryName, this.state, this.city, this.address1, this.address2, this.zipCode]
      .filter((p) => p)
      .join(', ');
  }
}

export const fragment = gql`
  fragment addressFragment on AddressNode {
    id
    label
    address1
    address2
    city
    zipCode
    state
    isMain
    createdTs
    editedTs
    isDeleted
    importedId
    importedSource
    importedTs
  }
`;

export const fragmentNew = gql`
  fragment newAddressFragment on NewAddressNode {
    id
    label
    address1
    address2
    city
    zipCode
    state
    isMain
    isDeleted
  }
`;

export const fragmentRevision = gql`
  fragment addressRevisionFragment on RevisionAddressNode {
    id
    label
    address1
    address2
    city
    zipCode
    state
    isMain
    createdTs
    editedTs
    isDeleted
  }
`;

export const fragmentConnection = gql`
  fragment addressConnectionFragment on AddressNodeConnection {
    edges {
      node {
        ...addressFragment
      }
    }
  }
  ${fragment}
`;
