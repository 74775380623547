import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { BInquiry, BInteraction } from 'app/modules/data-model/data-model.module';

@Component({
  selector: 'app-select-inquiry',
  templateUrl: './select-inquiry.component.html',
})
export class SelectInquiryComponent {
  interaction: BInteraction;
  selectedInquiry: BInquiry;
  titleTranslationKey: string = 'SELECT_QUESTION';
  noSelectedEnquiryTranslationKey: string;

  constructor(public dialogRef: MatDialogRef<SelectInquiryComponent>) {}
}
