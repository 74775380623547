import { gql } from 'apollo-angular';

import { BaseModel, rProperty, base64ToPK } from '../data-model';

export class BMedicalDocumentType extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() code: string;

  isActive = true;

  init(json: any) {}

  static fromRest(json: any): BMedicalDocumentType {
    return Object.assign(new BMedicalDocumentType({}), json);
  }

  static fromRestArray(json: any[]): BMedicalDocumentType[] {
    return json && json.map((v) => BMedicalDocumentType.fromRest(v));
  }

  getMId() {
    return base64ToPK(this.id);
  }

  getMName() {
    return this.name;
  }
}

export const fragment = gql`
  fragment medicalDocumentTypeFragment on MedicalDocumentTypeNode {
    id
    name
    code
  }
`;

export const fragmentConnection = gql`
  fragment medicalDocumentTypeConnectionFragment on MedicalDocumentTypeNodeConnection {
    edges {
      node {
        ...medicalDocumentTypeFragment
      }
    }
  }
  ${fragment}
`;
