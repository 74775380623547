import { BaseModel } from '../data-model';
import { BRole } from '../role/role';
import { BTeam, BUser } from '../user/user';
import { EDialogStatuses } from '../../../common/common/enums/dialog.enum';
import { STATUSES_KEY } from 'app/common/common/constants/statuses';
import { DateRange } from 'app/common/types';

export type TTeamConfigData = {
  documentApprovalWithinEnqmed: boolean;
  hasWorkflowTemplates?: boolean;
};

export type TDocumentsApprovalWorkflowDialogResult = {
  status: EDialogStatuses;
  approvalWorkflow?: TWorkflowData;
};

export type TWorkflowStep = {
  approvalTypeId: number;
  reviewerIds: number[];
  approvalPeriod: number;
  actionOnApproveWithChangesId: number;
  actionOnRejectId: number;
  order: number;
};
export type TWorkflowData = {
  id?: number;
  name: string;
  teamId: number;
  steps: TWorkflowStep[];
};

export type TMedicalDocumentsForApproval = {
  actions: string[];
  approvalDate: string | null;
  countries: string[];
  deadline: string;
  id: number;
  products: string[];
  status: string;
  team: string;
  therapeuticAreas: string[];
  title: string;
  type: string;
  validUntil: string;
  version: number;
};
export type TMedicalDocumentsForApprovalResponse = {
  docsCountPerStatus: { [key in typeof STATUSES_KEY[number]]: number };
  totalRecords: number;
  records: TMedicalDocumentsForApproval[];
};

export class MedicalDocumentsAppliedFilters {
  id: number | null;
  title: string | null;
  type: string | null;
  version: number | null;
  productIds: string[] | null;
  approvalDate: DateRange | null;
  validUntil: DateRange | null;
  teamIds: string[] | null;
  therapeuticAreaIds: string[] | null;
  countries: string[] | null;
  deadline: DateRange | null;
  actions: string[] | null;
  searchQuery: string | null;
  statuses: string[] | null;
  categoryIds: number[];
  languageIds: number[];
  targetAudienceIds: number[];
  topicIds: number[];
  orderBy: string | null;
  pageIndex: number | null;
  pageSize: number | null;

  static defaults() {
    return {
      id: null,
      title: null,
      type: null,
      version: null,
      productIds: [],
      approvalDate: null,
      validUntil: null,
      teamIds: [],
      therapeuticAreaIds: [],
      countries: [],
      deadline: null,
      actions: null,
      searchQuery: null,
      statuses: [],
      categoryIds: [],
      languageIds: [],
      targetAudienceIds: [],
      topicIds: [],
      orderBy: null,
      pageIndex: null,
      pageSize: null,
    };
  }
}

export class BDocumentWorkflowStep extends BaseModel {
  id: number;
  createdBy: BUser;
  editedBy: BUser;
  createdTs: Date;
  editedTs: Date;
  approvalType: BApprovalType;
  approvalTypeId: number;
  approvalPeriod: number;
  order: number;
  reviewer: BUser;
  reviewerIds: number[];
  actionOnReject: BChangeRequestedActionType;
  actionOnRejectId: number;
  actionOnApproveWithChanges: BChangeRequestedActionType;
  actionOnApproveWithChangesId: number;
  active: boolean;

  init() {}

  static fromRest(json: any): BDocumentWorkflowStep {
    if (json) {
      json.createdBy = BUser.fromRest(json.created_by);
      json.editedBy = BUser.fromRest(json.edited_by);
      json.approvalType = BApprovalType.fromRest(json.approvalType);
      json.reviewer = BUser.fromRest(json.reviewer);
      json.actionOnReject = BChangeRequestedActionType.fromRest(json.actionOnReject);
      json.actionOnApproveWithChanges = BChangeRequestedActionType.fromRest(
        json.actionOnApproveWithChanges
      );
    }
    return Object.assign(new BDocumentWorkflowStep(), json);
  }

  static fromRestArray(json: any[]): BDocumentWorkflowStep[] {
    return json && json.map((obj) => BDocumentWorkflowStep.fromRest(obj));
  }
}

export class BDocumentWorkflowTemplate extends BaseModel {
  id: number;
  createdBy: BUser;
  editedBy: BUser;
  createdTs: Date;
  editedTs: Date;
  name: string;
  team: BTeam;
  active: boolean;

  steps: BDocumentWorkflowStep[];

  init() {}

  static fromRest(json: any): BDocumentWorkflowTemplate {
    if (json) {
      json.createdBy = BUser.fromRest(json.created_by);
      json.editedBy = BUser.fromRest(json.edited_by);
      json.team = BTeam.fromRest(json.team);
      json.steps = BDocumentWorkflowStep.fromRestArray(json.steps);
    }
    return Object.assign(new BDocumentWorkflowTemplate(), json);
  }

  static fromRestArray(json: any[]): BDocumentWorkflowTemplate[] {
    return json && json.map((obj) => BDocumentWorkflowTemplate.fromRest(obj));
  }
}

export class BApprovalType extends BaseModel {
  id: number;
  name: string;
  code: string;
  description: string;
  reviewerRoles: BRole[];

  init() {}

  static fromRest(json: any): BApprovalType {
    if (json) {
      json.reviewerRoles = BRole.fromRestArray(json.reviewer_roles);
    }
    return Object.assign(new BApprovalType(), json);
  }

  static fromRestArray(json: any[]): BApprovalType[] {
    return json && json.map((obj) => BApprovalType.fromRest(obj));
  }

  static filterApprovalTypesForTeam(
    teamCode: string,
    approvalTypes: BApprovalType[]
  ): BApprovalType[] {
    const teamCodes = ['at_cardio', 'at_onco', 'dse_cardio', 'dse_onco', 'de_cardio', 'de_onco']; // Codes for Information officer.
    return approvalTypes.filter((approvalType) => {
      if (teamCodes.includes(teamCode)) {
        return approvalType.code != 'final';
      }
      return approvalType.code != 'info';
    });
  }

  static getInitialAndFinalApprovalType(approvalTypes: BApprovalType[]): {
    initialApprovalType: BApprovalType;
    finalApprovalType: BApprovalType;
  } {
    const initialApprovalType = approvalTypes.find(
      (approvalType) => approvalType.code === 'submit'
    );
    const finalApprovalType = approvalTypes.find((approvalType) =>
      ['info', 'final'].includes(approvalType.code)
    );
    return { initialApprovalType, finalApprovalType };
  }
}

export class BApprovalStepOutcomeType extends BaseModel {
  id: number;
  name: string;
  code: string;
  description: string;

  init() {}

  static fromRest(json: any): BApprovalStepOutcomeType {
    return Object.assign(new BApprovalStepOutcomeType(), json);
  }

  static fromRestArray(json: any[]): BApprovalStepOutcomeType[] {
    return json && json.map((obj) => BApprovalStepOutcomeType.fromRest(obj));
  }
}

export class BChangeRequestedActionType extends BaseModel {
  id: number;
  name: string;
  code: string;
  description: string;

  init() {}

  static fromRest(json: any): BChangeRequestedActionType {
    return Object.assign(new BChangeRequestedActionType(), json);
  }

  static fromRestArray(json: any[]): BChangeRequestedActionType[] {
    return json && json.map((obj) => BChangeRequestedActionType.fromRest(obj));
  }
}
