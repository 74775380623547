import { Injectable } from '@angular/core';

@Injectable()
export class PeriodicalService {
  submit(executor: PeriodicalExecutor): void {
    executor.executorReference = setInterval(() => executor.execute(), executor.interval);
  }
}

export abstract class PeriodicalExecutor {
  interval: number = 5000;
  executorReference;

  abstract execute();

  executeData(): number {
    return undefined;
  }

  stop(): void {
    if (this.executorReference) {
      clearInterval(this.executorReference);
    }
  }
}
