import { gql } from 'apollo-angular';

import { BaseModel, rProperty, parseAttr } from '../data-model';
import { BState } from './state';

export class BCurrentObjectState extends BaseModel {
  @rProperty() id: string;
  @rProperty() objectId: string;

  //relations

  state: BState;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.state = parseAttr<BState>(json, BState, 'state');
  }
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment currentObjectStateFragment on CurrentObjectStateNode {
    id
    objectId
  }
`;
