export enum Operator {
  And = 'AND',
  OR = 'OR',
  Not = 'NOT',
}

export enum SearchOrigin {
  Inbox = 'Inbox',
  AdvancedSearch = 'AdvancedSearch',
  LeftMenu = 'LeftMenu',
}
