import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ajax } from 'app/common/ajax';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { BMailbox, BMailboxHistory, BMailboxTeams } from '../mailbox/mailbox';
import { MailboxFilters } from './mailbox-filters';
import { MailboxChangeRecordsFilters } from './mailbox-change-records-filters';
import { MailboxPollingFilters } from './mailbox-polling-filters';
import { DateRange, PageQuery, PaginatedResponse } from 'app/common/types';

@Injectable()
export class MailboxService {
  private readonly url: string;

  constructor(private http: HttpClient, urlResolver: UrlResolverService) {
    this.url = urlResolver.misApiUrlForPath('/mailbox/');
  }

  public getTeams(id, background = false): Observable<MailboxTeams> {
    const options = background ? Ajax.X_NO_LOADING_OPTIONS : undefined;
    return this.http.get<MailboxTeams>(this.url + id + '/teams/', options).pipe(
      map((resp) => {
        resp.mailboxteams = BMailboxTeams.fromRestArray(resp.mailboxteams);
        return resp;
      })
    );
  }

  public updateTeamsMailbox(
    id,
    mailboxteams: BMailboxTeams,
    background = false
  ): Observable<MailboxTeams> {
    const options = background ? Ajax.X_NO_LOADING_OPTIONS : undefined;
    return this.http.put<MailboxTeams>(this.url + id + '/teams/', mailboxteams, options).pipe(
      map((resp) => {
        resp.mailboxteams = BMailboxTeams.fromRestArray(resp.mailboxteams);
        return resp;
      })
    );
  }

  public get(
    query: PageQuery<MailboxFilters>,
    background = false
  ): Observable<PaginatedResponse<BMailbox>> {
    const options = background ? Ajax.X_NO_LOADING_OPTIONS : undefined;

    return this.http.get<PaginatedResponse<BMailbox>>(this.buildGetQueryString(query), options);
  }

  public getAll(background = false): Observable<Mailbox> {
    const options = background ? Ajax.X_NO_LOADING_OPTIONS : undefined;

    return this.http.get<Mailbox>(this.url + 'all/', options).pipe(
      map((resp) => {
        resp.mailbox = BMailbox.fromRestArray(resp.mailbox);

        return resp;
      })
    );
  }

  public create(mailbox: BMailbox, background = false): Observable<Mailbox> {
    const options = background ? Ajax.X_NO_LOADING_OPTIONS : undefined;

    return this.http.post<Mailbox>(this.url, mailbox, options).pipe(
      map((resp) => {
        resp.mailbox = BMailbox.fromRestArray(resp.mailbox);
        return resp;
      })
    );
  }

  public update(mailbox: BMailbox, background = false): Observable<Mailbox> {
    const options = background ? Ajax.X_NO_LOADING_OPTIONS : undefined;

    return this.http.put<Mailbox>(this.url, mailbox, options).pipe(
      map((resp) => {
        resp.mailbox = BMailbox.fromRestArray(resp.mailbox);
        return resp;
      })
    );
  }

  public getMailboxHistoryDropdowns(
    background = false
  ): Observable<MailboxHistoryDropdownsResponse> {
    const options = background ? Ajax.X_NO_LOADING_OPTIONS : undefined;

    return this.http
      .get<MailboxHistoryDropdownsResponse>(this.url + '/history/dropdowns', options)
      .pipe(
        map((resp) => {
          return {
            names: resp.names,
            teams: resp.teams,
            users: resp.users,
            categories: resp.categories,
          } as MailboxHistoryDropdownsResponse;
        })
      );
  }

  public getChangeRecords(
    query: PageQuery<MailboxChangeRecordsFilters>,
    background = false
  ): Observable<PaginatedResponse<MailboxChangeRecord>> {
    const options = background ? Ajax.X_NO_LOADING_OPTIONS : undefined;

    const { pageSize, ascending, pageIndex, filters, orderBy } = query;

    const params = new URLSearchParams(
      `pageSize=${pageSize}&pageIndex=${pageIndex}&orderBy=${orderBy}&ascending=${ascending}`
    );

    Object.keys(filters).forEach((key) => {
      const values = filters[key];

      if (values === null || values === undefined) {
        return;
      }

      if (key === 'dateRange') {
        params.set('dateFrom', (filters[key] as DateRange).from.toISOString());
        params.set('dateTo', (filters[key] as DateRange).to.toISOString());

        return;
      }

      values.forEach((value) => params.append(key, value));
    });

    return this.http
      .get<{ items: BChangeRecord[]; totalItemsCount: number }>(
        this.url + '/history?' + params.toString(),
        options
      )
      .pipe(
        map((resp) => {
          const items = resp.items.map((x) => {
            return {
              date: x.created_ts,
              new: x.new_data,
              old: x.old_data,
              changedField: x.updated_fields,
              name: x.mailbox_name,
              id: x.mailbox_id,
              user: x.user_full_name,
              team: x.team.name,
              teamId: x.team.id,
              productGroups: x.team.product_groups.map((x) => x.name).join(', '),
            } as MailboxChangeRecord;
          });

          return {
            items,
            totalItemsCount: resp.totalItemsCount,
          } as PaginatedResponse<MailboxChangeRecord>;
        })
      );
  }

  public getMailboxMessageDropdowns(
    background = false
  ): Observable<MailboxMessageDropdownsResponse> {
    const options = background ? Ajax.X_NO_LOADING_OPTIONS : undefined;

    return this.http
      .get<MailboxMessageDropdownsResponse>(this.url + '/messages/dropdowns', options)
      .pipe(
        map((resp) => {
          return {
            names: resp.names,
            recipients: resp.recipients,
            senders: resp.senders,
            teams: resp.teams,
          } as MailboxMessageDropdownsResponse;
        })
      );
  }

  public getPollingRecords(
    query: PageQuery<MailboxPollingFilters>,
    background = false
  ): Observable<PaginatedResponse<MailboxPollingRecord>> {
    const options = background ? Ajax.X_NO_LOADING_OPTIONS : undefined;

    const { pageSize, ascending, orderBy, pageIndex, filters } = query;

    const params = new URLSearchParams(
      `pageSize=${pageSize}&pageIndex=${pageIndex}&orderBy=${orderBy}&ascending=${ascending}`
    );

    Object.keys(filters).forEach((key) => {
      const values = filters[key];

      if (values === null || values === undefined || values === 'undefined') {
        return;
      }

      if (key === 'dateRange') {
        params.set('dateFrom', (filters[key] as DateRange).from.toISOString());
        params.set('dateTo', (filters[key] as DateRange).to.toISOString());

        return;
      }

      if (key === 'direction') {
        params.set('direction', filters[key].toString());

        return;
      }

      values.forEach((value) => params.append(key, value));
    });

    return this.http
      .get<{ items: BPollingRecord[]; totalItemsCount: number }>(
        this.url + '/messages?' + params.toString(),
        options
      )
      .pipe(
        map((resp) => {
          const items = resp.items.map((x) => {
            return {
              sender: x.from_header,
              recipient: x.to_header,
              date: x.processed,
              direction: x.outgoing === true ? 'Outgoing' : 'Incoming',
              mailbox: x.mailbox.name,
              teams: x.mailbox.team_name.join(', '),
              message: x.decoded_message,
            } as MailboxPollingRecord;
          });

          return {
            items,
            totalItemsCount: resp.totalItemsCount,
          } as PaginatedResponse<MailboxPollingRecord>;
        })
      );
  }

  private buildGetQueryString(query: PageQuery<MailboxFilters>): string {
    const { pageSize, ascending, orderBy, pageIndex, filters } = query;
    const { active, from_email, name, dateRange } = filters;

    let queryString = `${this.url}?pageSize=${pageSize}&pageIndex=${pageIndex}&orderBy=${orderBy}&ascending=${ascending}`;

    if (active && active !== 'undefined') {
      queryString += `&active=${encodeURIComponent(active)}`;
    }

    if (from_email) {
      queryString += `&from_email=${encodeURIComponent(from_email)}`;
    }

    if (name) {
      queryString += `&name=${encodeURIComponent(name)}`;
    }

    if (dateRange) {
      queryString += `&dateFrom=${dateRange.from.toISOString()}&dateTo=${dateRange.to.toISOString()}`;
    }

    return queryString;
  }
}

export interface DropdownItem {
  id?: number;
  value: string;
}

export interface MailboxHistoryDropdownsResponse {
  users: DropdownItem[];
  names: DropdownItem[];
  teams: DropdownItem[];
  categories: DropdownItem[];
}

export interface MailboxMessageDropdownsResponse {
  senders: DropdownItem[];
  names: DropdownItem[];
  teams: DropdownItem[];
  recipients: DropdownItem[];
}

interface BChangeRecord {
  created_ts: string;
  log_source: string;
  mailbox_name: string;
  mailbox_id: number;
  new_data: string;
  old_data: string;
  updated_fields: string;
  user_full_name: string;
  team: {
    id: number;
    name: string;
    product_groups: { id: number; therapeuticArea: string; name: string }[];
  };
}

interface BPollingRecord {
  from_header: string;
  to_header: string;
  processed: string;
  mailbox: {
    id: number;
    name: string;
    team_name: string[];
  };
  outgoing: boolean;
  decoded_message: string;
}

export type Mailbox = {
  mailbox?: BMailbox[];
};
export type MailboxTeams = {
  mailboxteams?: BMailboxTeams[];
};
export type MailboxHistory = {
  mailboxhistory?: BMailboxHistory[];
};

export interface MailboxChangeRecord {
  date: string;
  user: string;
  name: string;
  id: number;
  team: string;
  teamId: number;
  productGroups: string;
  changedField: string;
  old: string;
  new: string;
}

export interface ProductGroup {
  id: number;
  area: string;
  name: string;
}

export interface MailboxPollingRecord {
  date: string;
  mailbox: string;
  teams: string;
  direction: string;
  sender: string;
  recipient: string;
  message: string;
}
