import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { urlSearchParamsToObject } from '@mi-tool/utils/mappers';
import { omitBy, isNil } from 'lodash';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate() {
    const tokenValid = <boolean>await this.authService.tokenIsValid();
    if (tokenValid) {
      return true;
    }
    // not logged in so redirect to login page
    const options =
      window.location.pathname.length > 3
        ? {
            queryParams: omitBy(
              {
                ref: window.location.pathname,
                urlParams: window.location.search
                  ? JSON.stringify(urlSearchParamsToObject(window.location.search))
                  : null,
              },
              isNil
            ),
          }
        : undefined;
    this.router.navigate(['/auth/login'], options);
    return false;
  }
}
