import { Component, Input } from '@angular/core';
import { NameValue } from '@mi-tool/consts';
import { BUser, BInquirer, BEntity } from 'app/modules/data-model/data-model.module';

@Component({
  selector: 'user-inquirer-card',
  templateUrl: './user-inquirer-card.html',
})
export class UserInquirerCard {
  @Input() inquirer: BInquirer;
  @Input() department: BEntity;
  @Input() minimizedVisualization: boolean = false;

  visibleTeams: NameValue<string>[] = [];
  allTeams: NameValue<string>[] = [];

  private _user: BUser;

  @Input()
  set user(user: BUser) {
    if (user && user.affiliations && user.affiliations.length) {
      const teamToRoles = {};
      user.affiliations.forEach((a) => {
        teamToRoles[a.team.name] = teamToRoles[a.team.name] || [];
        teamToRoles[a.team.name].push(a.role.description);
      });
      this.allTeams = Object.keys(teamToRoles).map((teamName) => {
        return { name: teamName, value: teamToRoles[teamName].join(', ') };
      });
      this.visibleTeams = this.allTeams.length <= 5 ? this.allTeams : this.allTeams.slice(0, 5);
    } else {
      this.allTeams = this.visibleTeams = [];
    }
    this._user = user;
  }

  get user(): BUser {
    return this._user;
  }

  showAllTeams(): void {
    this.visibleTeams = this.allTeams;
  }
}
