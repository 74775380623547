import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BInteraction } from 'app/modules/data-model/interaction/interaction';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  EditInteractionService,
  SingleInteractionService,
} from 'app/modules/data-model/interaction/interaction.service';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { GenericDialogComponent } from 'app/common/common/generic-dialog/generic-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import {
  InteractionsService,
  LastMergeInfo,
} from 'app/modules/data-model/interaction/interactions.service';
import { GenericDialogService } from 'app/common/common/generic-dialog/generic-dialog.service';
import {
  InteractionMergeService,
  MergeData,
  MergeParams,
} from 'app/modules/data-model/interaction/interaction-merge.service';
import { ErrorTitles } from '@mi-tool/enums/error.enum';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MERGE_REASONS, MERGE_REASONS_WITHOUT_REOPEN } from '@mi-tool/consts';

@Component({
  selector: 'app-detail-close',
  templateUrl: './detail-close.component.html',
})
export class DetailCloseComponent implements OnInit, OnDestroy {
  interaction: BInteraction;
  interactionHasMergeSuggestion = false;
  targetInteractionLastMergeInfo: LastMergeInfo;
  mergeData: MergeData;
  mergeInProcess = false;
  showMergeButton = true;

  private subs = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<DetailCloseComponent>,
    private messageService: MessageHandlerService,
    private editInteractionService: EditInteractionService,
    private singleInteractionService: SingleInteractionService,
    private router: Router,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private interactionsService: InteractionsService,
    private interactionMergeService: InteractionMergeService,
    private genericDialog: GenericDialogService
  ) {
    this.mergeData = this.interactionMergeService.getInitialMergeData();
  }

  ngOnInit(): void {
    this.subs.add(
      this.interactionMergeService.mergeData$.pipe(debounceTime(100)).subscribe((mergeData) => {
        this.mergeData = mergeData;
        this.showMergeButton = !this.isMergeAsNewInquiryAndTargetClosedOrInReview();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  init(interaction: BInteraction | number): void {
    if (typeof interaction === 'number') {
      const subs = this.singleInteractionService.interaction.subscribe((interactionObj) => {
        this.interaction = interactionObj;
        this.interactionHasMergeSuggestion = this.interaction.hasMergeSuggestion();
        subs.unsubscribe();
      });
      this.singleInteractionService.performQueryWithID(interaction);
    } else {
      this.interaction = interaction;
      this.interactionHasMergeSuggestion = this.interaction.hasMergeSuggestion();
    }
  }

  handleCloseDialog(): void {
    if (this.interactionHasMergeSuggestion) {
      this.confirmRejectSuggestionAndCloseDialog();
    } else {
      this.closeDialog();
    }
  }

  private closeDialog(rejectSuggestionConfirmed: boolean = false): void {
    if (rejectSuggestionConfirmed) {
      this.rejectMergeSuggestion();
    }
    this.dialogRef.close();
  }

  private confirmRejectSuggestionAndCloseDialog(): void {
    const dialogReference = GenericDialogComponent.openConfirmationMsg(
      this.matDialog,
      'YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION',
      'DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN'
    );
    dialogReference.componentInstance.response.subscribe((rejectSuggestionConfirmed) => {
      dialogReference.close();
      this.closeDialog(rejectSuggestionConfirmed);
    });
  }

  handleMerge(): void {
    switch (true) {
      case Boolean(this.mergeData.validationMsg):
        this.messageService.info(this.mergeData.validationMsg);
        break;
      case this.isMergeAsNewInquiryWithDifferentInquirers():
        this.confirmMergeWithDifferentInquirers();
        break;
      case this.isMergeAsCQOrErrorMsgWithoutReopen() && this.mergeData.targetInteraction.isMerged():
        this.getLastMergeInfoAndOpenConfirmDialog();
        break;
      case this.isMergeAsCQOrErrorMsgWithoutReopen() &&
        !this.mergeData.targetInteraction.isMerged():
        this.confirmMergeWithoutReopen();
        break;
      default:
        this.performMerge();
        break;
    }
  }

  private isMergeAsNewInquiryAndTargetClosedOrInReview(): boolean {
    return (
      this.mergeData.mergeReason === MERGE_REASONS.newInquiry &&
      this.mergeData.targetClosedOrInReview
    );
  }

  private isMergeAsNewInquiryWithDifferentInquirers(): boolean {
    return (
      this.mergeData.mergeReason === MERGE_REASONS.newInquiry &&
      this.interaction.inquirer?.id !== this.mergeData.targetInteraction.inquirer?.id &&
      !this.mergeData.targetClosedOrInReview
    );
  }

  private isMergeAsCQOrErrorMsgWithoutReopen(): boolean {
    return (
      (this.mergeData.mergeReason === MERGE_REASONS.clarificationQuestion ||
        this.mergeData.mergeReason === MERGE_REASONS.errorMessage) &&
      this.mergeData.targetClosedOrInReview &&
      !this.mergeData.reopenAfterMerge
    );
  }

  private performMerge(): void {
    this.mergeInProcess = true;
    this.interactionMergeService.performMerge(this.getMergeParams()).subscribe({
      next: (response) => {
        this.mergeInProcess = false;
        if (response.success) {
          this.messageService.info('Interaction merged successfully');
          this.closeDialog();
          this.redirectToTargetInteraction();
        } else {
          this.handleErrorWhileMerging(response.error);
        }
      },
      error: (errorObj) => {
        const displayError =
          (errorObj.status === 409 && errorObj.error['detail']) || errorObj.error;
        this.handleErrorWhileMerging(displayError);
        this.mergeInProcess = false;
      },
    });
  }

  private redirectToTargetInteraction(): void {
    this.router.navigate(['detail', this.mergeData.targetInteraction.pk()]).then((isNavigated) => {
      isNavigated && window.location.reload();
    });
  }

  private handleErrorWhileMerging(errorMessage: string): void {
    this.genericDialog.openDialogError(ErrorTitles.ErrorWhileMerging, errorMessage);
  }

  private getMergeParams(): MergeParams {
    let mergeParams: MergeParams = {
      action: 'close',
      reason: 'merged',
      object_id: this.interaction.id,
      related_to: this.mergeData.targetInteraction.id,
      merge_reason: this.mergeData.mergeReason,
    };
    if (
      this.mergeData.reopenAfterMerge &&
      !MERGE_REASONS_WITHOUT_REOPEN.includes(this.mergeData.mergeReason)
    ) {
      mergeParams.reopen_after_merge = true;
    }
    if (this.mergeData.sourceInquiryIds.length) {
      mergeParams.source_inquiry_ids = this.mergeData.sourceInquiryIds;
    }
    if (this.mergeData.targetInquiryIds.length) {
      mergeParams.target_inquiry_ids = this.mergeData.targetInquiryIds;
    }
    return mergeParams;
  }

  private confirmMergeWithDifferentInquirers(): void {
    const dialogReference = GenericDialogComponent.confirmMergeWithDifferentInquirers(
      this.matDialog,
      this.interaction?.inquirer,
      this.mergeData.targetInteraction?.inquirer
    );
    this.handleDialogResponse(dialogReference);
  }

  private confirmMergeWithoutReopen(): void {
    let tipText = '';
    let lastMergeInfoAsStr = '';
    if (this.mergeData.targetInteraction.isMerged() && this.targetInteractionLastMergeInfo) {
      tipText = this.translateService.instant('MERGE_TIP_TEXT', {
        MERGED_INTO_ID: this.targetInteractionLastMergeInfo.mergedIntoId,
      });
      lastMergeInfoAsStr = this.getLastMergeInfoAsStr();
    }
    let message = this.translateService.instant('YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN');
    if (this.mergeData.targetMergedOrClosedWithoutAnswer) {
      message = this.translateService.instant('YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION', {
        TARGET_INTERACTION_ID: this.mergeData.targetInteraction.pk(),
        STATUS: this.mergeData.targetInteraction.statusLabel,
        LAST_MERGE_INFO: lastMergeInfoAsStr,
      });
    }
    message += `<br>${this.translateService.instant(
      'CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER'
    )}`;

    const dialogReference = GenericDialogComponent.openConfirmationMsg(
      this.matDialog,
      message,
      'DO_YOU_WANT_TO_CONTINUE',
      tipText
    );
    this.handleDialogResponse(dialogReference);
  }

  private handleDialogResponse(dialogReference: MatDialogRef<GenericDialogComponent>): void {
    dialogReference.componentInstance.response.subscribe((mergeConfirmed) => {
      dialogReference.close();
      mergeConfirmed && this.performMerge();
    });
  }

  private getLastMergeInfoAsStr(): string {
    const mergeReasonTranslated = this.interactionMergeService
      .getMergeReasonTranslationKeys(this.targetInteractionLastMergeInfo.mergeReason)
      .map((mergeReasonTranslationKey) =>
        this.translateService.instant(mergeReasonTranslationKey).toLocaleLowerCase()
      )
      .join(' ');
    const mergedIntoIdTranslated = `${this.translateService.instant('INTO_INTERACTION')} ${
      this.targetInteractionLastMergeInfo.mergedIntoId
    }`;
    return ` ${mergeReasonTranslated} ${mergedIntoIdTranslated}`;
  }

  private getLastMergeInfoAndOpenConfirmDialog(): void {
    this.interactionsService.getLastMergeInfo(this.mergeData.targetInteraction.pk()).subscribe({
      next: (lastMergeInfo) => {
        this.targetInteractionLastMergeInfo = lastMergeInfo;
        this.confirmMergeWithoutReopen();
      },
      error: (error) => {
        this.messageService.httpError(ErrorTitles.ErrorWhileFetchingLastMergeData, error);
        this.confirmMergeWithoutReopen();
      },
    });
  }

  private rejectMergeSuggestion(): void {
    this.editInteractionService.editInteraction({
      id: this.interaction.id,
      suggestionFieldUsed: 'merge_to_interaction',
      suggestionsUsed: false,
    });
  }
}
