import { BaseModel, rProperty } from '../data-model';

export class BSpecializationTypes extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;

  init(json: any) {}

  static fromRest(json: any): BSpecializationTypes {
    return Object.assign(new BSpecializationTypes({}), json);
  }

  static fromRestArray(json: any[]): BSpecializationTypes[] {
    return json && json.map((v) => BSpecializationTypes.fromRest(v));
  }
}
