import { gql } from 'apollo-angular';

import { BaseModel, rProperty, parseArray } from '../data-model';
import { BProduct } from '../region/region';

export class BProductGroup extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() therapeuticArea: string;

  //relations
  products: BProduct[];

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.products = parseArray<BProduct>(json, BProduct, 'products');
  }

  static fromRest(json: any): BProductGroup {
    return json && Object.assign(new BProductGroup({}), json);
  }

  static fromRestArray(json: any[]): BProductGroup[] {
    return json && json.map((v) => BProductGroup.fromRest(v));
  }
}

export const fragment = gql`
  fragment productGroupFragment on ProductGroupNode {
    id
    name
    therapeuticArea
  }
`;

export const fragmentConnection = gql`
  fragment productGroupConnectionFragment on ProductGroupNodeConnection {
    edges {
      node {
        ...productGroupFragment
      }
    }
  }
  ${fragment}
`;
