import { gql } from 'apollo-angular';

import { BaseModel, rProperty } from '../data-model';

export class BShareTemplate extends BaseModel {
  @rProperty() id: string;
  @rProperty() text: string;
  @rProperty() replacedText: string;
  @rProperty() category: string;
  init(json: any) {}
}

export const fragment = gql`
  fragment shareTemplateFragment on ShareTemplateNode {
    id
    text
    category
  }
`;

export const fragmentConnection = gql`
  fragment shareTemplateConnectionFragment on ShareTemplateNodeConnection {
    edges {
      node {
        ...shareTemplateFragment
      }
    }
  }
  ${fragment}
`;
