import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BTeam } from 'app/modules/data-model/data-model.module';
import { tinyMceOptions } from 'app/common/tiny-mce-options';
import { HolidayGDPRTemplateDataType } from 'app/modules/data-model/email-notifications-template/email-notifications-template';
import { Config, newTeamConfig } from '../search-select/search-select.config';

@Component({
  selector: 'app-holiday-email-notification-template-dialog',
  templateUrl: './holiday-email-notification-template-dialog.component.html',
  styleUrls: ['./holiday-email-notification-template-dialog.component.scss'],
})
export class HolidayEmailNotificationTemplateDialogComponent {
  tinymceOptions = tinyMceOptions();
  formValid: boolean = false;
  teamConfig: Config;
  templateName: string;
  subject: string;
  templateText: string;
  selectedTeamIds: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<HolidayEmailNotificationTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: Partial<
      HolidayGDPRTemplateDataType & {
        allTeams: BTeam[];
      }
    >
  ) {
    this.teamConfig = newTeamConfig(this.data.allTeams);
    this.templateName = this.data.name;
    this.subject = this.data.subject;
    this.templateText = this.data.text;
    if (this.data?.teamIds?.length) {
      this.selectedTeamIds = this.data.teamIds;
    }
  }

  save(): void {
    if (!this.isValidForm()) {
      throw new Error('Invalid form submission attempted!');
    }

    const templateData: HolidayGDPRTemplateDataType = {
      name: this.templateName.trim(),
      subject: this.subject.trim(),
      text: this.templateText.trim(),
      teamIds: this.selectedTeamIds,
    };
    this.dialogRef.close(templateData);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  private isValidForm(): boolean {
    this.formValid = Boolean(
      this.templateName?.trim() &&
        this.subject?.trim() &&
        this.templateText?.trim() &&
        this.selectedTeamIds.length
    );
    return this.formValid;
  }
}
