<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h2>{{ 'MY_DRAFT_INQ' | translate }}</h2>
      <span class="help">
        {{ 'YOU_CAN_FIND_HERE_THE_LAST_INQ_' | translate }}
      </span>
      <mat-card appearance="outlined" *ngFor="let interaction of dataSource">
        <mat-card-title>
          <div class="container-fluid">
            <div class="row">
              <div class="col-11">
                <a routerLink="/new/{{ interaction | pk }}">
                  {{ interaction | pk }} - {{ interaction.team.name }} -
                  {{ interaction.createdTs | dateChecker }}
                  <ng-container *ngIf="interaction.inquirer.lastName">
                    {{ 'FROM' | translate }} {{ interaction.inquirer.firstName }}
                    {{ interaction.inquirer.lastName }}
                  </ng-container>
                </a>
              </div>
              <div class="col-1 icons-box">
                <button mat-icon-button routerLink="/new/{{ interaction | pk }}">
                  <mat-icon aria-label="Open">edit</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="deleteInteraction(interaction)">
                  <mat-icon aria-label="Delete">delete</mat-icon>
                </button>
                <!--
                                <button mat-icon-button color="primary"><mat-icon aria-label="Duplicate">content_copy</mat-icon></button>
                                -->
              </div>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="container-fluid">
            <ng-container *ngFor="let inquiry of interaction.inquiries">
              <div class="row">
                <div class="col-12">
                  <h4>
                    <ng-container *ngIf="inquiry.product"
                      >{{ inquiry.product.name }} -
                    </ng-container>
                    <ng-container *ngIf="inquiry.category"
                      >{{ inquiry.category.name }} -
                    </ng-container>
                    <ng-container *ngIf="inquiry.topic">{{ inquiry.topic.name }} </ng-container>
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-12" *ngIf="!inquiry.answer">
                  <mat-icon aria-label="Open" class="top-left-icon" *ngIf="inquiry.question.text"
                    >chat_bubble
                  </mat-icon>
                  <div class="editor-editable text-box" [innerHTML]="inquiry.question.text"></div>
                </div>
                <div class="col-12 col-lg-6" *ngIf="inquiry.answer">
                  <mat-icon aria-label="Open" class="top-left-icon" *ngIf="inquiry.question.text"
                    >chat_bubble
                  </mat-icon>
                  <div class="editor-editable text-box" [innerHTML]="inquiry.question.text"></div>
                </div>
                <div class="col-12 col-lg-6" *ngIf="inquiry.answer">
                  <mat-icon aria-label="Open" class="top-left-icon">question_answer</mat-icon>
                  <div class="editor-editable text-box" [innerHTML]="inquiry.answer.text"></div>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
