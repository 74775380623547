<form (ngSubmit)="save()">
  <div class="options">
    <mat-form-field class="w-100 form-field-wrapper" [hideRequiredMarker]="true">
      <mat-label>{{ 'SUBJECT' | translate }} ({{ 'REQUIRED' | translate }})</mat-label>
      <input
        matInput
        [(ngModel)]="subject"
        [ngModelOptions]="{ standalone: true }"
        required
        autocomplete="off"
      />
    </mat-form-field>
    <mat-form-field class="w-100 form-field-wrapper" [hideRequiredMarker]="true">
      <mat-label>{{ 'Name' | translate }} ({{ 'REQUIRED' | translate }})</mat-label>
      <input matInput [(ngModel)]="templateName" [ngModelOptions]="{ standalone: true }" required />
    </mat-form-field>
    <app-search-select
      *ngIf="!isBulkUpdate"
      [config]="countryConfig.getValue()"
      [required]="true"
      [(selectedValue)]="selectedCountryId"
      (selectedValueChange)="onCountryChange($event)"
    ></app-search-select>
    <app-search-select
      *ngIf="!isBulkUpdate"
      [config]="langConfig.getValue()"
      [required]="true"
      [(selectedValue)]="selectedLanguageId"
      (selectedValueChange)="onLanguageChange($event)"
    ></app-search-select>
    <mat-slide-toggle
      *ngIf="isNotificationTypeTemplate"
      class="off-label-toggle"
      [checked]="isOffLabel"
      (change)="toggleOffLabel($event)"
      [disabled]="isDefault"
    >
      {{ 'OFF_LABEL' | translate }}
    </mat-slide-toggle>
  </div>
  <editor
    [(ngModel)]="templateText"
    [ngModelOptions]="{ standalone: true }"
    [inline]="false"
    [init]="tinymceOptions"
    [disabled]="isNotificationTypeTemplate && isFullAnswer"
    class="tiny-mce-popup"
  ></editor>
  <div class="medis-flex-container-even medis-pt-25">
    <button
      type="submit"
      mat-button
      class="medis-button-main"
      [disabled]="
        formValid
          | validateRequiredFields
            : subject
            : templateName
            : selectedCountryId
            : selectedLanguageId
            : templateText
      "
    >
      {{ 'SAVE_UPPERCASE' | translate }}
    </button>
    <button type="reset" mat-button class="medis-button-reject" (click)="cancel()">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
