<mat-form-field
  *ngIf="config"
  class="form-field-wrapper w-100"
  [appearance]="appearance"
  [attr.data-testid]="dataTestId"
  [ngClass]="resolveExtraClasses()"
  (click)="$event.stopPropagation()"
>
  <mat-label *ngIf="!required">{{ config.label | translate }}</mat-label>
  <mat-label *ngIf="required">
    {{ config.label | translate }} ({{ 'REQUIRED' | translate }})
  </mat-label>
  <mat-select
    #select
    [(ngModel)]="selectedValue"
    [disabled]="disabled"
    [multiple]="multiple"
    [placeholder]="placeholder"
    (selectionChange)="selectionChanged($event.value)"
    (openedChange)="$event && scrollToTop()"
    [ngClass]="{ 'not-empty': selectedValue && (!multiple || (multiple && selectedValue.length)) }"
  >
    <div *ngIf="config.items.length > 1">
      <mat-button-toggle-group class="toggle-labels">
        <mat-button-toggle
          *ngFor="let item of config.items"
          [checked]="activeItem === item"
          (click)="activeItem = item"
          >{{ item.headerLabel | translate }}</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
    <div class="medis-flex-container-even medis-pa-5">
      <mat-form-field
        *ngIf="activeItem.options.length > 5 || lazy || extendable"
        class="form-field-wrapper search-field"
        (keydown)="searchKeyDown($event)"
      >
        <mat-label>{{ activeItem.searchLabel | translate }}</mat-label>
        <input matInput autocomplete="off" [(ngModel)]="search" />
      </mat-form-field>
      <i
        *ngIf="multiple && (activeOptions$ | async).length > 0"
        class="fas fa-check-double medis-icon-accept"
        [attr.data-testid]="'select-all-button'"
        matTooltip="{{ 'SELECT_ALL' | translate }}"
        (click)="selectAll()"
      ></i>
      <i
        class="fas fa-check medis-icon-accept"
        [attr.data-testid]="'done-button'"
        matTooltip="{{ 'DONE' | translate }}"
        (click)="select.toggle()"
      ></i>
      <i
        *ngIf="selectedValue"
        class="fas fa-ban medis-icon-reject"
        matTooltip="{{ 'Reset' | translate }}"
        (click)="reset()"
      ></i>
    </div>
    <app-loading-indicator
      [loading]="loading"
      [empty]="(activeOptions$ | async).length === 0"
    ></app-loading-indicator>

    <mat-option class="not-displayed"></mat-option>
    <mat-option
      *ngIf="extendable && search && search.length > 0"
      [value]="search"
      (onSelectionChange)="optionSelected.emit($event)"
    >
      <span class="add-new">Add as new</span>
      <i class="fas fa-plus-square suggest-icon add-new"></i>
      <span>{{ search }}</span>
    </mat-option>
    <mat-option
      *ngFor="let option of activeOptions$ | async"
      [value]="option.value"
      (onSelectionChange)="optionSelected.emit($event)"
      [attr.data-testid]="option.name"
    >
      <div class="medis-flex-container-between">
        <span *ngIf="trans">{{ option.name | translate }}</span>
        <span *ngIf="!trans">{{ option.name }}</span>
        <i
          *ngIf="
            (multiple &&
              selectedValue &&
              selectedValue.includes &&
              selectedValue.includes(option.value)) ||
            option.value === selectedValue
          "
          class="fa fa-check"
        ></i>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
