<mat-expansion-panel>
  <mat-expansion-panel-header
    [ngClass]="{
      'followup-list': history.category === 'fu_response',
      'bounce-list': history.category === 'bounce_detected',
      'error-list': history.category === 'error'
    }"
  >
    <mat-panel-title>
      <button mat-icon-button>
        <i class="material-icons" *ngIf="history.category === 'fu_response'">
          notifications_active
        </i>
        <i class="material-icons" *ngIf="['bounce_detected', 'error'].includes(history.category)">
          error
        </i>
      </button>
      <span
        class="history-title"
        *ngIf="leftBarHistoryCategories.includes(history.category)"
        [matTooltip]="history.createdTs | dateChecker"
      >
        {{ leftBarHistoryCategoriesMap[history.category] | translate }}
        <br />
        <span>{{ 'ON' | translate }} {{ history.createdTs | dateCheckerNoHour }}</span>
      </span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="accordion-sub-content text-center">
    <div *ngIf="history.mergedFromInteractionId" class="medis-flex-container-center mb-1">
      <i class="fa fa-info-circle text-14-azure me-1" aria-hidden="true"></i>
      <a routerLink="/detail/{{ history.mergedFromInteractionId }}" class="clickable">
        {{ 'MERGED_FROM' | translate }} {{ 'INTERACTION' | translate }}
        {{ history.mergedFromInteractionId }}
      </a>
    </div>
    <div
      class="question-text"
      *ngIf="history?.text && (history.category == 'fu_response' || history.category == 'error')"
      [innerHtml]="history?.text | sanitizeHtml"
    ></div>
    <div class="question-text" *ngIf="history?.text && history.category == 'bounce_detected'">
      {{ 'ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_' | translate }}
    </div>
    <div *ngFor="let link of attachments">
      <app-link-visualization
        [link]="link"
        [allowRemove]="false"
        [style]="'compact'"
        [canOpenFile]="loggedInUserIsSysAdmin || !link.isDeleted"
      ></app-link-visualization>
    </div>
    <button mat-button class="medis-button-basic mt-2" (click)="openHistory()">
      <i class="fas fa-eye"></i>
      {{ 'SHOW_ORIGINAL_MESSAGE' | translate }}
    </button>
    <button
      *ngIf="history.category === 'fu_response' && !isInteractionClosedOrInReview"
      mat-button
      class="medis-button-basic mt-2"
      (click)="createQuestionFromFU(history.pk())"
      [matTooltip]="'CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP' | translate"
    >
      <i class="fas fa-plus"></i>
      {{ 'ADD_AS_A_NEW_QUESTION' | translate }}
    </button>
  </div>
</mat-expansion-panel>
