<div class="content-container">
  <ng-template #loadingContainer>
    <div>
      <h5 id="loading-title">
        <i class="fa fa-spinner fa-spin"></i> {{ 'LOADING' | translate }}...
      </h5>
    </div>
  </ng-template>
  <ng-container
    *ngIf="
      data?.htmlChanges || data?.originalDetailValue || data?.newDetailValue;
      else loadingContainer
    "
  >
    <mat-accordion>
      <mat-expansion-panel
        *ngIf="data?.htmlChanges"
        (opened)="panelOpenState = false"
        (closed)="panelOpenState = true"
        [expanded]="!panelOpenState"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="text-16-800 d-flex align-items-center">
            {{ 'HTML_CHANGES' | translate }}
            <i class="ms-2 fab fa-html5 medis-icon-toolbar"></i>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [innerHtml]="data.htmlChanges | sanitizeHtml"></div>
      </mat-expansion-panel>

      <mat-expansion-panel
        [disabled]="!data?.htmlChanges"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        [expanded]="panelOpenState"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="text-16-800 d-flex align-items-center">
            {{ 'SIDE_BY_SIDE_VIEW' | translate }}
            <i class="ms-2 fas fa-exchange-alt medis-icon-toolbar"></i>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col" class="text-18-800 text-center">
                  {{ 'ORIGINAL_VALUE' | translate }}
                </th>
                <th scope="col" class="text-18-800 text-center">{{ 'NEW_VALUE' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="w-50" [innerHTML]="data.originalDetailValue | sanitizeHtml"></td>
                <td class="w-50" [innerHTML]="data.newDetailValue | sanitizeHtml"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</div>
