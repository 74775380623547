import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  BRole,
  BTeam,
  NomenclaturesService,
  Scopes,
} from 'app/modules/data-model/data-model.module';
import { Config, newRoleIdConfig, newTeamIdConfig } from '../search-select/search-select.config';
import { MessageHandlerService } from '../message-handler/message-handler.service';
import { BAffiliation } from 'app/modules/data-model/user/affiliation.base';
import { AffiliationsRequest } from 'app/modules/data-model/auth-request/auth-request.service';

export type Affiliation = {
  team: BTeam;
  roles: BRole[];
};

export namespace Affiliation {
  export function fromBAffiliations(sources: BAffiliation[]): Affiliation[] {
    const result: Affiliation[] = [];
    for (const s of sources) {
      const targetAff = result.find((a) => a.team.code === s.team.code);
      if (targetAff) {
        targetAff.roles.push(s.role);
      } else {
        result.push({ team: s.team, roles: [s.role] });
      }
    }
    return result;
  }

  export function toAffiliationsRequest(sources: Affiliation[]): AffiliationsRequest {
    return sources.reduce((res, a) => {
      res[a.team.pk()] = a.roles.map((r) => r.pk());
      return res;
    }, {});
  }
}

type InternalAffiliation = {
  id: number;
  teamId: number;
  roleIds: number[];
};

@Component({
  selector: 'app-affiliation-picker',
  templateUrl: './affiliation-picker.component.html',
})
export class AffiliationPickerComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() set selectedValues(vals: Affiliation[]) {
    this.affiliations = vals?.length
      ? this.toInternal(vals)
      : [{ id: 1, teamId: undefined, roleIds: [] }];
  }
  @Output() selectedValuesChange = new EventEmitter<Affiliation[]>();

  roleConfig: Config = newRoleIdConfig([], 'ROLES');
  teamConfig: Config = newTeamIdConfig([], 'TEAM');
  affiliations: InternalAffiliation[] = [{ id: 1, teamId: undefined, roleIds: [] }];
  hasFullyDefinedAffiliation = false;

  private sub: Subscription;
  private teams: BTeam[] = [];
  private roles: BRole[] = [];

  constructor(
    private nomenclaturesService: NomenclaturesService,
    private messageService: MessageHandlerService
  ) {}

  ngOnInit(): void {
    this.sub = this.nomenclaturesService.get(new Scopes().teams().roles()).subscribe({
      next: (resp) => {
        this.roleConfig = newRoleIdConfig((this.roles = resp.roles), 'ROLES');
        this.teamConfig = newTeamIdConfig((this.teams = resp.teams), 'TEAM');
      },
      error: (err) => this.messageService.httpError('Load Teams and Roles', err),
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  addAffiliation(): void {
    this.affiliations.push({ id: new Date().getTime(), teamId: undefined, roleIds: [] });
  }

  removeAffiliation(id: number): void {
    this.affiliations.splice(
      this.affiliations.findIndex((a) => a.id === id),
      1
    );
    this.onChange();
  }

  onChange(): void {
    this.hasFullyDefinedAffiliation = this.affiliations.some((a) => a.teamId && a.roleIds?.length);
    this.selectedValuesChange.emit(this.fromInternal(this.affiliations));
  }

  private toInternal(affiliations: Affiliation[]): InternalAffiliation[] {
    let id = 1;
    return affiliations.map((a) => {
      return {
        id: id++,
        teamId: a.team?.pk(),
        roleIds: a.roles ? a.roles.map((r) => r.pk()) : [],
      };
    });
  }

  private fromInternal(affiliations: InternalAffiliation[]): Affiliation[] {
    return affiliations.map((a) => {
      return {
        team: this.teams.find((t) => t.pk() === a.teamId),
        roles: a.roleIds ? a.roleIds.map((rId) => this.roles.find((r) => r.pk() === rId)) : [],
      };
    });
  }
}
