import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { Observable } from 'rxjs';
import { SimplePage } from 'app/common/types';
import { Ajax } from 'app/common/ajax';
import { map } from 'rxjs/operators';
import { BDomain, TDomainData, TDomainParams } from './domain';
import { isNil, omitBy } from 'lodash';

@Injectable()
export class DomainService {
  private readonly URL: string;

  constructor(private http: HttpClient, private urlResolverService: UrlResolverService) {
    this.URL = urlResolverService.misApiUrlForPath('/domain');
  }

  get(params: TDomainParams): Observable<SimplePage<BDomain>> {
    return this.http
      .get<SimplePage<BDomain>>(this.URL, {
        ...Ajax.X_NO_LOADING_OPTIONS,
        params: omitBy(params, isNil),
      })
      .pipe(
        map((response) => {
          response.records = BDomain.fromRestArray(response.records);
          return response;
        })
      );
  }

  create(data: TDomainData): Observable<void> {
    return this.http.post<void>(this.URL, omitBy(data, isNil));
  }

  edit(data: TDomainData): Observable<void> {
    return this.http.put<void>(this.URL, omitBy(data, isNil));
  }
}
