import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlResolverService } from 'app/common/url-resolver.service';

@Injectable({
  providedIn: 'root',
})
export class MedicalDocumentsService {
  private readonly URL: string;
  private readonly USAGES_URL: string;

  constructor(private http: HttpClient, urlResolver: UrlResolverService) {
    this.URL = urlResolver.misApiUrlForPath('/medical-documents/');
    this.USAGES_URL = urlResolver.misApiUrlForPath('/medical-documents/usages/');
  }

  setStatus(id: number, name: 'Approved' | 'Archived'): Observable<string> {
    return this.http.put<string>(this.URL, { id: id, status: name });
  }

  addLink(
    documentId: number,
    sectionIds: number[],
    outputFormat: string,
    historyId: number,
    isDraft: boolean
  ): Observable<string> {
    return this.http.put<string>(this.USAGES_URL + documentId, {
      sectionIds: sectionIds,
      outputFormat: outputFormat,
      historyId: historyId,
      isDraft: isDraft,
    });
  }

  removeLink(documentId: number, historyId: number, isDraft: boolean): Observable<string> {
    return this.http.delete<string>(
      `${this.USAGES_URL}${documentId}?historyId=${historyId}&isDraft=${isDraft}`
    );
  }
}
