import { Directive, ViewChild } from '@angular/core';
import { tinyMceOptions } from 'app/common/tiny-mce-options';
import { UploadAttachmentComponent } from './upload-attachment.component';

@Directive()
export abstract class AbstractUploadComponent {
  @ViewChild('uploadComponent') uploadComponent: UploadAttachmentComponent;
  activeEditor: any;

  tinymceOptions = tinyMceOptions();

  constructor() {
    const component = this;
    this.tinymceOptions['setup'] = (editor: any) => {
      editor.addButton('upload-medis', {
        text: 'Upload',
        icon: 'upload',
        tooltip: 'Upload a file',
        onclick: () => {
          component.uploadComponent.openAttachment();
        },
      });
      editor.addButton('upload-image-medis', {
        text: 'Image',
        icon: 'image',
        tooltip: 'Upload an image',
        onclick: () => {
          this.activeEditor = editor;
          component.uploadComponent.openAttachmentImage();
        },
      });
    };
  }
}
