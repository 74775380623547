import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ajax } from 'app/common/ajax';
import { UrlResolverService } from 'app/common/url-resolver.service';
import {
  BEmailNotificationsTemplate,
  HolidayGDPRTemplateDataType,
} from './email-notifications-template';

@Injectable()
export class EmailNotificationsTemplateService {
  private readonly URL: string;
  private readonly HOLIDAY_TEMPLATES_URL: string;
  constructor(private http: HttpClient, urlResolverService: UrlResolverService) {
    this.URL = urlResolverService.misApiUrlForPath('/templates/emails');
    this.HOLIDAY_TEMPLATES_URL = urlResolverService.misApiUrlForPath(
      '/templates/holiday-templates'
    );
  }

  getAllTemplates(teamId: string): Observable<BEmailNotificationsTemplate[]> {
    const query = new Ajax.QueryParams().add('teamId', teamId).toString();
    return this.http.get<BEmailNotificationsTemplate[]>(this.URL + query).pipe(
      map((resp) => {
        return BEmailNotificationsTemplate.fromRestArray(resp);
      })
    );
  }

  createTemplate(data: Partial<BEmailNotificationsTemplate>): Observable<void> {
    return this.http.post<void>(this.URL, data);
  }

  editTemplate(data: Partial<BEmailNotificationsTemplate>): Observable<void> {
    return this.http.put<void>(this.URL, data);
  }

  softDelete(id: string): Observable<void> {
    const data: Partial<BEmailNotificationsTemplate> = {
      id,
      isDeleted: true,
    };
    return this.http.put<void>(this.URL, data);
  }

  /* Holiday GDPR templates */
  getAllHolidayGDPRTemplates(): Observable<BEmailNotificationsTemplate[]> {
    return this.http
      .get<BEmailNotificationsTemplate[]>(this.HOLIDAY_TEMPLATES_URL, {
        ...Ajax.X_NO_LOADING_OPTIONS,
      })
      .pipe(
        map((templates) => {
          return BEmailNotificationsTemplate.fromRestArray(templates);
        })
      );
  }

  createHolidayGDPRTemplate(data: HolidayGDPRTemplateDataType): Observable<void> {
    return this.http.post<void>(this.HOLIDAY_TEMPLATES_URL, data);
  }

  editHolidayGDPRTemplate(data: HolidayGDPRTemplateDataType): Observable<void> {
    return this.http.put<void>(this.HOLIDAY_TEMPLATES_URL, data);
  }

  softDeleteHolidayGDPRTemplate(deleteAll: boolean, id?: string): Observable<void> {
    return this.http.put<void>(
      this.HOLIDAY_TEMPLATES_URL,
      deleteAll ? { deleteAll: true } : { id }
    );
  }
}
