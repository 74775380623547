import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ajax } from 'app/common/ajax';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { BUserTemplate } from './user-template';

@Injectable()
export class UserTemplatesService {
  private readonly URL: string;

  constructor(private http: HttpClient, urlResolverService: UrlResolverService) {
    this.URL = urlResolverService.misApiUrlForPath('/templates/users');
  }

  get(teamId?: number, roleId?: number): Observable<BUserTemplate[]> {
    const query = new Ajax.QueryParams()
      .addIfPresent('teamId', teamId)
      .addIfPresent('roleId', roleId)
      .toString();
    return this.http
      .get<BUserTemplate[]>(this.URL + query)
      .pipe(map((resp) => BUserTemplate.fromRestArray(resp)));
  }

  create(
    userId: number,
    name: string,
    includeNotifications: boolean,
    includeTeams: boolean,
    overrideNotifications: boolean
  ): Observable<number> {
    const data = {
      userId: userId,
      name: name,
      includeNotifications: includeNotifications,
      includeTeams: includeTeams,
      overrideNotifications: overrideNotifications,
    };
    return this.http.post<number>(this.URL, data);
  }

  set(userId: number, templateId: number): Observable<string> {
    return this.http.patch<string>(this.URL, { userId: userId, templateId: templateId });
  }

  setAsDefault(templateId: number, roleId: number, teamId: number): Observable<string> {
    return this.http.put<string>(this.URL, {
      templateId: templateId,
      roleId: roleId,
      teamId: teamId,
    });
  }

  archive(templateId: number): Observable<string> {
    return this.http.delete<string>(this.URL + '?templateId=' + templateId);
  }
}
