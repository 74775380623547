import { Component, Input, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { BMedicalDocument } from 'app/modules/data-model/medical-document/medical-document';
import { BInteraction, BInquiry, BHistory } from 'app/modules/data-model/interaction/interaction';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import {
  InquiriesService,
  MedicalDocumentsService,
} from 'app/modules/data-model/data-model.module';
import { base64ToPK } from 'app/modules/data-model/data-model';

@Component({
  selector: 'app-related-content-link',
  templateUrl: './related-content-link.component.html',
})
export class RelatedContentLinkComponent implements OnDestroy {
  _document: BMedicalDocument;
  _interaction: BInteraction;
  @Input() history: BHistory;
  copiedText: Subject<string> = new Subject<string>();
  visualization: string;
  selectedInquiry: BInquiry;
  @Input() adminMode: boolean = false;

  private subs: Subscription = new Subscription();

  constructor(
    private medDocsService: MedicalDocumentsService,
    private inquiriesService: InquiriesService,
    private messageService: MessageHandlerService
  ) {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  @Input()
  set document(doc: BMedicalDocument) {
    this._document = doc;
    if (doc) {
      if (this.document.type && ['srd', 'faq'].indexOf(this.document.type.code) >= 0) {
        this.visualization = 'srd-faq';
      }
      if (this.document.type && this.document.type.code == 'slide') {
        this.visualization = 'slide';
      }
      if (
        this.document.type &&
        ['smpc', 'pil', 'other', 'lit'].indexOf(this.document.type.code) >= 0
      ) {
        this.visualization = 'smpc-literature-other';
      }
    }
  }

  get document(): BMedicalDocument {
    return this._document;
  }

  @Input()
  set interaction(int: BInteraction) {
    this._interaction = int;
    if (int) {
      this.visualization = 'interaction';
    }
  }

  get interaction(): BInteraction {
    return this._interaction;
  }

  attachDocumentLink(outputFormat: string, successListener: Function): void {
    let sectionIds = [];
    if (['srd', 'faq', 'slide'].includes(this.document.type.code)) {
      sectionIds = this.document.currentVersion.word.sections
        .filter((s) => s['selected'])
        .map((s) => s.pk());
    }
    this.subs.add(
      this.medDocsService
        .addLink(
          this.document.pk(),
          sectionIds,
          outputFormat,
          this.history.pk(),
          this.history.isNew()
        )
        .subscribe({
          next: () => {
            successListener();
          },
          error: (error) => {
            this.messageService.httpError('Attach Document Link', error);
          },
        })
    );
  }

  copyAnswer(): void {
    this.subs.add(
      this.inquiriesService
        .copyAnswerFrom(
          base64ToPK(this.history.inquiryId),
          this.history.isNew(),
          this.selectedInquiry.pk()
        )
        .subscribe({
          next: () => {
            this.copiedText.next(this.selectedInquiry.answer.text);
          },
          error: (error) => {
            this.messageService.httpError('Copy Answer', error);
          },
        })
    );
  }
}
