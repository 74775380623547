import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BInteractionAnswer } from '../interaction/interaction';

import { singleInteractionAnswerQuery } from './queries';

@Injectable({
  providedIn: 'root',
})
export class SingleInteractionAnswerService {
  /*
   * Service to retrieve the detail data of an InteractionAnswer
   */
  response: Subject<BInteractionAnswer> = new Subject<BInteractionAnswer>();

  constructor(private apollo: Apollo) {}

  performQuery(pk: string) {
    const query = this.apollo.query({
      query: singleInteractionAnswerQuery,
      variables: { id: pk },
      fetchPolicy: 'network-only',
    });
    query.subscribe(
      (response) => {
        this.response.next(new BInteractionAnswer(response.data['interactionAnswer']));
      },
      (error) => {
        console.error('Error', error);
      }
    );
  }
}
