<div
  *ngIf="link"
  class="link-container"
  [ngClass]="style ? style : ''"
  [matTooltip]="
    tooltipText +
    (isDeletedAttachment ? ' - ' + ('ATTACHMENT_DELETED' | translate | lowercase) : '')
  "
  [matTooltipDisabled]="!tooltipText"
>
  <div
    class="medis-flex-container-even"
    (click)="openLink()"
    [ngClass]="canOpenFile ? 'medis-clickable' : 'medis-non-clickable'"
  >
    <i class="{{ typeClass }} medis-mr-5"></i>
    <i *ngIf="linkClass" class="far {{ linkClass }} medis-mr-5"></i>
    <span class="text-14-500-dark medis-flex-grow truncated-text">{{ visualizeLink(link) }}</span>
    <i
      *ngIf="allowRemove"
      class="fa fa-times-circle"
      (click)="$event.stopPropagation(); deleteLink(link)"
    ></i>
  </div>
</div>
