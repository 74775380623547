import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BInquiry, BInteraction } from '../data-model/interaction/interaction';

@Injectable({ providedIn: 'root' })
export class ActiveInquiryService {
  interaction: Subject<BInteraction> = new Subject<BInteraction>();
  activeInquiry: Subject<BInquiry> = new Subject<BInquiry>();
  inquirySelectedFromLeftBar: Subject<BInquiry> = new Subject<BInquiry>();

  private last: BInteraction;
  private lastInquirieIds: string[];

  constructor() {
    this.interaction.subscribe((interaction) => {
      // if a new interaction is set, update the active inquiry with the first one
      const isNewInteractionOrInquiryDeleted =
        !this.last ||
        interaction.id !== this.last.id ||
        this.last.inquiries.length > interaction.inquiries.length;
      if (isNewInteractionOrInquiryDeleted && interaction.inquiries.length > 0) {
        this.activeInquiry.next(interaction.inquiries[0]);
      } else if (
        this.lastInquirieIds &&
        this.lastInquirieIds.length < interaction.inquiries.length
      ) {
        const addedInquiry = interaction.inquiries.find(
          (i) => !this.lastInquirieIds.includes(i.id)
        );
        if (addedInquiry) {
          this.activeInquiry.next(addedInquiry);
        }
      }
      this.last = interaction;
      this.lastInquirieIds = interaction.inquiries.map((i) => i.id);
    });
  }
}
