<div class="top-group">
  <span>
    <button
      mat-button
      *ngIf="selectedFiltersCount > 0"
      class="medis-button-white"
      [matMenuTriggerFor]="filters"
    >
      {{ 'RESET_FILTERS' | translate }} ( {{ selectedFiltersCount }} )
    </button>
    <button
      mat-button
      *ngIf="selectedFiltersCount === 0"
      class="medis-button-white"
      matTooltip="{{ 'INBOX_NO_FILTERS_HINT' | translate }}"
    >
      {{ 'RESET_FILTERS' | translate }} ( 0 )
    </button>
  </span>
</div>

<mat-menu #filters="matMenu" class="medis-menu" overlapTrigger="false">
  <button mat-menu-item *ngIf="selectedFiltersCount > 1" (click)="resetFilters('ALL')">
    <i class="fas fa-ban"></i>{{ 'Reset' | translate }} {{ 'ALL' | translate }}
  </button>
  <span *ngFor="let propToTranslation of filterToTranslation">
    <button
      mat-menu-item
      *ngIf="isSpecified(propToTranslation.name)"
      (click)="resetFilters(propToTranslation.name)"
    >
      <i class="fas fa-ban"></i>{{ 'Reset' | translate }} {{ propToTranslation.value | translate }}
    </button>
  </span>
</mat-menu>
