import { BaseModel, rProperty } from '../data-model';

export class BHcpTypes extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;

  init(json: any) {}

  static fromRest(json: any): BHcpTypes {
    return Object.assign(new BHcpTypes({}), json);
  }

  static fromRestArray(json: any[]): BHcpTypes[] {
    return json && json.map((v) => BHcpTypes.fromRest(v));
  }
}
