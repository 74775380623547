import { gql } from 'apollo-angular';

import { BaseModel, rProperty } from '../data-model';

export class BGeography extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {}
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment geographyFragment on GeographyNode {
    id
    name
  }
`;

export const fragmentConnection = gql`
  fragment geographyConnectionFragment on GeographyNodeConnection {
    edges {
      node {
        ...geographyFragment
      }
    }
  }
  ${fragment}
`;
