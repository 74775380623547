import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BCountry, BLanguage } from 'app/modules/data-model/data-model.module';
import { tinyMceOptions } from 'app/common/tiny-mce-options';
import {
  BEmailNotificationsTemplate,
  SubmissionCondition,
  TemplateCondition,
  TemplateType,
} from 'app/modules/data-model/email-notifications-template/email-notifications-template';
import { Config, newCountryConfig, newLanguageConfig } from '../search-select/search-select.config';
import { BehaviorSubject } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-email-notification-template-dialog',
  templateUrl: './email-notification-template-dialog.component.html',
  styleUrls: ['./email-notification-template-dialog.component.scss'],
})
export class EmailNotificationTemplateDialogComponent {
  tinymceOptions = tinyMceOptions();
  formValid: boolean = false;
  langConfig = new BehaviorSubject<Config>(null);
  countryConfig = new BehaviorSubject<Config>(null);
  subject: string;
  templateName: string;
  templateText: string;
  selectedLanguageId: string;
  selectedCountryId: string;
  isNotificationTypeTemplate: boolean;
  isOffLabel: boolean;
  isBulkUpdate: boolean;
  isFullAnswer: boolean;
  isDefault: boolean;

  constructor(
    public dialogRef: MatDialogRef<EmailNotificationTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: Partial<
      BEmailNotificationsTemplate & {
        allTemplates: BEmailNotificationsTemplate[];
        langConfig: BLanguage[];
        countryConfig: BCountry[];
        maxTemplateCountAllowed: number;
        isBulkUpdate?: boolean;
        isFullAnswer?: boolean;
      }
    >
  ) {
    this.subject = this.data.subject;
    this.templateName = this.data.name;
    this.templateText = this.data.text;
    this.selectedLanguageId = this.data.languageId;
    this.selectedCountryId = this.data.countryId;
    this.isNotificationTypeTemplate = this.data.type === TemplateType.NOTIFICATION;
    this.isOffLabel = this.data.condition === TemplateCondition.OFF_LABEL;
    this.isBulkUpdate = this.data.isBulkUpdate;
    this.isFullAnswer = this.isNotificationTypeTemplate && this.data.isFullAnswer;
    this.isDefault = this.data.isDefault;
    this.langConfig.next(
      newLanguageConfig(
        (this.data.langConfig || []).filter((configItem) =>
          this.getAvailableLanguages(
            configItem,
            this.data.allTemplates,
            this.data.maxTemplateCountAllowed
          )
        )
      )
    );

    this.countryConfig.next(
      newCountryConfig(
        (this.data.countryConfig || []).filter((configItem) =>
          this.getAvailableCountries(
            configItem,
            this.data.allTemplates,
            this.data.maxTemplateCountAllowed
          )
        )
      )
    );
  }

  toggleOffLabel(event: MatSlideToggleChange): void {
    this.isOffLabel = event.checked;
    this.langConfig.next(
      newLanguageConfig(
        (this.data.langConfig || []).filter((configItem) =>
          this.getAvailableLanguages(
            configItem,
            this.data.allTemplates,
            this.data.maxTemplateCountAllowed
          )
        )
      )
    );

    this.countryConfig.next(
      newCountryConfig(
        (this.data.countryConfig || []).filter((configItem) =>
          this.getAvailableCountries(
            configItem,
            this.data.allTemplates,
            this.data.maxTemplateCountAllowed
          )
        )
      )
    );
  }

  onCountryChange(event: string): void {
    this.selectedCountryId = event;
    this.langConfig.next(
      newLanguageConfig(
        this.data.langConfig.filter((configItem) =>
          this.getAvailableLanguages(
            configItem,
            this.data.allTemplates,
            this.data.maxTemplateCountAllowed
          )
        )
      )
    );
    if (!this.langConfig.getValue().rawItems.find((item) => item.id === this.selectedLanguageId)) {
      this.selectedLanguageId = null;
    }
  }

  onLanguageChange(event: string): void {
    this.selectedLanguageId = event;
    this.countryConfig.next(
      newCountryConfig(
        this.data.countryConfig.filter((configItem) =>
          this.getAvailableCountries(
            configItem,
            this.data.allTemplates,
            this.data.maxTemplateCountAllowed
          )
        )
      )
    );
    if (
      !this.countryConfig.getValue().rawItems.find((item) => item.id === this.selectedCountryId)
    ) {
      this.selectedCountryId = null;
    }
  }

  save(): void {
    if (!this.isValidForm()) {
      throw new Error('Invalid form submition attempted!');
    }
    const secondTemplate = this.data.allTemplates.find(
      (template) =>
        template.countryId === this.selectedCountryId &&
        template.languageId === this.selectedLanguageId
    );

    const templateData: Partial<BEmailNotificationsTemplate> = {
      subject: this.subject,
      name: this.templateName,
      text: this.templateText,
      languageId: this.selectedLanguageId,
      countryId: this.selectedCountryId,
      condition: this.isOffLabel ? TemplateCondition.OFF_LABEL : TemplateCondition.ON_LABEL,
      submissionCondition:
        secondTemplate && secondTemplate.submissionCondition === SubmissionCondition.DIRECT
          ? SubmissionCondition.VIA_COMPANY_EMPLOYEE
          : SubmissionCondition.DIRECT,
    };
    this.dialogRef.close(templateData);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  private isValidForm(): boolean {
    this.formValid = Boolean(
      this.subject &&
        this.templateName &&
        this.templateText &&
        this.selectedCountryId &&
        this.selectedLanguageId
    );
    return this.formValid;
  }

  private getAvailableLanguages(
    configItem: BLanguage,
    allTemplates: BEmailNotificationsTemplate[],
    maxTemplateCount: number
  ): boolean {
    const templates = this.isNotificationTypeTemplate
      ? allTemplates.filter((t) =>
          this.isOffLabel
            ? t.condition === TemplateCondition.OFF_LABEL
            : t.condition === TemplateCondition.ON_LABEL
        )
      : allTemplates;
    const currentTemplatesCount = templates.filter(
      (template) =>
        template.languageId === configItem.id && template.countryId === this.selectedCountryId
    ).length;

    return currentTemplatesCount < maxTemplateCount;
  }

  private getAvailableCountries(
    configItem: BCountry,
    allTemplates: BEmailNotificationsTemplate[],
    maxTemplateCount: number
  ): boolean {
    const templates = this.isNotificationTypeTemplate
      ? allTemplates.filter((t) =>
          this.isOffLabel
            ? t.condition === TemplateCondition.OFF_LABEL
            : t.condition === TemplateCondition.ON_LABEL
        )
      : allTemplates;
    const currentTemplatesCount = templates.filter(
      (template) =>
        template.countryId === configItem.id && template.languageId === this.selectedLanguageId
    ).length;

    return currentTemplatesCount < maxTemplateCount;
  }
}
