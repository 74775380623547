import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { Ajax } from 'app/common/ajax';
import { BInquirer } from './inquirer';
import { BAlignment } from '../user/user';
import { isNil, omitBy } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class InquirersService {
  private URL: string;
  private URL_NOTIFICATION_TEXT: string;
  private URL_RELATED_INTERACTIONS_AND_THEIR_ASSIGNED_USERS_TEXT: string;

  constructor(private http: HttpClient, urlResolver: UrlResolverService) {
    this.URL = urlResolver.misApiUrlForPath('/inquirers/');
    this.URL_NOTIFICATION_TEXT = urlResolver.misApiUrlForPath('/inquirers/notifications');
    this.URL_RELATED_INTERACTIONS_AND_THEIR_ASSIGNED_USERS_TEXT = urlResolver.misApiUrlForPath(
      '/inquirers/related-interactions-data-after-country-change'
    );
  }

  get(names: string): Observable<BInquirer[]> {
    const query = new Ajax.QueryParams().add('names', names).toString();
    return this.http
      .get<any>(this.URL + query, Ajax.X_NO_LOADING_OPTIONS)
      .pipe(map((resp) => BInquirer.fromRestArray(resp)));
  }

  getTerritory(territory: string): Observable<BAlignment[]> {
    const query = new Ajax.QueryParams().add('territory', territory).toString();
    return this.http
      .get<any>(this.URL + query, Ajax.X_NO_LOADING_OPTIONS)
      .pipe(map((resp) => BAlignment.fromRestArray(resp)));
  }

  getInquirerNotificationText(data: IInquirerNotificationData): Observable<string> {
    let params = new HttpParams();
    for (const key in data) {
      if (data[key]) {
        params = params.set(key, data[key]);
      }
    }
    return this.http.get<string>(this.URL_NOTIFICATION_TEXT, { params });
  }

  getInquirerRelatedInteractionsData(
    params: InquirerRelatedInteractionsParamsType
  ): Observable<IInquirerRelatedInteractionsDataResponse> {
    return this.http.get<IInquirerRelatedInteractionsDataResponse>(
      this.URL_RELATED_INTERACTIONS_AND_THEIR_ASSIGNED_USERS_TEXT,
      { params: omitBy(params, isNil) }
    );
  }

  updateInquirerNotification(
    requestBody: IInquirerNotificationRequestData
  ): Observable<IInquirerNotificationResponse> {
    return this.http.post<IInquirerNotificationResponse>(this.URL_NOTIFICATION_TEXT, requestBody);
  }
}

export interface IInquirerNotificationData {
  action: string;
  interactionId: string;
  inquirerCountryId: string;
  inquirerLanguageId: string;
  inquirerEmail: string;
}

export interface IInquirerNotificationRequestData {
  interactionId: string;
  inquirerNeedNotification: boolean;
}

export interface IInquirerNotificationResponse {
  success: boolean;
  msg: boolean;
}

export interface IInquirerRelatedInteractionsDataResponse {
  removeAssignTo: boolean;
  interactionIdToCountryData: {
    [key: number]: { [key: string]: string };
  };
}

export type InquirerRelatedInteractionsParamsType = {
  interactionId: string | null;
  inquirerId: string;
  inquirerCountryId: string;
};
