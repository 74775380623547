import { BaseModel, parseAttr, rProperty } from '../data-model';
import { BRole } from '../role/role';
import { BTeam, BUser } from './user';

export class BAffiliation extends BaseModel {
  @rProperty() id: string;
  @rProperty() statusName: string;
  authRequestId?: number;

  //relations
  role: BRole;
  user: BUser;
  team: BTeam;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.role = parseAttr<BRole>(json, BRole, 'role');
    this.user = parseAttr<BUser>(json, BUser, 'user');
    this.team = parseAttr<BTeam>(json, BTeam, 'team');
  }

  static fromRest(json: any): BAffiliation {
    json.role = BRole.fromRest(json.role);
    json.team = BTeam.fromRest(json.team);
    return Object.assign(new BAffiliation({}), json);
  }

  static fromRestArray(json: any[]): BAffiliation[] {
    return json && json.map((v) => BAffiliation.fromRest(v));
  }

  isApproved(): boolean {
    return this.statusName === 'Approved';
  }
}
