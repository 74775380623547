import { gql } from 'apollo-angular';

export const singleInteractionAnswerQuery = gql`
  query interactionAnswerQuery($id: ID!) {
    interactionAnswer(id: $id) {
      id
      answerPreview
    }
  }
`;
