import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import * as MSpecialization from './specialization';
import { BSpecialization } from './specialization';
import { specializationQuery } from './queries';
import { BaseDataService } from '../data-service';

import { HttpClient } from '@angular/common/http';
import { UrlResolverService } from 'app/common/url-resolver.service';
import {
  ISpecializationAddEditDataModel,
  ISpecializationDataModel,
  ISpecializationViewModel,
} from '../../administration/administration-specializations/models/specialization.model';
import { ESpecializationType } from '@mi-tool/enums/specialization.enum';
import { SimplePage } from '../../../common/types';
import { IOrderBy } from '@mi-tool/models/sort-model';
import { ICountrySpecificModel } from '../../administration/administration-specializations/models/country-specific.model';
import { base64ToPK, pkToBase64 } from '../data-model';
import { map } from 'rxjs/operators';

@Injectable()
export class SpecializationService extends BaseDataService<MSpecialization.BSpecialization> {
  query = specializationQuery;
  objectInstance = new MSpecialization.BSpecialization({});
  fieldName = 'allSpecializations';
  specializationChanged: Subject<MSpecialization.BSpecialization> =
    new Subject<MSpecialization.BSpecialization>();

  private readonly SPECIALIZATIONS_URL: string;
  private readonly COUNTRY_SPECIFIC_URL: string;

  constructor(
    private http: HttpClient,
    urlResolverService: UrlResolverService,
    injector: Injector
  ) {
    super(injector);
    this.SPECIALIZATIONS_URL = urlResolverService.misApiUrlForPath('/specializations/');
    this.COUNTRY_SPECIFIC_URL = urlResolverService.misApiUrlForPath('/specializations/countries');
  }

  getAll(
    searchText: string,
    filters: ISpecializationViewModel,
    pageIndex: number,
    pageSize: number
  ): Observable<SimplePage<ISpecializationDataModel>> {
    const params = {
      pageIndex,
      pageSize,
      ...this.prepFiltersForSearch(filters),
      ...(searchText && { search: searchText }),
    };
    return this.http
      .get<SimplePage<ISpecializationDataModel>>(`${this.SPECIALIZATIONS_URL}`, {
        params,
        headers: { 'X-NO-LOADING': 'true' },
      })
      .pipe(
        map((resp) => {
          resp.records.map((iSpec) => {
            iSpec.id = base64ToPK('' + iSpec.id);
            return iSpec;
          });

          return resp;
        })
      );
  }

  updateSpecialization(specialization: ISpecializationDataModel) {
    const body = this.getPreparedBody(specialization);
    return this.http.put(`${this.SPECIALIZATIONS_URL}`, body);
  }

  createSpecialization(specialization: ISpecializationDataModel) {
    const body = this.getPreparedBody(specialization);
    return this.http.post(this.SPECIALIZATIONS_URL, body);
  }

  private getPreparedBody(
    specialization: ISpecializationDataModel
  ): ISpecializationAddEditDataModel {
    const result: ISpecializationAddEditDataModel = {};
    const excludedProps = ['orderBy', 'actions'];

    Object.keys(specialization)
      .filter((key) => !excludedProps.includes(key))
      .forEach((key) => {
        const newKey = this.getCorrectFieldName(key);
        this.addBodyFieldValue(newKey, specialization[key], result);
      });

    return result;
  }

  getFilteredSpecializations(
    type: ESpecializationType,
    specializations: BSpecialization[]
  ): BSpecialization[] {
    return specializations.filter((spec) => !type || !type.length || type.includes(spec.typeName));
  }

  setCountrySpecializations(specializations: string[] = [], countries: string[] = []) {
    const body: ICountrySpecificModel = {
      specializationIds: specializations,
      countryIds: countries,
    };

    return this.http.post(this.COUNTRY_SPECIFIC_URL, body);
  }

  private addBodyFieldValue(key: string, value, result) {
    // We have different types of values
    let realValue = value;

    if (Array.isArray(value)) {
      realValue = value.map((val) => val.id || val);
    } else if (value instanceof Object) {
      realValue = value.id;
    }

    // Remove the empty values(false is also a correct value). Else leads to Status 400
    if (realValue || typeof realValue === 'boolean') {
      result[key] = realValue;
    }
  }

  private prepFiltersForSearch(filters: ISpecializationViewModel): Record<string, string> {
    let result = {};
    if (filters) {
      Object.keys(filters).forEach((keyName) => {
        if (keyName === 'id' && filters[keyName]) {
          result['specialization'] = pkToBase64('id', +filters[keyName]);
        } else if (keyName === 'orderBy') {
          result['orderBy'] = this.getOrderByVal(filters[keyName]);
        } else if (
          typeof filters[keyName] === 'boolean' ||
          (Array.isArray(filters[keyName]) && filters[keyName].length) ||
          (!Array.isArray(filters[keyName]) && filters[keyName])
        ) {
          // Skip the undefined/empty values
          result[keyName] = Array.isArray(filters[keyName])
            ? filters[keyName].join(',')
            : filters[keyName];
        }
      });
    }

    return result;
  }

  private getOrderByVal(orderBy: IOrderBy): string {
    const orderByName = this.getCorrectOrderByName(orderBy);
    return orderBy.direction === 'asc' ? orderByName : `-${orderByName}`;
  }

  private getCorrectOrderByName(orderBy: IOrderBy): string {
    switch (orderBy.active) {
      case 'specialization':
        return 'name';
      case 'specializationType':
        return 'type_id';
      case 'hcpType':
        return 'hcp_type__name';
      case 'therapeuticArea':
        return 'therapeutic_area__name';
      default:
        return orderBy.active;
    }
  }

  private getCorrectFieldName(propertyName: string): string {
    switch (propertyName) {
      case 'specializationType':
        return 'specializationTypeId';
      case 'hcpType':
        return 'hcpTypeId';
      case 'therapeuticArea':
        return 'therapeuticAreaId';
      case 'countries':
        return 'countryIds';
      default:
        return propertyName;
    }
  }
}
