<section id="pagination-wrapper-container">
  <mat-paginator
    [length]="pagesCount"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="showFirstLastButtons"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</section>
