import { gql } from 'apollo-angular';

import { base64ToPK, BaseModel, parseArray, parseAttr, rProperty } from '../data-model';

import { BLanguage } from '../language/language';
import { BTherapeuticArea } from '../medical-document/medical-document';
import { BProductGroup } from '../product/product-group';

export class BRegion extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() code: string;
  @rProperty() isMain: boolean;
  isActive = true;

  defaultLanguage: BLanguage;
  products: BProduct[];

  constructor(json: any) {
    super(json);

    this.init(json);
  }

  init(json: any) {
    // manually deserialize relations
    this.defaultLanguage = parseAttr<BLanguage>(json, BLanguage, 'defaultLanguage');
    this.products = parseArray<BProduct>(json, BProduct, 'products');
  }

  static fromRest(json: any): BRegion {
    return Object.assign(new BRegion({}), json);
  }

  static fromRestArray(json: any[]): BRegion[] {
    return json && json.map((v) => BRegion.fromRest(v));
  }

  getMId() {
    return base64ToPK(this.id);
  }

  getMName() {
    return this.name;
  }
}

export namespace MRegion {
  export const fragment = gql`
    fragment regionFragment on RegionNode {
      id
      name
      code
      isMain
    }
  `;

  export const fragmentConnection = gql`
    fragment regionConnectionFragment on RegionNodeConnection {
      edges {
        node {
          ...regionFragment
        }
      }
    }
    ${fragment}
  `;
}

export class BProduct extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() productCode: string;
  @rProperty() licensingPartner: string;
  @rProperty() isActive: boolean;
  @rProperty() importedId: string;
  @rProperty() importedSource: string;
  @rProperty(Date) importedTs: Date;

  region: BRegion[];
  therapeuticAreas: BTherapeuticArea[];
  productGroups: BProductGroup[];
  productTerritories: string;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.region = parseArray<BRegion>(json, BRegion, 'region');
    this.therapeuticAreas = parseArray<BTherapeuticArea>(json, BRegion, 'therapeuticAreas');
    this.productGroups = parseArray<BProductGroup>(json, BProduct, 'productGroups');
  }

  static fromRest(json: any): BProduct {
    return Object.assign(new BProduct({}), json);
  }

  static fromRestArray(json: any[]): BProduct[] {
    return json && json.map((v) => BProduct.fromRest(v));
  }

  getMId() {
    return base64ToPK(this.id);
  }

  getMName() {
    return this.name;
  }

  get therapeuticAreasNames(): string[] {
    return this.therapeuticAreas.map((t) => t.name);
  }
}

export namespace MProduct {
  export const fragment = gql`
    fragment productFragment on ProductNode {
      id
      name
      productCode
      licensingPartner
      isActive
    }
  `;

  export const fragmentConnection = gql`
    fragment productConnectionFragment on ProductNodeConnection {
      edges {
        node {
          ...productFragment
        }
      }
    }
    ${fragment}
  `;
}
