import { gql } from 'apollo-angular';

export const getEntities = gql`
  query getEntities($params: String) {
    entitySearch(params: $params)
  }
`;

export const getRelatedContent = gql`
  query relatedContentSearch($params: String) {
    relatedContentSearch(params: $params)
  }
`;
