import { DateRange, TimeZone } from './types';

export namespace DateUtility {
  export function deserializeDate(serializedValue: string | Date): Date {
    var msec: number;
    if (serializedValue instanceof Date) {
      msec = serializedValue.getTime();
    } else {
      msec = Date.parse(serializedValue);
    }
    var res: Date;
    if (isNaN(msec)) {
      res = null; // since json() already uses null and not undefined when deserializing
      if (serializedValue) {
        console.error('Error deserilizing date: ', serializedValue);
      }
    } else {
      res = new Date(msec);
    }
    return res;
  }

  export function greaterThan(date1: Date, date2: Date): boolean {
    return date1 !== undefined && date2 !== undefined && date1.getTime() > date2.getTime();
  }

  export function newDate(msDiff: number): Date {
    const now = new Date();
    now.setTime(now.getTime() + msDiff);
    return now;
  }

  export function newDateAfterYears(years: number): Date {
    const now = new Date();
    now.setFullYear(now.getFullYear() + years);
    return now;
  }

  export function hasElapsed(date: Date, ms: number): boolean {
    return new Date().getTime() - date.getTime() < ms;
  }

  type DayPoint = 'dayStart' | 'dayEnd';
  export function adjustTime(target: Date | string, point: DayPoint, tz: TimeZone = 'UTC'): Date {
    function copyTzFullYear(target: Date, source: Date): void {
      if (tz === 'UTC') {
        target.setUTCFullYear(source.getFullYear(), source.getMonth(), source.getDate());
      } else {
        target.setFullYear(source.getFullYear(), source.getMonth(), source.getDate());
      }
    }

    function setTzHours(target: Date, hours: number, min: number, sec: number, ms: number): void {
      if (tz === 'UTC') {
        target.setUTCHours(hours, min, sec, ms);
      } else {
        target.setHours(hours, min, sec, ms);
      }
    }

    if (target) {
      const date = typeof target === 'string' ? new Date(target) : target;
      const filterDate = new Date();
      copyTzFullYear(filterDate, date);
      if (point === 'dayStart') {
        setTzHours(filterDate, 0, 0, 0, 0);
      } else {
        setTzHours(filterDate, 23, 59, 59, 999);
      }
      return filterDate;
    }
    return undefined;
  }

  export function adjustTimeStr(target: Date | string, point: DayPoint): string {
    const result = adjustTime(target, point);
    return result ? result.toISOString() : undefined;
  }

  export function adjustDateToTimeZone(target: Date | undefined, timeZone: TimeZone): Date {
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    if (!target || target.getTimezoneOffset() === (timeZone === 'UTC' ? 0 : localTimeZoneOffset)) {
      return target;
    }
    return new Date(target.getTime() + localTimeZoneOffset * 60 * 1000);
  }

  export function isBetween(date: Date, from: Date | undefined, to: Date | undefined): boolean {
    return (!from || from.getTime() <= date.getTime()) && (!to || date.getTime() <= to.getTime());
  }

  export function dateMonthsAgo(months: number): Date {
    const now = new Date();
    now.setMonth(now.getMonth() - months);
    return now;
  }

  export function formatDateRange(range: DateRange): string | null {
    const isPopulated = range?.from && range?.to;
    return isPopulated ? `${range.from.toISOString()},${range.to.toISOString()}` : null;
  }

  export function resolveSpan(period: DateRange, interval: 'day' | 'week' | 'month'): number {
    const dayInMilliseconds = 24 * 60 * 60 * 1000;
    const { from, to } = period;
    if (interval === 'day') {
      const msDiff = to.getTime() - from.getTime();
      return Math.ceil(msDiff / dayInMilliseconds);
    } else if (interval === 'week') {
      const mondayBasedWeekDay = from.getDay() === 0 ? 6 : from.getDay() - 1;
      const startingMonday = new Date(from.getTime() - mondayBasedWeekDay * dayInMilliseconds);
      const msDiff = to.getTime() - startingMonday.getTime();
      const weekInMs = 7 * dayInMilliseconds;
      return Math.ceil(msDiff / weekInMs);
    } else {
      // 'month'
      return (to.getFullYear() - from.getFullYear()) * 12 + to.getMonth() - from.getMonth() + 1;
    }
  }

  export function areOverlaping(dr1: DateRange, dr2: DateRange): boolean {
    return isBetween(dr1.from, dr2.from, dr2.to) || isBetween(dr1.to, dr2.from, dr2.to);
  }
}
