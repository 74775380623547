import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BEntity } from '../../data-model/entity/entity';

@Component({
  selector: 'app-entity-chip',
  templateUrl: './entity-chip.component.html',
  styleUrls: ['./entity-chip.component.scss'],
})
export class EntityChipComponent {
  @Input() entity: BEntity;
  @Input() selectable: boolean = true;
  @Input() removable: boolean = true;
  @Output() removed: EventEmitter<boolean>;

  constructor() {
    this.removed = new EventEmitter<boolean>();
  }

  unselect() {
    this.removed.emit(true);
  }
}
