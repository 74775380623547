import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { Ajax } from 'app/common/ajax';
import { SimplePage } from 'app/common/types';
import { BUser } from './user';
import { isNil, omitBy } from 'lodash';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';

@Injectable()
export class UsersService {
  private readonly USERS_URL: string;
  private readonly USERS_REPORT_URL: string;
  private readonly URL_USER_INTERACTIONS_DATA_BEFORE_ARCHIVING: string;

  constructor(
    private http: HttpClient,
    private urlResolverService: UrlResolverService,
    private messageService: MessageHandlerService
  ) {
    this.USERS_URL = urlResolverService.apiUrl('users');
    this.USERS_REPORT_URL = urlResolverService.apiUrlForPath(['reporting', 'users']);
    this.URL_USER_INTERACTIONS_DATA_BEFORE_ARCHIVING = urlResolverService.apiUrl(
      'users/user-interactions-data-before-archiving'
    );
  }

  deactivate(id: number, host?: string): Observable<'activated-legacy' | string> {
    return this.http.delete<'activated-legacy' | string>(this.resolveUrl(host, id));
  }

  get(params: UsersService.UsersSearchParams, host?: string): Observable<UsersService.UsersResult> {
    const query = new Ajax.QueryParams()
      .addIfPresent('teamId', params.teamId)
      .addIfPresent('searchText', params.searchText)
      .addIfPresent('status', params.status)
      .addIfPresent('pageSize', params.pageSize)
      .addIfPresent('page', params.page)
      .addIfPresent('orderBy', params.orderBy)
      .toString();
    return this.http
      .get<UsersService.UsersResult>(this.resolveUrl(host, query), Ajax.X_NO_LOADING_OPTIONS)
      .pipe(
        map((res) => {
          res.records = BUser.fromRestArray(res.records);
          return res;
        })
      );
  }

  findUser(username: string, status: 'all' | 'active', host?: string): Observable<BUser> {
    username = username.toLowerCase();
    return this.get({ searchText: username, status: status }, host).pipe(
      map((res) => res.records.find((u) => u.username.toLowerCase() === username))
    );
  }

  create(data: UsersService.CreateUser, host?: string): Observable<number> {
    return this.http.post<number>(this.resolveUrl(host, ''), data);
  }

  updateDelegations(userId: number, data: UsersService.Delegations): Observable<string> {
    return this.http.put<string>(`${this.USERS_URL}/${userId}`, data);
  }

  getOutOfOfficeData(userId: number): Observable<UsersService.outOfOfficeData> {
    return this.http.get<UsersService.outOfOfficeData>(`${this.USERS_URL}/${userId}`).pipe(
      map((data) => {
        data.users = BUser.fromRestArray(data.users);
        data.delegators = BUser.fromRestArray(data.delegators);
        return data;
      })
    );
  }

  usersReportGenerateAndDownload(teamId: number | null = null): Observable<Blob> {
    return this.http
      .get<Blob>(this.USERS_REPORT_URL, {
        params: omitBy({ teamId }, isNil),
        responseType: 'blob' as 'json',
        ...Ajax.X_NO_LOADING_OPTIONS,
      })
      .pipe(
        tap((usersReport) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(usersReport);
          link.download = `Users-report-${new Date().toISOString().slice(0, 10)}.xlsx`;
          link.click();
        }),
        catchError(() => {
          this.messageService.error('An error occurred while fetching the users report.');
          return of(null);
        })
      );
  }

  private resolveUrl(host: string | undefined, sufix: string | number): string {
    const base = host ? this.urlResolverService.apiUrlForHost(host, 'users') : this.USERS_URL;
    return `${base}/${sufix}`;
  }

  getUserRelatedInteractionsData(
    authRequestId: number
  ): Observable<UserRelatedInteractionsDataResponse> {
    return this.http.get<UserRelatedInteractionsDataResponse>(
      `${this.URL_USER_INTERACTIONS_DATA_BEFORE_ARCHIVING}/${authRequestId}`,
      {}
    );
  }
}

export interface UserRelatedInteractionsDataResponse {
  userAssignedInteractionIds: number[];
}

export namespace UsersService {
  export type outOfOfficeData = {
    users: BUser[];
    delegators: BUser[];
  };

  export type UsersSearchParams = {
    teamId?: number;
    searchText?: string;
    status?: 'active' | 'inactive' | 'all';
    pageSize?: number;
    page?: number;
    orderBy?: string;
  };

  export type UsersResult = SimplePage<BUser>;

  export type Delegations = {
    autoAssignToId?: number;
    deputyId?: number;
    outOfOfficeImmediate?: boolean;
    outOfOfficeFrom?: Date | string;
    outOfOfficeTo?: Date | string;
  };

  export type CreateUser = {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    templateId?: number;
    affiliations?: { [teamId: number]: number[] };
    welcomeEmail?: boolean;
  };
}
