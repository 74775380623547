<h2 mat-dialog-title class="modal-title">
  <span class="modal-title-text">
    <i class="fa fa-history"></i>
    {{ 'HISTORY_CHANGES_FOR' | translate | uppercase }}
    {{ selectedUser ? (selectedUser | displayInfo) : titleDataType }}
  </span>
  <div class="modal-title-dropdowns">
    <app-search-select
      [style]="'mt-0'"
      [config]="columnsConfig"
      [multiple]="true"
      [trans]="true"
      [(selectedValue)]="selectedColumns"
      (selectedValueChange)="onColumnsChange()"
    ></app-search-select>
    <button
      [disabled]="selectedColumns.length == 0"
      mat-button
      class="me-2 medis-button-main"
      (click)="exportColumns()"
    >
      <i class="fa fa-file-download"></i>
      {{ 'EXPORT' | translate }}
    </button>
  </div>
  <i class="far fa-times-circle medis-icon-large" mat-dialog-close></i>
</h2>
<mat-dialog-content class="modal-wrapper">
  <div id="topDiv"></div>
  <mat-table
    [dataSource]="auditList"
    class="medis-table"
    matSort
    (matSortChange)="onSortChange($event)"
  >
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef class="mat-cell-70-fixed">
        <div mat-sort-header>{{ 'ID' | translate }}</div>
        <mat-form-field class="form-field-wrapper fs-10" (click)="$event.stopPropagation()">
          <input
            matInput
            autocomplete="off"
            type="text"
            numbersOnly
            (keyup)="triggerSearch()"
            [(ngModel)]="searchedId"
            [ngClass]="{ 'not-empty': searchedId }"
          />
        </mat-form-field>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-70-fixed">{{ element.id }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="eventDatetime">
      <mat-header-cell *matHeaderCellDef class="mat-cell-100">
        <div mat-sort-header>{{ 'DATE' | translate | uppercase }}</div>
        <app-date-time-picker
          [enableReset]="true"
          [timeZone]="'UTC'"
          (valueChange)="onDateRangeChange($event)"
        ></app-date-time-picker>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-100">
        <span [matTooltip]="element.eventDatetime | dateChecker">
          {{ element.eventDatetime | dateCheckerNoHour }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actionUser">
      <mat-header-cell *matHeaderCellDef class="mat-cell-100">
        <div mat-sort-header class="truncated-text">
          {{ 'ACTION_BY_USER' | translate | uppercase }}
        </div>
        <app-search-select
          [style]="'fs-10'"
          [config]="userConfig"
          [(selectedValue)]="actionUserId"
          (selectedValueChange)="onUserChange($event)"
        ></app-search-select>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-100 viewable">
        <ng-container *ngIf="element.actionUser">
          <i
            class="fas fa-eye cursor-pointer"
            (click)="viewUserInfo(element.actionUser, $event)"
          ></i>
          <span>{{ element.actionUser | displayInfo }}</span>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="changedUser">
      <mat-header-cell *matHeaderCellDef class="mat-cell-100">
        <div mat-sort-header class="truncated-text">
          {{ 'CHANGED_USER' | translate | uppercase }}
        </div>
        <app-search-select
          [style]="'fs-10'"
          [config]="userConfig"
          [(selectedValue)]="changedUserID"
          (selectedValueChange)="triggerSearch()"
        ></app-search-select>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-100 viewable">
        <ng-container *ngIf="element.changedUser || element.changedInquirer">
          <i
            class="fas fa-eye cursor-pointer"
            (click)="
              element.changedUser
                ? viewUserInfo(element.changedUser, $event)
                : viewInquirerInfo(element.changedInquirer, $event)
            "
          ></i>
          <span>
            {{
              (element.changedUser ? element.changedUser : element.changedInquirer) | displayInfo
            }}
          </span>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef class="mat-cell-100">
        <div mat-sort-header>{{ 'ACTION' | translate | uppercase }}</div>
        <app-search-select
          [style]="'fs-10'"
          [multiple]="true"
          [config]="auditActionsListConfig"
          [(selectedValue)]="selectedActions"
          (selectedValueChange)="triggerSearch()"
        ></app-search-select>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-100">{{ element?.action }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="originalValue">
      <mat-header-cell *matHeaderCellDef class="mat-cell-200">
        <div mat-sort-header>{{ 'ORIGINAL_VALUE' | translate | uppercase }}</div>
        <mat-form-field class="form-field-wrapper fs-10" (click)="$event.stopPropagation()">
          <input
            matInput
            autocomplete="off"
            type="text"
            (keyup)="triggerSearch()"
            [(ngModel)]="searchedOriginalValue"
            [ngClass]="{ 'not-empty': searchedOriginalValue }"
          />
        </mat-form-field>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-200">
        <div [innerHtml]="element?.originalValue"></div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="newValue">
      <mat-header-cell *matHeaderCellDef class="mat-cell-200">
        <div mat-sort-header>{{ 'NEW_VALUE' | translate | uppercase }}</div>
        <mat-form-field class="form-field-wrapper fs-10" (click)="$event.stopPropagation()">
          <input
            matInput
            autocomplete="off"
            type="text"
            (keyup)="triggerSearch()"
            [(ngModel)]="searchedNewValue"
            [ngClass]="{ 'not-empty': searchedNewValue }"
          />
        </mat-form-field>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-200">
        <div>
          <div [innerHtml]="element?.newValue"></div>
          <div *ngIf="element?.type" (click)="handleAudiTrailPreview(element)">
            {{ 'VIEW_CHANGES' | translate }}:<i class="fas fa-eye cursor-pointer ms-1"></i>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="statusIndicator">
      <mat-header-cell *matHeaderCellDef>
        <app-loading-indicator [loading]="loading" [empty]="!pageCount"></app-loading-indicator>
      </mat-header-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-header-row
      *matHeaderRowDef="['statusIndicator']; sticky: true"
      [class.medis-not-displayed]="auditList.length !== 0 && !loading"
    ></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <app-paginated-navbar
    *ngIf="pageCount"
    [pagesCount]="pageCount"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [showFirstLastButtons]="true"
    (pageChange)="goToPage($event)"
  ></app-paginated-navbar>
</mat-dialog-content>
