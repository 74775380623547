import { Component } from '@angular/core';
import { NameValue } from '@mi-tool/consts';
import { IAuthFilters } from '@mi-tool/models/auth-model';
import { AuthRequestsService } from 'app/modules/administration/administration-auth-requests/administration-auth-requests.service';

@Component({
  selector: 'app-auth-request-toolbar',
  templateUrl: './auth-request-toolbar.component.html',
  styleUrls: ['./auth-request-toolbar.component.scss'],
})
export class AuthRequestToolbarComponent {
  selectedFilters: IAuthFilters = IAuthFilters.defaults();
  selectedFiltersCount = 0;

  readonly filterToTranslation: NameValue<string>[] = [
    { name: 'id', value: 'ID' },
    { name: 'title', value: 'TITLE' },
    { name: 'type', value: 'TYPE' },
    { name: 'requestedBy', value: 'Requestor' },
    { name: 'selectedCountry', value: 'COUNTRY' },
    { name: 'team', value: 'TEAM' },
    { name: 'dateRange', value: 'REQUESTED_DATE' },
    { name: 'approvedDate', value: 'REVIEWED_DATE' },
    { name: 'assignedTo', value: 'Assigned_To' },
    { name: 'role', value: 'ROLE' },
    { name: 'status', value: 'Status' },
  ];

  constructor(private authRequestsService: AuthRequestsService) {
    this.authRequestsService.selectedFilters.subscribe((f) => {
      this.selectedFilters = f;
      this.selectedFiltersCount = this.filterToTranslation.filter((e) =>
        this.isSpecified(e.name)
      ).length;
    });
  }

  resetFilters(propName: string): void {
    const targetNames =
      propName === 'ALL' ? this.filterToTranslation.map((f) => f.name) : [propName];
    targetNames.forEach((name) => {
      this.selectedFilters[name] = undefined;
    });
    this.authRequestsService.resetFilters.next(this.selectedFilters);
  }

  isSpecified(propName: string): boolean {
    return this.isSpecifiedVal(this.selectedFilters[propName]);
  }

  private isSpecifiedVal(val: any): boolean {
    return (!Array.isArray(val) && val !== undefined) || (Array.isArray(val) && val.length > 0);
  }
}
