<h2 mat-dialog-title>
  {{ (edit ? 'UPDATE' : 'NEW') | translate}} {{ 'SAVED_SEARCH' | translate }}
</h2>
<mat-dialog-content class="filter-modal" [attr.data-testid]="'modal-save-search'">
  <mat-form-field
    class="form-field-wrapper"
    [ngClass]="allSavedSearches.includes(saveValue) ? 'ng-invalid' : 'ng-valid'"
  >
    <input
      matInput
      autocomplete="off"
      type="text"
      minlength="3"
      maxlength="35"
      [(ngModel)]="saveValue"
      (keyup)="saveValue = saveValue?.trimStart()"
      (blur)="saveValue = saveValue?.trim()"
      placeholder="{{ 'SAVE_AS' | translate }}"
      [ngClass]="{'not-empty': saveValue}"
      [attr.data-testid]="'modal-save-input'"
    />

    <mat-hint align="start" *ngIf="!saveValue || saveValue?.length < 1">
      {{'CHARACTER_LIMIT' | translate}}
    </mat-hint>
    <mat-hint
      align="start"
      *ngIf="saveValue?.length > 0"
      [attr.data-testid]="'modal-save-input-hint'"
    >
      {{ 35 - saveValue.length }} {{'CHARACTER_LEFT' | translate}}
      <div *ngIf="saveValue && allSavedSearches.includes(saveValue)" class="text-12-700-coral">
        {{ 'SAVED_SEARCH_ALREADY_EXIST' | translate }}
      </div>
    </mat-hint>
  </mat-form-field>
  <i
    class="fa fa-share-alt-square ms-3 cursor-pointer"
    title="{{'SHARE_SEARCH' | translate}}"
    aria-hidden="true"
    [ngClass]="{'opacity': !canSaveOrShare()}"
    (click)="openShare()"
    [attr.data-testid]="'modal-share-button'"
  ></i>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-button
    class="medis-button-main"
    (click)="onSave()"
    [disabled]="!canSaveOrShare()"
    [attr.data-testid]="'modal-save-button'"
  >
    <i class="fa fa-save"></i>
    {{ 'SAVE' | translate }}
  </button>
  <button
    mat-button
    class="medis-button-basic"
    (click)="dialogRef.close('Close click')"
    [attr.data-testid]="'modal-back-button'"
  >
    {{ 'BACK_ACTION' | translate }}
  </button>
</mat-dialog-actions>
