import { gql } from 'apollo-angular';

import { BaseModel, rProperty } from '../data-model';

export class BOrganization extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;

  init(json: any) {}
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment organizationFragment on OrganizationNode {
    id
    name
  }
`;

export const fragmentConnection = gql`
  fragment organizationConnectionFragment on OrganizationNodeConnection {
    edges {
      node {
        ...roleFragment
      }
    }
  }
  ${fragment}
`;
