import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IAuditTrailPreviewDialogData {
  htmlChanges: string;
  originalDetailValue: string;
  newDetailValue: string;
}

@Component({
  selector: 'app-audit-trail-preview-dialog',
  templateUrl: './audit-trail-preview-dialog.component.html',
  styleUrls: ['./audit-trail-preview-dialog.component.scss'],
})
export class AuditTrailPreviewDialogComponent {
  panelOpenState = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IAuditTrailPreviewDialogData) {}
}
