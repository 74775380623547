import { base64ToPK, BaseModel } from '../data-model';
import { BCountry } from '../country/country';
import { BProduct, BRegion } from '../region/region';
import { BTherapeuticArea } from '../medical-document/medical-document';
import { ECompanyType } from '@mi-tool/enums';

export type TCompanyParams = {
  pageIndex: number;
  pageSize: number;
  orderBy?: string;
  id?: number;
  name?: string;
  countryIds?: string;
  productIds?: string;
  therapeuticAreaIds?: string;
  regionIds?: string;
  type?: string;
  status?: string;
};

export type TCompanyData = {
  id: string;
  name: string;
  active: boolean;
  type: ECompanyType;
  regions: string[];
  countries: string[];
  therapeuticAreas: string[];
  products: string[];
};

export type TCompanyDialogData = {
  company: BCompany;
  regions: BRegion[];
  countries: BCountry[];
  therapeuticAreas: BTherapeuticArea[];
  products: BProduct[];
};

export type TCompanyUpdateResponse = {
  message?: string;
};

export class BCompany extends BaseModel {
  id: string;
  name: string;
  active: boolean;
  type: ECompanyType;
  regions: BRegion[];
  countries: BCountry[];
  therapeuticAreas: BTherapeuticArea[];
  products: BProduct[];

  init() {}

  static fromRest(json: any): BCompany {
    if (json) {
      json.regions = BRegion.fromRestArray(json.regions);
      json.countries = BCountry.fromRestArray(json.countries);
      json.therapeuticAreas = BTherapeuticArea.fromRestArray(json.therapeuticAreas);
      json.products = BProduct.fromRestArray(json.products);
    }
    return Object.assign(new BCompany(), json);
  }

  static fromRestArray(json: any[]): BCompany[] {
    return json && json.map((v) => BCompany.fromRest(v));
  }

  pk(): number | undefined {
    if (this.id) {
      return base64ToPK(this.id);
    }
    return undefined;
  }
}
