import { BaseModel, rProperty } from '../data-model';

export class BEntity extends BaseModel {
  @rProperty() id: string;
  @rProperty() class: string;
  @rProperty() value: string;
  @rProperty() email: string;
  @rProperty() teamIds: number[];
  @rProperty() team: string;
  @rProperty() firstName: string;
  @rProperty() lastName: string;
  @rProperty() globalId: string;

  isExternal: boolean;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.isExternal = false;
  }

  static newExternal(email: string): BEntity {
    const entity = new BEntity({ email: email });
    entity.isExternal = true;
    return entity;
  }

  get global_id(): string {
    return this.globalId;
  }

  get avatarCode(): string {
    if (this.isExternal) {
      return 'EX';
    }
    if (!this.class) {
      return '';
    }
    return this.class.substr(0, 2).toUpperCase();
  }

  get avatarDescription(): string {
    if (this.isExternal) {
      return 'External';
    }
    if (!this.class) {
      return '';
    }
    return this.class.substr(0, 1).toUpperCase() + this.class.substr(1);
  }
}

export class BRelatedContent extends BEntity {
  @rProperty() title: string;
  @rProperty() description: string;
  // needed for type
  @rProperty() subClass: string;
  @rProperty() products: string;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {}
}
