import { gql } from 'apollo-angular';

import { MUserNotification } from '../user/user';

export const allUserNotificationsQuery = gql`
  query allUserNotificationsQuery {
    allUserNotifications {
      edges {
        node {
          ...userNotificationFragment
        }
      }
    }
  }
  ${MUserNotification.fragment}
`;
