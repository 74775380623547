<h2 mat-dialog-title>
  {{ titleTranslationKey | translate }}
  <i class="far fa-times-circle medis-icon-large" (click)="dialogRef.close()"></i>
</h2>
<mat-dialog-content>
  <app-similar-inquiry-link
    [interaction]="interaction"
    (selectInquiryChange)="selectedInquiry = $event"
  ></app-similar-inquiry-link>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button class="medis-button-main" (click)="dialogRef.close(selectedInquiry)">
    {{ 'DONE' | translate }}
  </button>
  <button
    *ngIf="noSelectedEnquiryTranslationKey"
    mat-button
    class="medis-button-basic"
    (click)="dialogRef.close()"
  >
    {{ noSelectedEnquiryTranslationKey | translate }}
  </button>
</mat-dialog-actions>
