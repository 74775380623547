import { base64ToPK, BaseModel } from '../data-model';
import { BMailbox } from '../mailbox/mailbox';

export class BDMMessage extends BaseModel {
  id: string;
  subject: string;
  messageId: string;
  fromHeader: string;
  toHeader: string;
  outgoing: boolean;
  body: string;
  encoded: boolean;
  processed: Date;
  read: Date;
  eml: string;

  mailbox: BMailbox;
  inReplyTo: BDMMessage;

  init() {}

  pk(): number {
    if (!this.id) {
      return null;
    }
    return base64ToPK(this.id);
  }

  static fromRest(object: any): BDMMessage {
    return object && Object.assign(new BDMMessage(), object);
  }

  static fromRestArray(arrayOfObjects: any[]): BDMMessage[] {
    return arrayOfObjects && arrayOfObjects.map((object) => BDMMessage.fromRest(object));
  }
}
