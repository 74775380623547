import { VERSION } from './version';

export const environment = {
  production: false,
  name: 'development',
  version: VERSION,
  hideWorkInProgress: false,
  apiBaseUrl: 'https://medis.westeurope.cloudapp.azure.com/server/',
  localBaseUrl: 'https://medis.westeurope.cloudapp.azure.com/',
  supportEmail: 'support@madjenta.com',
  keepAliveInterval: 30000,
  syncRemoteName: 'test',
  syncRemoteHost: 'https://medis.azureedge.net',
  disableEmailSent: false,
  systemWarning: '',
};
