import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-document-card-type',
  templateUrl: './document-card-type.component.html',
  styleUrls: ['./document-card-type.component.scss'],
})
export class DocumentCardTypeComponent {
  @Input() documentType = '';
  @Input() isSelected = false;
}
