<app-srd-faq-link
  *ngIf="visualization === 'srd-faq'"
  [document]="document"
  [adminMode]="adminMode"
></app-srd-faq-link>
<app-slide-link *ngIf="visualization === 'slide'" [document]="document" [adminMode]="adminMode">
</app-slide-link>
<app-smpc-literature-other-link
  *ngIf="visualization === 'smpc-literature-other'"
  [document]="document"
  [adminMode]="adminMode"
></app-smpc-literature-other-link>
<app-similar-inquiry-link
  *ngIf="visualization === 'interaction'"
  [interaction]="interaction"
  (selectInquiryChange)="selectedInquiry = $event"
></app-similar-inquiry-link>
