import { MedisNavigationItem, newGroup, newItem } from '../types';
import { environment } from 'app/../environments/environment';

const _inquiriesNavigation: MedisNavigationItem[] = [
  newGroup('Filter_inquiries_by', 'Filter_inquiries_by', 'fa-filter', [
    newItem('Assigned_to_me', 'Assigned_to_me', 'fa-user-circle', '/inq/assigned-to-me'),
    newItem('My_drafts', 'My_drafts', 'fa-pencil-ruler', '/drafts'),
    newItem('My_open_inquiries', 'My_open_inquiries', 'fa-reply', '/inq/my-open-inquiries'),
    newItem('My_closed_inquiries', 'My_closed_inquiries', 'fa-reply', '/inq/my-closed-inquiries'),
  ]),
  newGroup('My_team', '', '', [
    newItem(
      'My_teams_all_inq',
      'My_teams_all_inq',
      '',
      '/inq/all-inq-for-my-team',
      false,
      'my_team'
    ),
    newItem('My_teams_open_inq', 'My_teams_open_inq', 'fa-user-friends', '/inq/my-teams-open-inq'),
    newItem('My_teams_closed_inq', 'My_teams_closed_inq', 'fa-reply', '/inq/my-teams-closed-inq'),
    newItem('Pending_approval', 'Pending_approval', 'fa-hourglass', '/inq/pending-approval', true),
    newItem('Spam_list', 'Spam_list', 'fa-ban', '/spam/list'),
  ]),
  newGroup('All_teams', '', '', [
    newItem(
      'All_teams_all_inq',
      'All_teams_all_inq',
      '',
      '/inq/all-inq-for-all-team',
      false,
      'all_teams'
    ),
    newItem(
      'All_teams_open_inq',
      'All_teams_open_inq',
      'fa-globe-americas',
      '/inq/all-teams-open-inq'
    ),
    newItem(
      'All_teams_closed_inq',
      'All_teams_closed_inq',
      'fa-reply',
      '/inq/all-teams-closed-inq'
    ),
  ]) /*,
    newGroup('Overdue_', '', '', [
        newItem('Overdue', 'Overdue', 'fa-clock', '/inq/overdue'),
        newItem('Due_this_week', 'Due_this_week', 'fa-calendar-check', '/inq/due-this-week')
    ])*/,
];

export function inquiriesNavigation(): MedisNavigationItem[] {
  if (environment.hideWorkInProgress) {
    const filterBy = _inquiriesNavigation.find((i) => i.id === 'Filter_inquiries_by');
    if (filterBy) {
      filterBy.children = filterBy.children.filter((c) => !['My_drafts'].includes(c.id));
    }
  }
  return _inquiriesNavigation;
}
