import { Injectable } from '@angular/core';
import { BTask } from './task';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { SimplePage } from 'app/common/types';
import { Ajax } from 'app/common/ajax';
import { map } from 'rxjs/operators';
import { isNil, omitBy } from 'lodash';

export type TTaskData = {
  id: string;
  name: string;
  module: string;
  function: string;
  every: string;
  period: string;
  active: boolean;
};

export type TTaskPartialUpdateData = {
  id: string;
  failureCount?: number;
  active?: boolean;
};

export type TTaskDialogData = {
  task: BTask;
};

export type TTaskParams = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: string;
  id?: number;
  name?: string;
  module?: string;
  function?: string;
  lastRun?: string;
  lastSuccess?: string;
  lastFailure?: string;
  failureCount?: number;
  every?: number;
  period?: string;
  active?: string;
  basicData?: boolean;
};

@Injectable({ providedIn: 'root' })
export class TaskRestService {
  private readonly URL: string;

  constructor(private http: HttpClient, private urlResolver: UrlResolverService) {
    this.URL = urlResolver.misApiUrlForPath('/tasks/');
  }

  fetchTasksList(params: TTaskParams): Observable<SimplePage<BTask>> {
    return this.http
      .get<SimplePage<BTask>>(this.URL, {
        ...Ajax.X_NO_LOADING_OPTIONS,
        params: omitBy(params, isNil),
      })
      .pipe(
        map((response) => {
          return { ...response, records: BTask.fromRestArray(response.records) };
        })
      );
  }

  edit(data: TTaskData | TTaskPartialUpdateData): Observable<void> {
    return this.http.put<void>(this.URL, omitBy(data, isNil));
  }

  create(data: TTaskData): Observable<void> {
    return this.http.post<void>(this.URL, omitBy(data, isNil));
  }
}
