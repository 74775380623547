<h2 mat-dialog-title>{{'CONFIRM_DELETION' | translate}}</h2>

<mat-dialog-content>
  <div
    class="height-auto text-center text-15 overflow-hidden"
    [attr.data-testid]="'modal-delete-search'"
  >
    <p>{{'ARE_SURE' | translate}} <span class="text-16-azure">{{inquiryTitle}}</span></p>
    <p>
      <i class="fa fa-exclamation-triangle text-22-orange-yellow me-2" aria-hidden="true"></i>
      <span [innerHTML]="'PERMANENTLY_DELETED' | translate"></span>
    </p>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-button
    class="medis-button-reject"
    (click)="onDelete()"
    [attr.data-testid]="'modal-delete-button'"
  >
    {{ 'TEAM_DELETE' | translate }}
  </button>
  <button
    mat-button
    class="medis-button-basic"
    (click)="dialogRef.close('Close click')"
    [attr.data-testid]="'modal-cancel-button'"
  >
    {{ 'CANCEL' | translate }}
  </button>
</mat-dialog-actions>
