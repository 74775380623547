import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';

import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { NgbdModalUser } from './modal/modal-user/modal-user.component';
import { UserInquirerCard } from './user-inquirer-card/user-inquirer-card';
import {
  ChannelTranslationKeyPipe,
  DateChecker,
  DateCheckerNoHour,
  DisplayInfoPipe,
  FilterBy,
  FullNamePipe,
  GetNames,
  PrimaryKeyPipe,
  SanitizeHtml,
  SanitizeUrl,
  ToCSVPipe,
  ValidateRequiredFields,
  ValueFromKeyPipe,
} from './custom-pipes.pipe';
import { NgbdModalInquiry } from './modal/modal-inquiry/modal-inquiry.component';
import { LoadingSpinComponent } from './loading-spin/loading-spin.component';
import { MessageHandlerService } from './message-handler/message-handler.service';
import { GenericDialogComponent } from './generic-dialog/generic-dialog.component';
import { GenericDialogService } from './generic-dialog/generic-dialog.service';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatSliderModule } from '@angular/material/slider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTreeModule } from '@angular/material/tree';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import {
  CalendarCommonModule,
  CalendarDateFormatter,
  CalendarModule,
  CalendarUtils,
  DateAdapter,
} from 'angular-calendar';
import { LoadingIndicatorComponent } from './loading-component/loading-indicator.component';
import { MultiToggleComponent } from './multi-toggle/multi-toggle.component';
import { SearchSelectComponent } from './search-select/search-select.component';
import { ReassignComponent } from './reassign/reassign.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../@mi-tool/shared.module';
import { AppNotificationsService } from './app-notifications.service';
import { DisplayTerm } from '../pipes/display-term';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatNativeDateModule } from '@angular/material/core';
import { LinkVisualizationComponent } from '../../modules/related-content/link-visualization/link-visualization.component';
import { RelatedContentComponent } from '../../modules/related-content/related-content.component';
import { RelatedContentLinkComponent } from '../../modules/related-content/related-content-link/related-content-link.component';
import { AdvancedEntitySearchComponent } from '../../modules/advanced-entity-search/advanced-entity-search.component';
import { EntitySearchComponent } from '../../modules/advanced-entity-search/entity-search.component';
import { EntityChipComponent } from '../../modules/advanced-entity-search/entity-chip/entity-chip.component';
import { EntityPreviewComponent } from '../../modules/advanced-entity-search/entity-preview/entity-preview.component';
import { DocumentAvailableActionsComponent } from '../../modules/repository/document-management/document-available-actions/document-available-actions.component';
import { DocumentUploadComponent } from '../../modules/repository/document-management/document-upload/document-upload.component';
import { SimilarInquiryLinkComponent } from '../../modules/related-content/related-content-link/similar-inquiry-link/similar-inquiry-link.component';
import { SrdFaqLinkComponent } from '../../modules/related-content/related-content-link/srd-faq-link/srd-faq-link.component';
import { SmpcLiteratureOtherLinkComponent } from '../../modules/related-content/related-content-link/smpc-literature-other-link/smpc-literature-other-link.component';
import { SlideLinkComponent } from '../../modules/related-content/related-content-link/slide-link/slide-link.component';
import { PeriodicalService } from './periodical-service/periodical-service.service';
import { PreviewTemplateDialogComponent } from './preview-template-dialog/preview-template-dialog.component';
import { EmailNotificationTemplateDialogComponent } from './email-notification-template-dialog/email-notification-template-dialog.component';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PaginationWrapperComponent } from './pagination-wrapper/pagination-wrapper.component';
import { HolidayEmailNotificationTemplateDialogComponent } from './holiday-email-notification-template-dialog/holiday-email-notification-template-dialog.component';
import { PasswordFieldComponent } from './password/password-field.component';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { AuditTrailPreviewDialogComponent } from './audit-trail/audit-trail-preview-details/audit-trail-preview-dialog.component';
import { AffiliationPickerComponent } from './affilication-picker/affiliation-picker.component';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  imports: [
    MatSidenavModule,
    NgbModule,
    CommonModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatListModule,
    MatChipsModule,
    MatCardModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatTableModule,
    MatGridListModule,
    MatSortModule,
    MatTooltipModule,
    MatTabsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTreeModule,
    EditorModule,
    MatSliderModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: momentAdapterFactory,
    }),
    CalendarCommonModule,
    TranslateModule,
    SharedModule,
    MatPaginatorModule,
    FlatpickrModule,
  ],
  exports: [
    AffiliationPickerComponent,
    MatSidenavModule,
    DateTimePickerComponent,
    NgbdModalUser,
    NgbdModalInquiry,
    ToCSVPipe,
    ValueFromKeyPipe,
    DateChecker,
    DateCheckerNoHour,
    SanitizeHtml,
    SanitizeUrl,
    ValidateRequiredFields,
    LoadingSpinComponent,
    LinkVisualizationComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatExpansionModule,
    MatListModule,
    MatChipsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatIconModule,
    MatCardModule,
    MatStepperModule,
    MatTableModule,
    MatDialogModule,
    MatGridListModule,
    MatSortModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatSliderModule,
    MatTabsModule,
    MatTreeModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RelatedContentComponent,
    RelatedContentLinkComponent,
    EditorModule,
    UserInquirerCard,
    LoadingIndicatorComponent,
    MultiToggleComponent,
    SearchSelectComponent,
    ReassignComponent,
    CalendarModule,
    CalendarCommonModule,
    AdvancedEntitySearchComponent,
    EntitySearchComponent,
    EntityChipComponent,
    EntityPreviewComponent,
    DocumentAvailableActionsComponent,
    DocumentUploadComponent,
    TranslateModule,
    SimilarInquiryLinkComponent,
    DisplayTerm,
    PaginationWrapperComponent,
    GetNames,
    FilterBy,
    PrimaryKeyPipe,
    DisplayInfoPipe,
    FullNamePipe,
    ChannelTranslationKeyPipe,
    PasswordFieldComponent,
    FlatpickrModule,
  ],
  declarations: [
    AffiliationPickerComponent,
    AuditTrailComponent,
    AuditTrailPreviewDialogComponent,
    DateTimePickerComponent,
    NgbdModalUser,
    NgbdModalInquiry,
    ToCSVPipe,
    ValueFromKeyPipe,
    DateChecker,
    DateCheckerNoHour,
    SanitizeHtml,
    SanitizeUrl,
    ValidateRequiredFields,
    LoadingSpinComponent,
    RelatedContentComponent,
    LinkVisualizationComponent,
    RelatedContentLinkComponent,
    SrdFaqLinkComponent,
    SmpcLiteratureOtherLinkComponent,
    SlideLinkComponent,
    SimilarInquiryLinkComponent,
    GenericDialogComponent,
    UserInquirerCard,
    LoadingIndicatorComponent,
    MultiToggleComponent,
    SearchSelectComponent,
    ReassignComponent,
    AdvancedEntitySearchComponent,
    EntitySearchComponent,
    EntityChipComponent,
    EntityPreviewComponent,
    DocumentAvailableActionsComponent,
    DocumentUploadComponent,
    DisplayTerm,
    PreviewTemplateDialogComponent,
    EmailNotificationTemplateDialogComponent,
    PaginationWrapperComponent,
    GetNames,
    FilterBy,
    PrimaryKeyPipe,
    DisplayInfoPipe,
    FullNamePipe,
    ChannelTranslationKeyPipe,
    HolidayEmailNotificationTemplateDialogComponent,
    PasswordFieldComponent,
  ],
  providers: [
    MessageHandlerService,
    PeriodicalService,
    CalendarUtils,
    CalendarDateFormatter,
    AppNotificationsService,
    GenericDialogService,
  ],
})
export class AppCommonModule {}
