import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IConfirmDialog {
  title?: string;
  message?: string;
  secondParagraph?: string;
  confirmBtn?: string;
  cancelBtn?: string;
  array?: any[];
  dropdownTitle?: string;
  selected?: string;
  icon?: string;
  thirdParagraph?: string;
  fourthParagraph?: string;
  fifthParagraph?: string;
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  public confirmObj: IConfirmDialog = {
    title: 'Confirm',
    message: 'Are you sure?',
    secondParagraph: '',
    confirmBtn: 'CONFIRM',
    cancelBtn: 'CANCEL',
  };

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: IConfirmDialog
  ) {
    this.confirmObj = { ...this.confirmObj, ...this._data };
    this.confirmObj.selected =
      this.confirmObj.array && this.confirmObj.array.length === 1
        ? this.confirmObj.array[0]
        : undefined;
  }
}
