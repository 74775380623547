import { Component, Input } from '@angular/core';
import { BMedicalDocument } from 'app/modules/data-model/medical-document/medical-document';
import { BMDWordSection } from 'app/modules/data-model/md-word-section/md-word-section';
import { MatDialog } from '@angular/material/dialog';
import { DocumentCreateComponent } from 'app/modules/repository/document-create/document-create.component';
import { RepositoryBrowserService } from 'app/modules/data-model/data-model.module';

@Component({
  selector: 'app-srd-faq-link',
  templateUrl: './srd-faq-link.component.html',
  styleUrls: ['./srd-faq-link.component.scss'],
})
export class SrdFaqLinkComponent {
  selectAll = false;
  @Input() adminMode: boolean = false;

  private _document: BMedicalDocument;

  constructor(private matDialog: MatDialog, private repoBrowserService: RepositoryBrowserService) {}

  @Input()
  set document(doc: BMedicalDocument) {
    this._document = doc;
    if (doc && doc.currentVersion && doc.currentVersion.word && doc.currentVersion.word.sections) {
      for (var section of this._document.currentVersion.word.sections) {
        section['selected'] = false;
      }
    }
  }

  get document(): BMedicalDocument {
    return this._document;
  }

  checkAll(selected: boolean): void {
    this.document.currentVersion.word.sections.forEach(
      (section) => (section['selected'] = selected)
    );
  }

  openSectionPreview(section: BMDWordSection): void {
    this.repoBrowserService.downloadSectionMedicalDocumentVersion(
      this.document.currentVersion,
      section
    );
  }

  finalizeLocalization(): void {
    // finalizeLocalization
    const ref = this.matDialog.open(DocumentCreateComponent);
    ref.componentInstance.finalizeLocalization(this.document);
  }
}
