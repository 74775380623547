<div *ngIf="document" class="medis-flex-container-between">
  <span class="text-16-800-dark">ID: {{ document | pk }}</span>
  <span *ngIf="version" class="text-16-800-dark"
    >{{ 'PUBLISHED_VERSION' | translate }}: {{ version.versionNumber }}</span
  >
  <span *ngIf="!version" class="text-16-900-coral">{{ 'NO_VERSION_AVAILABLE' | translate }}</span>
  <span *ngIf="statusName" class="text-16-800-dark"
    >{{ 'Status' | translate }}: {{ statusName | translate }}</span
  >
  <div>
    <button
      *ngIf="
        !version ||
        version.statusName === 'Approved' ||
        (version && version?.scheduledExpirationDate < todayDateTime)
      "
      mat-button
      class="medis-button-basic"
      (click)="createNewVersion()"
    >
      <i class="fas fa-plus"></i>
      {{ 'NEW_VERSION' | translate }}
    </button>
    <span class="medis-mr-10"></span>
    <button
      *ngIf="version && version?.statusName !== 'Archived'"
      mat-button
      class="medis-button-reject"
      (click)="setStatus('Archived')"
    >
      <i class="fas fa-trash-alt"></i>
      {{ 'Archive' | translate }}
    </button>
    <button
      *ngIf="
        version &&
        version?.statusName === 'Archived' &&
        version?.scheduledExpirationDate > todayDateTime
      "
      mat-button
      class="medis-button-approve"
      (click)="setStatus('Approved')"
    >
      <i class="fas fa-undo-alt"></i>
      {{ 'REPUBLISH' | translate }}
    </button>
  </div>
</div>
