import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { filter } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleSysAdminMimGuard {
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(): Promise<boolean> {
    const user = await firstValueFrom(this.authService.user.pipe(filter((user) => !!user)));

    if (user && (user.isSysAdmin() || user.isMim())) {
      return true;
    }

    this.router.navigate(['access-denied']);
    return false;
  }
}
