import { BCategory, BProduct, BTopic } from 'app/modules/data-model/data-model.module';
import {
  Config,
  newBCategoryConfig,
  newBProductConfig,
  newBTopicConfig,
} from 'app/common/common/search-select/search-select.config';

export class MetadataFilter {
  private _availableProducts: BProduct[];
  private _availableCategories: BCategory[];
  private _availableTopics: BTopic[];

  private _selectedProduct: BProduct;
  private _selectedCategory: BCategory;
  private _selectedTopic: BTopic;

  private products: BProduct[] = [];
  private categories: BCategory[] = [];
  private topics: BTopic[] = [];

  private _productConfig: Config = newBProductConfig([]);
  private _categoryConfig: Config = newBCategoryConfig([]);
  private _topicConfig: Config = newBTopicConfig([]);

  constructor(private onlyActive: boolean) {}

  set allProducts(products: BProduct[]) {
    this.products = products;
    this.adjustNomenclatures(this.onlyActive);
  }

  set allCategories(categories: BCategory[]) {
    this.categories = categories;
    this.adjustNomenclatures(this.onlyActive);
  }

  set allTopics(topics: BTopic[]) {
    this.topics = topics;
    this.adjustNomenclatures(this.onlyActive);
  }

  get availableCategories(): BCategory[] {
    return this._availableCategories;
  }

  get availableTopics(): BTopic[] {
    return this._availableTopics;
  }

  get availableProducts(): BProduct[] {
    return this._availableProducts;
  }

  get selectedCategory(): BCategory {
    return this._selectedCategory;
  }

  get selectedTopic(): BTopic {
    return this._selectedTopic;
  }

  get selectedProduct(): BProduct {
    return this._selectedProduct;
  }

  get categoryConfig(): Config {
    return this._categoryConfig;
  }

  get topicConfig(): Config {
    return this._topicConfig;
  }

  get productConfig(): Config {
    return this._productConfig;
  }

  set selectedProduct(product: BProduct) {
    this._selectedProduct = (product && this.products.find((p) => p.id === product.id)) || product;
    this.resetIfEmptySet(product);
    this.refreshAvailable();
  }

  set selectedCategory(category: BCategory) {
    this._selectedCategory =
      (category && this.categories.find((c) => c.id === category.id)) || category;
    this.resetIfEmptySet(category);
    this.refreshAvailable();
  }

  set selectedTopic(topic: BTopic) {
    this._selectedTopic = (topic && this.topics.find((t) => t.id === topic.id)) || topic;
    this.resetIfEmptySet(topic);
    this.refreshAvailable();
  }

  refresh(product: BProduct, category: BCategory, topic: BTopic): void {
    this._selectedProduct = (product && this.products.find((p) => p.id === product.id)) || product;
    this._selectedCategory =
      (category && this.categories.find((c) => c.id === category.id)) || category;
    this._selectedTopic = (topic && this.topics.find((t) => t.id === topic.id)) || topic;
    this.refreshAvailable();
  }

  private resetIfEmptySet(update: BProduct | BCategory | BTopic): void {
    if (!update) {
      this._selectedProduct = this._selectedCategory = this._selectedTopic = undefined;
    }
  }

  private refreshAvailable(): void {
    this._availableTopics = this.resolveAvailableTopics();
    if (this._availableTopics.length === 0) {
      this._availableTopics = this.topics;
    } else if (this._availableTopics.length === 1) {
      this._selectedTopic = this._availableTopics[0];
    } else if (
      this._selectedTopic &&
      !this._availableTopics.find((t) => t.id === this._selectedTopic.id)
    ) {
      this._selectedTopic = undefined;
    }
    this._topicConfig = newBTopicConfig(this._availableTopics);
    this._availableProducts = this.resolveAvailableProducts();
    if (this._availableProducts.length === 0) {
      this._availableProducts = this.products;
    } else if (this._availableProducts.length === 1) {
      this._selectedProduct = this._availableProducts[0];
    } else if (
      this._selectedProduct &&
      !this._availableProducts.find((p) => p.id === this._selectedProduct.id)
    ) {
      this._selectedProduct = undefined;
    }
    this._productConfig = newBProductConfig(this._availableProducts);
    this._availableCategories = this.resolveAvailableCategories();
    if (this._availableCategories.length === 0) {
      this._availableCategories = this.categories;
    } else if (this._availableCategories.length === 1) {
      this._selectedCategory = this._availableCategories[0];
      this._availableCategories = this.categories;
    } else if (
      this._selectedCategory &&
      !this._availableCategories.find((c) => c.id === this._selectedCategory.id)
    ) {
      this._selectedCategory = undefined;
    }
    this._categoryConfig = newBCategoryConfig(this._availableCategories);
  }

  private resolveAvailableTopics(): BTopic[] {
    let available = this.topics;
    if (this._selectedCategory) {
      available = available.filter((t) => t.categoryId === this._selectedCategory.id);
    }
    if (this._selectedProduct) {
      available = available.filter(
        (t) => !t.isProductSpecific || t.products.find((p) => p.id === this._selectedProduct.id)
      );
    }
    return available;
  }

  private resolveAvailableCategories(): BCategory[] {
    let available = this.categories;
    if (this._selectedTopic) {
      available = available.filter((c) => c.id === this._selectedTopic.categoryId);
    }
    if (this._selectedProduct) {
      available = available.filter(
        (c) =>
          this.topics.filter(
            (t) =>
              c.id === t.categoryId &&
              (!t.isProductSpecific || t.products.find((p) => p.id === this._selectedProduct.id))
          ).length > 0
      );
    }
    return available;
  }

  private resolveAvailableProducts(): BProduct[] {
    let available = this.products;
    if (this._selectedTopic && this._selectedTopic.isProductSpecific) {
      available = available.filter((p) =>
        this._selectedTopic.products.find((p2) => p.id === p2.id)
      );
    }
    if (this._selectedCategory && !this._selectedTopic) {
      let categoryTopics = this.topics.filter((t) => this._selectedCategory.id === t.categoryId);
      if (!categoryTopics.find((t) => !t.isProductSpecific)) {
        let categoryProductIds = new Set<string>();
        categoryTopics.forEach((t) => t.products.forEach((p) => categoryProductIds.add(p.id)));
        let productIds = Array.from(categoryProductIds);
        available = available.filter((p) => productIds.includes(p.id));
      }
    }
    return available;
  }

  private adjustNomenclatures(onlyActive: boolean): void {
    if (
      this.categories &&
      this.categories.length &&
      this.topics &&
      this.topics.length &&
      this.products &&
      this.products.length
    ) {
      this.products = this.products.filter((p) => !onlyActive || p.isActive);
      this.categories = this.categories.filter(
        (c) =>
          (!onlyActive || c.isActive) &&
          this.topics.find((t) => t.categoryId === c.id && (!onlyActive || t.isActive))
      );
      this.topics = this.topics.filter(
        (t) =>
          (!onlyActive || t.isActive) &&
          this.categories.find((c) => c.id === t.categoryId && (!onlyActive || c.isActive))
      );
      this.refreshAvailable();
    }
  }
}
