import { gql } from 'apollo-angular';

import { BaseModel, rProperty } from '../data-model';

export class BRole extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() description: string;
  code: string;

  constructor(json) {
    super(json);
    this.init(json);
  }

  init(_json: any) {
    this.code = this.name;
  }

  static fromRest(json: any): BRole {
    return Object.assign(new BRole({}), json);
  }

  static fromRestArray(json: any[]): BRole[] {
    return json && json.map((v) => BRole.fromRest(v));
  }
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment roleFragment on RoleNode {
    id
    name
    description
  }
`;

export const fragmentConnection = gql`
  fragment roleConnectionFragment on RoleNodeConnection {
    edges {
      node {
        ...roleFragment
      }
    }
  }
  ${fragment}
`;
