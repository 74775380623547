import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BAttachment, BMedicalDocument } from 'app/modules/data-model/data-model.module';
import { NgbdModalUser } from 'app/common/common/modal/modal-user/modal-user.component';
import { BInquiry, BInteraction } from 'app/modules/data-model/interaction/interaction';

@Component({
  selector: 'app-similar-inquiry-link',
  templateUrl: './similar-inquiry-link.component.html',
  styleUrls: ['./similar-inquiry-link.component.scss'],
})
export class SimilarInquiryLinkComponent {
  @Output() selectInquiryChange = new EventEmitter<BInquiry>();
  @Output() selectedInquiryIdsChange = new EventEmitter<string[]>();

  @Input() showSelectContainer: boolean = true;
  @Input() isMultipleSelect: boolean = false;

  selectedInquiryIds: string[] = [];
  private _interaction: BInteraction;
  private _selectedInquiry: BInquiry;

  constructor(private modalService: MatDialog) {}

  @Input()
  set interaction(interaction: BInteraction) {
    this._interaction = interaction;
    if (interaction.inquiries.length >= 1) {
      if (this.isMultipleSelect) {
        this.handleSelectedInquiryChange(interaction.inquiries[0].id);
      } else {
        this.selectedInquiry = interaction.inquiries[0];
      }
    }
  }

  get interaction(): BInteraction {
    return this._interaction;
  }

  set selectedInquiry(inquiry: BInquiry) {
    this._selectedInquiry = inquiry;
    this.selectInquiryChange.emit(inquiry);
  }

  get selectedInquiry(): BInquiry {
    return this._selectedInquiry;
  }

  handleSelectedInquiryChange(inquiryId: string): void {
    const indexOfInquiryId = this.selectedInquiryIds.indexOf(inquiryId);
    if (indexOfInquiryId > -1) {
      this.selectedInquiryIds.splice(indexOfInquiryId, 1);
    } else {
      this.selectedInquiryIds.push(inquiryId);
    }
    this.selectedInquiryIdsChange.emit(this.selectedInquiryIds);
  }

  previewInquirer(inquirerId: number): void {
    this.modalService.open(NgbdModalUser, { data: { inquirerId: inquirerId } });
  }

  getAttachments(inquiry: BInquiry): (BAttachment | BMedicalDocument)[] {
    let attachments = [];
    if (this.interaction.interactionAnswers.length > 0) {
      for (const h of this.interaction.interactionAnswers[0].historyObjects) {
        if (['answer', 'answer_draft'].includes(h.category) && h.inquiryId === inquiry.id) {
          attachments = attachments.concat(h.linkedFiles);
        }
      }
    }
    return attachments;
  }
}
