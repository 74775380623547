import { BaseModel, parseAttr, rProperty } from '../data-model';
import { BUser } from 'app/modules/data-model/user/user';

export class BMigrationError extends BaseModel {
  @rProperty() id: string;
  @rProperty() migrationName: string;
  @rProperty() objectType: string;
  @rProperty() importedId: string;
  @rProperty() error: string;
  @rProperty() resolved: boolean;
  @rProperty(Date) editedTs: Date;
  @rProperty(Date) errorTs: Date;

  editedBy: BUser;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.editedBy = parseAttr<BUser>(json, BUser, 'editedBy');
  }

  static fromRest(json: any): BMigrationError {
    if (json) {
      json.editedBy = BUser.fromRest(json.editedBy);
      return Object.assign(new BMigrationError({}), json);
    }
    return json;
  }

  static fromRestArray(json: any[]): BMigrationError[] {
    return json && json.map((v) => BMigrationError.fromRest(v));
  }
}
