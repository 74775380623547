import { gql } from 'apollo-angular';

import { MTeam } from '../user/user';

export const getAllTeams = gql`
  query getAllTeams {
    allTeams {
      edges {
        node {
          ...teamFragment
        }
      }
    }
  }
  ${MTeam.fragment}
`;

export const updateTeam = gql`
  mutation updateTeam($params: UpdateTeamInput!) {
    updateTeam(input: $params) {
      team {
        ...teamFragment
      }
    }
  }
  ${MTeam.fragment}
`;
