<ng-template #selectorTypes let-item="item" let-selector="selector" let-disabled="disabled">
  <p
    *ngIf="(!disabled && filterConfigTypes[selector] === types.TEXT) || disabled"
    class="pe-0"
    [attr.data-testid]="'advanced-search-search-box-field-container'"
  >
    <mat-form-field class="form-field-wrapper mat-full-width">
      <i class="fas fa-search medis-icon-prefix" matPrefix></i>
      <mat-label [attr.data-testid]="'search-box-field-label'">
        {{ 'SEARCH_TERM' | translate }}
      </mat-label>
      <input
        matInput
        autocomplete="off"
        [attr.data-testid]="'advanced-search-search-box-field-input-field'"
        [type]="disabled ? 'text' : filterConfigTypes[selector]"
        [(ngModel)]="item.value"
        [disabled]="disabled"
        (ngModelChange)="searchTermText($event)"
      />
    </mat-form-field>
  </p>
  <p *ngIf="!disabled && filterConfigTypes[selector] === types.NUMBER" class="pe-0">
    <mat-form-field class="form-field-wrapper w-100">
      <i class="fas fa-search medis-icon-prefix" matPrefix></i>
      <mat-label>{{ 'SEARCH_TERM' | translate }}</mat-label>
      <input
        matInput
        autocomplete="off"
        [attr.data-testid]="'advanced-search-search-box-field-input-field'"
        [type]="disabled ? 'text' : filterConfigTypes[selector]"
        [(ngModel)]="item.value"
        [disabled]="disabled"
        (ngModelChange)="searchTermText($event)"
        numbersOnly
      />
    </mat-form-field>
  </p>
  <p
    class="ps-0 pe-0"
    *ngIf="
      !disabled &&
      filterConfigTypes[selector] === types.DROPDOWN &&
      !!filterConfigMultiples[selector]
    "
  >
    <app-search-select
      [lazy]="selector === fConfigs.INQUIRER || selector === fConfigs.TERRITORY"
      (searchChange)="
        selector === fConfigs.INQUIRER
          ? filterInquirers($event)
          : selector === fConfigs.TERRITORY
          ? filterTerritory($event)
          : null
      "
      [trans]="filterConfigTranslate[selector]"
      [config]="filterConfigMapping[selector]"
      [default]="filterConfigDefault[selector]"
      [multiple]="true"
      [(selectedValue)]="item.value"
      (selectedChange)="searchTermDropdown(filterConfigMapping[selector])"
      [dataTestId]="'advanced-search-' + selector + '-dropdown'"
    ></app-search-select>
  </p>
  <p
    class="ps-0 pe-0"
    *ngIf="
      !disabled &&
      filterConfigTypes[selector] === types.DROPDOWN &&
      !filterConfigMultiples[selector]
    "
  >
    <app-search-select
      [trans]="filterConfigTranslate[selector]"
      [config]="filterConfigMapping[selector]"
      [multiple]="false"
      [default]="filterConfigDefault[selector]"
      [(selectedValue)]="item.value"
      (selectedChange)="searchTermDropdown(filterConfigMapping[selector])"
      [dataTestId]="'advanced-search-' + selector + '-dropdown'"
    ></app-search-select>
  </p>
  <p class="ps-0 pe-0" *ngIf="!disabled && filterConfigTypes[selector] === types.DATE">
    <app-date-time-picker
      [label]="filterConfigLabels[selector]"
      [style]="''"
      [altInputClass]="
        'advanced-search-date-field-input-' + selector + '-field date-field-input-advanced-search'
      "
      [(value)]="item.value"
      (valueChange)="searchTermDate($event)"
    ></app-date-time-picker>
  </p>
</ng-template>

<h2 mat-dialog-title>
  {{ 'ADVANCED_SEARCH' | translate }}
  <i
    class="far fa-times-circle medis-icon-large"
    (click)="closeModal()"
    [attr.data-testid]="'advanced-search-close-button'"
  ></i>
</h2>

<mat-dialog-content>
  <div class="row m-0 pb-4">
    <div class="col-md-7 ps-0">
      <div class="row d-flex" *ngIf="!view">
        <div class="col-md-5 ms-0 pe-1">
          <p>
            <app-search-select
              [(selectedValue)]="advancedSearch.selectedValue"
              (selectedChange)="setAdvancedSelector()"
              [config]="filterConfig"
              [multiple]="false"
              [trans]="true"
              [dataTestId]="'advanced-search-select-fields-dropdown'"
            ></app-search-select>
          </p>
        </div>
        <div class="col-md-4 ps-0 pe-0" [attr.data-testid]="'advanced-search-second-dropdown'">
          <ng-template
            [ngTemplateOutlet]="selectorTypes"
            [ngTemplateOutletContext]="{
              selector: advancedSearch?.selectedValue?.selector,
              item: advancedSearch,
              disabled: !advancedSearch.selectedValue
            }"
          ></ng-template>
        </div>
        <div class="col-md-3 ms-0 me-0 ps-1 pe-1">
          <mat-form-field
            class="mat-full-width form-field-wrapper min-w-60"
            [ngClass]="selectedJoinError ? 'invalid' : ''"
          >
            <mat-select
              [(ngModel)]="advancedSearch.selectedJoin"
              (ngModelChange)="selectedJoin()"
              [placeholder]="'OPTION_ADD' | translate"
              [attr.data-testid]="'advanced-search-options-dropdown'"
            >
              <mat-option *ngFor="let join of joinList" [attr.data-testid]="join" [value]="join">
                {{ join | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div
        class="row m-0 align-items-center d-flex mt-2"
        *ngFor="let query of advancedSearch.query; let i = index"
      >
        <div class="col-md-2 m-0 p-0">
          <mat-chip-option
            color="azure"
            class="advanced-search"
            [selectable]="false"
            [removable]="!view"
            (removed)="deleteQuery(i)"
            [ngClass]="{
              centered: view || i === 0,
              'ps-0': view || i === 0
            }"
          >
            <span class="text-14-500-white">
              {{ i === 0 ? '' : query.join ? query.join : ('OPTION_AND' | translate) }}
            </span>
            <span *ngIf="!view" matChipRemove>
              <i class="fas fa-times text-16-900-white"></i>
            </span>
          </mat-chip-option>
        </div>
        <div class="col-md-9 m-0 ms-4 p-0">
          <p
            class="text-13-500-azure medis-frame-4-azure query-desc"
            *ngIf="query.field"
            [attr.data-testid]="'generated-search-query'"
          >
            {{ filterConfigLabels[query.field] | translate }} : {{ query.displayTerm }}
          </p>
        </div>
      </div>
    </div>

    <div class="col-md-5 ps-4" style="margin-top: 6px">
      <div class="query-string">
        <p class="text-14-500-cool-grey p-0 m-0">{{ 'QUERY' | translate }}:</p>
        <p
          [innerHTML]="advancedSearch.combinedQuery"
          [attr.data-testid]="'generated-search-result'"
        ></p>
      </div>
    </div>
  </div>

  <mat-accordion [multi]="true">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="truncated-text">
            <span class="text-13-600-azure"> {{ 'EXPORT_LABEL' | translate }} </span>
          </div>
          <hr />
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="row">
        <ng-container *ngFor="let selection of exportSelections; let i = index">
          <div class="col" *ngIf="i % 12 === 0">
            <div class="row" *ngFor="let selection of exportSelections.slice(i, i + 12)">
              <label>
                <mat-checkbox
                  [disabled]="view"
                  [checked]="selection.checked"
                  (change)="setExported(selection)"
                ></mat-checkbox>
                <span
                  [attr.data-testid]="selection.exportName ? selection.exportName : selection.name"
                  class="text-14-500-dark ms-2"
                  >{{
                    (selection.exportName ? selection.exportName : selection.name) | translate
                  }}</span
                >
              </label>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    [matTooltip]="
      isValidExport() ? ('EXPORT_SEARCH' | translate) : ('SELECT_EXPORT_COLUMNS' | translate)
    "
    mat-button
    class="medis-button-main"
    [disabled]="!isValidSearch()"
    [attr.data-testid]="'export-button'"
    (click)="exportColumns()"
  >
    <i class="fa fa-file-download"></i>
    {{ 'EXPORT' | translate }}
  </button>
  <button
    mat-button
    *ngIf="edit"
    class="medis-button-main"
    [disabled]="!isValidSearch()"
    [attr.data-testid]="'save-changes-button'"
    (click)="onSave(inquiryName)"
  >
    <i class="fa fa-save"></i>
    {{ 'SAVE_CHANGES' | translate }}
  </button>
  <button
    mat-button
    *ngIf="(!edit && !view) || viewSharedSearch"
    class="medis-button-main"
    [disabled]="!isValidSearch()"
    [attr.data-testid]="'save-search-button'"
    (click)="openSave()"
  >
    <i class="fa fa-save"></i>
    {{ 'SAVE_SEARCH' | translate }}
  </button>
  <button
    mat-button
    class="medis-button-main"
    [attr.data-testid]="'submit-search-button'"
    [disabled]="!isValidSearch()"
    (click)="onSubmitSearch()"
  >
    <i class="fa fa-search"></i>
    {{ 'SEARCH_BTN' | translate }}
  </button>
  <button
    mat-button
    *ngIf="edit || view"
    class="medis-button-main"
    [attr.data-testid]="'share-button'"
    [disabled]="!isValidSearch()"
    (click)="openShare()"
  >
    <i class="fa fa-share"></i>
    {{ 'SHARE' | translate }}
  </button>
  <button
    mat-button
    class="medis-button-reject"
    *ngIf="!view"
    [disabled]="!shouldReset()"
    [attr.data-testid]="'reset-button'"
    (click)="clearSearch()"
  >
    {{ 'Reset' | translate }}
  </button>
</mat-dialog-actions>
