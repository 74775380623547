import { AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class DelegatedMatcher implements ErrorStateMatcher {
  constructor(private delegate: (control?: AbstractControl<any, any>) => boolean) {}

  isErrorState(control: AbstractControl<any, any>, _form: FormGroupDirective | NgForm): boolean {
    return this.delegate(control);
  }
}

export class DirtyTouchedMatcher implements ErrorStateMatcher {
  isErrorState(control: AbstractControl<any, any>, _form: FormGroupDirective | NgForm): boolean {
    return control.invalid && (control.dirty || control.touched);
  }
}
