import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlResolverService } from 'app/common/url-resolver.service';

@Injectable()
export class UserPreferencesService {
  private readonly PREFERENCES_URL: string;

  constructor(private http: HttpClient, _urlResolverService: UrlResolverService) {
    this.PREFERENCES_URL = _urlResolverService.misApiUrlForPath('/users/preferences');
  }

  get(name: string): Observable<string> {
    return this.http.get<string>(this.PREFERENCES_URL + '/' + name);
  }

  set(name: string, preferences: string): Observable<string> {
    return this.http.post<string>(this.PREFERENCES_URL + '/' + name, preferences);
  }

  setWithoutAnswer(name: string, preferencesJson: any): void {
    this.set(name, JSON.stringify(preferencesJson)).subscribe();
  }
}

/**
 * Intended to be used as list for user preferences names, in order to avoid collisions.
 * Might be switched to enum as well
 */
export namespace UserPreferencesService {
  export const AUTH_REQ_COLUMNS = 'AUTH_REQ_COLUMNS';
  export const INQUIRIES_RESULTS_COLUMNS = 'INQUIRIES_RESULTS_COLUMNS';
  export const TEAM_SETTINGS_SELECTED_TEAM = 'TEAM_SETTINGS_SELECTED_TEAM';
}
