import { MedisNavigationItem, newGroup, newItem } from '../types';
import { filterNavigationItemsByRoles } from './profile-navigation';
import { userRoles as roles } from '../consts';

function commonUserAdminNavigation(): MedisNavigationItem {
  return newGroup(
    'sections',
    ' ',
    '',
    [
      newItem('users', 'USERS', 'fa-users', '/administration/users'),
      newItem('sync', 'SYNC', 'fa-exchange-alt', '/administration/syncs/users'),
    ],
    [...roles.admin]
  );
}

export function userAdminSyncNavigation(userRoles: string[]): MedisNavigationItem[] {
  return filterNavigationItemsByRoles([commonUserAdminNavigation()], userRoles);
}

export function userAdminNavigation(userRoles: string[]): MedisNavigationItem[] {
  const userAdminNavigation: MedisNavigationItem[] = [
    commonUserAdminNavigation(),
    newGroup(
      'users',
      'FILTER_BY',
      '',
      [
        newItem('active', 'Active', 'fa-user-friends'),
        newItem('inactive', 'INACTIVE', 'fa-user-slash'),
        newItem('no_teams', 'NO_TEAMS', 'fa-user'),
        newItem('all', 'ALL', 'fa-users'),
      ],
      [...roles.admin]
    ),
  ];

  return filterNavigationItemsByRoles(userAdminNavigation, userRoles);
}

export function hcAdminNavigation(userRoles: string[]): MedisNavigationItem[] {
  const hcpNavigation = [
    newGroup('users', 'HCP_ADMINISTRATION', '', [
      newItem(
        'enquiriesList',
        'ENQUIRERS_LIST',
        'fa-user-friends',
        '/administration/hcps',
        null,
        null,
        [...roles.admin]
      ),
      newItem(
        'specializations',
        'SPECIALIZATIONS',
        'fa-book-medical',
        '/administration/specializations',
        null,
        null,
        [...roles.sysAdmin]
      ),
    ]),
  ];

  return filterNavigationItemsByRoles(hcpNavigation, userRoles);
}
