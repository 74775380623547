import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { BaseModel, parseArray } from './data-model';
import { Injector } from '@angular/core';

export interface DataService<T extends BaseModel> {
  values: BehaviorSubject<T[]>;

  query: any;

  refresh(): void;
}

export interface ICursorPaginationData {
  totalCount: number;
  startCursor: string;
  endCursor: string;
}

export interface ICursorPaginateForwardParams {
  first: number;
  after: string;
}

export interface ICursorPaginateBackwardParams {
  last: number;
  before: string;
}

export type TCursorPaginationParams = ICursorPaginateBackwardParams | ICursorPaginateForwardParams;

export abstract class BaseDataService<T extends BaseModel> implements DataService<T> {
  apollo: Apollo;
  values: BehaviorSubject<T[]> = new BehaviorSubject([]);
  allValues: BehaviorSubject<T[]> = new BehaviorSubject([]);
  abstract objectInstance: T;
  abstract query: any;
  abstract fieldName: string;

  constructor(injector: Injector) {
    this.apollo = injector.get(Apollo);
  }

  refresh(ignoreCache?: boolean, variables?: any) {
    let q: any;
    if (ignoreCache) {
      q = this.apollo.query({
        query: this.query,
        variables: variables,
        fetchPolicy: 'network-only',
      });
    } else {
      q = this.apollo.query({
        query: this.query,
        variables: variables,
      });
    }
    this.setupSubscriptionValues(q);
  }

  refreshAllValues(ignoreCache?: boolean, variables?: any) {
    if (variables == undefined) {
      variables = { 'X-NO-LOADING': true };
    }
    let q: any;
    if (ignoreCache) {
      q = this.apollo.query({
        query: this.query,
        variables: variables,
        fetchPolicy: 'network-only',
      });
    } else {
      q = this.apollo.query({
        query: this.query,
        variables: variables,
      });
    }
    this.setupSubscriptionAllValues(q);
  }

  setupSubscriptionValues(q: any) {
    q.subscribe((res) => {
      if (res.data) {
        let v = parseArray<T>(res.data, this.objectInstance.constructor, this.fieldName);
        this.values.next(v);
      } else {
        console.log('Response with no data!');
      }
    });
  }

  setupSubscriptionAllValues(q: any) {
    q.subscribe((res) => {
      //console.log(res);
      if (res.data) {
        let v = parseArray<T>(res.data, this.objectInstance.constructor, this.fieldName);
        this.allValues.next(v);
      } else {
        console.log('Response with no data!');
      }
    });
  }

  refreshIfEmpty() {
    if (this.values.getValue().length == 0) {
      this.refresh();
    }
  }
}
