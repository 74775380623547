export const malayLangData = {
  MEDICAL_INFO_SYSTEM: 'Sistem Maklumat Perubatan',
  ACTIVE_EVENTS: 'Acara Aktif',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'Pilih acara untuk menyemak imbas pengguna.',
  BACK_TO_THE_EVENT_SELECTION: 'Kembali ke pemilihan acara',
  IF_YOU_FORGOT_YOUR_PASSWORD: 'Jika anda terlupa kata laluan anda',
  LOGIN: 'Log masuk',
  RESET_IT_HERE: 'tetapkan semula di sini',
  CHANGE_LANGUAGE_TO: 'Tukar Bahasa kepada',
  TEST_SYSTEM: 'SISTEM UJIAN',
  HELP: 'menolong',
  THIS_IS_TEST_ENV:
    'Ini adalah persekitaran ujian, jika anda ingin memasukkan pertanyaan sebenar, pergi ke',
  USER_OR_PASS_IS_INCORRECT: 'Nama pengguna atau kata laluan tidak betul',
  OPS: 'Ops',
  WE_DID_NOT_FIND: 'Kami tidak menemui apa yang anda cari',
  VIDEO_TUTORIALS: 'Tutorial Video',
  STILL_DIDNT_FIND: 'Masih tidak mencari apa yang anda cari,',
  ASK_THE_SUPPORT_TEAM: 'Tanya pasukan sokongan',
  LOGGED_IN_AS: 'Log masuk sebagai',
  MY_PROFILE: 'Profil saya',
  LOGOUT: 'Log keluar',
  RETRIEVING_YOUR_ACCESS_INFO: 'Mengambil maklumat akses anda',
  LOGGED_IN_CAN_CLOSE: 'Log masuk, kini anda boleh menutup tab ini',
  TOKEN_HAS_NOT_BEEN_SAVED: 'Token belum disimpan dengan betul, sila hubungi sokongan',
  SHARE_POINT_LOGIN: 'Log Masuk SharePoint',
  LOGIN_TO_SHARE_POINT: 'Sila log masuk ke SharePoint untuk meneruskan',
  PASSWORD_RESET: 'Memadam kata laluan',
  RESET_PASSWORD: 'Menetapkan semula kata laluan',
  INSERT_YOUR_EMAIL_TO_RESET_PASS:
    'Sila masukkan e-mel anda untuk menetapkan semula kata laluan anda',
  CANCEL: 'Batal',
  CANCEL_UPPERCASE: 'CANCEL',
  RETURN_TO_LOGIN: 'Kembali ke Log Masuk',
  USERNAME: 'Nama pengguna',
  USERNAME_IS_REQUIRED: 'Nama pengguna diperlukan',
  PASSWORD: 'Kata Laluan',
  PASSWORD_IS_REQUIRED: 'kata laluan diperlukan',
  FIRST_NAME: 'Nama pertama',
  LAST_NAME: 'Nama terakhir',
  REGISTER: 'Daftar',
  LOADING: 'Memuatkan',
  INSERT_NEW_PASS: 'Sila masukkan kata laluan baru anda',
  VERIFYING_TOKEN: 'Mengesahkan Token',
  PASSWORD_DO_NOT_MATCH: 'Kata laluan tidak sepadan',
  PASS_RESET_SUCCESSFUL: 'Reset Kata Laluan Berjaya',
  SAVE_NEW_PASS: 'Simpan Kata Laluan Baru',
  GO_TO_INBOX: 'Pergi ke Peti Masuk',
  SELECT_ALL: 'Pilih semua',
  DESELECT_ALL: 'Nyahpilih Semua',
  DO_NOT_SHOW_AGAIN: 'Jangan tunjukkan lagi',
  INQUIRY: 'Siasatan',
  ATTACHMENT_WITH_THIS_INQUIRY: '> Lampiran dengan Pertanyaan ini',
  USER_DETAILS: 'Maklumat Pengguna',
  CLOSE: 'Tutup',
  TEAMS: 'Pasukan',
  TEAM: 'Pasukan',
  SPECIALIZATION_IS: 'Pengkhususan adalah',
  REQUIRED: 'diperlukan',
  RESULTS_PER_PAGE: 'keputusan setiap halaman',
  RESULTS_FOUND: 'Keputusan Ditemui',
  EXPORT: 'Eksport',
  PERSONAL_SETTINGS: 'Tetapan Peribadi',
  TEAM_SETTINGS: 'Tetapan Pasukan',
  USER_SETTINGS_OF: 'PENGATURAN PENGGUNA',
  SET_TEMPLATE: 'Tetapkan Templat',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: 'Pemberitahuan akan ditimpa dengan yang terkini',
  PREVIEW: 'Pratonton',
  NOTIFICATIONS: 'PEMBERITAHUAN',
  ROLE: 'ROLE',
  TEMPLATE_NAME: 'TEMPLATE NAME',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'Simpan Pengguna ini sebagai Templat',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'Tambah templat untuk pengguna anda dan salin peranan dan pilihan untuk pemberitahuan',
  AUTH_AND_NOTIFY_TEMPLATE: 'Templat Pemberitahuan dan Pemberitahuan',
  MY_ROLES: 'Peranan saya',
  STATUS: 'STATUS',
  THIS_ROLE_IS_TEMP:
    'Peranan ini bersifat sementara, telah diberikan kerana pengguna ini adalah wakil',
  CREATED: 'DIBUAT',
  APPROVED_REJECTED: 'DILULUSKAN / DITEMUKAN',
  APPROVED_REJECTED_BY: 'DILULUSKAN / DILAKSANAKAN OLEH',
  ARCHIVE: 'ARKIB',
  SAVE: 'Jimat',
  IMMEDIATE_EFFECT: 'Kesan serta merta',
  OUT_OF_OFFICE_DEPUTY: 'TIMBALAN PEJABAT',
  AUTO_ASSIGN_TO: 'AUTO ASSIGN TO',
  GEOGRAPHY: 'GEOGRAFI',
  ALIGNMENT: 'PERUNDANGAN',
  Alignments: 'Penjajaran',
  Address: 'Alamat',
  PHONE_FAX: 'Telefon / Faks',
  Email: 'E-mel',
  Contacts: 'Kenalan',
  WORK_INFO: 'Maklumat Kerja',
  LAST_NAME_IS: 'Nama akhir adalah',
  PERSONAL_INFO: 'Maklumat peribadi',
  USER: 'Pengguna',
  ID: 'ID',
  UPDATED_FROM: 'dikemas kini dari',
  ON: 'pada',
  CRM: 'CRM',
  IMPORTED_FROM: 'diimport dari',
  CHANGE_PASS: 'Tukar kata laluan',
  NOTIFICATION_IN_TEMPLATE: 'Pemberitahuan dalam Templat',
  CONTINUE: 'Teruskan',
  DONT_SHOW_AGAIN: 'Jangan tunjukkan mesej ini lagi',
  USING_THE_TABS_ON_THE: 'menggunakan tab di bahagian atas skrin',
  PERSONAL_PROFILE_SETTINGS: 'Tetapan Profil Peribadi',
  AND_YOUR: 'dan anda',
  YOU_CAN_SWITCH_BETWEEN: 'Anda boleh menukar antara anda',
  WELCOME_TO_YOUR_USER_PROFILE: 'Selamat datang ke Profil Pengguna anda',
  SAVE_THIS_USER_AS: 'Simpan Pengguna ini sebagai Templat',
  INCLUDE_NOTIFY_SETTINGS: 'Termasuk Tetapan Pemberitahuan',
  INCLUDE_ROLES_SETTINGS: 'Termasuk Tetapan Peranan',
  ACTIVE_NOTIFICATIONS: 'PEMBERITAHUAN AKTIF',
  SET_THIS_USER_AS_A_: 'Tetapkan Kebenaran dan Pemberitahuan Pengguna ini sebagai Templat Baru',
  REQUEST_AUTH: 'Memohon Kebenaran',
  REQUEST: 'Permintaan',
  FEEDBACK_SUPPORT: 'Maklum balas dan Sokongan',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'Jika anda masih tidak dapat mencari apa yang anda cari, tanya pasukan sokongan! Sekiranya anda mempunyai cadangan untuk penambahbaikan di masa hadapan, ini adalah tempat yang tepat.',
  TEMPLATE: 'Templat',
  SIGNATURE: 'Tandatangan',
  Disclaimers: 'Penafian',
  Greetings: 'Salam',
  CUSTOMIZE: 'Menyesuaikan',
  SAVE_UPPERCASE: 'JIMAT',
  DELETE: 'DELETE',
  RESET_TO_DEFAULT: 'KEMBALI KE DEFAULT',
  AFTER_SIGNATURE: 'Selepas Tandatangan',
  BEFORE_SIGNATURE: 'Sebelum Tandatangan',
  AFTER_ANSWER: 'Selepas Jawab',
  BEFORE_ANSWER: 'Sebelum Jawapan',
  AFTER_GREET: 'Selepas Salam',
  BEFORE_GREET: 'Sebelum Salam',
  Any: 'Mana-mana',
  HCP: 'HCP',
  NO_HCP: 'Tanpa HCP',
  Position: 'Jawatan',
  ON_LABEL: 'On label',
  OFF_LABEL: 'Off label',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'Anda cuba menyesuaikan Templat',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    'Anda tidak mempunyai kebenaran untuk melakukannya. Sekiranya anda ingin meneruskan, salinan templat akan dibuat dan anda akan membuat perubahan di sana.',
  CREATE_COPY: 'Buat Salin',
  RECEIVED_ON: 'Diterima',
  SENDER_EMAIL: 'E-mel Pengirim',
  SUBJECT: 'Subjek',
  UNSPAM: 'Unspam',
  UPLOAD: 'Muat naik',
  APPROVED: 'Diluluskan',
  DRAFT: 'Draf',
  SELECT_STATUS: 'Pilih Status',
  UPLOAD_AS: 'Muat Naik Sebagai',
  TITLE: 'Tajuk',
  REPOSITORY: 'Repositori',
  BROWSE: 'Semak imbas',
  URL: 'URL',
  APPROVED_ON: 'Diluluskan pada',
  AUTH_HOLDER: 'Pemegang kebenaran',
  AUTH_NUMBERS: 'Nombor kebenaran',
  APPROVED_FOR: 'Diluluskan untuk',
  SELECT_ONE_REGION: 'Pilih Satu Wilayah',
  OWNED_BY: 'Dimiliki oleh',
  KEYWORDS: 'Kata kunci',
  TOPICS: 'Topik',
  CATEGORIES: 'Kategori',
  PRODUCTS: 'Produk',
  PRODUCT: 'Produk',
  LANGUAGE_IS_REQUIRED: 'Bahasa diperlukan',
  LANGUAGE: 'Bahasa',
  TARGET_CUSTOMERS: 'Pelanggan sasaran',
  TYPE_IS_REQUIRED: 'Jenis diperlukan',
  TYPE: 'Jenis',
  TITLE_IS_REQUIRED: 'Tajuk diperlukan',
  DEFAULT_REFERENCE_IS_CREATED_:
    'Rujukan lalai dicipta dengan gaya Vancouver. Jika anda ingin mengatasi ini, masukkan keseluruhan rujukan di sini',
  REFERENCE: 'Rujukan',
  DOI: 'DOI',
  PUBMED_ID: 'ID Pub',
  PAGES: 'Halaman',
  ISSUE: 'Isu',
  VOLUME: 'Isipadu',
  JOURNAL: 'Jurnal',
  AUTHORS: 'Pengarang',
  ABSTRACT: 'Abstrak',
  REFERENCE_IS_REQUIRED: 'Rujukan diperlukan',
  THIS_DOC_IS_APPROVED: 'Dokumen ini diluluskan. Klik di sini untuk membuka',
  ORIGINAL_DOCUMENT_AND_: 'dokumen asal dan masukkan komen anda',
  EDIT_FILE: 'Edit Fail',
  PROPERTIES: 'Hartanah',
  OPEN: 'Buka',
  LOCALIZED_DOCUMENTS: 'Dokumen yang dilokalkan',
  ORIGINAL_DOCUMENTS: 'Dokumen asal',
  INTERNAL_APPROVAL_NUMBER: 'Nombor kelulusan dalaman',
  CREATED_BY: 'Dicipta oleh',
  EXPIRATION_DATE: 'Tarikh luput',
  VERSION_NUMBER: 'Nombor versi',
  NO_VERSION_AVAILABLE: 'Versi Tidak Ada',
  ARCHIVED_IF_NO_DRAFT: 'Diarkib: jika tiada Draf / versi yang diluluskan hadir.',
  APPROVED_FOR_USERS: 'Diluluskan: untuk pengguna.',
  DRAFT_FOR_AUTHORS_: 'Draf: untuk pengarang dan penyunting.',
  VERSION_LEGEND: 'Legenda versi',
  VERSION: 'versi',
  SELECT_ONE_LANG: 'Pilih satu Bahasa.',
  SELECT_ONE_TYPE: 'Pilih satu Jenis.',
  INTERNAL_NUMBER_REQUIRED: 'Nombor kelulusan dalaman diperlukan.',
  FILE: 'Fail',
  ATTACH_FILE: 'Melampirkan fail',
  LITERATURE: 'Kesusasteraan',
  SLIDE_DECK: 'Gelongsor Geladak',
  PIL: 'PIL',
  FAQ: 'Soalan Lazim',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: 'Tambahkan langkah kelulusan',
  ADD_PARALLEL_STEP: 'Tambah langkah selari',
  DELETE_STEP: 'Padamkan langkah',
  DELETE_ALL_PARALLEL_STEPS: 'Padam semua langkah selari',
  CHANGE_STATUS_NAME: 'Tukar Nama Status',
  SET_REQUIRED_ROLE: 'Tetapkan peranan yang diperlukan',
  SET_TEAM_MEMBER: 'Tetapkan ahli pasukan',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: 'Anda tidak mempunyai kebenaran untuk mengubah proses ini.',
  SAVE_ROLE: 'JIMAT PERAN',
  CREATE_NEW_WORKFLOW: 'BUAT KERJA BARU',
  CREATE_NEW_STEP: 'LAKUKAN LANGKAH BARU',
  PLEASE_ENTER_THE_NUMBER_OF_:
    'Sila masukkan bilangan langkah kelulusan selari, yang bermaksud langkah-langkah yang diluluskan pada masa yang sama (tidak secara berurutan). Sekiranya anda tidak mempunyai langkah kelulusan yang selari, masukkan 1. Anda boleh menukar bilangan langkah kelulusan di kemudian hari.',
  APPROVAL_MUST_BE_AT_LEAST: 'Kelulusan sekurang-kurangnya',
  INSERT_THE_NEW_STATUS_NAME: 'Masukkan nama status baru',
  INSERT_THE_NEW_WORKFLOW_NAME: 'Masukkan nama alur kerja baru.',
  INSERT_THE_NEW_STEP_NAME: 'Masukkan nama langkah baru.',
  NEW_DOCUMENT: 'Dokumen Baru',
  CREATE: 'Buat',
  DOCUMENT_MANAGEMENT: 'Pengurusan dokumen',
  CONFIRM: 'Sahkan',
  CONFIRM_PROCESS: 'PROSES PENGESAHAN',
  DEFINE_APPROVAL_STEPS_AND_TEAM: 'Tentukan LANGKAH-LANGKAH KELULUSAN DAN PASUKAN',
  SET_DETAILS: 'SET BUTIRAN',
  SELECT_TYPE: 'JENIS PEMILIH',
  COMPLETE_LOCALIZATION_PROCESS: 'Proses Penyetempatan Lengkap',
  CREATE_NEW_VERSION: 'Buat Versi Baru',
  CREATE_NEW_DOCUMENT: 'Buat Dokumen Baru',
  IS_PRIVATE: 'Adakah Swasta',
  SHARED_WITH_OTHER_TEAMS: 'Dikongsi dengan pasukan lain',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    'Sila masukkan bilangan langkah kelulusan yang diperlukan. Jika dua kelulusan selari, ia dianggap satu langkah. Anda sentiasa boleh mengubah bilangan langkah kelulusan kemudian.',
  DONE: 'Selesai',
  SUBMIT_FOR_APPROVAL: 'Hantar Untuk Kelulusan',
  YOU_HAVE_TO_CONFIRM_THE_: 'Anda perlu mengesahkan proses sebelum muat naik.',
  SELECT_YOUR_DEC_TYPE: 'Pilih jenis dokumen anda',
  TARGET_AUDIENCE: 'Penonton sasaran',
  PROCESS_DETAILS: 'Perincian Proses',
  START_DATE: 'Tarikh mula',
  DOC_DETAILS: 'Butiran Dokumen',
  CONFIRMATION: 'Pengesahan',
  APPROVAL_PROCESS: 'Proses Kelulusan',
  SCHEDULED_EXP_DATE: 'Tarikh Luput Berjadual',
  SCHEDULED_PUBLISHING_DATE: 'Tarikh Penerbitan Berjadual',
  NO_PROCESS_SELECTED: 'Tiada Proses Dipilih',
  COUNTRIES: 'Negara',
  DOC_ID: 'ID Dokumen',
  THERAPEUTIC_AREA: 'Kawasan Terapi',
  FILTER: 'Penapis',
  BACK: 'belakang',
  ATTACH: 'Lampirkan',
  COPY_ANSWER: 'Salin Jawapan',
  SIMILAR_INQUIRIES: 'Pertanyaan yang serupa',
  SDR_FAQ: 'SRD / Soalan Lazim',
  SLIDE_DECKS: 'Gelongsor Geladak',
  MEDICAL_DOC: 'Dokumen Perubatan',
  SHOW: 'Tunjuk',
  LOAD_MORE: 'Tambah lagi',
  NO_RESULT_FOUND: 'Tiada keputusan dijumpai.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'dapat digunakan untuk mencari keseluruhan ayat, - dapat digunakan untuk mengecualikan dari hasil pencarian dalam kata yang ditentukan',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'Cari Dokumen perubatan atau pertanyaan serupa',
  SEARCH: 'SEARCH',
  DRAFT_VERSION: 'Versi draf',
  AVAILABLE_ACTIONS: 'Tindakan yang ada',
  OPEN_DOC: 'Buka Dokumen',
  UPLOAD_FILE: 'Muat Naik Fail',
  THE_DOCUMENT_IS_STILL_IN_APPROVAL_:
    'Dokumen masih dalam kelulusan, jika anda mahu mengubah kandungan dokumen anda boleh membuka dokumen dalam Sharepoint atau memuat naik versi yang dikemaskini.',
  FINALIZE_LOCALIZATION: 'Selesaikan penyetempatan',
  THIS_DOC_HAS_BEEN_LOCALIZED:
    'Dokumen ini telah dilokalkan, proses mesti diselesaikan sebelum mendapat persetujuan.',
  Preview: 'Pratonton',
  PREVIEW_AND_SEND: 'Pratonton dan hantar',
  PREVIEW_AS_PDF: 'Pratonton sebagai pdf',
  PUBLISHED_VERSION: 'Versi Diterbitkan',
  NEW_VERSION: 'Versi baru',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    'Dokumen masih dalam persetujuan, jika anda mahu menukar kandungan dokumen muat naik fail lain.',
  OUT_TO: 'keluar ke',
  NEXT: 'seterusnya',
  SELECTED_SLIDES: 'Slaid Terpilih',
  GO_TO_SLIDE: 'PERGI KE SLIDE',
  PREV: 'sebelumnya',
  SELECT_SLIDE: 'Pilih Slaid',
  ANSWER: 'Jawapan',
  BACK_TO_STANDARD_VIEW: 'Kembali ke paparan standard.',
  UPLOAD_NEW_FILE: 'Muat naik Fail Baru',
  CREATE_FILE: 'BUAT FILE',
  EDITING_WORD_DOC: 'Menyunting Dokumen Word',
  INSERT_FILE_NAME: 'Masukkan nama fail',
  PROCEED_WITH_THE_LINK_: 'Teruskan dengan pautan fail',
  A_NEW_WINDOW_WILL_BE_:
    'Tetingkap baru akan dibuka dengan fail kosong, menulis kandungan dan menutup tab',
  INSERT_THE_NAME_FOR_:
    'Masukkan nama lampiran yang ingin anda buat dan teruskan dengan pembuatan fail',
  INSTRUCTIONS: 'Arahan',
  CREATE_NEW_WORD_FILE_FOR: 'Buat fail perkataan baru untuk',
  FROM: 'dari',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'Anda boleh mendapatkan pertanyaan terakhir yang anda masukkan dalam sistem yang dihantar dan belum dihantar. Anda boleh menggunakan semula pertanyaan yang telah anda masukkan untuk membuat lebih banyak pertanyaan serupa atau kembali ke pertanyaan yang belum selesai anda masukkan.',
  MY_DRAFT_INQ: 'Pertanyaan Draf Saya',
  WARNING_CHANGES_NOT_SAVED: 'Amaran: Perubahan tidak disimpan',
  PAGE_THAT_WILL_BE_LOST: 'halaman yang akan hilang sekiranya anda memutuskan untuk meneruskan.',
  YOU_HAVE_UNUSED: 'Anda tidak mempunyai perubahan dalam',
  ARE_YOU_SURE_YOU_WANT_TO_: 'Adakah anda pasti mahu meninggalkan laman ini tanpa menjimatkan?',
  LEAVE_THIS_PAGE: 'Meninggalkan halaman ini',
  STAY_ON: 'Teruskan',
  ASSIGNED_TO: 'Ditugaskan untuk',
  THIS_INQ_HAS_BEEND_INSERTED_: 'Siasatan ini telah dimasukkan semasa acara tersebut.',
  STATUS_IN_APPROVAL: 'Status: Dalam Kelulusan',
  THE_RESPONSIBLE_FOR_THE_ANSWER_:
    'Yang bertanggungjawab untuk kelulusan jawapan mesti menerima jawapannya.',
  STATUS_DRAFT: 'Status: Draf',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    'Jika anda ingin membuat siasatan sebenar, pergi ke tab dan klik pada butang buat.',
  INQ_IMPORTED_FROM: 'Pertanyaan yang diimport dari',
  SEND_NEW_ANSWER: 'KIRIM BARU',
  RETURN_TO_INBOX: 'KEMBALI KE INBOX',
  SUBMITTED_FOR_APPROVAL: 'Dihantar untuk Kelulusan',
  ANSWER_SUBMITTED_ON: 'Jawapan dihantar pada',
  BY: 'oleh',
  INQ_HAS_BEEN_CLOSED: 'Siasatan telah ditutup tanpa jawapan.',
  BCC: 'Bcc',
  ANSWER_GIVEN_ON: 'Jawapan diberikan pada',
  ANSWER_SENT_ON: 'Jawapan dihantar',
  INBOX: 'Peti masuk',
  OPEN_ORIGINAL_INQ: 'Buka pertanyaan asal',
  CLOSE_ANSWER_NOT_NEEDED: 'Tutup: Anser tidak diperlukan',
  HISTORY: 'Sejarah',
  ADD_A_NOTE: 'Tambah Nota',
  ADD_A_NOTE_INTO_: 'Tambahkan nota ke dalam sejarah siasatan.',
  SHARE_OR_START_A_FOLLOW_UP: 'Kongsi atau mulakan tindak lanjut',
  SEE_ALL_INQ_RELATED_TO: 'Lihat semua Pertanyaan yang berkaitan dengan',
  SRD_NEEDED: 'SRD Diperlukan',
  ANSWER_UNAVAILABLE: 'Jawapan tidak tersedia',
  QUESTION: 'Soalan',
  SUBMIT: 'SUBMIT',
  ANSWER_UPPERCASE: 'JAWAPAN',
  DETAILS: 'BUTIRAN',
  SPLIT_INQ: 'Percubaan Split',
  ADD_QUESTION: 'Tambah Soalan',
  ENTER_QUESTION: 'Masukkan Soalan',
  MARK_AS_ANSWERED: 'Tandakan sebagai Dijawab',
  TO_BE_ANSWERED: 'Untuk Dijawab',
  FOLLOW_UP_RECEIVED: 'Ikut terus terima',
  ERROR: 'Ralat',
  SHOW_ORIGINAL_MESSAGE: 'Tunjukkan mesej asal',
  SEND: 'Hantar',
  DO_NO_SEND: 'Jangan hantar',
  OK: 'Okey',
  MAX_LENGTH_FOR_ZIP_CODE: 'Panjang Maksimum untuk Poskod adalah',
  CHARACTERS: 'watak',
  ADDRESS_1_IS_: 'Alamat 1 adalah',
  LABEL_IS: 'Label ialah',
  NO_QUESTION_INSERTED: 'Tiada soalan dimasukkan',
  WARNING: 'AMARAN',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'Tindakan susulan tidak tersedia untuk interaksi ini.',
  INSERT_INQ_TEXT: 'Masukkan teks pertanyaan',
  SHOW_MORE: 'Tunjukkan Lagi',
  OUT_OF: 'daripada',
  INQ_TO_BE_SENT: 'Pertanyaan untuk dihantar',
  HIDE_INQ: 'Sembunyikan Pertanyaan',
  SHARE: 'Berkongsi',
  HCP_PROFILE_OF: 'PROFIL HCP daripada',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'Isikan semua medan wajib di bawah dan sekurang-kurangnya satu alamat e-mel dan kemudian simpan untuk melihat pemberitahuan penerimaan GDPR.',
  CONFIRMATION_SENT_BY: 'Pemberitahuan dihantar oleh',
  COUNTRY_IS: 'Negara adalah',
  COUNTRY: 'Negara',
  AT_LEAST_ONE_CONTACT_IS_: 'Sekurang-kurangnya satu kenalan adalah',
  EMAIL_IS: 'E-mel adalah',
  EMAIL_LABEL_IS: 'Label e-mel adalah',
  PHONE_FAX_IS: 'Label telefon / Faks ialah',
  PHONE_FAX_NUMBER_IS: 'Nombor telefon / Faks adalah',
  ADDRESS: 'Alamat',
  DELETE_PERSONAL_DATA: 'Padamkan Data Peribadi',
  HCP_VERIFIED: 'HCP disahkan',
  KEY_OPINION_LEADER: 'Pemimpin pendapat utama',
  HOSPITAL_BASED: 'Berasaskan Hospital',
  HCP_RELATED_INFORMATION: 'MAKLUMAT BERKAITAN HCP',
  ACCEPT: 'Terima',
  REJECT: 'Tolak',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'Adakah anda mahu menerima cadangan ini?',
  SALES_REP: 'REP JUALAN',
  SUGGEST_A_TOPIC: 'Cadangkan topik',
  TEAM_SPECIFIC_FIELDS: 'TEAM KHUSUS TEAM',
  ADR_RELATED: 'ADR Berkaitan',
  NOT_ADR_RELATED: 'Tidak Berkaitan dengan ADR',
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'Sila semak mesej dan klasifikasikan sebagai Reaksi Ubat-ubatan yang Berkaitan atau tidak.',
  ADVERSE_DRUG_REACTION: 'Tindak Balas Ubat-ubatan yang merugikan',
  CHANGE_DETAILS: 'PERUBAHAN BUTIRAN',
  YES: 'Ya',
  NO: 'Tidak',
  DUE_DATE: 'Tarikh Akhir',
  CRITICAL: 'Kritikal',
  NORMAL: 'Biasa',
  PRIORITY: 'Keutamaan',
  SELECT_DETAILS: 'BUTIRAN PILIH',
  SAVE_REVIEW: 'Simpan semula',
  SET_TO_ALL_INQ: 'SET SEMUA PERTANYAAN',
  PRODUCT_QUALITY_COMPLAINT: 'Aduan kualiti produk',
  PQC_PC_A_PRODUCT_:
    'PQC / PC Aduan Kualiti Produk (QC) atau masalah ditakrifkan sebagai sebarang kebimbangan yang melibatkan kecacatan atau kerosakan produk ubat atau mengenai kualiti, prestasi, keselamatan produk ubat, contohnya. bau dan / atau rasa yang diubah, kemasan yang buruk atau rosak, disyaki pencemaran, dll.',
  OFF_LABEL_USE_OF_PHARMA___:
    'Penggunaan ubat farmasi di luar label untuk petunjuk yang tidak disetujui atau dalam kumpulan umur, dos, atau cara pentadbiran yang tidak diluluskan.',
  A_RESPONSE_TO_A_MEDICINAL_:
    'Tindak balas terhadap produk ubat-ubatan yang berbahaya dan tidak disengaja dan hubungan kausal antara produk ubat dan kejadian buruk sekurang-kurangnya kemungkinan masuk akal. Kes-kes keadaan khas (contohnya kes kehamilan, penggunaan label luar, dos berlebihan) perlu ditanda sebagai ADR.',
  NORMAL_IS_7_WORKING_DAYS:
    'Normal adalah 7 hari bekerja, Tinggi 48 jam dan Kritikal (berkaitan pesakit) 24 jam',
  CATEGORY_AND_TOPIC_ARE: 'Kategori dan topik adalah',
  PRODUCT_IS: 'Produk adalah',
  DO_YOU_WANT_TO_ACCEPT_: 'Adakah anda mahu menerima cadangan ini?',
  MERGE: 'Gabung',
  Duplicate: 'Gandakan',
  Other: 'Yang lain',
  Spam: 'Spam',
  CLOSE_INTERACTION: 'Interaksi rapat',
  RE_OPEN_THE_TARGET_: 'Buka semula siasatan sasaran setelah bergabung',
  MARK_AS_INTERNAL_: 'Tandakan sebagai mesej dalaman untuk siasatan sedia ada.',
  MARK_AS_AN_ERROR_: 'Tandai sebagai mesej ralat untuk pertanyaan yang ada.',
  THIS_MESSAGE_WILL_BE_EXISTING_:
    'Mesej ini akan ditandai sebagai tindak balas dari pertanyaan yang ada.',
  THANK_YOU: 'Terima kasih',
  AN_INTERNAL_MESSAGE: 'Mesej dalaman',
  AN_ERROR_MESSAGE: 'Mesej ralat',
  NEW_INQ: 'Siasatan baru',
  A_FOLLOW_UP_RESPONSE: 'Sambutan tindak balas',
  THIS_INQ_IS: 'Pertanyaan ini adalah',
  SPLIT_INTERACTION: 'Interaksi Split',
  SAVE_AND_CLOSE: 'Simpan dan tutup',
  SAVE_INQ: 'Simpan Pertanyaan',
  REVIEW_THE_TEXT_AND: 'Kaji teks dan pengkategorian, dan sahkan',
  CHOOSE_THE_RELEVANT_PRODUCT:
    'Pilih produk, kategori, dan topik yang berkaitan untuk teks yang dipilih',
  SELECT_THE_TEXT_THAT_:
    'Pilih teks yang mengidentifikasi pertanyaan mengenai satu produk / topik (jangan sertakan ucapan, tandatangan, penafian, atau maklumat lain yang tidak berkaitan)',
  REPEAT_THE_PREVIOUS_: 'Ulangi langkah sebelumnya untuk menambah lebih banyak pertanyaan',
  READ_MORE: 'Baca lebih lanjut',
  READ_LESS: 'Baca kurang',
  DO_NOT_INCLUDE_:
    'tidak termasuk salam, tandatangan, penafian, atau maklumat lain yang tidak berkaitan',
  SELECT_THE_TEXT_THAT_IDENT: 'Pilih teks yang mengenal pasti soalan mengenai satu produk / topik',
  IF_YOU_HAVE_MULTIPLE_:
    'Sekiranya anda mempunyai banyak produk atau topik, pertanyaan mesti dibahagikan.',
  THE_FOLLOWING_PROCESS_:
    'Proses berikut akan membantu anda mengenal pasti teks yang berkaitan untuk setiap produk / topik: ',
  EXPORT_TO_PDF: 'EXPORT TO PDF',
  Added: 'Ditambah',
  Deleted: 'Dipadamkan',
  From: 'Dari',
  To: 'Untuk',
  BACK_TO_INBOX: 'Kembali ke Peti Masuk',
  PERMISSION_DENIED: 'Kebenaran Dihentikan',
  THIS_INQ_IS_LOCKED_BY: 'Siasatan ini dikunci oleh',
  FORCE_UNLOCK: 'FORCE UNLOCK',
  INQUIRIES: 'Pertanyaan',
  Download: 'Muat turun',
  Event: 'Acara',
  INSERTED_BY: 'Disisipkan Oleh',
  LAST_EDITED_ON: 'Terakhir diedit pada',
  LAST_EDITED_BY: 'Terakhir Disunting Oleh',
  CREATED_ON: 'Dicipta pada',
  TOPIC: 'Topik',
  TABLE_OPTIONS: 'Pilihan Jadual',
  DEPARTMENT: 'Jabatan',
  User: 'Pengguna',
  ADD_THERAPEUTIC_AREA: 'Tambah Kawasan Terapi',
  CREATE_THERAPEUTIC_AREA: 'Buat kawasan terapi baru',
  ADD_NEW: 'Tambah baru',
  SELECT_TEAM_TO_PROCEED: 'Pilih Pasukan untuk meneruskan',
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  SALES: 'Jualan',
  CALL_CENTER: 'Pusat panggilan',
  ALL: 'Semua',
  Warning: 'Amaran',
  clear: 'jelas',
  THESE_CHANGES_WILL_BE_: 'Perubahan ini akan digunakan untuk pengguna yang dipilih',
  INSERT_A_FOLLOW_UP_RESP: 'Masukkan tindak balas susulan',
  INSERT_A_NOTE: 'Masukkan nota',
  MERGED_FROM: 'Digabung Dari',
  CHANGES: 'Perubahan',
  SHOW_LESS: 'menunjukkan kurang',
  COMPLETE: 'Lengkap',
  MISSING: 'Hilang',
  CLOSE_AS_FORWARDED: 'Tutup sebagai Teruskan',
  CLOSE_EXT_TEAM: 'Tutup pertanyaan seperti yang dihantar ke pasukan luar',
  THE_INQ_BELONG_TO_A_:
    'Pertanyaan ini adalah milik negara yang menjadi tanggungjawab pasukan luar. Pertanyaan kemudian akan dikemukakan kepada pasukan ini secara automatik.',
  RESOLVE: 'Selesaikan',
  TERRITORIES: 'Wilayah',
  EXTERNAL_COMMUNICATION: 'KOMUNIKASI LUAR',
  FOR_INQ_COMING_DIRECTLY_:
    'Untuk pertanyaan yang datang terus dari HCP melalui e-mel, hantarkan pemberitahuan secara automatik untuk memberitahunya bahawa pertanyaan telah diterima.',
  INTERNAL_COMMUNICATION: 'KOMUNIKASI DALAMAN',
  SALESREP_RECEIVES_: 'Salesrep hanya menerima pemberitahuan bahawa siasatan telah dijawab',
  SALESREP_RECEIVES_FULL_ANSWER_: 'Salesrep menerima jawapan penuh untuk Pertanyaan Label Atas',
  SALESREP_RECEIVES_ONLY_NOTIF_:
    'Salesrep hanya menerima pemberitahuan bahawa siasatan telah dijawab',
  Name: 'Nama',
  Module: 'Modul',
  Function: 'Fungsi',
  LAST_RUN: 'Larian Terakhir',
  End: 'Akhirnya',
  Every: 'Setiap',
  Period: 'Tempoh',
  Active: 'Aktif',
  Resolved: 'Diselesaikan',
  ERROR_PREVIEW: 'Ralat Ralat',
  FAILURE_COUNT: 'Bilangan Kegagalan',
  COMPLETED_ON: 'Selesai',
  STARTED_ON: 'Dimulakan',
  TASK_NAME: 'Nama Tugas',
  PRODUCT_SPECIFIC: 'SPESIFIKAL PRODUK',
  THERAPEUTIC_AREAS: 'KAWASAN TERAPEUTIK',
  PRODUCT_GROUPS: 'KUMPULAN PRODUK',
  LICENSING_PARTNER: 'RAKAN PELESENAN',
  PRODUCT_CODE: 'KOD PRODUK',
  EXTERNAL_ID: 'ID luaran',
  OBJECT_TYPE: 'Jenis Objek',
  MIGRATION_NAME: 'Nama Migrasi',
  MIGRATION_ERRORS: 'Kesalahan Penghijrahan',
  ADD_SALES_REP: 'Tambah Pegawai Jualan',
  ASSIGNED_TO_MSL: 'DITETAPKAN KE MSL',
  NOT_ASSIGNED: 'Tidak ditugaskan',
  ASSIGNED_TO_OTHER_MSL: 'Ditugaskan ke MSL lain',
  ASSIGNED_TO_THIS_MSL: 'Ditugaskan ke MSL ini',
  SHOW_ONLY: 'Tunjukkan sahaja',
  SALES_REP_LIST: 'Senarai Rep Jualan',
  ACTIVE_TASK: 'Aktifkan Petugas',
  CHOOSE_USER_AND_SET_THE_SHIFT_: 'Pilih pengguna dan tetapkan pergeseran untuk acara tersebut',
  MANAGE_SHIFTS: 'MENGURUSKAN SHIF',
  CREATE_NEW_USER: 'Buat Pengguna Baru',
  THIS_TEMPLATE_WILL_BE_AUTO_:
    'Templat ini akan ditambah secara automatik kepada ahli pasukan anda.',
  TEMPLATE_FOR: 'Templat untuk',
  Day: 'Hari',
  Week: 'Minggu',
  Month: 'Bulan',
  Today: 'Hari ini',
  Archive: 'Arkib',
  PARTICIPANTS: 'PESERTA',
  EVENT_MANAGEMENT: 'PENGURUSAN ACARA',
  ADD_TASK: 'Tambah Petugas',
  EDIT_TASK: 'Edit Tugas',
  IS_ACTIVE: 'Adalah aktif',
  Months: 'Bulan',
  Days: 'Hari',
  Hours: 'Jam',
  Minutes: 'Minit',
  HCP_ADMINISTRATION: 'PENTADBIRAN HCP',
  AUTH_REQ_ADMINISTRATION: 'Pentadbiran Permintaan Auth',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_: 'Produk akan diserlahkan di Kawasan Terapi yang dipilih.',
  PRODUCT_GROUPS_DEFINE_:
    'Kumpulan produk menentukan pasukan yang bertanggungjawab untuk menjawab pertanyaan produk ini.',
  USER_ADMINISTRATION: 'Pentadbiran Pengguna',
  IS_PRODUCT_SPECIFIC: 'Adakah Produk Khusus',
  USER_TEMPLATES_ADMIN: 'Pentadbiran Templat Pengguna',
  SET_DEFAULT_VALUES: 'SET NILAI DEFAULT',
  SET_DEFAULT_VALUES_FOR_: 'Tetapkan nilai lalai untuk acara tersebut',
  INQUIRY_DEFAULT_VAL: 'Nilai lalai siasatan',
  CREATE_NEW_EVENT: 'BUAT ACARA BARU',
  EDIT_EVENT: 'EDIT ACARA',
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'Tetapkan perincian untuk membuat acara baru',
  ADD_DOC_LINKED_TO_: 'Tambah dokumen yang dikaitkan dengan acara tersebut',
  INC_CHANNEL: 'Saluran Inc.',
  CHANGE_DETAILS_OF_THE_EVENT: 'Ubah butiran acara',
  DESCRIPTION: 'Penerangan',
  CREATE_APPROVAL_PROCESS: 'Pilih Proses Kelulusan',
  CREATE_NEW: 'Buat baru',
  CUSTOMIZE_PROCESS: 'Peribadikan Proses',
  SELECTED_PROCESS: 'Proses Terpilih',
  CLONE: 'Klon',
  PREFERENCES_TITLE: 'Keutamaan',
  NOTIFICATIONS_TITLE: 'Pemberitahuan',
  TEMPLATES: 'Templat',
  LETTER_TEMPLATES: 'Templat surat',
  USER_TEMPLATES: 'Templat Pengguna',
  TEAM_MEMBERS: 'Anggota kumpulan',
  NEW_PASSWORD: 'Kata laluan baharu',
  REPEAT_NEW_PASSWORD: 'ulangi katalaluan baharu',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'Sekurang-kurangnya 1 huruf kecil',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'Sekurang-kurangnya 1 surat huruf besar',
  AT_LEAST_1_DIGIT: 'Sekurang-kurangnya 1 digit',
  AT_LEAST_1_SPECIAL_CHARACTER: 'Sekurang-kurangnya 1 watak khas',
  MINIMUM_8_CHARACTERS: 'Minimum 8 aksara',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'Tidak boleh mengandungi e-mel atau nama',
  SHOULD_MATCH_PASSWORD: 'Sekiranya sepadan dengan kata laluan',
  USER_MANUAL: 'Panduan pengguna',
  SUPPORT: 'Sokongan',
  BACK_TO_LOGIN: 'Kembali ke Log Masuk',
  PASSWORD_CONFIRMATION: 'Pengesahan kata laluan',
  HAVE_AN_ACCOUNT: 'Telah ada akaun?',
  Submit: 'Hantar',
  Gender: 'Jantina',
  PROFESSION: 'Profesion',
  MEDICAL_INQ_ROLES: 'Peranan Pemprosesan Pertanyaan Perubatan',
  MEDICAL_INFO_MANAGER: 'Pengurus Maklumat Perubatan',
  MEDICAL_SCIENCE_LIAISON: 'Perhubungan Perubatan Sains',
  NOT_PROCESSING_INQUIRIES: 'Tidak Memproses Pertanyaan',
  ACTIVE_HEALTH_CARE_SPEC: 'Pakar Penjagaan Kesihatan Aktif',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'Pakar Penjagaan Kesihatan Bukan Aktif',
  PASS_IS_REQUIRED: 'Kata laluan diperlukan!',
  EMAIL_IS_REQUIRED: 'E-mel diperlukan!',
  VALID_EMAIL: 'Sila masukkan alamat emel yang sah!',
  FIELD_IS_REQUIRED: 'Bidang ini Diperlukan!',
  ONLY_LETTERS: 'Sila masukkan huruf sahaja!',
  ARE_YOU_daiichi_EMPLOYEE: 'Adakah anda Pekerja Daiichi Sankyo?',
  here: 'di sini',
  CREATE_AN_ACCOUNT: 'Buat akaun',
  FORGOT: 'Terlupa?',
  Medical: 'Perubatan',
  Medical_not_resp: 'Perubatan - tidak bertanggungjawab untuk bertindak balas terhadap MIR',
  Commercial: 'Komersial',
  Market_access: 'Akses pasaran',
  Pharmacovigilance: 'Farmakovigilance',
  PR: 'PR',
  Please_specify: 'Sila jelaskan',
  Medical_information_manager: 'Pengurus maklumat perubatan',
  Medical_advisor: 'Penasihat perubatan',
  Are_Medical_inquiries: 'Adakah anda bertanggungjawab untuk menjawab pertanyaan perubatan?',
  Are_Health_care_professional: 'Adakah anda profesional penjagaan kesihatan yang berlatih?',
  Health_care_Industry: 'Industri penjagaan kesihatan',
  Health_Authority: 'Lembaga Kesihatan',
  Journalist: 'Wartawan',
  Lawyer: 'Peguam',
  Patient: 'Pesakit',
  Payor: 'Pembayar',
  Scientist: 'Ahli sains',
  Angiologist: 'Ahli sains',
  Cardiologist: 'Pakar Kardiologi',
  Cardiovascular_Surgeon: 'Pakar Bedah Kardiovaskular',
  Dentist: 'Doktor gigi',
  Gastroenterologist: 'Ahli gastroenterologi',
  General_Practitioner: 'Pengamal Am',
  Geriatrician: 'Geriatrik',
  Gynecologist: 'Pakar sakit puan',
  Haematologist: 'Ahli hematologi',
  Internist: 'Internist',
  Medical_Student: 'Pelajar perubatan',
  Nephrologist: 'Pakar Nefrologi',
  Neurologist: 'Ahli neurologi',
  Neurosurgeon: 'Neurosurgeon',
  Nurse: 'Jururawat',
  Oncologist: 'Pakar Onkologi',
  Oncology_Nurse: 'Jururawat onkologi',
  Other_HCP: 'HCP lain',
  Other_Surgeon: 'Pakar Bedah Lain',
  Pediatrician: 'Pakar Pediatrik',
  Pharmacist: 'Ahli farmasi',
  Pharmacologist: 'Ahli farmakologi',
  Pharmacy_Technician: 'Juruteknik Farmasi',
  Pneumologist: 'Pneumologist',
  Radiologist: 'Radiologi',
  Rheumatologist: 'Pakar Rheumatologi',
  USER_PASS_INCORRECT: 'Nama pengguna atau kata laluan tidak betul',
  SUBMITTED_REQUEST: 'Permintaan anda berjaya dihantar.',
  Reset: 'Tetapkan semula',
  Reset_pass: 'Menetapkan semula kata laluan',
  New_pass: 'Kata laluan baharu',
  RESET_PASS_EMAIL: 'Pautan kata laluan semula telah dihantar ke e-mel anda',
  NEW_PASS_SUCCESS: 'Kata laluan anda berjaya ditetapkan semula',
  EVENTS: 'Acara',
  Login_here: 'Daftar masuk disini',
  REGISTRATION: 'Pendaftaran',
  Create_an_account: 'Buat akaun',
  NO_RECORDS: 'Tiada Rekod',
  INTERACTION: 'Interaksi',
  SPECIALIZATION: 'Pengkhususan',
  SPECIALIZATION_TYPE: 'Jenis Pengkhususan',
  SAVED: 'Disimpan',
  SAVING: 'Menyimpan',
  TYPING: 'Menaip',
  CREATE_NEW_INTERACTION: 'Buat Interaksi Baru',
  FILTER_BY: 'Tapis mengikut',
  SEND_EMAIL: 'Menghantar e-mel',
  SAVE_WITHOUT_EMAIL: 'Simpan Tanpa E-mel',
  SUBMIT_TO_INBOX: 'Hantar ke Peti Masuk',
  Create_my_acc: 'Buat akaun saya',
  NEW_QUESTION: 'Soalan Baru',
  LABEL: 'Label',
  SELECT_A_LABEL: 'Pilih label',
  CITY: 'Bandar',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: 'Cadangkan kategori baru atau pilih yang ada',
  SUGGEST_CATEGORY_OR_TOPIC: 'Cadangkan kategori atau topik baru',
  URGENT: 'Mendesak',
  EXISTING_CATEGORY: 'Kategori Sedia Ada',
  NEW_CATEGORY_NAME: 'Nama Kategori Baru',
  NEW_TOPIC_NAME: 'Nama Topik Baru',
  CATEGORY: 'Kategori',
  MSLA: 'MSLA',
  OUT_CHANNEL: 'Saluran Keluar',
  LOGIN_TO_CONTINUE: 'Log masuk untuk terus ke Madjenta',
  Email_address: 'Alamat emel',
  FORGOT_PASS: 'Lupa kata laluan?',
  Remember_me: 'Ingat saya',
  YOU_WILL_BE_NOTIFIED:
    'E-mel pengesahan telah dihantar dengan arahan lebih lanjut untuk pendaftaran anda',
  SUCCESS_EMAIL_CONFIRMATION: 'E-mel anda berjaya disahkan.',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'Anda akan diberitahu apabila permintaan anda telah dikaji',
  FAILED_EMAIL_CONFIRMATION: 'Kami tidak dapat mengesahkan e-mel anda.',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'Cuba lagi dan jika masalah itu berlanjutan, tulislah untuk menyokong dengan penerangan mengenai masalah ini',
  RESET_FILTERS: 'Tetapkan semula Penapis',
  NEW_PASS_RESET_SUCCESS: 'Berjaya menetapkan semula kata laluan!',
  SOMETHING_WENT_WRONG: 'Kesalahan berlaku, sila hubungi sokongan.',
  Cardiovascular: 'Kardiovaskular',
  Oncology: 'Onkologi',
  Due_On: 'Habis Aktif',
  Follow_Up_Sent_On: 'Tindak Balas Dihantar',
  Follow_Up_Received_On: 'Tindak Balas Diterima Pada',
  Closed_On: 'Tutup Pada',
  Reopened_On: 'Dibuka Semula',
  Inquiry_ID: 'ID pertanyaan',
  Search: 'Cari',
  Assigned_to_Now: 'Ditugaskan untuk (Sekarang)',
  Edited_By: 'Diedit oleh',
  Assigned_to_Any_Time: 'Ditugaskan kepada (Bila-bila masa)',
  Closed: 'Tertutup',
  Foreign: 'Asing',
  Complex_search_Sentence_: 'Pencarian kompleks: Kalimat: "ayat untuk dicari", Bukan: -kata',
  Search_inquiries: 'Carian pertanyaan',
  SEARCH_REPOSITORY: 'Cari repositori',
  MIR_INBOX: 'INBOX MIR',
  Filters: 'Penapis',
  Active_Filters: 'Penapis Aktif',
  CONTACT_SUPPORT: 'Hubungi sokongan',
  MedicalInformationRequests: 'Pertanyaan (pdf)',
  UserProfileNavigation: 'Profil Pengguna (pdf)',
  UserManualChanges: 'Perubahan Manual Pengguna (docx)',
  iPad_How_To: 'iPad (pdf)',
  MedIS_Assign_Inquiry: 'Tugaskan Pertanyaan',
  MedIS_Documentation_Approval_Process: 'Proses Kelulusan Dokumentasi',
  MedIS_Documentation_Overview: 'Tinjauan Dokumentasi',
  MedIS_Inquiry_Documentation: 'Dokumentasi Pertanyaan',
  MedIS_MIR_Inbox_Filters: 'Penapis Peti Masuk MIR',
  MedIS_Merge_MIR: 'Gabungkan MIR',
  MedIS_New_Documentation: 'Dokumentasi Baru',
  MedIS_New_Inquiry: 'Pertanyaan Baru',
  MedIS_Out_Of_Office: 'Diluar pejabat',
  MedIS_Prepare_Custom_Answer: 'Sediakan Jawapan Tersuai',
  MedIS_Split_MIR_Part_I: 'Bahagian MIR Berpisah',
  MedIS_Split_MIR_Part_II: 'Split MIR Bahagian II',
  I_AGREE_TO: 'Saya setuju',
  TermsAndConditionsLink: 'Terma dan syarat',
  Title: 'Tajuk',
  INTERACTION_TITLE: 'Tajuk Interaksi',
  Move: 'Bergerak',
  Left: 'Ditinggalkan',
  Right: 'Betul',
  Requestor: 'Pemohon',
  Assigned_To: 'Ditugaskan untuk',
  Status: 'Status',
  Note: 'Nota',
  Confirm: 'Sahkan',
  rejectConfirm: 'Adakah anda pasti mahu menolak permintaan tersebut?',
  approveConfirm: 'Adakah anda pasti mahu menyetujui permintaan tersebut?',
  newUser: 'Pengguna baru',
  editUser: 'Edit Pengguna',
  deleteUser: 'Padamkan Pengguna',
  newProduct: 'Produk baru',
  editProduct: 'Edit Produk',
  deleteProduct: 'Padamkan Produk',
  newCategory: 'Kategori Baru',
  editCategory: 'Edit Kategori',
  deleteCategory: 'Padam Kategori',
  newTopic: 'Topik baru',
  editTopic: 'Edit Topik',
  deleteTopic: 'Padamkan Topik',
  userRequest: 'Permintaan Pengguna',
  productRequest: 'Permintaan Produk',
  categoryRequest: 'Permintaan Kategori',
  categoryTopicRequest: 'Permintaan Kategori / Topik',
  topicRequest: 'Permintaan Topik',
  Suggestion: 'Cadangan',
  tradeName: 'Nama dagangan',
  activeSubstance: 'Bahan Aktif',
  productName: 'Nama Produk',
  productSpecific: 'Adakah produk itu khusus?',
  EMAIL_IN_USE: 'Terdapat akaun yang ada dengan e-mel ini',
  fromDate: 'Dari tarikh',
  toDate: 'Sehingga kini',
  applyFilter: 'Sapukan Penapis',
  requestReceivedFrom: 'Permintaan diterima dari',
  on: 'pada',
  at: 'di',
  to: 'ke',
  add: 'Tambah',
  from: 'dari',
  approve: 'terima',
  reject: 'menolak',
  withTheFollowingRole: 'dengan peranan berikut',
  forTheFollowingTeam: 'untuk pasukan berikut',
  request: 'permintaan',
  submittedBy: 'dikemukakan oleh',
  INVALID_OR_EXPIRED_RESET_REQUEST:
    'Permintaan penetapan semula kata laluan tidak sah atau tamat tempoh',
  PASS_IS_RECENT: 'Kata laluan digunakan baru-baru ini',
  Assigned_to_me: 'Ditugaskan kepada saya',
  My_drafts: 'Draf saya',
  My_open_inquiries: 'Pertanyaan terbuka saya',
  My_closed_inquiries: 'Pertanyaan tertutup saya',
  Filter_inquiries_by: 'Tapis pertanyaan mengikut',
  New_inq: 'Pertanyaan baru',
  Inq_inbox: 'Peti Masuk Pertanyaan',
  REPORTS: 'Laporan',
  My_teams_open_inq: 'Pertanyaan terbuka pasukan saya',
  My_teams_closed_inq: 'Pertanyaan tertutup pasukan saya',
  All_teams_open_inq: 'Semua pasukan membuka pertanyaan',
  All_teams_closed_inq: 'Semua pasukan menutup pertanyaan',
  Overdue: 'Terlambat',
  Due_this_week: 'Tamat minggu ini',
  Pending_approval: 'Menunggu kelulusan',
  ADMINISTRATION: 'Pentadbiran',
  Show_all: 'Tunjukkan semua',
  In_progress: 'Sedang berlangsung',
  Table_Settings_Columns: 'Tetapan Jadual - Lajur',
  Change_column_visibility: 'Ubah keterlihatan lajur',
  Reassign_to: 'Tugaskan semula ke',
  set_priority: 'Tetapkan Keutamaan',
  set_status: 'Tetapkan Status',
  Spam_list: 'Senarai spam',
  Institution: 'Institusi',
  DUE_ON: 'Habis pada',
  Profile_settings: 'Tetapan Profil',
  Preferences: 'Keutamaan',
  Role_settings: 'Tetapan Peranan',
  Notification_settings: 'Tetapan Pemberitahuan',
  User_profile: 'Profil pengguna',
  Select_person: 'Pilih orang',
  FROM_DATE: 'Dari tarikh',
  TO_DATE: 'Sehingga kini',
  JOB_DESC: 'Deskripsi kerja',
  INQ_Management: 'Pengurusan Siasatan',
  DOCS_Management: 'Pengurusan dokumen',
  USER_Management: 'Pengurusan pengguna',
  Define_Frequency: 'Tentukan Kekerapan',
  All_teams_all_inq: 'Semua pertanyaan untuk semua pasukan',
  My_teams_all_inq: 'Semua pertanyaan untuk pasukan saya',
  Search_people_here: 'Cari orang di sini',
  Search_team_here: 'Cari pasukan di sini',
  people: 'ORANG',
  teams: 'PASUKAN',
  empty: '(kosong)',
  can_not_be_assigned: 'Pengguna ini tidak mempunyai pasukan dan tidak dapat ditugaskan',
  select_a_team: 'Sila pilih pasukan untuk',
  confirm_team_change: 'Sahkan Perubahan Pasukan',
  change_the_team_: 'Adakah anda pasti mahu menukar pasukan menjadi',
  can_not_see_it_anymore: 'Anda tidak akan dapat melihatnya pada masa ini',
  filtered_view: 'paparan yang ditapis.',
  PHONE: 'Telefon',
  FAX: 'Faks',
  LETTER: 'Surat',
  F2F_SITE_VISIT: 'F2F (Lawatan Tapak)',
  F2F_CONGRESS: 'F2F (Kongres)',
  F2F_ADBOARD: 'F2F (Papan Iklan)',
  F2F_OTHER: 'F2F (Lain-lain)',
  WEBINAR_SITE_VISIT: 'Maya (Lawatan Tapak)',
  WEBINAR_CONGRESS: 'Maya (Kongres)',
  WEBINAR_ADBOARD: 'Maya (Papan Iklan)',
  WEBINAR_OTHER: 'Maya (Lain-lain)',
  CHAT: 'Berbual',
  COURIER: 'kurier',
  REP_DELIVERY: 'Penghantaran Rep',
  SYSTEM: 'Sistem',
  WEB_FORM: 'Borang Web',
  Signature: 'Tandatangan',
  Disclaimer: 'Penafian',
  STATE: 'Nyatakan',
  ADDRESS_1: 'alamat 1',
  ADDRESS_2: 'Alamat 2',
  ZIP_CODE: 'Poskod',
  HIGH: 'Tinggi',
  ADVANCED: 'Mahir',
  CLICK_BELOW_TO_POPULATE_ANSWER:
    'Klik pada teks dan medan di bawah untuk mengisi dan mengedit jawapannya',
  DOCUMENT_VIEW: 'Paparan Dokumen',
  FORM_VIEW: 'Paparan Bentuk',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: 'Anda tidak mempunyai hak untuk menghantar jawapan.',
  ANSWER_TEXT: 'Teks Jawapan',
  CONTACT_INFORMATION: 'Maklumat perhubungan',
  COMPANY: 'Syarikat',
  SRD_NEEDED_TOOLTIP: 'Jawapan untuk pertanyaan ini memerlukan dokumen SRD baru',
  ENABLE_ALL: 'Dayakan Semua',
  DISABLE_ALL: 'Lumpuhkan Semua',
  BEFORE: 'Sebelum',
  AFTER: 'Selepas',
  EDIT: 'Edit',
  MY_TEMPLATES: 'Templat Saya',
  TEAM_TEMPLATES: 'Templat Pasukan',
  TEAM_TEMPLATES_HINT: 'Templat yang dibuat oleh Pengurus Maklumat Perubatan untuk seluruh pasukan',
  SOURCE: 'Sumber',
  TEMPLATE_NO_MATCH_INTERACTION:
    'Templat ini tidak sepadan dengan perincian interaksi dan tidak berkenaan',
  EMAIL_SUBJECT: 'Subjek email',
  INQUIRY_SUBMIT_HINT: 'Sila isi semua bidang yang diperlukan untuk menjawab pertanyaan',
  SCHEDULED_PUBLISH_TO_EXP_DATE: 'Penerbitan Berjadual hingga Tarikh Luput',
  COPY_ATTACHMENT_TO_ANSWER: 'Salin Lampiran untuk Menjawab',
  COPY_TEXT_TO_ANSWER: 'Salin Teks ke Jawapan',
  SELECT_INQUIRY: 'Pilih Pertanyaan',
  EDIT_INQ: 'Edit Soalan',
  SPLIT_ANOTHER: 'Berpisah Yang Lain',
  DELETE_INQ: 'Padamkan Pertanyaan',
  SELECT_TEXT_FOR_SPLIT: 'Tambahkan soalan baru dalam interaksi yang sama',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'Hasilkan interaksi / kes yang berasingan',
  SPLIT_ORIGINAL_TEXT: 'Teks Asal (Pilih teks untuk perpecahan)',
  SPLIT_TEXT_TO_REMAIN_: 'Teks untuk kekal dalam siasatan awal',
  SPLIT_TEXT_TO_SPLIT_: 'Teks untuk dibahagi',
  SPLIT_SELECTED_SECTIONS: 'Bahagian terpilih untuk perpecahan',
  SPLIT_NO_MODIFICATIONS_HINT:
    'Teks akan terisi secara automatik semasa anda memilih bahagian dari teks asal',
  SPLIT_MODIFICATIONS_HINT:
    'Teks telah diubah secara manual dan pilihan lebih lanjut dari teks asal tidak akan diterapkan.\n Klik di sini untuk membuang pengubahsuaian.',
  TEMPLATE_NO_MODIFICATIONS_HINT:
    'Teks akan diperbaharui secara automatik dengan perubahan pada butiran pertanyaan.',
  SELECT_DIFFERENT_TEMPLATE: 'Pilih templat lain',
  APPROVE: 'Terima',
  LAST_EDIT_ON: 'Edit Terakhir Hidup',
  LAST_EDIT_BY: 'Edit Terakhir Oleh',
  INTERNAL_COMM: 'Komunikasi dalaman',
  INACTIVE: 'Tidak aktif',
  DEACTIVATE: 'Nyahaktifkan',
  ARCHIVED: 'Diarkibkan',
  REPUBLISH: 'Terbitkan semula',
  INBOX_NO_FILTERS_HINT:
    'Tidak ada penapis yang dipilih.\n Sekiranya anda tidak dapat menemui pertanyaan yang anda cari, periksa bahawa anda berada di bahagian peti masuk yang betul.',
  INTERACTION_TEMPLATE_HINT:
    'Tanda tangan, ucapan dan penafian adalah sebahagian daripada komunikasi bertulis, seperti e-mel, surat dan faks.\n Untuk menggunakannya, pastikan saluran keluar ditetapkan ke salah satu dari mereka.',
  CONFIRM_AUTH_REQUEST: 'Sahkan Permintaan Pengesahan',
  VIEW_AUTH_REQUEST: 'Lihat Permintaan Pengesahan',
  QUESTION_TITLE: 'Tajuk soalan: ',
  NO_TITLE_INQUIRY: '-tidak-tajuk-pertanyaan-',
  EXISTING_CATEGORIES: 'Kategori Sedia Ada',
  EXISTING_TOPICS: 'Topik Sedia Ada',
  DO_NOT_ASSIGN_PRODUCT: 'Jangan berikan produk',
  QUESTION_TEXT: 'Teks soalan',
  DATE: 'Tarikh',
  REJECTED: 'Ditolak',
  FILTER_AUTH_REQUESTS_BY: 'Tapis permintaan autentikasi mengikut',
  ALL_REQUESTS_ALL_TEAMS: 'Semua permintaan untuk semua pasukan',
  ALL_TEAMS: 'Semua pasukan',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST:
    'Anda tidak mempunyai hak untuk mengedit permintaan Kategori / Topik.',
  SEND_FOLLOW_UP: 'Hantar Susulan',
  RECEIPT_NOTIFICATION_CUSTOMER: 'Pemberitahuan resit kepada pelanggan',
  GDPR_DISCLAIMER: 'Penafian GDPR',
  ACTIONS: 'Tindakan',
  SUBMISSION_CONDITIONS: 'Syarat penyerahan',
  TYPE_OF_NOTIFICATION: 'Jenis pemberitahuan',
  DIRECT_SUBMISSION: 'Penyerahan langsung',
  DIRECT_SUBMISSION_TOOLTIP:
    'Untuk pertanyaan yang dihantar terus daripada pelanggan melalui e-mel, hantar pemberitahuan untuk memaklumkan pelanggan tentang pengendalian data peribadi mereka.',
  COMPANY_EMPLOYEE_SUBMISSION: 'Dihantar melalui pekerja syarikat',
  ENABLE_NOTIFICATION: 'Dayakan pemberitahuan',
  NOTIFICATION_SENDER: 'Pemberitahuan kepada Pengirim',
  SALES_REP_DELIVERED_RESPONSE: 'Wakil jualan untuk maklum balas yang dihantar',
  NOTIFICATION_DELIVERED_RESPONSE: 'Pemberitahuan untuk respons yang dihantar',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    'Untuk pertanyaan yang dikemukakan melalui wakil syarikat (wakil jualan, MSL atau lain-lain), beritahu penghantar apabila pelanggan menerima jawapan kepada pertanyaan mereka',
  FULL_ANSWER: 'Jawapan penuh',
  NOTIFICATION: 'Pemberitahuan',
  NAME: 'Nama',
  COLUMN_INIT_QUESTION_TEXT: 'Teks soalan asal',
  NOTIFICATION_ENABLED: 'Pemberitahuan didayakan',
  DEFAULT_TEMPLATE: 'Templat lalai',
  SET_AS_DEFAULT: 'Tetapkan sebagai lalai',
  DEFAULT_TEMPLATE_TOOLTIP:
    'Togol dilumpuhkan kerana mesti ada sekurang-kurangnya satu templat lalai!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: 'Templat lalai tidak boleh dipadamkan!',
  BATCH_UPDATE_ONLY: 'Hanya kemas kini kelompok boleh dilakukan melalui kotak pilihan paling atas.',
  ACCESS_DENIED: 'Akses dinafikan!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE:
    'Anda tidak mempunyai kebenaran untuk mengakses halaman ini.',
  USER_ROLES: 'Peranan pengguna',
  MANAGE_USER_ROLES: 'Urus peranan pengguna',
  ARCHIVE_ROLE: 'Peranan arkib',
  ROLE_ARCHIVE_CONFIRMATION: 'Adakah anda benar-benar mahu mengarkibkan peranan ini?',
  ROLES_FOR: 'Peranan untuk',
  AUDIT_TRAIL: 'Jejak Audit',
  ACTION_BY_USER: 'Tindakan oleh pengguna',
  ACTION: 'Tindakan',
  ORIGINAL_VALUE: 'Nilai asal',
  CHANGE: 'Ubah',
  CONDITION: 'Keadaan',
  IP: 'IP',
  GEOLOCATION: 'Geolokasi',
  NEW_VALUE: 'Nilai baharu',
  FILTER_BY_ACTION: 'Tapis mengikut tindakan',
  SELECT_DATE: 'Pilih tarikh',
  TEAM_SAVE: 'Simpan',
  TEAM_DELETE: 'Padam',
  TEAM_CREATE: 'Buat',
  TEAM_CLONE: 'Salinan',
  TEAM_NAME: 'Nama',
  TEAM_CODE: 'Kod',
  TEAM_ACTION_DELETE_CONFIRM: 'Adakah anda benar -benar mahu memadam',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: 'Berhati-hati!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: 'Setelah dipadam, ia tidak boleh dipulihkan!',
  TEAM_FULL_ANSWER_ON_LABEL: 'Jawapan penuh pada label',
  TEAM_RESTRICTED_TEAM: 'Terhad',
  TEAM_SEGREGATED_DATA: 'Data yang dipisahkan',
  TEAM_PRIVATE_TEAM: 'Pasukan persendirian',
  TEAM_AUTO_ASSIGN: 'Berikan Auto',
  TEAM_MAILBOX_IN: 'Peti mel masuk',
  TEAM_MAILBOX_OUT: 'Peti Mel Outbound',
  TEAM_DEFAULT_LANGUAGE: 'Bahasa lalai',
  TEAM_COUNTRIES: 'Negara yang ditugaskan',
  TEAM_PRODUCT_GROUPS: 'Menetapkan kumpulan produk',
  TEAM_PARENT: 'Pasukan induk',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'Mengatasi pasukan akar cluster',
  TEAM_AFFILIATIONS: 'Pasukan gabungan',
  TEAM_TIMEZONE: 'Zon masa',
  UPDATE: 'Kemas kini',
  AUTO_ASSIGN_BY: 'Berikan automatik oleh',
  AUTO_ASSIGNMENTS: 'Tugasan automatik',
  NO_ONE: 'Tiada sesiapa',
  NOT_APPLICABLE: 'Tidak berkaitan',
  OVERRIDE_NOTIFICATIONS: 'Mengatasi pemberitahuan',
  OVERRIDE_NOTIFICATIONS_HINT:
    'Apabila templat pengguna dengan pemberitahuan override ditetapkan kepada pengguna, ia akan melumpuhkan sebarang tetapan pemberitahuan yang sudah ada dan membolehkan hanya tetapan pemberitahuan yang disenaraikan dalam templat pengguna',
  CREATED_MANUALLY: 'Dicipta secara manual',
  DOCUMENT_TITLE_FOR_WORD:
    'Tajuk untuk dokumen perkataan biasanya diambil dari seksyen tajuk yang diformat dalam dokumen itu sendiri. Jika dokumen tidak mengandungi tajuk, anda boleh menentukannya di sini. Jika anda tidak menentukan tajuk di sini dan dokumen Word tidak mengandungi seksyen Formatted Tajuk, maka ID dokumen akan digunakan untuk tajuk.',
  ORDER_BY: 'Perintah oleh',
  FORMAT: 'Format',
  SELECT_QUESTION: 'Pilih soalan',
  SELECT_QUESTION_FOR_NOTE: 'Pilih soalan yang ingin anda tambahkan',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: 'Tambahkan nota untuk keseluruhan pertanyaan',
  REQUESTED_DATE: 'Tarikh yang diminta',
  UNASSIGNED: 'Tidak ditugaskan',
  BLANK: 'Kosong',
  ANSWER_SAVED_WITHOUT_EMAIL: 'Jawapan disimpan tanpa e -mel',
  INQ_RELATED_TO: 'Pertanyaan yang berkaitan dengan',
  QUESTION_TEXT_BEFORE_SPLIT: 'Teks soalan sebelum berpecah',
  SPLIT_MERGE: 'Berpecah dan bergabung',
  CLARIFICATION_QUESTION: 'Soalan penjelasan',
  ACTIVE_ON: 'Aktif pada',
  DISMISS: 'Menolak',
  GO_TO_ENQUIRY: 'Pergi ke pertanyaan',
  NO_TEAMS: 'Tanpa pasukan',
  NO_TEAMS_TIP:
    'Mengandungi pengguna, yang tidak diberikan kepada mana -mana pasukan. Anda boleh menyemak sama ada sebahagiannya dari pasukan anda dan memberi mereka peranan yang sesuai.',
  TASKS: 'Tugas',
  Therapeutic_Areas: 'Kawasan terapeutik',
  Product_Groups: 'Kumpulan produk',
  Licensing_Partner: 'Rakan pelesenan',
  Product_Code: 'Kod Produk',
  SELECT_EXISTING_ONE: 'Pilih yang ada',
  OR: 'atau',
  MANAGE_FORM: 'Menguruskan borang',
  EVENT_FORM_ADDRESS: 'Alamat Borang Peristiwa',
  EVENT_FORM_PREVIEW_WARING:
    'Pastikan anda telah menyelamatkan sebarang perubahan sebelum membuka borang Kongres atau menyalin alamatnya',
  SEARCH_SAVED_SEARCHES: 'Cari carian yang disimpan',
  GO: 'Pergi',
  ASSIGN: 'Menetapkan',
  CERTIFICATION: 'Pensijilan',
  SORT: 'Menyusun',
  CREATE_NEW_MAILBOX: 'Buat peti mel baru',
  EDIT_MAILBOX: 'Edit peti mel',
  ADD_MAILBOX: 'Tambah peti mel',
  UNSET_INCOMING_MAILBOX: 'Peti mel masuk yang tidak tersendiri',
  SET_INCOMING_MAILBOX: 'Tetapkan peti mel masuk',
  UNSET_OUTGOING_MAILBOX: 'Peti mel keluar yang tidak tersembunyi',
  SET_OUTGOING_MAILBOX: 'Tetapkan peti mel keluar',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'Semua pasukan dengan peti mel semasa',
  MAILBOX_HISTORY: 'Sejarah peti mel',
  ALL_TEAMS_WITH_MAILBOX: 'Semua pasukan dengan peti mel',
  MAILBOX: 'Peti mel',
  EMAIL_POLLING: 'Pengundian e -mel',
  ADVANCED_SEARCH: 'carian terperinci',
  SELECT_FIELDS: 'Pilih medan',
  SEARCH_TERM: 'Istilah carian',
  COLUMN_INTERACTION_ID: 'ID Interaksi',
  COLUMN_QUESTION_ID: 'ID Soalan',
  COLUMN_CREATED_ON: 'Dicipta pada',
  COLUMN_LAST_EDITED_DATE: 'Terakhir diedit',
  COLUMN_ASSIGNED_TO: 'Berikan kepada',
  COLUMN_DUE_DATE: 'Tarikh yang ditetapkan',
  COLUMN_FOLLOW_SENT_DATE: 'Tarikh dihantar susulan',
  COLUMN_FOLLOW_RECEIVED_DATE: 'Tarikh yang diterima susulan',
  COLUMN_ANSWER_DATE: 'Tarikh jawab',
  COLUMN_CLOSED_ON_DATE: 'Ditutup pada tarikh',
  COLUMN_REOPENED_DATE: 'Tarikh dibuka semula',
  COLUMN_INSERTED_BY: 'Dimasukkan oleh',
  COLUMN_LAST_EDITED_BY: 'Terakhir diedit oleh',
  COLUMN_ANSWERED_BY: 'Dijawab oleh',
  COLUMN_APPROVED_BY: 'Disahkan oleh',
  COLUMN_INT_COMM_USER: 'Pengguna komunikasi dalaman',
  COLUMN_INT_COMM_DATE: 'Tarikh komunikasi dalaman',
  COLUMN_MY_TEAM: 'Pasukan saya',
  COLUMN_PQC_RELATED: 'PQC berkaitan',
  COLUMN_INCOMING_CHANNEL: 'Saluran masuk',
  COLUMN_OUTGOING_CHANNEL: 'Saluran keluar',
  COLUMN_OFF_LABEL: 'Off-Label',
  COLUMN_HCP_SPEC: 'HCP + Pengkhususan',
  COLUMN_NON_HCP_SPEC: 'Profesion bukan HCP +',
  COLUMN_KEE: 'Kee',
  COLUMN_HOSPITAL_BASED: 'Berasaskan hospital',
  COLUMN_CITY: 'Bandar',
  COLUMN_INSTITUTION: 'Institusi',
  COLUMN_TERRITORY: 'Wilayah',
  CASE_ID: 'ID Kes',
  OPTION_ADD: 'TAMBAH',
  OPTION_AND: 'Dan',
  OPTION_OR: 'Atau',
  OPTION_NOT: 'Tidak',
  OPTION_YES: 'Ya',
  OPTION_NO: 'Tidak',
  QUERY: 'Pertanyaan',
  EXPORT_LABEL: 'Tentukan lajur eksport',
  SAVE_SEARCH: 'Simpan carian',
  SHARE_SEARCH: 'Kongsi carian',
  SEARCH_BTN: 'Cari',
  BACK_ACTION: 'Kembali',
  SAVE_CHANGES: 'Simpan perubahan',
  SAVED_SEARCH: 'Carian yang disimpan',
  NEW: 'Baru',
  SAVE_AS: 'Jimat sebagai nama',
  ENTER_RECIPIENTS: 'Masukkan penerima',
  CLEAR_SAVED_SEARCH: 'Carian yang disimpan',
  NO_SAVED_SEARCH: 'Tiada carian yang disimpan dijumpai',
  INT_COMM_TEXT: 'Teks komunikasi dalaman',
  FOLLOW_UP_TEXT: 'Teks susulan',
  CONFIRM_DELETION: 'Sahkan penghapusan',
  ARE_SURE: 'Adakah anda pasti mahu memadamkan carian yang disimpan',
  PERMANENTLY_DELETED: 'Carian akan secara kekal <b> dipadam </b>.',
  NOTHING_SAVE: 'Tiada apa -apa untuk disimpan.',
  NOTHING_SEARCH: 'Tiada apa -apa untuk dicari.',
  NOTHING_EXPORT: 'Carian tidak mempunyai rekod untuk mengeksport.',
  SHARE_SUCCESS: 'Carian telah berjaya dikongsi',
  SHARE_FAILURE: 'Carian tidak dapat dikongsi',
  DELETED_SUCCESS: 'Berjaya memadamkan carian yang disimpan',
  ALL_DELETED_SUCCESS: 'Berjaya memadamkan semua carian yang disimpan',
  VIEW_SAVED_SEARCH: 'Lihat carian yang disimpan',
  EDIT_SAVED_SEARCH: 'Edit carian yang disimpan',
  EXPORT_SAVED_SEARCH: 'Eksport disimpan carian',
  EXPORT_SEARCH: 'Carian eksport',
  DELETE_SAVED_SEARCH: 'Padam carian yang disimpan',
  SELECT_EXPORT_COLUMNS: 'Pilih lajur untuk dieksport',
  SAVED_SEARCH_ALREADY_EXIST: 'Cari dengan nama itu sudah ada.',
  CHARACTER_LIMIT: 'Min 3 aksara. Max 35 aksara.',
  CHARACTER_LEFT: 'watak ditinggalkan',
  ACTIVATE: 'Aktifkan',
  INACTIVE_USER: 'Pengguna ini tidak lagi aktif. Sila hubungi pentadbir sistem anda.',
  FOLLOW_UP: 'Susulan',
  USER_NOT_FOUND: 'Pengguna tidak dijumpai',
  DEPARTMENTS: 'Jabatan',
  EMAIL: 'E -mel',
  ADD_NEW_DEPARTMENT: 'Tambah jabatan baru',
  DEPARTMENT_COUNTRY: 'Negara',
  DEPARTMENT_COMPANY: 'Syarikat',
  DEPARTMENT_TEAM: 'Pasukan',
  DEPARTMENT_TA: 'Kawasan terapeutik',
  DEPARTMENT_PRODUCT: 'Produk',
  DEPARTMENT_ACTIONS: 'Tindakan',
  DEPARTMENT_CONFIRMATION_1: 'Sahkan penghapusan?',
  DEPARTMENT_CONFIRMATION_2: 'Tindakan ini tidak dapat dipulihkan.',
  DEPARTMENT_NAME: 'Nama jabatan',
  TYPE_DEPARTMENT: 'Jenis',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: 'Negara - Sila pilih Pasukan Pertama',
  DEP_NAME_CHARACTER_LIMIT: 'Max 200 aksara.',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'Permintaan pengguna',
      CategoryTopicRequest: 'Permintaan Kategori/Topik',
      ProductRequest: 'Permintaan produk',
      UserUpdateRequest: 'Permintaan kemas kini pengguna',
    },
    TYPE: {
      NewUser: 'Pengguna baru',
      NewCategoryTopic: 'Kategori/topik baru',
      NewUserUpdate: 'Kemas kini pengguna',
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS: 'Tentukan senarai pengkhususan khusus negara',
      IS_COUNTRY_SPECIFIC: 'Adalah khusus negara',
      TYPE: 'Jenis Penanya',
      SPECIALIZATION_EXISTS: 'Konfigurasi untuk "Jenis Penanya/Pengkhususan" sudah wujud.',
    },
    AUTHORIZATION_REQUESTS: { GENDER: 'Jantina', COUNTRY: 'Negara', TITLE: 'Tajuk' },
  },
  hcp: 'HCP',
  emp: 'Pekerja',
  cons: 'Bukan HCP',
  OOO_DEPUTY: 'Timbalan Ooo',
  NO_DEPUTY: 'Tiada timbalan',
  UNSPECIFIED_END_DATE: 'Tarikh akhir yang tidak ditentukan',
  DELEGATORS: 'DELEGATORS',
  NO_DELEGATORS: 'Tiada perwakilan',
  PREVIEW_DELEGATORS: 'Pratonton perwakilan',
  REMOVE_DEPUTY: 'Keluarkan timbalan',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'Pengguna yang menetapkan anda sebagai timbalan',
  CHANGED_USER: 'Pengguna yang diubah',
  DEFAULT: 'Lalai',
  SPECIALIZATIONS: 'Pengkhususan',
  ENQUIRERS_LIST: 'Senarai Enquirers',
  EDIT_THE_RECORD: 'Edit rekod',
  DEACTIVATE_THE_RECORD: 'Menyahaktifkan rekod',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'Diluar pejabat',
      HINT: 'Hanya pengguna aktif, dengan peranan pemprosesan siasatan, yang tidak keluar dari pejabat tersedia untuk pemilihan.',
      MODAL_TITLE: 'Sahkan tetapan pejabat',
      MODAL_MSG_1:
        'Anda telah menetapkan pilihan Office dengan segera, semua pertanyaan anda akan segera ditugaskan kepada Timbalan.',
      MODAL_MSG_2:
        'Anda telah menetapkan pilihan Office, semua pertanyaan anda akan ditugaskan kepada Timbalan semasa tempoh pejabat.',
    },
  },
  GENDER_MALE: 'Encik.',
  GENDER_FEMALE: 'Puan.',
  TITLE_DR: 'Dr.',
  TITLE_PHD: 'PhD',
  TITLE_PROF: 'Prof.',
  TITLE_PROF_DR: 'Prof. Dr.',
  HOME: 'Rumah',
  HOSPITAL: 'Hospital',
  OFFICE: 'Pejabat',
  WORK: 'Bekerja',
  INSERT_IMAGE: 'Masukkan imej',
  USER_ADMIN: 'Pentadbir pengguna',
  MAILBOXES: 'Peti mel',
  AUTHORIZATION_REQUESTS: 'Permintaan kebenaran',
  METADATA: 'Metadata',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: 'Tugasan',
      FORWARD: 'Ke hadapan',
      FOLLOW_UP: 'Susulan',
      DUE_DATE_SETTINGS: 'Tetapan tarikh yang sepatutnya',
      CLOSE: 'Tutup',
      DELIVERY_ERROR: 'Ralat penghantaran',
      SCHEDULER: 'Penjadual',
      USER_REPORTS: 'Laporan pengguna',
      DOCUMENT_APPROVAL: 'Kelulusan dokumen',
      AUTHORIZATION_REQUEST: 'Permintaan kebenaran',
      NEW_INQUIRY: 'Siasatan baru',
      INQUIRY_APPROVAL: 'Kelulusan Pertanyaan',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: 'Permintaan peranan telah diluluskan/ditolak',
      AUTHORIZATION_REQUEST_3: 'Peranan sedang menunggu kelulusan saya',
      DOCUMENT_APPROVAL_1: 'Dokumen sedang menunggu kelulusan anda',
      DOCUMENT_APPROVAL_2: 'Dokumen telah diluluskan/ditolak',
      DOCUMENT_APPROVAL_3: 'Dokumen telah diarkibkan',
      DOCUMENT_APPROVAL_4: 'Dokumen telah diluluskan dengan perubahan',
      DOCUMENT_APPROVAL_5: 'Dokumen akan diarkibkan di',
      DOCUMENT_APPROVAL_6: 'Senarai dokumen yang diluluskan',
      DUE_DATE_2: 'Ulangi selepas masa lalu',
      ERROR_1: 'Kegagalan pemberitahuan penghantaran (mis. E -mel yang salah)',
      FOLLOW_UP_1: 'Susulan atau peringatan dihantar kepada pelanggan',
      FOLLOW_UP_4: 'Susulan diterima',
      IMPORT_DATA_1: 'Objek baru telah dibuat semasa import',
      IMPORT_DATA_4: 'Pengguna baru telah dibuat semasa import',
      IMPORT_DATA_5:
        'Pengguna yang sepadan dengan yang sudah ada tetapi tidak datang dari import sebelumnya',
      IMPORT_DATA_6: 'Ralat berlaku semasa mengimport data dari sumber luaran',
      INQUIRY_APPROVAL_4:
        'Jawapan cadangan anda telah diluluskan / diluluskan dengan perubahan / ditolak',
      METADATA_MANAGEMENT_1: 'Metadata baru telah dibuat',
      NEW_DOCUMENT_1: 'Dokumen yang diluluskan baru boleh didapati di negara saya',
      NEW_DOCUMENT_2: 'Dokumen baru tersedia untuk kelulusan tempatan',
      NEW_DOCUMENT_3: 'Dokumen tempatan baru tersedia untuk kelulusan Eropah / Global',
      NEW_DOCUMENT_4: 'Dokumen Global Baru tersedia',
      NEW_DOCUMENT_5: 'Dokumen Eropah baru tersedia',
      NEW_DOCUMENT_6: 'Dokumen Eropah baru tersedia untuk kelulusan global',
      SCHEDULER_TASK_1: 'Ralat berlaku semasa pelaksanaan tugas',
      USER_REPORTS_1: 'Senarai pengguna aktif',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3:
          'Terima pemberitahuan memaklumkan kepada anda apabila permintaan peranan pengguna perlu diluluskan/ditolak',
        AUTHORIZATION_REQUEST_2:
          'Terima pemberitahuan apabila pengguna baru dibuat atau apabila peranan diminta oleh pengguna dalam pasukan anda',
        AUTHORIZATION_REQUEST_4:
          'Terima pemberitahuan apabila topik dan/atau kategori baru dicadangkan',
        FOLLOW_UP_4:
          'Terima pemberitahuan untuk pertanyaan yang ditugaskan kepada saya yang menerima tindak balas Susulan daripada penanya',
        NEW_INQUIRY_1:
          'Terima pemberitahuan untuk pertanyaan baharu yang diterima di negara anda, dimasukkan oleh penanya/pengguna atau dimajukan daripada pasukan lain',
        ANSWER_2:
          'Terima pemberitahuan untuk pertanyaan yang dijawab bahawa saya bekerja sebagai rakan usaha sama, tetapi saya tidak ditugaskan secara langsung untuk pertanyaan itu',
        DUE_DATE_1:
          'Dengan memilih pilihan ini semua pemberitahuan di bawah akan diaktifkan. Untuk mengurangkan bilangan pemberitahuan, nyahpilih yang tidak diperlukan.',
      },
      FREQUENCIES: {
        INSTANTLY: 'Serta -merta',
        DAILY: 'Setiap hari',
        WEEKLY: 'Setiap minggu',
        QUARTERLY: 'Suku tahunan',
        MONTHLY: 'Bulanan',
        SECOND_DAY: 'Setiap hari kedua',
        THIRD_DAY: 'Setiap hari ketiga',
        ONE_WEEK: 'Seminggu',
        ONE_MONTH: 'Satu bulan',
        TWO_MONTHS: 'Dua bulan',
        THREE_MONTHS: 'Tiga bulan',
        SIX_MONTHS: 'Enam bulan',
        NINE_MONTHS: 'Sembilan bulan',
        EVERY_TWO_WEEKS: 'Setiap dua minggu',
        EVERY_THREE_WEEKS: 'Setiap tiga minggu',
        EVERY_FOUR_WEEKS: 'Setiap empat minggu',
      },
      OPTIONS: {
        ALMOST_DUE: 'Hampir jatuh tempo',
        DUE_NOW: 'Oleh itu sekarang',
        DUE_SOON: 'Tidak lama lagi',
        EARLY_ON: 'Awal',
        TEXT_GUIDES: {
          DUE_NOW: 'Terima pemberitahuan apabila siasatan perlu dibuat',
          EARLY_ON:
            'Terima pemberitahuan awal untuk pertanyaan yang perlu dibayar: Biasa 4 hari - Tinggi 2 hari - Kritikal 24 jam sebelum tarikh tamat',
          DUE_SOON:
            'Terima pemberitahuan untuk pertanyaan yang perlu dibuat tidak lama lagi: Normal 48 jam - Tinggi 12 jam - Kritikal 6 jam sebelum tarikh tamat',
          ALMOST_DUE:
            'Terima pemberitahuan untuk pertanyaan yang hampir tamat: Biasa 24 jam - Tinggi 6 jam - Kritikal 3 jam sebelum tarikh tamat',
        },
      },
      AUTHORIZATION_REQUEST_4: 'Kategori/ topik diminta dalam pasukan saya',
      AUTHORIZATION_REQUEST_2: 'Permintaan pengguna',
      IMPORT_DATA_2: 'Penanya baharu telah dibuat semasa import',
      IMPORT_DATA_3:
        'Penanya memadankan yang sudah sedia ada tetapi tidak datang dari import sebelumnya',
      ANSWER_2: 'Siasatan yang saya terlibat telah Dijawab',
      ASSIGNMENT_1: 'Satu Siasatan telah diberikan kepada Saya',
      ASSIGNMENT_5: 'Siasatan telah diberikan kepada timbalan saya',
      CLOSE_1: 'Satu Siasatan telah ditutup',
      DUE_DATE_1:
        'Siasatan yang ditugaskan kepada saya adalah berhampiran tarikh akhir, maklumkan kepada saya',
      FOLLOW_UP_7: 'Siasatan telah hilang untuk Susulan',
      FORWARD_1: 'Satu Siasatan telah dimajukan',
      INQUIRY_APPROVAL_1: 'Siasatan sedang menunggu kelulusan anda',
      INTERNAL_1: 'Nota telah ditambahkan pada pertanyaan yang diberikan kepada saya',
      NEW_INQUIRY_1: 'Siasatan baru telah diterima di Negara saya',
      SPAM_1: 'Pertanyaan baharu diterima dalam Spam',
      ANSWER_1: 'Terima salinan jawapan dalam interaksi yang dijawab oleh saya',
      ANSWER_6: 'Terima salinan jawapan dalam interaksi yang diluluskan oleh saya',
    },
  },
  NEW_TEAM: 'Pasukan baru',
  SmPc: 'SMPC',
  USERS: 'Pengguna',
  PAGINATOR_NEXT_PAGE_LABEL: 'Muka surat seterusnya',
  PAGINATOR_PREV_PAGE_LABEL: 'Halaman sebelumnya',
  PAGINATOR_FIRST_PAGE_LABEL: 'Muka surat pertama',
  PAGINATOR_LAST_PAGE_LABEL: 'Muka surat terakhir',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'Item setiap halaman',
  Date: 'Tarikh',
  Action: 'Tindakan',
  'Action by user': 'Tindakan oleh pengguna',
  'Changed user': 'Pengguna yang diubah',
  'Original value': 'Nilai asal',
  'New value': 'Nilai baru',
  REGION: 'Wilayah',
  TEAM_REGION_LABEL: 'Wilayah - Sila pilih syarikat terlebih dahulu',
  TEAM_THERAPEUTIC_AREA_LABEL: 'Kawasan Terapeutik - Sila Pilih Syarikat Pertama',
  TEAM_PRODUCT_LABEL: 'Produk - Sila pilih kawasan terapeutik terlebih dahulu',
  TEAM_COUNTRIES_LABEL: 'Negara - Sila pilih Wilayah Pertama',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'Tentukan produk untuk setiap negara',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'Sila pilih sekurang -kurangnya satu produk untuk',
  VIEW_CHANGES: 'Lihat perubahan',
  Sales_Representative: 'Wakil jualan',
  Other_Commercial: 'Komersial lain',
  REGIONS: 'Kawasan',
  MAIN_CLIENT: 'Pelanggan Utama',
  LICENSE_PARTNER: 'Rakan Kongsi Lesen',
  TEAM_SET_UP: 'Pasukan ditubuhkan',
  LIST_TEAMS: 'Senarai pasukan',
  COMPANIES: 'Syarikat',
  DOMAINS: 'Domain',
  TOKEN_REFRESH_NOTICE: 'Anda meminta e -mel pengesahan baru untuk pendaftaran akaun ENQMED anda.',
  TOKEN_REFRESH_SUCCESS: 'Pautan pengesahan baru telah dihantar ke e -mel anda.',
  TOKEN_REFRESH_ERROR: 'Ralat berlaku. Sila hubungi sokongan jika anda memerlukan bantuan lanjut.',
  PLEASE_ENTER_MAX: 'Sila masukkan maksimum',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    'Sila semak perubahan di bawah, jika ada, dan sahkan jika syarikat itu perlu diubah.',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE:
    'Sila teruskan dengan berhati -hati kerana perubahan akan menjejaskan pasukan',
  THERE_ARE_NO_CHANGES: 'Tidak ada perubahan.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Sila periksa syarikat sedia ada dalam jadual di belakang dialog kerana kami telah syarikat dengan nilai yang sama.',
  SIMPLE_TEXT:
    'Sila masukkan hanya huruf (a-z, a-z), nombor (0-9) dan simbol khas seperti (dash, tempoh, ruang dan kolon).',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS:
    'Sila pilih sekurang -kurangnya satu negara untuk wilayah',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    'Sila pilih sekurang -kurangnya satu produk untuk kawasan terapeutik',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    'Anda telah memilih produk/s yang dimiliki oleh lebih daripada satu kawasan terapeutik, sila pilih semua kawasan terapeutik yang diperlukan.',
  HTML_CHANGES: 'HTML berubah',
  SIDE_BY_SIDE_VIEW: 'Pandangan bersebelahan',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Sila periksa domain sedia ada dalam jadual di belakang dialog kerana kami telah domain dengan nilai yang sama.',
  OUT_CHANNEL_ERROR_MSG: 'Surat saluran keluar alamat pos Enquirer',
  OUT_CHANNEL_ERROR_MSG_LONG:
    'Saluran keluar dipilih sebagai surat. Sila masukkan alamat pos Enquirer di kawasan Enquirer',
  LABEL_FOR: 'Label untuk',
  male: 'Lelaki',
  female: 'Perempuan',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'Anda tidak mempunyai hak untuk mengarkibkan peranan ini. Hanya <b> MIMS </b> untuk pasukan boleh mengarkibkan peranan untuk ahli pasukan mereka.',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION:
    'Hantarkan e -mel kepada pengguna atas permintaan kelulusan/penolakan',
  APPROVE_REQUEST_QUESTION: 'Adakah anda ingin meluluskan permintaan itu?',
  NEW_USER_REQUEST: 'Permintaan pengguna baru',
  OPEN_REQUESTS: 'Permintaan terbuka',
  APPROVED_REQUESTS: 'Permintaan yang diluluskan',
  REJECTED_REQUESTS: 'Permintaan yang ditolak',
  USER_REQUESTS: 'Permintaan pengguna',
  REQUEST_FOR: 'Permintaan untuk',
  CATEGORY_TOPIC_REQUESTS: 'Permintaan Kategori/Topik',
  WITH_THE_FOLLOWING_PAIRS: 'dengan pasangan berikut:',
  MY_TEAM_OPEN_REQUESTS: 'Permintaan terbuka',
  MY_TEAM_APPROVED_REQUESTS: 'Permintaan yang diluluskan',
  MY_TEAM_REJECTED_REQUESTS: 'Permintaan yang ditolak',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'Kelulusan dokumen dalam EnqMed',
  DOCUMENTS_APPROVAL: 'Kelulusan dokumen',
  DOCUMENTS_APPROVAL_TITLE: 'Tetapan untuk proses kelulusan dokumen untuk pasukan yang dipilih',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    'Saluran keluar dipilih sebagai faks. Sila masukkan nombor faks Enquirer di kawasan Enquirer',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    'Saluran keluar dipilih sebagai telefon. Sila masukkan nombor telefon Enquirer di kawasan Enquirer',
  OUT_CHANNEL_FAX_ERROR_MSG: 'Nilai Fax Enquirer Outgoing',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: 'Nilai Enquirer Telefon Saluran Keluar',
  COPYRIGHT_PART_ONE: 'hak cipta',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. Semua hak terpelihara.',
  REVIEWED_DATE: 'Tarikh yang ditinjau',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    'Saluran keluar dipilih sebagai e -mel. Sila masukkan e -mel Enquirer di kawasan Enquirer',
  OUT_CHANNEL_EMAIL_ERROR_MSG: 'Nilai E -mel Enquirer Outgoing Channel',
  ENTER_USER_COMMENT_OPTIONAL: 'Masukkan komen kepada pengguna (pilihan)',
  SELECT_ANOTHER_CATEGORY: 'Pilih kategori lain',
  SELECT_ANOTHER_PRODUCT: 'Pilih produk lain',
  SUGGESTED_TOPIC: 'Topik yang dicadangkan',
  EDIT_USER_REQUEST: 'Edit permintaan pengguna',
  NEW_TOPIC_REQUEST: 'Topik baru dicadangkan',
  EDIT_TOPIC_REQUEST: 'Edit topik yang dicadangkan',
  NEW_CATEGORY_REQUEST: 'Kategori baru dicadangkan',
  EDIT_CATEGORY_REQUEST: 'Edit kategori yang dicadangkan',
  REVIEW_COMMENT: 'Tinjau komen',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'Aliran kerja kelulusan dokumen',
    CREATE_NEW: 'Buat aliran kerja baru',
    CUSTOMIZE: 'Sesuaikan alur kerja',
    NAME: 'Nama aliran kerja',
    ERROR_MSG_DUPLICATE_NAME: 'Aliran kerja dengan nama ini sudah ada.',
    SAVE: 'Simpan aliran kerja',
    DELETE: 'Padam aliran kerja',
    STEP: 'Langkah',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: 'Semua pengguna dengan peranan pengarang dokumen.',
    APPROVAL_DEADLINE: 'Tarikh akhir kelulusan',
    SELECT_APPROVAL_TYPE: 'Pilih jenis kelulusan',
    SELECT_APPROVAL_TYPE_FIRST: 'Pilih Jenis Kelulusan Pertama',
    SELECT_REVIEWERS: 'Pilih pengulas',
    ACTION_ON_APPROVE_WITH_CHANGES: 'Tindakan meluluskan perubahan',
    ACTION_ON_REJECT: 'Tindakan menolak',
    FILL_ALL_REQUIRED_FIELDS: 'Sila isi semua bidang yang diperlukan untuk menambah langkah baru.',
    ALL_APPROVAL_TYPES_USED:
      'Anda tidak boleh menambah lebih banyak langkah sub kelulusan kerana anda menggunakan semua jenis yang tersedia untuk langkah semasa.',
    CONFIRMATION_ROW_1:
      'Anda cuba memadam aliran kerja kelulusan dokumen untuk pasukan {{ NAME }}.',
    CONFIRMATION_ROW_2: 'Adakah anda benar -benar mahu memadamkan aliran kerja kelulusan?',
    CONFIRMATION_TITLE: 'Penghapusan Aliran Kerja Kelulusan Dokumen',
    TEAM_VIEW_SELECT_EXISTING:
      'Pilih alur kerja kelulusan dokumen yang ada dari senarai di bawah atau ',
    DOC_VIEW_SELECT_EXISTING: 'Pilih aliran kerja kelulusan dokumen yang ada',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      'Hanya langkah kelulusan tunggal yang boleh disenaraikan sebagai muktamad.',
    APPROVAL_TYPE_TOOLTIP:
      'Tiada pengguna dengan peranan kelulusan dokumen untuk pasukan ini. Sila tambahkan peranan kelulusan terlebih dahulu.',
    INFO_DIALOG_TITLE: 'Tiada aliran kerja kelulusan dokumen yang ditetapkan untuk pasukan ini.',
    INFO_DIALOG_MSG:
      'Sila hubungi MIM tempatan anda untuk membuat aliran kerja kelulusan dokumen untuk pasukan.',
    STEP_LIMIT_REACHED: 'Anda telah mencapai had {{ MAX_STEPS }} langkah kelulusan.',
  },
  DOCUMENTS_WITHOUT_APPROVAL: 'Jenis dokumen yang tidak memerlukan kelulusan',
  PLEASE_SELECT_TYPE: 'Sila pilih Jenis',
  ABORT_FILE_UPLOAD: 'Muat naik fail',
  USERS_REPORT: 'Laporan Pengguna',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG:
    'Dengan menutup tetingkap ini, anda akan kehilangan maklumat yang telah diisi.',
  DO_YOU_WANT_TO_PROCEED: 'Adakah anda mahu meneruskan?',
  USERS_REPORT_DESCRIPTION:
    'Senarai pengguna semua pasukan tambahan di mana MIM untuk onkologi dan kardiovaskular Daiichi Sankyo Europe mempunyai peranan MIM.',
  TEMPLATE_CONFIRM_MSG: 'Sebaik sahaja templat dipadam, ia tidak boleh dipulihkan!',
  EVENT_CONFIRM_MSG: 'Sebaik sahaja acara itu diarkibkan, ia tidak boleh dipulihkan!',
  CREATE_DRAFT: 'Buat draf',
  CREATE_USER: 'Buat pengguna',
  ADD_ROLE: 'Tambah peranan',
  DOMAIN_ADDRESS_INVALID_FOR: 'Alamat domain tidak sah untuk',
  AVAILABLE_ADDRESSES: 'Alamat yang ada',
  IN_TEAM: 'dalam pasukan',
  TO_THE_SELECTED_USER: 'kepada pengguna yang dipilih',
  USER_INFORMATION: 'Maklumat pengguna',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    'Alamat e -mel yang anda gunakan perlu dikemas kini kerana alamat domain tidak sesuai dengan pasukan/profil anda dikaitkan dengan. <br> Sila rujuk pentadbir sistem anda untuk mendapatkan bantuan atau hubungi pasukan sokongan.',
  MESSAGE: 'Mesej',
  IN_APPROVAL: 'Dalam kelulusan',
  IN_REVIEW: 'Dalam semakan',
  DOCUMENT_APPROVAL: 'Kelulusan dokumen',
  DOCUMENT_TITLE: 'Tajuk dokumen',
  SEARCH_DOCUMENTS: 'Dokumen cari',
  APPROVAL_INBOX: 'Peti masuk kelulusan',
  FILTER_DOCUMENTS_BY: 'Dokumen penapis oleh',
  submit_to_approval: 'Hantar kepada kelulusan',
  create_document_metadata: 'Buat Metadata Dokumen',
  archive: 'Arkib',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    'Saya mengakui bahawa adalah kewajipan saya untuk melaporkan semua tindak balas ubat buruk (ADR) semua produk perubatan mengikut keperluan tempatan. ENQMED tidak bertujuan untuk menangkap laporan mengenai tindak balas ubat buruk - ADR mesti dilaporkan kepada farmakovigilance secara langsung mengikut keperluan tempatan',
  VERSION_DIALOG: {
    TITLE: 'Versi Baharu Tersedia',
    MESSAGE:
      'Terdapat versi baharu perisian yang tersedia!\n Anda perlu mendapatkan versi baharu, untuk mendapat manfaat daripada penambahbaikan di dalamnya dan mengelakkan isu daripada menggunakan versi lama, anda perlu mendapatkannya.',
    SAVE_BUTTON: 'Dapatkan Versi Baharu',
  },
  ACTIVE_INACTIVE: 'Aktif / Tidak Aktif',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Sila semak kategori sedia ada dalam jadual di belakang dialog kerana kami telah pun mempunyai kategori dengan nilai yang sama.',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'Ralat! Penghantaran gagal',
  VIA: 'melalui',
  INSERTED_MANUALLY: 'Dimasukkan secara manual',
  AS_A: 'sebagai',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Sila semak produk sedia ada dalam jadual di belakang dialog kerana kami sudah mempunyai produk dengan nilai yang sama.',
  NOT_PRODUCT_SPECIFIC: 'Bukan Produk Khusus',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Sila semak topik sedia ada dalam jadual di belakang dialog kerana kami telah pun mempunyai topik dengan nilai yang sama.',
  PLEASE_ENTER_MAX_CHARACTERS: 'Sila masukkan maksimum {{ MAX_CHARS }} aksara.',
  THANK_YOU_MESSAGE: 'Mesej terima kasih',
  AS: 'sebagai',
  THERAPEUTIC_AREA_PARENT: 'Kawasan induk',
  MAIN_THERAPEUTIC_AREA: 'Kawasan Terapeutik Utama',
  CHOOSE_AN_IMAGE: 'Pilih imej',
  ALLOWED_FORMATS: 'Hanya format {{ FORMAT }} dibenarkan',
  REMOVE_IMAGE: 'Alih keluar imej',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Sila semak kawasan terapeutik sedia ada dalam jadual di belakang dialog kerana kami telah pun mempunyai kawasan dengan nilai yang sama.',
  PLAIN_TEXT_VIEW: 'Paparan Teks Biasa',
  RICH_TEXT_VIEW: 'Paparan Teks Kaya',
  EDIT_ITEM: 'Edit {{ ITEM }}',
  CREATE_NEW_ITEM: 'Buat {{ ITEM }} baharu',
  ADD_NEW_ITEM: 'Tambah {{ ITEM }} baharu',
  ADD_ITEM: 'Tambahkan {{ ITEM }}',
  AND_HENCE_STATUS_CHANGED_FROM: 'dan oleh itu status berubah daripada',
  FOLLOW_UP_WITH_ENQUIRER: 'Susulan dengan penanya',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW: 'Perkara berikut mesti dimasukkan untuk menyimpan perubahan:',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION:
    'Tindakan susulan tidak boleh dilakukan untuk interaksi tertutup.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'Interaksi dalam status semakan. Sila simpan ulasan untuk mendayakan menu ini.',
  QUESTION_TEXT_MIN_SIX_CHARS: 'Teks soalan (minimum 6 aksara)',
  PLEASE_PROVIDE_AN_ANSWER_: 'Sila berikan jawapan (minimum 6 aksara atau lampiran).',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    "Sila ambil perhatian bahawa pengguna perlu mendapatkan semula kata laluan daripada 'Terlupa Kata Laluan'.",
  ENABLE_USER_LOGIN_MESSAGE: 'Ini akan membolehkan log masuk pengguna.',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE:
    'Ini akan melumpuhkan log masuk dan pemberitahuan pengguna.',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    'Sila tetapkan pilihan pemberitahuan anda daripada profil pengguna anda!',
  SET_PASSWORD: 'Sila tetapkan kata laluan anda',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'Selepas pengaktifan semula akaun anda, kata laluan anda telah dipadamkan.',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: 'Sahkan penyahaktifan pengkhususan!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'Ini akan mengalih keluarnya daripada senarai dengan pengkhususan aktif.',
  GO_TO_ROLES: 'Pergi ke Peranan',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    'Perubahan anda pada maklumat hubungan pengguna mencetuskan perubahan e-mel log masuk daripada {{ OLD_USERNAME }} kepada {{ NEW_USERNAME }}',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'Sila berikan peranan dan pemberitahuan.',
  DO_YOU_WANT_TO_CONTINUE: 'Adakah anda mahu meneruskan?',
  COPY_ANSWER_TO_NEW_INTERACTION: 'Salin jawapan kepada interaksi baharu',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'Pertanyaan baru telah diimport. Pastikan anda mencirikan dan menjawab semua pertanyaan.',
  LEAVE_WITHOUT_SAVING: 'Cuti Tanpa Simpan',
  PENDING_CHANGES_MESSAGE: 'Perubahan belum selesai ialah {{ CHANGES }}?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    'Adakah anda pasti mahu membahagikan teks yang dipilih dalam interaksi baharu?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    'Ini akan mengalih keluar teks yang dipilih daripada interaksi dan mencipta teks baharu daripadanya.',
  DOWNLOAD_ERROR: 'Ralat Muat Turun!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    'Anda cuba memuat turun lebih 1000 pertanyaan. Nombor maksimum yang disokong ialah 1000. Sila perhalusi pilihan anda.',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION: 'Anda cuba menukar pencirian soalan!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    'Sila ambil perhatian bahawa menukar pencirian soalan tidak akan membenarkan sebarang perubahan dalam jawapan yang diberikan sebelum ini atau membenarkan untuk menghantar jawapan baharu!',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    'Anda akan membuka semula interaksi tertutup untuk menghantar jawapan baharu.',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE: 'Adakah anda pasti mahu menukar Susulan ini kepada Soalan?',
  ARE_YOU_SURE: 'Adakah anda pasti?',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: 'Sahkan penyahaktifan {{ USER_INFO }}',
  USER_REACTIVATION_CONFIRMATION_QUESTION: 'Sahkan pengaktifan semula {{ USER_INFO }}',
  USER_REACTIVATED_MESSAGE: 'Pengguna {{ USER_INFO }} berjaya diaktifkan semula!',
  DELETE_NOT_POSSIBLE: 'Padam tidak boleh!',
  SEARCH_NO_LONGER_EXIST: 'Carian ini tidak lagi wujud kerana ia telah dipadamkan oleh pengirim.',
  LOGIN_CREDENTIALS_CHANGED: 'Kelayakan Log Masuk Berubah!',
  EDIT_TEXT: 'Edit teks',
  FOLLOW_UP_ATTEMPTS: 'Percubaan susulan',
  FIRST_FOLLOW_UP_INFO_TEXT: 'Susulan pertama dihantar dalam interaksi individu',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    'Jika mana-mana di bawah dinyahaktifkan selepas permintaan awal, proses akan beralih ke langkah seterusnya',
  SECOND_ATTEMPT: 'Percubaan kedua (peringatan pertama)',
  THIRD_ATTEMPT: 'Percubaan ketiga (peringatan pertama)',
  FINAL_ATTEMPT: 'Hilang untuk susulan (tutup)',
  REMINDER_FREQUENCY: 'Kekerapan peringatan',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE:
    'Gunakan terjemahan automatik ke dalam bahasa yang digunakan untuk menghubungi penanya',
  ON_OFF_SWITCH: 'Hidup/Mati',
  DAY: 'Hari',
  DAYS: 'hari-hari',
  MONTH: 'bulan',
  MONTHS: 'berbulan-bulan',
  RESET_TEMPLATES_TO_DEFAULTS: 'Tetapkan semula templat kepada lalai',
  SAVE_FREQUENCY: 'Simpan kekerapan',
  SELECT_FREQUENCY: 'Pilih kekerapan',
  TEMPLATE_MODIFICATIONS_HINT:
    'Teks telah diubah suai secara manual dan tidak akan ditukar secara automatik lagi. Klik di sini untuk membuang pengubahsuaian dan mendayakan semula kemas kini automatik.',
  LOGIN_INCORRECT_EMAIL_ADDRESS: 'Alamat emel salah!',
  AUDIT_TRAIL_FOR: 'Jejak audit untuk {{ ITEM }}',
  AUDIT_TRAIL_FOR_ALL: 'Jejak audit untuk semua {{ ITEM }}',
  HISTORY_CHANGES_FOR: 'Sejarah berubah untuk',
  HOLIDAY_GDPR_DISCLAIMER: 'Penafian GDPR Cuti',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER: 'Urus penafian GDPR cuti',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS: 'Templat GDPR percutian tiada untuk pasukan berikut',
  BULK_CREATE: 'Buat pukal',
  LAST_SUCCESS: 'Kejayaan Terakhir',
  LAST_FAILURE: 'Kegagalan Terakhir',
  FAILURE: 'Kegagalan',
  TASK: 'Tugasan',
  Weeks: 'minggu',
  REPEAT_EVERY: 'Ulang pada setiap',
  DEACTIVATE_ITEM: 'Nyahaktifkan {{ ITEM }}',
  RESET_FAILURE_COUNT: 'Tetapkan Semula Kiraan Kegagalan',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Sila semak tugasan sedia ada dalam jadual di sebalik dialog kerana kami sudah mempunyai tugasan dengan nilai yang sama.',
  ACTIVATE_ITEM: 'Aktifkan {{ ITEM }}',
  PLEASE_ENTER_MIN_NUMBER: 'Sila masukkan nombor yang lebih besar daripada {{ MIN_NUMBER }}.',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      'Interaksi sasaran yang dipilih untuk gabungan sudah ditutup. Siasatan ini adalah interaksi baharu dan tidak perlu digabungkan.',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      'Soalan baru akan ditambah dalam pertanyaan sedia ada, semua soalan mesti dijawab. Sila pilih soalan untuk digabungkan.',
    VALIDATION_MSG:
      'Sila pilih sekurang-kurangnya satu soalan daripada interaksi gabungan untuk meneruskan gabungan.',
  },
  MERGED_INTERACTION: 'Interaksi gabungan',
  TARGET_INTERACTION: 'Interaksi sasaran',
  MERGE_SUGGESTION: 'Cadangan gabungan',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'Sila semak cadangan gabungan di bawah',
  INQUIRERS_COMPARISON_TITLE:
    'Sila ambil perhatian penanya yang berbeza untuk interaksi gabungan dan interaksi sasaran',
  INQUIRERS_COMPARISON_QUESTION:
    'Sila sahkan jika anda ingin meneruskan walaupun terdapat perbezaan ini.',
  TERRITORY: 'wilayah',
  DOMAIN: 'Domain',
  IN: 'dalam',
  TEAM_GROUP_FILTER: {
    TITLE: 'Tentukan kebenaran untuk melihat pasukan tambahan',
    SAVE_BTN_TOOLTIP: 'Sila masukkan semua medan wajib untuk pasukan dan simpan pasukan dahulu.',
  },
  ADR_REF_ID: 'ID Rujukan ADR',
  PQC_REF_ID: 'ID Rujukan PQC',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS: 'Lampiran ini terdapat dalam pelbagai interaksi',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'Sila pilih pilihan kotak semak jika anda ingin memadamkan lampiran daripada semua interaksi.',
  CONFIRM_DELETE_ATTACHMENT: 'Sila sahkan jika anda ingin memadamkan lampiran ini.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE:
    'Padamkan daripada semua interaksi yang dinyatakan di atas',
  ATTACHMENT_DELETED: 'Lampiran dipadamkan',
  ROLES: 'Peranan',
  SET_TO_ALL_INQ_TOOLTIP:
    'Tetapkan pencirian ADR, PQC dan Luar label yang sama untuk semua soalan.',
  TASKS_LIST: 'Senarai Tugas',
  TASK_LOGS: 'Log Tugas',
  SELECTED: 'Dipilih',
  LICENSE_COUNTER: 'Kaunter lesen',
  LICENSES_LIMIT: 'Had nombor lesen',
  USERS_TO_EXCLUDE: 'Pengguna untuk dikecualikan',
  ENTER_MAX_NUMBER_OF_LICENSES: 'Yuran lesen yang dikontrakkan (peranan pengguna).',
  SELECT_USER_ROLES_TO_COUNT: 'Pilih peranan pengguna yang berkenaan untuk kiraan yuran lesen.',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'Kecualikan pengguna daripada kiraan yuran lesen.',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'Pemberitahuan Resit dan Jualan',
  REFRESH_MIGRATION_ERRORS: 'Muat semula senarai Ralat Migrasi',
  MARKED_AS_RESOLVED_BY: 'Ditandakan sebagai diselesaikan oleh',
  MARKED_AS_RESOLVED_ON: 'Ditandai sebagai diselesaikan pada',
  MARK_AS_RESOLVED: 'Tandai sebagai diselesaikan',
  ERROR_ON: 'Ralat dihidupkan',
  TEXT_WITH_SPECIAL_CHARS:
    'Sila masukkan hanya huruf (a-z, A-Z), nombor (0-9), dan simbol khas seperti sempang (-), noktah (.), ruang, bertindih (:), koma (,), kurungan (()), ke hadapan garis miring (/), dan tambah (+).',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    'Interaksi dalam diserahkan untuk status kelulusan. Sila luluskan/tolak untuk mendayakan menu ini.',
  REFRESH_TASK_LOGS: 'Muat semula senarai Log Tugas',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE: 'Sila sahkan, jika anda ingin meneruskan perubahan!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED: 'Arkib manual tidak dibenarkan untuk peranan yang diwakili.',
  RUN_COUNTER: 'Lari kaunter',
  EXPORT_LICENSES: 'Lesen eksport',
  LICENSE_COUNT_MESSAGE: 'Bilangan lesen yang digunakan ialah:',
  QUESTIONS: 'Soalan',
  CLARIFICATION: 'Penjelasan',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG:
      'Sila pilih sekurang-kurangnya satu soalan dalam interaksi sasaran untuk meneruskan penggabungan.',
    DESCRIPTION:
      'Soalan penjelasan akan ditambah di bawah soalan yang dipilih dalam interaksi sasaran.',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION: 'Pilih pilihan ini untuk menandakan interaksi sebagai pendua interaksi sedia ada.',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    'Gabungan sebelumnya sebagai soalan penjelasan dikesan. Semua medan jawapan yang dipilih sebagai tambahan kepada medan jawapan yang dipilih sebelum ini dalam interaksi sasaran akan dibuka semula untuk jawapan.',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    'Interaksi dibuka semula untuk memberikan jawapan pada soalan lain. Jawapan untuk soalan ini telah disediakan sebelum ini.',
  TIP: 'Petua',
  MERGE_TIP_TEXT:
    'Jika anda ingin membuka semula interaksi {{ MERGED_INTO_ID }} sebaliknya, sila tekan tidak dan mulakan proses cantum baharu yang menunjukkan interaksi sasaran {{ MERGED_INTO_ID }}.',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    'Anda memilih operasi gabungan tanpa membuka interaksi sasaran.',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    'Anda sedang memilih operasi gabungan ke dalam interaksi sasaran yang tidak sah {{ TARGET_INTERACTION_ID }}.<br>Interaksi {{ TARGET_INTERACTION_ID }} telah ditutup dengan status {{ STATUS }}{{ LAST_MERGE_INFO }}.',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'Ini akan mengakibatkan interaksi tertutup dan ketidakupayaan untuk memberikan jawapan.',
  INTO_INTERACTION: 'ke dalam interaksi',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: 'Sila pilih interaksi untuk gabungan.',
  PLEASE_SELECT_MERGE_REASON: 'Sila pilih sebab gabungan.',
  QUESTION_CANNOT_BE_DELETED:
    'Soalan ini tidak boleh dipadamkan kerana terdapat soalan penjelasan yang tidak dijawab yang berkaitan.',
  MERGE_REASON: 'Gabung sebab',
  WITH_REOPEN: 'dengan dibuka semula',
  MERGED_INTO_QUESTION_IDS: 'digabungkan ke dalam ID soalan',
  MERGED_INTO_QUESTIONS: 'digabungkan menjadi soalan',
  MERGED_PREVIOUSLY_FROM: 'bergabung sebelum ini daripada',
  FROM_INTERACTION_ID: 'daripada ID interaksi',
  IN_INTERACTION_ID: 'dalam ID interaksi',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: 'Anda akan membatalkan cadangan gabungan.',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'Jangan tunjukkan cadangan gabungan lagi?',
  PRODUCT_RESOLVED_VIA_AI: 'Produk (Diselesaikan melalui AI)',
  REQUIRED_FIELDS_HINT: 'Sila isi semua medan yang diperlukan',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: 'Sekurang-kurangnya satu kenalan diperlukan!',
  DISCLAIMER_INFO: 'Maklumat penafian',
  QUESTION_ID: 'ID Soalan',
  QUESTION_IDS: 'ID Soalan',
  SHOW_TEAMS: 'Tunjukkan Pasukan',
  LAST_POLLING: 'Undian Terakhir',
  HOST: 'hos',
  PROTOCOL: 'Protokol',
  FROM_EMAIL: 'Daripada E-mel',
  REASON_FOR_REMOVAL: 'Sebab penyingkiran',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: 'Interaksi tertutup tidak boleh diedit.',
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    'Interaksi mempunyai lebih daripada satu soalan. Pengeditan boleh dilakukan daripada interaksi terbuka sahaja.',
  BULK_EDIT: 'Suntingan pukal',
  HOW_TO_ENABLE_BULK_EDIT:
    'Sekurang-kurangnya dua templat dalam bahasa yang sama mesti dipilih untuk melakukan kemas kini pukal.',
  REASON_FOR_UPDATE: 'Sebab untuk kemas kini',
  FOLLOW_UP_RESEND: 'Susulan dihantar semula',
  SENT_FOLLOW_UP_REMINDER: 'telah menghantar peringatan susulan',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'interaksi tertutup dan dihantar hilang kepada pemberitahuan susulan',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: 'Sila masukkan teks dengan panjang minimum 15 aksara.',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST:
    'Tindakan susulan dilumpuhkan! Sila masukkan penanya dahulu.',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    'Permintaan susulan dilumpuhkan! Sila masukkan e-mel penanya yang sah dahulu.',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'Nombor untuk jenis kekerapan yang dipilih hendaklah antara 1 dan {{ MAX_ALLOWED_FREQUENCY }}.',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'Sila pilih jenis kekerapan.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'Kiraan bermula dari tarikh dan masa permintaan susulan awal. Untuk hari penukaran kepada minggu darab dengan 7, mis. 2 minggu = 14 hari, untuk hari ke bulan darab dengan 30, mis. 1 bulan = 30 hari.',
  VALID_PHONE_FAX: 'Sila masukkan nombor telefon / faks yang sah!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    'Pilih bahasa untuk melihat dan mengurus templat yang diterjemahkan',
  RETRANSLATE: 'Terjemah semula',
  FOLLOW_UP_REMINDERS_INFO_TEXT:
    'Templat di bawah adalah dalam bahasa Inggeris sahaja, terjemahan diuruskan di bawah',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'Semua templat di atas akan ditetapkan semula kepada lalai (pengubahsuaian manual akan dialih keluar, tetapan semula kekerapan kepada 14 hari dan kotak pilihan terjemahan dinyahpilih), diterjemahkan semula kepada semua bahasa dan akan dikosongkan is_manually_modified.',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'Edit teks templat dan terjemah semula semua terjemahan yang tidak diubah suai secara manual.',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'Terjemah semula teks templat asal, termasuk yang diubah suai secara manual.',
  FOLLOW_UP_REMINDER_TEMPLATES: 'Templat peringatan susulan',
  MANUALLY_MODIFIED: 'Diubah suai secara manual',
  SYNC: 'Segerakkan',
  DIFFERENT: 'Berbeza',
  DEFAULT_CONFIRMATION_QUESTION: 'Sila sahkan jika anda ingin meneruskan.',
  SEND_WELCOME_MAIL_: 'Hantar e-mel alu-aluan dengan tetapan semula kata laluan',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    'Saya mengesahkan maklumat susulan baharu telah diterima oleh penanya dan saya ingin membuka semula interaksi untuk penyediaan jawapan.',
  ONLY_LETTERS_SPACE_AND_DASH: 'Sila masukkan huruf, ruang dan simbol sahaja!',
  INFO: 'info',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    'Perubahan dalam negara penanya akan menukar penugasan interaksi daripada pasukan {{ CURRENT_TEAM }} kepada pasukan {{ FUTURE_TEAM }}.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    'Perubahan dalam negara penanya akan menukar penugasan interaksi daripada pasukan {{ CURRENT_TEAM }} kepada pasukan {{ FUTURE_TEAM }}. Pengguna {{ ASSIGNED_TO }} tidak mempunyai kebenaran untuk pasukan {{ FUTURE_TEAM }} dan tugasan peribadi mereka akan dialih keluar.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'Perubahan itu akan menjejaskan tugasan pasukan dalam interaksi tambahan berikut, di mana tugasan peribadi akan dialih keluar: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'Perubahan dalam negara penanya akan menukar tugasan pasukan dalam interaksi tambahan berikut, yang mana tugasan peribadi akan dialih keluar. {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    'ID Interaksi: {{ INTERACTION_ID }} daripada {{ CURRENT_TEAM }} kepada {{ FUTURE_TEAM }}, mengalih keluar tugasan peribadi daripada pengguna {{ ASSIGNED_TO }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Pengguna {{ EDITING_USER }} menukar negara penanya {{ ENQUIRER }} dalam ID interaksi: {{ UPDATED_INTERACTION_ID }}, yang menukar tugasan interaksi semasa daripada pasukan {{ OLD_TEAM }} kepada pasukan {{ NEW_TEAM }}.',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Pengguna {{ EDITING_USER }} menukar negara penanya {{ ENQUIRER }} dalam pentadbiran HCP, yang menukar penugasan interaksi semasa daripada pasukan {{ OLD_TEAM }} kepada pasukan {{ NEW_TEAM }}.',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Pengguna {{ INTERACTION_ASSIGN_TO }} tidak mempunyai kebenaran untuk pasukan {{ NEW_TEAM }} dan tugasan peribadi mereka dialih keluar.',
  PERSONAL_ASSIGNMENT: 'Tugasan peribadi',
  DUE_TO_FU_REQUEST: 'kerana permintaan susulan',
  DUE_TO_FU_RESPONSE: 'kerana tindak balas susulan',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE:
    'disebabkan kemas kini kekerapan peringatan susulan daripada halaman pentadbir',
  UPDATED_FROM_INBOX: 'dikemas kini dari peti masuk',
  ARCHIVED_ROLE: 'Peranan yang diarkibkan',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    'Mengarkibkan peranan pengguna akan menukar tugasan dalam interaksi tambahan berikut, di mana tugasan peribadi akan dialih keluar: {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Pengguna {{ EDITING_USER }} menyunting pengguna {{ INTERACTION_ASSIGN_TO }}, mengarkibkan peranan mereka sebagai {{ USER_ROLE }} dalam pasukan {{ TEAM }}. Pengguna {{ INTERACTION_ASSIGN_TO }} tidak mempunyai kebenaran untuk pasukan {{ TEAM }} dan tugasan peribadi mereka dialih keluar.',
  ASSIGNMENT: 'Tugasan',
  PERIOD_TO_PERIOD_OVERLAP: 'Tempoh dan Ke Tempoh bertindih',
  PERIOD_TO_PERIOD_MISMATCH:
    'Tempoh dan Ke Tempoh mempunyai tempoh yang berbeza - {{ PERIOD }} {{ INTERVAL }} dan {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'mencipta soalan daripada tindak balas susulan',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    'Pilihan ini membolehkan mencipta soalan baharu daripada susulan.',
  ADD_AS_A_NEW_QUESTION: 'Tambah sebagai soalan baharu',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    'Harap maklum bahawa anda sedang cuba mengarkibkan peranan terakhir yang diluluskan untuk pengguna ini, yang akan menyebabkan penyahaktifan pengguna dan menghalang akses lanjut mereka kepada EnqMed.',
  ATTEMPTED_FOLLOW_UP: 'mencuba susulan',
  FAILED_FU_ATTEMPT: 'Percubaan susulan yang gagal',
  UNABLE_TO_REACH_ENQUIRER: 'Tidak dapat menghubungi yang bertanya.',
  FAILED_FU_ATTEMPT_TOOLTIP:
    'Pilih pilihan ini jika percubaan untuk menghubungi penanya telah dibuat tetapi tidak berjaya, mis. tidak dapat menghubungi melalui telefon, gagal F2F atau janji temu lain.\nPercubaan akan direkodkan dalam sejarah interaksi.',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS:
    'Anda akan dialihkan ke halaman yang anda pegang kebenarannya.',
  FU_STOPPED_DUE_TO_ENQ_CHANGE: 'kerana proses susulan dihentikan akibat menukar penanya',
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    'Anda akan menukar penanya, yang akan menghentikan proses susulan.',
  INQUIRER_DETAILS: 'Butiran Penanya',
  TEAM_NOTIFY_INQUIRER: 'Maklumkan Penanya',
  INQUIRER_TYPE: 'Jenis Penanya',
  DELETE_INQUIRER_PII: 'Padamkan maklumat peribadi penanya',
  MARKED_INQUIRER_PII_DELETE: 'maklumat peribadi penanya bertanda untuk dipadamkan',
  UNMARKED_INQUIRER_PII_DELETE: 'maklumat peribadi penanya yang tidak bertanda untuk dipadamkan',
  NEW_ENQUIRER: 'Penanya baru',
  NEW_ENQUIRER_TOOLTIP:
    'Gunakan pilihan ini untuk membuat penanya baharu sepenuhnya. Jika sebaliknya mengedit data penanya yang sedia ada diperlukan, jangan gunakan pilihan ini.',
  CONFIRM_SET_NEW_ENQUIRER:
    'Sila sahkan bahawa anda ingin menggantikan penanya sedia ada dengan yang baharu.<br>Semua maklumat yang dimasukkan akan hilang.<br>Sebaliknya anda mahu mengedit data penanya sedia ada, ubah suai data terus dalam borang dan tekan Simpan .',
  INQUIRER: 'Penanya',
  KEEP_INQUIRER_DETAILS_OPEN: 'Pastikan Butiran Penanya Terbuka',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'Anda mempunyai perubahan yang belum disimpan, yang akan hilang jika anda menutup butiran penanya.',
  SHARE_FROM_HERE_YOU_CAN_:
    'Kongsi: dari sini anda boleh menetapkan, memajukan atau memulakan tindakan susulan dengan penanya.',
  UPDATED_INQUIRER_DETAILS: 'butiran penanya dikemas kini',
  CREATED_INQUIRER: 'dicipta enquirer',
  SUGGESTED_INQ: 'Penanya yang Dicadangkan',
  SELECT_INQUIRER: 'Pilih Penanya',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'Persetujuan kewajipan ADR dan penanya mesti dipilih, untuk menyerahkan interaksi',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    'Jawapan belum dihantar kepada penanya kerana alamat e-mel yang salah.',
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'terima kasih! Pemberitahuan akan dihantar setelah pertanyaan diserahkan atau dijawab.',
  MSG_NOTIFICATION_WILL_BE_SEND_2:
    'terima kasih! Pemberitahuan akan dihantar setelah penanya disimpan.',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'Pemberitahuan GDPR berikut akan dihantar kepada penanya melalui e-mel.',
  NOTIFICATION_SENT_TO_THE_INQ:
    'Pemberitahuan GDPR berikut telah dihantar kepada penanya melalui e-mel.',
  SEND_A_NOTIFICATION_TO_THE_INQ: 'Hantar pemberitahuan GDPR berikut kepada penanya melalui e-mel.',
  INQ_HAS_NO_EMAIL: 'penanya tidak mempunyai e-mel.',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    'Pemberitahuan BELUM dihantar kepada penanya bahawa siasatan telah diterima.',
  NOTIFICATION_SENT_TO_THE_INQUIRER:
    'Pemberitahuan dihantar kepada penanya bahawa siasatan telah diterima.',
  THIS_IS_JUST_A_THANK_YOU:
    'Ini hanyalah ucapan terima kasih daripada penanya bagi pertanyaan yang sedia ada.',
  SAVE_INQUIRER: 'Simpan Enquirer',
  INQ_DEFAULT_VAL: 'Nilai lalai penanya',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    'Untuk pertanyaan yang dikemukakan melalui wakil syarikat (wakil jualan, MSL, atau lain-lain), maklumkan penanya tentang pengendalian data peribadi mereka.',
  SELECT_PREFERRED_CONTACT_ERROR: 'Anda mesti memilih kenalan pilihan untuk penanya.',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'Tindakan susulan dimulakan oleh penanya',
  NO_INQUIRER: 'Tiada penanya',
  'Changed inquirer': 'Penanya berubah',
  DELETE_INQUIRY_MESSAGE:
    'Anda akan memadamkan pertanyaan ini. Tindakan ini tidak boleh diterbalikkan.',
  QUESTION_REQUIRE_ANSWER_MESSAGE:
    'Soalan baharu akan memerlukan jawapan untuk menutup pertanyaan.',
  IMAGE: 'Imej',
  THERAPEUTIC_AREA_STATUS_MESSAGE: 'Kawasan Terapeutik ialah {{ STATUS }}',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    ', kerana tiada produk yang berkaitan. Sila pilih sekurang-kurangnya satu produk untuk menetapkan kawasan terapeutik kepada aktif.',
  EXPAND_ALL_THERAPEUTIC_AREAS: 'Kembangkan semua kawasan terapeutik',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: 'Runtuhkan semua kawasan terapeutik',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    'Untuk menetapkan Kawasan Terapeutik (TA) sebagai TA utama, nyahpilih TA induk dan simpan.',
  EXTRA_STEP_REQUIRED_TO_LOGIN: 'Langkah tambahan diperlukan untuk log masuk',
  EXTRA_STEP_DESCRIPTION:
    'Pengesahan log masuk telah dihantar ke e-mel anda. Sila, ikuti langkah di dalamnya, untuk meneruskan ke sistem.',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT:
    'Sila masukkan teks dengan panjang minimum 15 aksara atau lampiran.',
  REASON_FOR_REJECTION: 'Sebab penolakan',
  NOTE_PROPERLY_INSERTED_IN_HISTORY: 'Nota telah dimasukkan dengan betul dalam sejarah.',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    'Oleh {{ ACTION_TYPE }} {{ METADATA_TYPE }} yang dipilih, perubahan berkaitan tambahan berikut akan digunakan:',
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    'Dengan mengalih keluar produk, perubahan berkaitan tambahan berikut akan dikenakan:',
};
