import { MedisNavigationItem, newGroup, newItem } from '../types';
import { userRoles as roles } from '../consts';
import { filterNavigationItemsByRoles } from './profile-navigation';

const authRequestNav: MedisNavigationItem[] = [
  newGroup(
    'All_teams',
    'ALL_TEAMS',
    '',
    [
      newItem(
        'ALL_REQUESTS_ALL_TEAMS',
        'ALL_REQUESTS_ALL_TEAMS',
        '',
        '/administration/auth-requests',
        false,
        'all_teams',
        undefined,
        { reqType: 'all' }
      ),
    ],
    [...roles.admin]
  ),
  newGroup(
    'USER_REQUESTS',
    'USER_REQUESTS',
    undefined,
    [
      newItem(
        'OPEN_REQUESTS',
        'OPEN_REQUESTS',
        '',
        '/administration/auth-requests/',
        false,
        'my_team',
        undefined,
        { reqType: 'user', status: 'open' }
      ),
      newItem(
        'APPROVED_REQUESTS',
        'APPROVED_REQUESTS',
        'fa-user-friends',
        '/administration/auth-requests/',
        null,
        null,
        undefined,
        { reqType: 'user', status: 'approved' }
      ),
      newItem(
        'REJECTED_REQUESTS',
        'REJECTED_REQUESTS',
        'fa-reply',
        '/administration/auth-requests/',
        null,
        null,
        undefined,
        { reqType: 'user', status: 'rejected' }
      ),
    ],
    [...roles.mim, ...roles.admin]
  ),
  newGroup(
    'CATEGORY_TOPIC_REQUESTS',
    'CATEGORY_TOPIC_REQUESTS',
    undefined,
    [
      newItem(
        'MY_TEAM_OPEN_REQUESTS',
        'MY_TEAM_OPEN_REQUESTS',
        '',
        '/administration/auth-requests/',
        false,
        'my_team',
        undefined,
        { reqType: 'auth', status: 'open' }
      ),
      newItem(
        'MY_TEAM_APPROVED_REQUESTS',
        'MY_TEAM_APPROVED_REQUESTS',
        'fa-user-friends',
        '/administration/auth-requests/',
        null,
        null,
        undefined,
        { reqType: 'auth', status: 'approved' }
      ),
      newItem(
        'MY_TEAM_REJECTED_REQUESTS',
        'MY_TEAM_REJECTED_REQUESTS',
        'fa-reply',
        '/administration/auth-requests/',
        null,
        null,
        undefined,
        { reqType: 'auth', status: 'rejected' }
      ),
    ],
    [...roles.euMim, ...roles.admin]
  ),
];

export function authRequestNavigation(userRoles: string[]): MedisNavigationItem[] {
  return filterNavigationItemsByRoles(authRequestNav, userRoles);
}
