import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NewInteractionService } from '../data-model/new-interaction/new-interaction.service';
import { BNewInteraction } from '../data-model/new-interaction/new-interaction';
import { InteractionsService } from 'app/modules/data-model/interaction/interactions.service';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-my-drafts',
  templateUrl: './my-drafts.component.html',
  styleUrls: ['./my-drafts.component.scss'],
})
export class MyDraftsComponent implements OnInit, OnDestroy {
  dataSource: BNewInteraction[];
  displayedColumns = ['id', 'created_ts'];
  private subs: Subscription = new Subscription();

  constructor(
    private newInteractionService: NewInteractionService,
    private authService: AuthService,
    private interactionsService: InteractionsService
  ) {}

  ngOnInit() {
    this.subs.add(
      this.newInteractionService.values.subscribe((values) => {
        values.sort((a, b) => {
          let g = new Date(a.createdTs) > new Date(b.createdTs);
          if (g) return -1;
          else return 1;
        });
        this.dataSource = values;
      })
    );
    this.subs.add(
      this.authService.user.subscribe((user) => {
        if (user != null) {
          this.newInteractionService.user.next(user);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  deleteInteraction(interaction: BNewInteraction) {
    this.interactionsService.deleteDraft(interaction.pk()).subscribe(() => {
      this.newInteractionService.performQuery();
    });
  }
}
