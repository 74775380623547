import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { Helpers } from '@mi-tool/utils/helpers';
import { GenericDialogComponent } from './generic-dialog.component';
import { environment } from 'app/../environments/environment';
import { capitalize } from 'lodash';
import { BInteraction } from 'app/modules/data-model/interaction/interaction';

@Injectable()
export class GenericDialogService {
  constructor(private dialog: MatDialog, private helpers: Helpers) {}

  openConfirmUserDeactivation(userDisplayInfo: string): GenericDialogComponent.Builder {
    return new GenericDialogComponent.Builder(this.dialog)
      .title('USER_DEACTIVATION_CONFIRMATION_QUESTION', { USER_INFO: userDisplayInfo })
      .message('DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE')
      .cancelLabel('CANCEL')
      .saveLabel('DEACTIVATE')
      .closeOnButton();
  }

  openConfirmUserDeactivationWithRemoteOpt(userDisplayInfo: string): Subject<boolean> {
    return this.openConfirmUserDeactivation(userDisplayInfo)
      .checkboxLabel(this.resolveCheckboxLabelRemoteOpt('DEACTIVATE', 'ON'))
      .getSaveSubjectWithCheckboxVal();
  }

  openConfirmUserActivation(userDisplayInfo: string): GenericDialogComponent.Builder {
    return new GenericDialogComponent.Builder(this.dialog)
      .title('USER_REACTIVATION_CONFIRMATION_QUESTION', { USER_INFO: userDisplayInfo })
      .message('ENABLE_USER_LOGIN_MESSAGE')
      .additionalMessage('SET_NOTIFICATIONS_PREFERENCES_MESSAGE')
      .cancelLabel('CANCEL')
      .saveLabel('ACTIVATE')
      .closeOnButton();
  }

  openConfirmUserActivationWithRemoteOpt(userDisplayInfo: string): Subject<boolean> {
    return this.openConfirmUserActivation(userDisplayInfo)
      .checkboxLabel(this.resolveCheckboxLabelRemoteOpt('ACTIVATE', 'ON'))
      .getSaveSubjectWithCheckboxVal();
  }

  openMsgUserActivation(userDisplayInfo: string, saveLbl: string = 'GO_TO_ROLES'): Subject<void> {
    return new GenericDialogComponent.Builder(this.dialog, { disableClose: true })
      .title('USER_REACTIVATED_MESSAGE', { USER_INFO: userDisplayInfo })
      .message('ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE')
      .additionalMessage('USER_MUST_RECOVER_PASSWORD_MESSAGE')
      .saveLabel(saveLbl)
      .closeOnButton()
      .getSaveSubject();
  }

  openRoleArchiveConfirmation(
    deactivate: boolean,
    userAssignedInteractionsValues: string = ''
  ): GenericDialogComponent.Builder {
    return new GenericDialogComponent.Builder(this.dialog, {
      autoFocus: 'dialog',
    })
      .title('ARCHIVE_ROLE')
      .message(deactivate ? 'ROLE_ARCHIVE_WITH_USER_DEACTIVATION' : 'ROLE_ARCHIVE_CONFIRMATION')
      .additionalMessage(
        userAssignedInteractionsValues.length > 0
          ? 'USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA'
          : '',
        {
          USER_INTERACTIONS: userAssignedInteractionsValues,
        }
      )
      .confirmationQuestion('DO_YOU_WANT_TO_PROCEED')
      .cancelLabel('NO')
      .saveLabel('YES')
      .closeOnButton();
  }

  openRoleArchiveConfirmationWithRemoteOpt(
    deactivate: boolean,
    userAssignedInteractions: number[]
  ): Subject<boolean> {
    const userAssignedInteractionsValues = userAssignedInteractions
      .map((interactionId) => `<b>${interactionId}</b>`)
      .join(', ');

    return this.openRoleArchiveConfirmation(deactivate, userAssignedInteractionsValues)
      .checkboxLabel(this.resolveCheckboxLabelRemoteOpt('ARCHIVE_ROLE', 'ON'))
      .getSaveSubjectWithCheckboxVal();
  }

  openCantArchiveRoleWarning(): void {
    new GenericDialogComponent.Builder(this.dialog, { autoFocus: 'dialog' })
      .title('ARCHIVE_ROLE')
      .message('ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE')
      .saveLabel('CLOSE')
      .closeOnButton();
  }

  loginCredentialsChanged(oldUsername: string, newUsername: string): void {
    const changes = { OLD_USERNAME: oldUsername, NEW_USERNAME: newUsername };
    new GenericDialogComponent.Builder(this.dialog)
      .title('LOGIN_CREDENTIALS_CHANGED')
      .message('CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE', changes)
      .saveLabel('CLOSE')
      .closeOnButton();
  }

  alertUserForNewImportedInquiries(): void {
    new GenericDialogComponent.Builder(this.dialog)
      .title('WARNING')
      .message('NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE')
      .saveLabel('DONE')
      .closeOnButton();
  }

  showMaxDownloadsError(): void {
    new GenericDialogComponent.Builder(this.dialog, { height: 'fit-content' })
      .title('DOWNLOAD_ERROR')
      .message('DOWNLOAD_INQUIRIES_ERROR_MESSAGE')
      .saveLabel('CANCEL')
      .closeOnButton();
  }

  openDialogError(title: string, error: any): void {
    GenericDialogComponent.showMessage(this.dialog, error, title);
  }

  showMessage(message: string, title: string = null): void {
    GenericDialogComponent.showMessage(this.dialog, message, title);
  }

  applyPendingChangesInformationDialog(interaction: BInteraction): Subject<void> {
    const pendingChanges = JSON.parse(interaction.pendingChanges);
    let message: string;
    let additionalMessage: string = '';
    let messageParams: { [key: string]: string };
    let additionalMessageParams: { [key: string]: string } = {};

    if (pendingChanges['interaction_team_values']) {
      message = pendingChanges['edited_interaction_id']
        ? 'INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS'
        : 'INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS';
      messageParams = {
        ENQUIRER: `<b>${interaction?.inquirer.fullName()}</b>`,
        EDITING_USER: `<b>${pendingChanges['editing_user']['fullname']}</b>`,
        UPDATED_INTERACTION_ID: `<b>${pendingChanges['edited_interaction_id']}</b>`,
        OLD_TEAM: `<b>${pendingChanges['interaction_team_values']['old_team_name']}</b>`,
        NEW_TEAM: `<b>${pendingChanges['interaction_team_values']['new_team_name']}</b>`,
      };
      additionalMessage = 'INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS';
      additionalMessageParams = {
        INTERACTION_ASSIGN_TO: `<b>${pendingChanges['interaction_assigned_to']}</b>`,
        NEW_TEAM: `<b>${pendingChanges['interaction_team_values']['new_team_name']}</b>`,
      };
    } else {
      message = 'USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS';
      messageParams = {
        EDITING_USER: `<b>${pendingChanges['editing_user']['fullname']}</b>`,
        INTERACTION_ASSIGN_TO: `<b>${pendingChanges['interaction_assigned_to']}</b>`,
        USER_ROLE: `<b>${pendingChanges['interaction_assigned_to_role']}</b>`,
        TEAM: `<b>${pendingChanges['interaction_team']}</b>`,
      };
    }

    return new GenericDialogComponent.Builder(this.dialog, {
      disableClose: true,
      autoFocus: 'dialog',
    })
      .title('WARNING')
      .message(message, messageParams)
      .additionalMessage(
        additionalMessage.length > 0 ? additionalMessage : '',
        additionalMessageParams
      )
      .confirmationQuestion('REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS')
      .saveLabel('CLOSE')
      .closeOnButton()
      .getSaveSubject();
  }

  confirmInquirerCountryChange(
    message: string,
    messageParams: { [p: string]: string } | null,
    additionalMessage: string | null,
    additionalMessageParams: { [p: string]: string } | null
  ): MatDialogRef<GenericDialogComponent> {
    return new GenericDialogComponent.Builder(this.dialog, {
      disableClose: true,
      autoFocus: 'dialog',
    })
      .title('WARNING')
      .message(message, messageParams)
      .additionalMessage(additionalMessage, additionalMessageParams)
      .saveLabel('SAVE')
      .cancelLabel('CANCEL')
      .closeOnButton()
      .getRef();
  }

  confirmDeleteAttachment(interactionIds: number[]): Subject<boolean> {
    const builder = new GenericDialogComponent.Builder(this.dialog)
      .cancelLabel('CANCEL')
      .saveLabel('CONFIRM');
    const ref = builder.getRef();
    const translateService = ref.componentInstance.translateService;

    if (interactionIds.length > 1) {
      ref.componentInstance.message = `${translateService.instant(
        'ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS'
      )}: ${this.helpers.arrayOfItemsToText(
        interactionIds.map((id) => id.toString())
      )}<br>${translateService.instant(
        'SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS'
      )}`;

      builder.checkboxLabel('DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE');
    } else {
      builder.message('CONFIRM_DELETE_ATTACHMENT');
    }
    return builder.closeOnButton().getSaveSubjectWithCheckboxVal();
  }

  openSetNewPassword(): Subject<void> {
    return new GenericDialogComponent.Builder(this.dialog, {
      disableClose: true,
      autoFocus: 'dialog',
    })
      .message('ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION')
      .saveLabel('SET_PASSWORD')
      .closeOnButton()
      .getSaveSubject();
  }

  openVerifyEmailAddress(): void {
    new GenericDialogComponent.Builder(this.dialog, { autoFocus: 'dialog' })
      .title('LOGIN_INCORRECT_EMAIL_ADDRESS')
      .message('LOGIN_VERIFY_EMAIL_ADDRESS')
      .saveLabel('CLOSE')
      .closeOnButton();
  }

  openLoginConfirmation(): void {
    new GenericDialogComponent.Builder(this.dialog, { autoFocus: 'dialog' })
      .title('EXTRA_STEP_REQUIRED_TO_LOGIN')
      .message('EXTRA_STEP_DESCRIPTION')
      .saveLabel('CLOSE')
      .closeOnButton();
  }

  private resolveCheckboxLabelRemoteOpt(...parts: string[]): string {
    const labelParts = this.helpers.translateMany(parts);
    return [...labelParts, capitalize(environment.syncRemoteName)].join(' ');
  }
}
