import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-paginated-navbar',
  templateUrl: './pagination-wrapper.component.html',
})
export class PaginationWrapperComponent {
  @Input() showFirstLastButtons = true;
  @Input() pagesCount = 0;
  @Input() pageSize = 0;
  @Input() pageIndex = 0;
  @Input() pageSizeOptions = [10, 25, 50, 100];

  @Output() pageChange = new EventEmitter<PageEvent>();

  onPageChange(event: PageEvent) {
    this.pageChange.emit(event);
  }
}
