import { Injectable } from '@angular/core';

import { InquiriesService } from 'app/modules/inquiries/services/inquiries.service';
import { createGuid } from './uuid-generator';

@Injectable()
export class StorageService {
  setRememberMe(value: boolean): void {
    localStorage.setItem('rememberMe', value + '');
  }

  getRememberMe(): boolean {
    return localStorage.getItem('rememberMe') === 'true';
  }

  getDeviceId(): string {
    let deviceId = localStorage.getItem(StorageService.Local.DEVICE_ID);
    if (!deviceId) {
      localStorage.setItem(StorageService.Local.DEVICE_ID, (deviceId = createGuid()));
    }
    return deviceId;
  }

  setToken(value: string): void {
    const storage = this.getRememberMe() ? localStorage : sessionStorage;
    storage.setItem('token', value);
  }

  getToken(): string {
    const storage = this.getRememberMe() ? localStorage : sessionStorage;
    return storage.getItem('token');
  }

  removeToken(): void {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
  }

  setLocal(name: StorageService.Local, value: any): void {
    localStorage.setItem(name, JSON.stringify(value));
  }

  getLocal<T>(name: StorageService.Local, defaultValue: T): T {
    const val = localStorage.getItem(name);
    return val ? JSON.parse(val) : defaultValue;
  }

  getLastInquiryResultUrl(): string {
    const myTeamsOpenUrl = InquiriesService.LEFT_MENU_URLS.TEAM_OPEN;
    const url = this.getLocal(StorageService.Local.INQUIRY_RESULT_LAST_URL, myTeamsOpenUrl);
    return InquiriesService.ALL_LEFT_MENU_MODES.hasOwnProperty(url) ? url : myTeamsOpenUrl;
  }
}

export namespace StorageService {
  export enum Local {
    INQUIRY_RESULT_LAST_URL = 'INQUIRY_RESULT_LAST_URL',
    ADVANCED_SEARCH = 'advancedSearch',
    DEVICE_ID = 'DEVICE_ID',
  }
}
