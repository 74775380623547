import { gql } from 'apollo-angular';

import { BaseModel, rProperty } from '../data-model';

export class BTerritory extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() importedId: string;
  @rProperty() importedSource: string;
  @rProperty(Date) importedTs: string;

  constructor(json: any) {
    super(json);

    this.init(json);
  }

  init(json: any) {}

  static fromRest(json: any): BTerritory {
    return Object.assign(new BTerritory({}), json);
  }

  static fromRestArray(json: any[]): BTerritory[] {
    return json && json.map((v) => BTerritory.fromRest(v));
  }
}

export const fragment = gql`
  fragment territoryFragment on TerritoryNode {
    id
  }
`;

export const fragmentConnection = gql`
  fragment territoryConnectionFragment on TerritoryNodeConnection {
    edges {
      node {
        ...territoryFragment
      }
    }
  }
  ${fragment}
`;
