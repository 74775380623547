import { gql } from 'apollo-angular';
import { Injectable, Injector } from '@angular/core';

import * as MMedicalAudience from './medical-document-audience';
import { BaseDataService } from '../data-service';

const getAllMedicalAudience = gql`
  query getAllMedicalAudience {
    allMedicalAudience {
      ...medicalDocumentAudienceConnectionFragment
    }
  }
  ${MMedicalAudience.fragmentConnection}
`;

@Injectable()
export class MedicalDocumentAudienceService extends BaseDataService<MMedicalAudience.BMedicalDocumentAudience> {
  query = getAllMedicalAudience;
  objectInstance = new MMedicalAudience.BMedicalDocumentAudience({});
  fieldName = 'allMedicalAudience';

  constructor(injector: Injector) {
    super(injector);
  }
}
