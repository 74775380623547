import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { SimplePage } from 'app/common/types';
import { Ajax } from 'app/common/ajax';
import { map } from 'rxjs/operators';
import { BSpamMessage } from './spam-message';
import { isNil, omitBy } from 'lodash';

export type TSpamParams = {
  pageIndex: number;
  pageSize: number;
  orderBy?: string;
  id?: number;
  messageSubject?: string;
  messageFromHeader?: string;
  teamIds?: string;
  createdTs?: string;
};

@Injectable({
  providedIn: 'root',
})
export class SpamMessagesService {
  private readonly URL: string;
  private readonly URL_REPOSITORY: string;

  constructor(private http: HttpClient, urlResolver: UrlResolverService) {
    this.URL = urlResolver.misApiUrlForPath('/spam');
    this.URL_REPOSITORY = urlResolver.misApiUrlForPath('/spam/repository');
  }

  fetchSpamMessages(params: TSpamParams): Observable<SimplePage<BSpamMessage>> {
    return this.http
      .get<SimplePage<BSpamMessage>>(this.URL_REPOSITORY, {
        ...Ajax.X_NO_LOADING_OPTIONS,
        params: omitBy(params, isNil),
      })
      .pipe(
        map((response) => {
          return { ...response, records: BSpamMessage.fromRestArray(response.records) };
        })
      );
  }

  fetchSpamMessage(spamMessageId: number): Observable<BSpamMessage> {
    return this.http.get<BSpamMessage>(this.URL + '/' + spamMessageId).pipe(
      map((spamMessage) => {
        return BSpamMessage.fromRest(spamMessage);
      })
    );
  }

  unspamSpamMessage(spamMessageId: number): Observable<void> {
    return this.http.post<void>(this.URL + '/' + spamMessageId, {});
  }

  archiveSpamMessage(spamMessageId: number): Observable<void> {
    return this.http.delete<void>(this.URL + '/' + spamMessageId);
  }
}
