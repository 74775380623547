import { BaseModel, rProperty } from '../data-model';
import { PERIOD_LABELS_MAP } from '@mi-tool/consts';

export class BTask extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() module: string;
  @rProperty() function: string;
  @rProperty() every: string;
  @rProperty() period: string;
  @rProperty() lastRun: string;
  @rProperty() lastSuccess: string;
  @rProperty() lastFailure: string;
  @rProperty() failureCount: string;
  @rProperty() active: boolean;
  periodLabel: string;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {}

  static fromRest(json: any): BTask {
    if (json.period) {
      json.periodLabel =
        json.period in PERIOD_LABELS_MAP
          ? PERIOD_LABELS_MAP[json.period]
          : `Missing label for period "${json.period}"`;
    }
    return Object.assign(new BTask({}), json);
  }

  static fromRestArray(json: any[]): BTask[] {
    return json && json.map((v) => BTask.fromRest(v));
  }
}
